import React, { useState, useEffect, useContext } from 'react'
import './ClientEventRequestBookPopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { CardElement, useElements, useStripe, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import Colors from '../../constants/Colors';
import { createPaymentIntentRequest, bookEventRequestForClientRequest, bookEventRequestForClientWithoutPaymentRequest } from '../../webservices/Webservice';
import { LoaderContext } from '../../contexts/LoaderContext';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { useNotification } from '../../contexts/NotificationContext';
import KalabEventRequestDetailElement from '../kalab/kalabEventRequestElements/KalabEventRequestDetailElement';
import { isExceedingMaxDays } from '../../utils/Date';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import PaymentMethods from '../../constants/PaymentMethods';
import { openInNewTab } from '../../utils/common';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function ClientEventRequestBookPopUp({
    closeFn,
    eventRequest
}) {
    const { height, width } = useWindowDimensions();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const stripe = useStripe();
    const elements = useElements();
    const [cardNumber, setCardNumber] = useState(null);
    const [cardExpiry, setCardExpiry] = useState(null);
    const [cardCVC, setCardCVC] = useState(null);
    const [showClientCancellationTerms, setShowClientCancellationTerms] = useState(false)
    const [showArtistCancellationTerms, setShowArtistCancellationTerms] = useState(false)
    const { settings } = useContext(DataStoreContext);
    const [modeOfPayment, setModeOfPayment] = useState(null);
    const [possibleModesOfPayment, setPossibleModesOfPayment] = useState([]);
    const [showBookingDetails, setShowBookingDetails] = useState(false);

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }

    const getServiceFeeForBookingLink = () => {
        var serviceFeeForBookingLink = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeeForBookingLink') {
                serviceFeeForBookingLink = parseFloat(setting.settingValue);
            }
        })
        return serviceFeeForBookingLink
    }

    const getPriceDetails = () => {
        var price = parseFloat(eventRequest.artistPrice);
        var serviceFeePercentage = eventRequest.createdFromLink ? 0 :  getServiceFeePercentage();
        var serviceFee = eventRequest.createdFromLink ? getServiceFeeForBookingLink() : price * serviceFeePercentage / 100;
        var totalPrice = price + serviceFee;


        return {
            isPriceUpdated: eventRequest.isUpdated && (eventRequest.lastUpdatedColumns.includes('artistPrice') || eventRequest.lastUpdatedColumns.includes('currency')),
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }
    const handleOkClick = () => {
        if (modeOfPayment === 'STRIPE_CARD') {
            showLoader()
            createPaymentIntentRequest(
                eventRequest.eventRequestId,
                getPriceDetails().totalPrice,
                eventRequest.currency,
                ({ clientSecret }) => {
                    console.log(clientSecret);
                    console.log(elements.getElement(CardElement));
                    stripe.confirmCardPayment(
                        clientSecret,
                        {
                            payment_method: {
                                card: elements.getElement(CardNumberElement)
                            }
                        }
                    )
                        .then(({ error, paymentIntent }) => {
                            if (error) {
                                console.log(error)
                                notify({
                                    type: "ERROR",
                                    message: error.message ? error.message : error,
                                    title: error.title ? error.title : "Error!!!"
                                })
                                hideLoader();
                            }
                            else {
                                console.log(paymentIntent);
                                bookEventRequestForClientRequest(
                                    eventRequest.eventRequestId,
                                    paymentIntent.id,
                                    paymentIntent.payment_method,
                                    (message) => {
                                        console.log(message)
                                        notify({
                                            type: "SUCCESS",
                                            message: message,
                                            title: "Success!!!"
                                        })
                                        hideLoader();
                                        closeFn();
                                    },
                                    (error) => {
                                        console.log(error)
                                        notify({
                                            type: "ERROR",
                                            message: error.message ? error.message : error,
                                            title: error.title ? error.title : "Error!!!"
                                        })
                                        hideLoader();
                                    }
                                )
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            notify({
                                type: "ERROR",
                                message: error.message ? error.message : error,
                                title: error.title ? error.title : "Error!!!"
                            })
                            hideLoader();
                        })
                },
                (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                    if (error.code && error.code === "PRICE_CHANGE") {
                        closeFn();
                    }

                }

            )
        }
        else if (modeOfPayment === 'DIRECT') {
            showLoader();
            bookEventRequestForClientWithoutPaymentRequest(
                eventRequest.eventRequestId,
                (message) => {
                    console.log(message)
                    notify({
                        type: "SUCCESS",
                        message: message,
                        title: "Success!!!"
                    })
                    hideLoader();
                    closeFn();
                },
                (error) => {
                    console.log(error)
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                }
            )
        }
        else {

        }

    }

    const handleClickOnlink = (link) => {
        openInNewTab(window.location.origin + '/' + link);
    }

    const isFormValid = () => {
        if (
            (
                modeOfPayment === 'STRIPE_CARD' &&
                stripe &&
                elements &&
                cardNumber &&
                cardNumber.complete &&
                cardExpiry &&
                cardExpiry.complete &&
                cardCVC &&
                cardCVC.complete
            ) ||
            modeOfPayment === 'DIRECT'
        ) {
            return true
        }
        else {
            return false
        }
    }

    const findModeOfPayment = () => {
        if (isExceedingMaxDays(eventRequest.date)) {
            if (eventRequest.clientPaymentCapabilities.includes('DIRECT')) {
                setModeOfPayment('DIRECT');
                setPossibleModesOfPayment(['DIRECT']);
            }
            else {
                setModeOfPayment(null);
                setPossibleModesOfPayment([]);
            }
        }
        else if (eventRequest.isResidency) {
            if (eventRequest.clientPaymentCapabilities.includes('DIRECT')) {
                setModeOfPayment('DIRECT');
                setPossibleModesOfPayment(['DIRECT']);
            }
            else {
                setModeOfPayment(null);
                setPossibleModesOfPayment([]);
            }
        }
        else {
            if (eventRequest.clientPaymentCapabilities.length > 1) {
                setModeOfPayment(null)
                setPossibleModesOfPayment(eventRequest.clientPaymentCapabilities)
            }
            else if (eventRequest.clientPaymentCapabilities.length === 1) {
                setModeOfPayment(eventRequest.clientPaymentCapabilities[0])
                setPossibleModesOfPayment(eventRequest.clientPaymentCapabilities)
            }
            else {
                setModeOfPayment(null);
                setPossibleModesOfPayment([]);
            }
        }
    }

    const getPaymentModeOptions = () => {
        return (
            possibleModesOfPayment.map(possibleModeOfPayment => ({
                value: possibleModeOfPayment,
                label: PaymentMethods[possibleModeOfPayment].text
            }))
        )
    }

    const handleChangeOfPaymentModeSelect = (value) => {
        setModeOfPayment(value ? value.value : null)
    }

    const getValuePaymentMode = () => {
        if (modeOfPayment === null) {
            return null
        }
        else {
            var paymentModes = getPaymentModeOptions().filter(option => option.value === modeOfPayment);
            if (paymentModes.length > 0) {
                return paymentModes[0];
            }
            else {
                return null;
            }
        }
    }

    useEffect(() => {
        if (eventRequest) {
            findModeOfPayment();
        }
    }, [eventRequest])





    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='client-event-request-book-popup-modal'>
                <div className='client-event-request-book-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='client-event-request-book-popup-modal__content--top'>
                        <div className='client-event-request-book-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                    </div>
                    <div className='client-event-request-book-popup-modal__content--main'>

                        <div className='right-side'>
                            <div className='payment-terms'>
                                <div className='payment-terms__title'>
                                    Payment Terms
                                </div>
                                <div className='payment-terms__content'>
                                    <div className='payment-terms__content--main'>
                                        By booking the event “{eventRequest.eventName}” you agree to our <span className='link' onClick={() => { handleClickOnlink('terms-of-service') }}>Terms & Conditions</span> including the following terms :
                                    </div>
                                    <div className='payment-terms__content--terms'>
                                        <div className='terms-heading'>
                                            <div className='terms-heading__text'>
                                                Artist Cancellation
                                            </div>
                                            <KalabIconButton
                                                iconName={showArtistCancellationTerms ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}
                                                noBorder
                                                onClick={() => {
                                                    setShowArtistCancellationTerms(!showArtistCancellationTerms)
                                                }}
                                                iconSize={18}
                                            />
                                        </div>
                                        <div className={showArtistCancellationTerms ? 'show-terms-animation' : 'hide-terms-animation'}>
                                            {
                                                showArtistCancellationTerms ?
                                                    (
                                                        <>
                                                            <div className='terms-points'>
                                                                Artist cancellations at any time will result in zero remuneration for the Artist. The Client will receive a full refund (Total Fees excluding stripe processing fee). KALAB will provide the Client with alternative Artist recommendations to the best of their ability but does not promise a replacement.
                                                            </div>
                                                            <div className='terms-points'>
                                                                In the case of recurring Artist cancellations, the participation of the Artist on the KALAB platform will be at jeopardy and will be re-evaluated by KALAB.
                                                            </div>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>


                                    </div>
                                    <div className='payment-terms__content--terms'>
                                        <div className='terms-heading'>
                                            <div className='terms-heading__text'>
                                                Client Cancellation
                                            </div>
                                            <KalabIconButton
                                                iconName={showClientCancellationTerms ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}
                                                noBorder
                                                iconSize={18}
                                                onClick={() => {
                                                    setShowClientCancellationTerms(!showClientCancellationTerms)
                                                }}
                                            />
                                        </div>
                                        <div className={showClientCancellationTerms ? 'show-terms-animation' : 'hide-terms-animation'}>
                                            {
                                                showClientCancellationTerms ?
                                                    (
                                                        <>
                                                            <div className='terms-points'>
                                                                Clients may make a cancellation up to 10 days prior to the event and receive a full-refund of the Artist Fee. However, the Service Fee will not be refunded.
                                                            </div>
                                                            <div className='terms-points'>
                                                                Client cancellations less than 10 days prior to the event will only result in a 50% refund of the Artist Fee and the Artist will keep the remaining 50% of the Artist Fee as a compensation. The Service Fees will not be refunded to the Client.
                                                            </div>
                                                            <div className='terms-points'>
                                                                If the Artist has already left for/arrived at the site and the event is cancelled last minute, the client must cover the transportation cost in addition to the 100% Artist Fee and the Service Fee.
                                                            </div>
                                                            <div className='terms-points'>
                                                                In the case of cancellations resulting from extenuating circumstances the terms above do not apply. In the following circumstances the client will receive a full refund of the Total Fee: i.Serious Illness of the client. To be confirmed (in writing) by a registered medical practitioner. ii.Unexpected death or serious illness to immediate family (grandparents, parents, spouse/ partner, legal guardian, siblings, and children). iii.Significant natural disasters, severe weather, pandemics that inhibits travel to or from the location of the event and jeopardizes the safety of the Client and Artist.
                                                            </div>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </div>


                                    </div>
                                    {
                                        modeOfPayment === 'STRIPE_CARD' ?
                                            (
                                                <div className='payment-terms__content--italic'>
                                                    The client will make a payment at the time of booking. The payment will be stored with our third-party payment provider, Stripe and don't worry it will only be released to the artist’s account a few days after the successful completion of the event.
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }


                                </div>

                            </div>
                            {possibleModesOfPayment.length > 1 ?
                                (
                                    <div className='payment-mode'>
                                        <div className='payment_mode__detail'>
                                            <KalabDropDown
                                                placeholder="Payment Mode"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="paymentMode"
                                                value={getValuePaymentMode()}
                                                onChange={handleChangeOfPaymentModeSelect}
                                                options={getPaymentModeOptions()}
                                            />
                                        </div>

                                    </div>
                                )
                                :
                                (
                                    possibleModesOfPayment.length === 0 ?
                                        (
                                            <div className='error'>
                                                You are not authorised to make this booking
                                            </div>
                                        ) :
                                        (
                                            null
                                        )
                                )

                            }

                            {modeOfPayment === 'STRIPE_CARD' ?
                                (
                                    <div className='card' >
                                        <div className='card__title'>Card Details</div>
                                        <div className='card__detail'>
                                            <div className='card__detail--row' style={{ borderColor: isFormValid() ? Colors.primary : Colors.lightGrey }}>
                                                <div className='card__detail--row__element' style={{ borderColor: isFormValid() ? Colors.primary : Colors.lightGrey }}>
                                                    <CardNumberElement
                                                        options={{
                                                            placeholder: 'Card Number',
                                                            showIcon: true,
                                                            style: {
                                                                base: {
                                                                    fontSize: '15px',
                                                                    fontWeight: '300',
                                                                    fontFamily: 'Montserrat, sans-serif',
                                                                    color: Colors.white,
                                                                    "::placeholder": {
                                                                        color: Colors.lightGrey
                                                                    }
                                                                },
                                                                invalid: {
                                                                    color: Colors.darkRed
                                                                }
                                                            }
                                                        }}
                                                        onReady={() => {
                                                            console.log("CardNumber [ready]");
                                                        }}
                                                        onChange={cardDetails => {
                                                            console.log("CardNumber [change]", cardDetails);
                                                            setCardNumber(cardDetails)
                                                        }}
                                                        onBlur={() => {
                                                            console.log("CardNumber [blur]");
                                                        }}
                                                        onFocus={() => {
                                                            console.log("CardNumber [focus]");
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='card__detail--row' style={{ borderColor: isFormValid() ? Colors.primary : Colors.lightGrey }}>
                                                <div className='card__detail--row__element' style={{ borderColor: isFormValid() ? Colors.primary : Colors.lightGrey }}>
                                                    <CardExpiryElement
                                                        options={{
                                                            showIcon: true,
                                                            style: {
                                                                base: {
                                                                    fontSize: '15px',
                                                                    fontWeight: '300',
                                                                    fontFamily: 'Montserrat, sans-serif',
                                                                    color: Colors.white,
                                                                    "::placeholder": {
                                                                        color: Colors.lightGrey
                                                                    }
                                                                },
                                                                invalid: {
                                                                    color: Colors.darkRed
                                                                }
                                                            }
                                                        }}
                                                        onReady={() => {
                                                            console.log("CardExpiry [ready]");
                                                        }}
                                                        onChange={cardDetails => {
                                                            console.log("CardExpiry [change]", cardDetails);
                                                            setCardExpiry(cardDetails)
                                                        }}
                                                        onBlur={() => {
                                                            console.log("CardExpiry [blur]");
                                                        }}
                                                        onFocus={() => {
                                                            console.log("CardExpiry [focus]");
                                                        }}
                                                    />
                                                </div>
                                                <div className='card__detail--row__element' style={{ borderColor: isFormValid() ? Colors.primary : Colors.lightGrey }}>
                                                    <CardCvcElement
                                                        options={{
                                                            showIcon: true,
                                                            style: {
                                                                base: {
                                                                    fontSize: '15px',
                                                                    fontWeight: '300',
                                                                    fontFamily: 'Montserrat, sans-serif',
                                                                    color: Colors.white,
                                                                    "::placeholder": {
                                                                        color: Colors.lightGrey
                                                                    }
                                                                },
                                                                invalid: {
                                                                    color: Colors.darkRed
                                                                }
                                                            }
                                                        }}
                                                        onReady={() => {
                                                            console.log("CardCVC [ready]");
                                                        }}
                                                        onChange={cardDetails => {
                                                            console.log("CardCVC [change]", cardDetails);
                                                            setCardCVC(cardDetails)
                                                        }}
                                                        onBlur={() => {
                                                            console.log("CardCVC [blur]");
                                                        }}
                                                        onFocus={() => {
                                                            console.log("CardCVC [focus]");
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                ) :
                                (
                                    modeOfPayment === 'DIRECT' ?
                                        (
                                            <div className='direct'>
                                                After the completion of your event, you will receive an invoice with a 30 day payment period.
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                )
                            }



                            <div className="pay-button">
                                <KalabMainButton
                                    text={modeOfPayment === 'DIRECT' ? 'Book Now' : 'Pay Now'}
                                    color={Colors.primary}
                                    onClick={handleOkClick}
                                    disabled={!isFormValid()}
                                />
                            </div>
                        </div>
                        <div className='left-side'>
                            {
                                width > 650 ?
                                    (
                                        <KalabEventRequestDetailElement
                                            eventRequest={eventRequest}
                                            isArtist={false}
                                            widthPercentage={width > 1000 ? 100 : 50}
                                        />
                                    )
                                    :
                                    (
                                        <>
                                            <div className='left-side__title'>
                                                <div className='left-side__title--text'>
                                                    Booking Details
                                                </div>
                                                <div className='left-side__title--btn'>
                                                    <KalabIconButton
                                                        iconName={showBookingDetails ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}
                                                        noBorder
                                                        iconSize={18}
                                                        onClick={() => {
                                                            setShowBookingDetails(!showBookingDetails)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={showBookingDetails ? ('show-booking')  : 'hide-booking'}>
                                            {
                                                showBookingDetails ?
                                                    (
                                                        <KalabEventRequestDetailElement
                                                            eventRequest={eventRequest}
                                                            isArtist={false}
                                                            
                                                        />
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                            </div>
                                        </>
                                    )
                            }


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
