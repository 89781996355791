import React, { useState, useContext, useEffect } from 'react'
import './LandingPage.scss';
import { NavLink } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import KalabAnimatedDiv from '../kalab/kalabAnimated/KalabAnimatedDiv';
import { scroller } from 'react-scroll';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { openInNewTab } from '../../utils/common';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../contexts/UserContext';

import PortaledComponent from '../modalPortal/ModalPortal';
import { ClientSignInPopUp } from '../popUps/ClientSignInPopUp';
import { ArtistSignInPopUp } from "../popUps/ArtistSignInPopUp";
import Config from '../../config/Config';
import { Helmet } from 'react-helmet';

export default function LandingPage() {

    const [modal, setModal] = useState(false);
    const [modeMode, setModalMode] = useState('');
    const { logOut, loggedInUserDetails } = useContext(UserContext);
    let history = useHistory();
    const { height, width } = useWindowDimensions();
    const [communitySwiper, setCommunitySwiper] = useState(null);
    const [communitySwiperDetail, setCommunitySwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const [creativeExpand, setCreativeExpand] = useState({
        benefits: false,
        howItWorks: false
    });

    const [hospitalityExpand, setHospitalityExpand] = useState(false);

    const CommunityCards = [
        {
            id: 1,
            title: 'AMERON Collection, Hospitality Brand',
            text: '"The tool is very good, user friendly, clear and fun to work with! The payment is  easy and problem-free via the tool."',
            image: '/Ameron.jpg'
        },
        {
            id: 2,
            title: 'Milana Alex. DJ',
            text: '"I wish KALAB to grow and bloom, because you guys are doing amazing things for artists that not many other companies do."',
            image: '/Milana.png'
        },
        {
            id: 3,
            title: 'Incipio Group, Hospitality Brand',
            text: '"KALAB made it very simple to quickly find and source local artists and musicians. This saved a lot of time when booking and negotiating."',
            image: '/Incipio.jpg'
        },
        {
            id: 4,
            title: 'Matt Ryan, Musician',
            text: '"KALAB, have been a dream to work with. The whole process from doing a gig to getting paid was so professional and quick. Can’t wait for the next one!"',
            image: '/Matt.png'
        },
        {
            id: 5,
            title: 'Katie, Occasion Client',
            text: '"We had something specific in mind and were struggling to find someone via Google. KALAB helped us find the perfect artist at a reasonable price, who delivered exceptionally on the night."',
            image: '/Katie.jpg'
        },
        {
            id: 6,
            title: 'George Bone, Musician/Producer',
            text: '"The KALAB services have given me a platform not only to advertise myself, but to negotiate a fair wage for my work done. The impressively sleek look of the platform has aided in me getting jobs and gigs."',
            image: '/George.png'
        },
        {
            id: 7,
            title: 'The Initiative, Hospitality Brand',
            text: '"We’ve been using KALAB across our F&B outlets and it’s been a huge help! We’ve found many exciting musicians and booking our own artists has become so much easier. No more time wasted on emails and everyone is kept in the loop through the dashboard."',
            image: '/Initiative.png'
        },
        {
            id: 8,
            title: 'Andreas Liberos, Musician',
            text: '"With KALAB everything went smoothly. Love the platform and the communication and transparency from the team."',
            image: '/Andreas.png'
        },
        {
            id: 9,
            title: 'Penta Hotels, Hospitality Brand',
            text: '“I think the platform is great and really allows us an opportunity to be flexible and book directly.“',
            image: '/Penta.png'
        },
        {
            id: 10,
            title: 'Christian, Occasion Client',
            text: '"Simple, supporting and straight forward. I love it!”',
            image: '/Christian.jpg'
        },
        {
            id: 11,
            title: 'Double Crème, Musician',
            text: '"Awesome platform that is easy to understand. Can’t recommend enough!"',
            image: '/Double.png'
        }
    ];

    const closeModal = () => {
        setModal(false);
        document.body.style.overflow = 'unset';

    }

    const openModal = (popUpName) => {
        document.body.style.overflow = 'hidden';
        setModalMode(popUpName);
        setModal(true);
    }

    const handleLogOutBtn = () => {
        logOut();
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenuIsOpen(open);
    };



    const reachOutBtnClick = () => {
        openInNewTab(`mailto:${Config.mailId}`);
    }


    // Auto scrolling after click button expand/hide in main block
    const scrollToCreativeSection = () => {
        scroller.scrollTo('creative-section', {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: 0,
        });
    };

    const scrollToHospitalitySection = () => {
        scroller.scrollTo('hospitality-section', {
            duration: 2000,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: 0,
        });
    };

    const scrollToClientsSection = () => {
        scroller.scrollTo('clients-section', {
            duration: 1500,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: 0,
        });
    };

    useEffect(() => {
        scroller.scrollTo('banner-section', {
            duration: 1500,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: 0,
        });
    }, [])


    const getList = () => {
        return (
            <div className='drawer'>
                {
                    loggedInUserDetails.user && loggedInUserDetails.role === 'artist' ?
                        (
                            <div className='section'>
                                <div
                                    className='section__btn'
                                    onClick={() => {
                                        history.push({
                                            pathname: '/artist/home',
                                        })
                                    }}
                                >The Hub</div>
                            </div>
                        )
                        :
                        (
                            loggedInUserDetails.user && loggedInUserDetails.role === 'client' ?
                                (

                                    <div className='section'>
                                        <div
                                            className='section__btn'
                                            onClick={() => {
                                                history.push({
                                                    pathname: '/home',
                                                })
                                            }}
                                        >Discover</div>
                                    </div>
                                )
                                :
                                (
                                    <div className='section'>
                                        <div className='section__title'>Sign In</div>
                                        <div
                                            className='section__btn'
                                            onClick={() => {
                                                setMenuIsOpen(false)
                                                openModal('signinArtist');
                                            }}
                                        >Creatives</div>
                                        <div
                                            className='section__btn'
                                            onClick={() => {
                                                setMenuIsOpen(false)
                                                openModal('signinClient');
                                            }}
                                        >
                                            Clients
                                        </div>
                                    </div>
                                )

                        )
                }


            </div>
        )
    }


    return (
        <>
        <Helmet>
          <title>{Config.title}</title>
        </Helmet>
            <div className='landing-page'>
                <div className='banner-section'>
                    <div className='banner-section__header'>
                        <KalabAnimatedDiv once className='banner-section__header--left'>
                            <NavLink to="/">
                                <img
                                    src='/LogoWrittenBlack.png'
                                    alt="Kalab Written Logo"
                                    className="logo"
                                />
                            </NavLink>
                        </KalabAnimatedDiv>
                        <div className='banner-section__header--right'>
                            {
                                width > 900 ?
                                    (
                                        loggedInUserDetails.user && loggedInUserDetails.role === 'artist' ?
                                            (
                                                <KalabAnimatedDiv className='nav-btn'
                                                    once
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: '/artist/home',
                                                        })
                                                    }}
                                                >
                                                    THE HUB
                                                </KalabAnimatedDiv>
                                            )
                                            :
                                            (
                                                loggedInUserDetails.user && loggedInUserDetails.role === 'client' ?
                                                    (
                                                        <KalabAnimatedDiv
                                                            once
                                                            className='nav-btn'
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: '/home',
                                                                })
                                                            }}
                                                        >
                                                            DISCOVER
                                                        </KalabAnimatedDiv>
                                                    )
                                                    :
                                                    (
                                                        <KalabAnimatedDiv once className='nav-dropdown'>
                                                            <div className='nav-dropdown__btn'>SIGN IN</div>
                                                            <div className='nav-dropdown__content'>
                                                                <div
                                                                    onClick={() => {
                                                                        openModal('signinArtist');
                                                                    }}
                                                                    className='nav-dropdown__content--link'
                                                                >CREATIVES</div>
                                                                <div
                                                                    onClick={() => {
                                                                        openModal('signinClient');
                                                                    }}
                                                                    className='nav-dropdown__content--link'
                                                                >CLIENTS</div>
                                                            </div>
                                                        </KalabAnimatedDiv>
                                                    )
                                            )

                                    )
                                    :
                                    (
                                        <KalabAnimatedDiv
                                            once
                                            className='nav-btn'
                                            onClick={() => {
                                                console.log(menuIsOpen);
                                                setMenuIsOpen(true)
                                            }}
                                        >
                                            <Icon>menu_rounded</Icon>
                                        </KalabAnimatedDiv>
                                    )


                            }


                        </div>
                    </div>
                    <div flyIn={false}
                        className='banner-section__title'>
                        <KalabAnimatedDiv flyIn={false} className='banner-section__title--one'>EXPERIENCES</KalabAnimatedDiv>
                        <KalabAnimatedDiv flyIn={false} delay={0.5} className='banner-section__title--two'>REDESIGNED</KalabAnimatedDiv>
                    </div>
                </div>
                <div className='buttons-section'>
                    <video autoPlay muted loop id="myVideo" className='video'>
                        <source src="/video.mp4" type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                    <div className='coloumn'>
                        <KalabAnimatedDiv once className='buttons-section__btn-row'>
                            <div className='buttons-section__btn-row--btn' onClick={() => { scrollToCreativeSection() }}>
                                <div className='buttons-section__btn-row--btn__text'>Creatives</div>
                            </div>
                            <div className='buttons-section__btn-row--btn'>
                                <div className='buttons-section__btn-row--btn__text' onClick={() => { scrollToClientsSection() }}>Occasion Clients</div>
                            </div>
                            <div className='buttons-section__btn-row--btn'>
                                <div className='buttons-section__btn-row--btn__text' onClick={() => { scrollToHospitalitySection() }}> Hospitality Brands</div>
                            </div>
                        </KalabAnimatedDiv>
                    </div>
                </div>
                <div className='creative-section'>

                    <div className='marquee'>
                        <svg width="100%" height="10vw" viewBox={width > 1200 ? `0 0 1200 75` : `0 0 ${width > 600 ? width : '600'}  75`}>
                            <path id="curve1" fill="transparent" d="M 0 73.5048 L 0 73.5048 S 160.08 19.5048 240 19.5048 S 400.08 73.5048 480 73.5048 S 640.08 19.5048 720 19.5048 S 880.08 73.5048 960 73.5048 S 1120.08 19.5048 1200 19.5048" />
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="-15s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="-10s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="-5s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="0s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="5s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve1" startOffset="100%">
                                    CREATE. COLLABORATE. PERFORM. &nbsp; •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="30s" begin="10s" repeatCount="indefinite" />
                                </textPath>
                            </text>

                        </svg>
                    </div>






                    <KalabAnimatedDiv className='main'>
                        <div className='main__text-area'>
                            <div className='main__text-area--title'>CREATIVES.</div>
                            <div className='main__text-area--description'>Wouldn’t it be great if you didn’t have to take care of those annoying invoices, nor chase clients to pay you, or have many calls and emails just to seal a deal? KALAB is your handy tool to manage all your live gigs so you can focus on your music. Join our community of hundreds of creatives and gain your independence now. </div>
                            <div className="main__text-area--details">
                                <div className='details-btn'
                                    onClick={() => {
                                        setCreativeExpand({
                                            benefits: !creativeExpand.benefits,
                                            howItWorks: false
                                        })
                                    }}>
                                    <Icon className={`details-btn--icon ${creativeExpand.benefits ? 'icon-open' : 'icon-close'}`}>keyboard_arrow_down_rounded</Icon>
                                    <div className='details-btn--text'>Benefits</div>

                                </div>
                                <div className={creativeExpand.benefits ? 'expand' : 'shrink'}>
                                    {
                                        creativeExpand.benefits ?
                                            (
                                                <ul>

                                                    <li>Less boring admin, more fun creating & performing!</li>
                                                    <li>Meet and collaborate with other creatives and be part of the tribe.</li>
                                                    <li>Access perks from our music industry partners in crime. </li>
                                                </ul>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }

                                </div>

                            </div>
                            <div className="main__text-area--details">
                                <div className='details-btn'
                                    onClick={() => {
                                        setCreativeExpand({
                                            benefits: false,
                                            howItWorks: !creativeExpand.howItWorks
                                        })
                                    }}>
                                    <Icon className={`details-btn--icon ${creativeExpand.howItWorks ? 'icon-open' : 'icon-close'}`}>keyboard_arrow_down_rounded</Icon>
                                    <div className='details-btn--text'>How it works</div>

                                </div>
                                <div className={creativeExpand.howItWorks ? 'expand' : 'shrink'}>
                                    {
                                        creativeExpand.howItWorks ?
                                            (
                                                <ul>

                                                    <li>Apply with your music & socials and we’ll get back to you.</li>
                                                    <li>Create your booking site for free: Set your prices, and show off your best music, pics, & story!</li>
                                                    <li>Share your personal booking page to your socials, website, & with your clients so they can easily book you.</li>
                                                </ul>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }

                                </div>

                            </div>
                            <div className='main__text-area--bottom'>
                                <div className='btn' onClick={() => {
                                    history.push({
                                        pathname: '/artist/application',
                                    })
                                }}>
                                    <div className='btn__text'>JOIN NOW</div>
                                </div>
                            </div>

                        </div>
                        <div className='main__photo-area'>
                            <div className='photo-1'>
                                <img
                                    src='/Creative1.jpeg'
                                    alt="Creative1"
                                    className="img"
                                />
                            </div>
                            <div className='photo-2'>
                                <img
                                    src='/Creative2.jpeg'
                                    alt="Creative2"
                                    className="img"
                                />
                            </div>
                        </div>
                    </KalabAnimatedDiv>
                </div>
                <div className='clients-section'>
                    <div className="img-background">
                        <img
                            src='/Clients.jpg'
                            alt="Clients"
                            className="img"
                        />
                    </div>
                    <KalabAnimatedDiv className='main'>
                        <div className='main__text-area'>
                            <div className='main__text-area--title'>OCCASION CLIENTS.</div>
                            <div className='main__text-area--description'>Tired of wasting time searching through countless pages of Google only to end up choosing the same old band? Find and book the ideal artist 7x faster than any other way and elevate your events in a couple clicks. </div>
                            <div className='main__text-area--bottom'>
                                <div className='btn' onClick={() => {
                                    scroller.scrollTo('banner-section', {
                                        duration: 100,
                                        delay: 100,
                                        smooth: 'easeInOutQuart',
                                        offset: -300,
                                    });
                                    history.push({
                                        pathname: '/home',
                                    })
                                }}>
                                    <div className='btn__text'>Discover</div>
                                </div>
                            </div>

                        </div>
                        <div className='main__photo-area'>

                        </div>
                    </KalabAnimatedDiv>

                </div>
                <div className='hospitality-section'>
                    <div className='marquee'>
                        <svg width="100%" height="10vw" viewBox={width > 1200 ? `0 0 1200 75` : `0 0 ${width > 600 ? width : '600'}  75`}>
                            <path id="curve" fill="transparent" d="M 0 73.5048 L 0 73.5048 S 160.08 19.5048 240 19.5048 S 400.08 73.5048 480 73.5048 S 640.08 19.5048 720 19.5048 S 880.08 73.5048 960 73.5048 S 1120.08 19.5048 1200 19.5048" />
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="-14s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="-10.5s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="-7s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="-3.5s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="0s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="3.5s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="7s" repeatCount="indefinite" />
                                </textPath>
                            </text>
                            <text className='marquee__text' width="100%" style={{ transform: 'translate3d(0,0,0)' }}>
                                <textPath style={{ transform: 'translate3d(0,0,0)' }} alignmentBaseline="top" xlinkHref="#curve" startOffset="100%">
                                    DISCOVER. BOOK. MANAGE. •
                                    <animate
                                        attributeName="startOffset" from="100%" to="-100%"
                                        dur="28s" begin="10.5s" repeatCount="indefinite" />
                                </textPath>
                            </text>

                        </svg>
                    </div>
                    <KalabAnimatedDiv className='main'>
                        <div className='main__text-area'>
                            <div className='main__text-area--title'>HOSPITALITY BRANDS.</div>
                            <div className='main__text-area--description'>
                                Would you like to activate your spaces or foster community by becoming a social hub that’s pulsing with the latest local sounds? KALAB digitises your entertainment discovery, booking, and management tasks from start to finish, so you can conveniently create memorable experiences.</div>
                            <div className="main__text-area--details">
                                <div className='details-btn'
                                    onClick={() => {
                                        setHospitalityExpand(!hospitalityExpand)
                                    }}>
                                    <Icon className={`details-btn--icon ${hospitalityExpand ? 'icon-open' : 'icon-close'}`}>keyboard_arrow_down_rounded</Icon>
                                    <div className='details-btn--text'>Benefits</div>

                                </div>
                                <div className={hospitalityExpand ? 'expand' : 'shrink'}>
                                    {
                                        hospitalityExpand ?
                                            (
                                                <ul>

                                                    <li>Onboard & book your existing artist network for free</li>
                                                    <li>Discover & book new artists 7x faster than traditional methods</li>
                                                    <li>Improve team collaboration by streamlining your operations & processes</li>
                                                </ul>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }

                                </div>

                            </div>

                            <div className='main__text-area--bottom'>
                                <div className='btn' onClick={reachOutBtnClick}>
                                    <div className='btn__text'>REACH OUT</div>
                                </div>
                            </div>

                        </div>
                        <div className='main__photo-area'>
                            <div className='photo-1'>
                                <img
                                    src='/Hospitality1.png'
                                    alt="Hospitality1"
                                    className="img"
                                />
                            </div>
                            <div className='photo-2'>
                                <img
                                    src='/Hospitality2.jpg'
                                    alt="Hospitality2"
                                    className="img"
                                />
                            </div>
                        </div>
                    </KalabAnimatedDiv>
                </div>
                <div className='about-section'>
                    <KalabAnimatedDiv className='main'>
                        <div className='main__text-area'>
                            <div className='main__text-area--title'>ABOUT KALAB.</div>
                            <div className='main__text-area--description'>Our passion for the arts and our belief in its’ power for good gives us an unwavering spirit to drive a movement that is grounded on providing the opportunity to access memorable experiences as well as empowering creatives everywhere.</div>
                            <div className='main__text-area--description'>We are a socially conscious brand that aims to nurture cultural ecosystems locally and internationally. We dedicate a portion of our proceeds toward promoting the arts in the developing world because every person deserves to live and feel the beauty of arts.</div>
                            <div className='main__text-area--description' style={{ fontStyle: 'italic' }}>Talitha & Flo</div>
                        </div>
                        <div className='main__photo-area'>
                            <div className='photo'>
                                <img
                                    src='/About.jpg'
                                    alt="About"
                                    className="img"
                                />
                            </div>
                        </div>
                    </KalabAnimatedDiv>
                </div>
                <div className='community-section'>
                    <div className='main'>
                        <KalabAnimatedDiv once className='main__title'>WHAT OUR COMMUNITY SAYS...</KalabAnimatedDiv>
                        <div className='main__swiper'>
                            <Swiper
                                onUpdate={(swiper) => {
                                    console.log(swiper);
                                    setCommunitySwiper(swiper);
                                    setCommunitySwiperDetail({
                                        isBeginning: swiper.isBeginning,
                                        isEnd: swiper.isEnd
                                    });
                                }}
                                onSwiper={(swiper) => {
                                    console.log(swiper);
                                    setCommunitySwiper(swiper);
                                    setCommunitySwiperDetail({
                                        isBeginning: swiper.isBeginning,
                                        isEnd: swiper.isEnd
                                    });
                                }}
                                spaceBetween={20}
                                loop={true}
                                slidesPerView={Math.floor(width / 400) === 0 ? 1 : (Math.floor(width / 400) > 3 ? 3 : Math.floor(width / 400))}
                                freeMode={true}
                                onSlideChange={(swiper) => {
                                    console.log(swiper);
                                    setCommunitySwiperDetail({
                                        isBeginning: swiper.isBeginning,
                                        isEnd: swiper.isEnd
                                    })
                                }}
                                className="mySwiper"
                            >
                                {CommunityCards.map((item) => (
                                    <SwiperSlide key={item.title}>
                                        <KalabAnimatedDiv once className='community-card'>
                                            <div className='image-view'>
                                                <img
                                                    src={item.image}
                                                    alt={item.title}
                                                    className="img"
                                                />

                                            </div>
                                            <div className='text' style={{ fontSize: width > 400 ? 16 : 14 }}>{item.text}</div>
                                            <div className='title' style={{ fontSize: width > 400 ? 12 : 10 }}>{item.title}</div>
                                        </KalabAnimatedDiv>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <KalabAnimatedDiv once className='main__ctrl-btns' >
                            <div className='main__ctrl-btns--left' onClick={() => {
                                communitySwiper.slidePrev();
                            }}>
                                <i className="fas fa-long-arrow-alt-left icon"></i>
                            </div>
                            <div className='main__ctrl-btns--right' onClick={() => {
                                communitySwiper.slideNext();
                            }}>
                                <i className="fas fa-long-arrow-alt-right icon"></i>
                            </div>
                        </KalabAnimatedDiv>

                    </div>

                </div>
                <div className='instagram-section'>
                    <div className='main'>
                        <div className='main__title'>
                            READY TO EXPERIENCE?
                        </div>
                        <div className='main__btn-grp'>
                            <div
                                className='btn'
                                onClick={() => {
                                    openInNewTab('https://www.instagram.com/kalab.arts/')
                                }}
                            >
                                <i className="fab fa-instagram icon" />

                            </div>
                            <div
                                className='btn'
                                onClick={() => {
                                    openInNewTab('https://open.spotify.com/playlist/1FOxDx8005HpxWZ95dUmR1?si=44839991f08a49a9')
                                }}
                            >
                                <i className="fab fa-spotify icon" />

                            </div>
                            <div
                                className='btn'
                                onClick={() => {
                                    openInNewTab('https://www.linkedin.com/company/kalabarts/')
                                }}
                            >
                                <i className="fab fa-linkedin-in icon" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* -------------modals from client sign in,  and artist sign in------------------- */}
            {modal && (
                <PortaledComponent
                    modal={
                        modeMode === 'signinClient' && (
                            <ClientSignInPopUp modal={modal} closeModal={closeModal} toHome={true} />
                        )
                    }
                />
            )}
            {modal && (
                <PortaledComponent
                    modal={
                        modeMode === 'signinArtist' && (
                            <ArtistSignInPopUp modal={modal} closeModal={closeModal} />
                        )
                    }
                />
            )}

            {
                width > 900 ?
                    (
                        null
                    )
                    :
                    (
                        <SwipeableDrawer
                            anchor={'right'}
                            open={menuIsOpen}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                        >
                            {getList()}
                        </SwipeableDrawer>
                    )
            }
        </>
    )
}
