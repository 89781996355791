import React, { useState } from 'react'
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core';
import 'react-calendar/dist/Calendar.css';
import Colors from '../../../constants/Colors';
import { BorderColor } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles({
    root: props => ({
        '& .react-calendar': {
            width: '80%',
            margin: '0 auto',
            border: 'none',
            '&__month-view': {
                '&__weekdays': {
                    '&__weekday': {
                        padding: '0.1em 0.1em',
                        margin: '0.25em 0',
                        fontSize: 16,
                        fontWeight: '300',
                        fontFamily: 'Montserrat, sans-serif',
                        color: Colors.black,
                        '& abbr[title]': {
                            textDecoration: 'none',
                        }
                    }
                },
                '&__days': {
                    '&__day': {
                        fontSize: 16,
                        fontWeight: '400',
                        fontFamily: 'Montserrat, sans-serif',
                        color: Colors.black,
                        '&--weekend': {
                            color: Colors.black
                        },



                    }
                }
            },
            '&__tile': {
                borderRadius: '100%',
                padding: '0.25em 0.25em',
                aspectRatio: '1 / 1',
                margin: '0.25em 0',
                lineHeight: 1.5,
                '&--now': {
                    backgroundColor: 'transparent',
                    color: Colors.primary,
                    '& .event': {
                        '&__text': {
                            color: Colors.black
                        }
                    }
                },
                '& .event': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 5,

                    '&__text': {
                        fontSize: 7,
                        fontWeight: '400',
                        fontFamily: 'Montserrat, sans-serif',
                        backgroundColor: Colors.darkBlueGradient,
                        padding: '0px 6px',
                        borderRadius: 3,
                        lineHeight: 1.5,
                        color: Colors.white,
                    }

                },

                '& .performance': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    '&__text': {
                        fontSize: 7,
                        fontWeight: '400',
                        fontFamily: 'Montserrat, sans-serif',
                        backgroundColor: Colors.lightestBlueishgGrey,
                        padding: '0px 6px',
                        borderRadius: 3,
                        lineHeight: 1.5,
                        color: Colors.black,
                    }

                },

                '&:enabled': {
                    '&:focus': {
                        backgroundColor: Colors.lightestBlueishgGrey
                    },
                    '&:hover': {
                        backgroundColor: Colors.lightestBlueishgGrey
                    }
                },
                '&.highlight': {
                    borderColor: Colors.primary,
                    borderWidth: 1,
                    borderStyle:'solid'
                },
                '&--active': {
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    '&:enabled': {
                        '&:focus': {
                            backgroundColor: Colors.primary
                        },
                        '&:hover': {
                            backgroundColor: Colors.primary
                        }
                    },
                    '& .event': {
                        '&__text': {
                            backgroundColor: Colors.white,
                            color: Colors.primary
                        }
                    },
                    '& .performance': {
                        '&__text': {
                            backgroundColor: Colors.white,
                            color: Colors.primary
                        }
                    }
                },

            }
        }
    })
});

export default function KalabClientCalendar({ selectedDate, onChange, bookings, showDate, highlightedDates = [] }) {
    const classes = useStyles();
    const getNoOfEventsOnADate = (date) => {
        var dateMoment = moment(date);
        var noOfEvents = 0;
        bookings.forEach(booking => {
            if (booking.isResidency) {
                booking.timings.forEach(timing => {
                    if (timing.date === dateMoment.format('YYYY-MM-DD')) {
                        noOfEvents = noOfEvents + 1;
                    }
                })
            }
            else {
                if (booking.date === dateMoment.format('YYYY-MM-DD')) {
                    noOfEvents = noOfEvents + 1;
                }
            }

        });
        return noOfEvents;
    }
    return (
        <div className={classes.root}>
            <Calendar
                showNavigation={false}
                onClickDay={onChange}
                value={selectedDate}
                activeStartDate={showDate}
                showNeighboringMonth={false}
                tileClassName={
                    ({ date, view }) => {
                        if (highlightedDates.includes(moment(date).format('YYYY-MM-DD'))) {
                            return ('highlight')
                        }
                        return null
                    }
                }
                tileContent={({ activeStartDate, date, view }) => {
                    if (view === 'month' && (getNoOfEventsOnADate(date) > 0)) {
                        return (
                            <>
                                {
                                    getNoOfEventsOnADate(date) > 0 ?
                                        (
                                            <div className="event">
                                                <div className="event__text">
                                                    {`${getNoOfEventsOnADate(date) > 1 ? getNoOfEventsOnADate(date) : ''} Event${getNoOfEventsOnADate(date) > 1 ? 's' : ''}`}
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                            </>

                        )
                    }
                    else {
                        return null
                    }

                }
                }
            />
        </div>
    )
}
