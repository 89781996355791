import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import KalabIconButton from '../kalabButtons/KalabIconButton';
const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        height: 'auto',
        '& .select-btn': {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: props.showDropDown || (!props.isMulti && props.value !== null) || (props.isMulti && props.value.length > 0) ? Colors.black : Colors.lightestGrey,
            borderRadius: 25,
            paddingTop: 5,
            paddingLeft: 10,
            paddingBottom: 5,
            paddingRight: 10,
            width: '100%',
            height: '100%',
            fontFamily: 'Montserrat, sans-serif',
            color: props.showDropDown || (!props.isMulti && props.value !== null) || (props.isMulti && props.value.length > 0) ? Colors.black : Colors.lightGrey,
            fontSize: 14,
            '&:hover': {
                borderColor: Colors.black,
                color: Colors.black,
            }

        },
        '& .select-dropdown': {
            position: 'absolute',
            zIndex: 10,
            left: '50%',
            transform: 'translate(-50%,10px)',
            backgroundColor: Colors.white,
            border: '1px solid',
            borderColor: Colors.lightestGrey,
            borderRadius: 5,
            display: 'flex',
            padding: 10,
            minWidth: '100%',
            maxHeight: 250,
            flexDirection: 'column',
            '&__search': {
                padding: 5,
                marginBottom: 10,
                border: '1px solid',
                borderColor: Colors.lightestGrey,
                display: 'flex',
                borderRadius: 5,
                alignItems: 'center',
                '&:focus-within': {
                    borderColor: Colors.lightGrey,
                },
                '&--input': {
                    flex: 1,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 15,
                    color: Colors.lightGrey,
                    '&::placeholder': {
                        color: Colors.lightGrey,
                        fontWeight: 300,
                        textTransform: 'capitalize'
                    },
                    '&:focus-visible': {
                        outline: 'none',
                    },
                    '&::-webkit-search-cancel-button': {
                        color: Colors.lightGrey,
                        backgroundColor: Colors.lightGrey
                    }

                }
            },
            '&__options': {
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            },
            '&__option': {
                display: 'flex',
                padding: 5,
                alignItems: 'center',
                cursor: 'pointer',
                '&--check-box': {
                    fontSize: 15,
                    marginRight: 10,
                    color: Colors.lightGrey,

                },
                '&--check-box-selected': {
                    color: Colors.primary,

                },
                '&--label-text': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 15,
                    color: Colors.lightGrey
                }

            },
            '&__footer': {
                borderTopWidth: 1,
                marginTop: 10,
                paddingTop: 15,
                paddingBottom: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 5,
                '&--save-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginLeft: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        backgroundColor: Colors.lightGrey,
                        color: Colors.white
                    },

                },
                '&--clear-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginRight: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.black,
                        color: Colors.black
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        color: Colors.lightGrey
                    },
                }
            }

        },

    })
});

export default function KalabFilterDropDownWithSearch({
    options,
    onChange,
    value,
    placeholder,
    multiValuePlaceholder,
    isMulti = false,
    noSearch = false
}) {
    const wrapperRef = useRef(null);
    const [searchWord, setSearchWord] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([])
    const [showDropDown, setShowDropDown] = useState(false);
    const getBtnTextAccordingToValue = () => {
        if (isMulti) {
            if (value.length === 0) {
                return (placeholder);
            }
            else if (value.length === 1) {
                return (value[0].label)
            }
            else {
                return (`${multiValuePlaceholder}(${value.length})`)
            }
        }
        else {
            if (value) {
                return (value.label);
            }
            else {
                return (placeholder)
            }
        }


    }

    const handleSearchTextChange = (e) => {
        setSearchWord(e.target.value)
    }

    const getFilterSelectedOptions = () => {
        if (searchWord === '') {
            return (selectedOptions);
        }
        else {
            return (selectedOptions.filter(option => (option.label.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1)))
        }
    }
    const handleTextClear = () => {
        setSearchWord('');
    }
    const handleBtnClick = (e) => {
        e.preventDefault();
        setShowDropDown(!showDropDown);
    }
    const handleSelectedOptions = () => {
        var selectedOptions = [];
        options.forEach(option => {
            selectedOptions.push({
                label: option.label,
                value: option.value,
                isSelected:
                    (isMulti ? (
                        value.filter(singleValue => (singleValue.value === option.value)).length > 0
                            ?
                            true
                            :
                            false

                    ) : (
                        (value && value.value === option.value)
                            ?
                            true
                            :
                            false
                    ))

            })
        })
        setSelectedOptions(selectedOptions.sort((a, b) => (b.isSelected - a.isSelected)))
    }

    const handleOptionClick = (option) => {
        var newSelectedOptions = [];
        selectedOptions.forEach(selectedOption => {
            newSelectedOptions.push(
                {
                    ...selectedOption,
                    isSelected: isMulti ?
                        (
                            selectedOption.value === option.value ?
                                !selectedOption.isSelected
                                :
                                selectedOption.isSelected
                        )
                        :
                        (
                            selectedOption.value === option.value ?
                                !selectedOption.isSelected
                                :
                                false
                        )
                }
            )
        })
        setSelectedOptions(newSelectedOptions);
    }

    const handleSaveBtnClick = (e) => {
        e.preventDefault();
        var filteredSelectedOptions = selectedOptions.filter(selectedOption => (selectedOption.isSelected));
        if (isMulti) {
            onChange(filteredSelectedOptions.map((option) => ({
                label: option.label,
                value: option.value
            })));
        }
        else {
            if (filteredSelectedOptions.length > 0) {
                onChange({
                    label: filteredSelectedOptions[0].label,
                    value: filteredSelectedOptions[0].value
                });
            }
            else {
                onChange(null);
            }
        }


        setShowDropDown(false);
    }

    const handleClearBtnClick = (e) => {
        e.preventDefault();
        if (isMulti) {
            var newSelectedOptions = [];
            selectedOptions.forEach(selectedOption => {
                newSelectedOptions.push(
                    {
                        ...selectedOption,
                        isSelected: false
                    }
                )
            })
            setSelectedOptions(newSelectedOptions);
        }
        else {
            onChange(null);
            setShowDropDown(false);
        }
    }
    const isClearButtonDisabled = () => {
        var filteredSelectedOptions = selectedOptions.filter(selectedOption => (selectedOption.isSelected));
        if (filteredSelectedOptions.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    const classes = useStyles({ showDropDown, value, isMulti });

    useEffect(() => {
        handleSelectedOptions();
        setSearchWord('');
    }, [options, showDropDown, value])


    const useClickOutsideModal = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowDropDown(false);
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useClickOutsideModal(wrapperRef);

    return (
        <div ref={wrapperRef} className={classes.root}>
            <button className={`${'select-btn'}`} onClick={handleBtnClick}>
                {getBtnTextAccordingToValue()}
            </button>
            {
                showDropDown
                    ?
                    (
                        <div className='select-dropdown'>
                            {
                                noSearch ?
                                    (
                                        null
                                    )
                                    :
                                    (
                                        <div className='select-dropdown__search'>
                                            <input
                                                className='select-dropdown__search--input'
                                                type='text'
                                                placeholder='search'
                                                value={searchWord}
                                                onChange={handleSearchTextChange}
                                            />
                                            {searchWord.length > 0 ?
                                                (
                                                    <KalabIconButton
                                                        iconName='clear'
                                                        isLightTheme
                                                        noDisabled
                                                        isWhite
                                                        noBorder
                                                        iconSize={16}
                                                        onClick={handleTextClear}
                                                    />
                                                )
                                                :
                                                (null)
                                            }

                                        </div>
                                    )
                            }

                            <div className='select-dropdown__options'>
                                {getFilterSelectedOptions().length > 0
                                    ?
                                    (
                                        getFilterSelectedOptions().map(option => (
                                            isMulti ? (
                                                <div className='select-dropdown__option' onClick={() => { handleOptionClick(option) }}>
                                                    <i className={`select-dropdown__option--check-box ${option.isSelected ? ('far fa-check-square select-dropdown__option--check-box-selected') : ('far fa-square')}`} />
                                                    <span className='select-dropdown__option--label-text'>{option.label}</span>
                                                </div>
                                            ) : (
                                                <div className='select-dropdown__option' onClick={() => { handleOptionClick(option) }}>
                                                    <i className={`select-dropdown__option--check-box ${option.isSelected ? ('far fa-check-circle select-dropdown__option--check-box-selected') : ('far fa-circle')}`} />
                                                    <span className='select-dropdown__option--label-text'>{option.label}</span>
                                                </div>
                                            )

                                        ))) : (
                                        <div className='select-dropdown__option' style={{ cursor: 'unset' }}>
                                            <span className='select-dropdown__option--label-text'>{'No Options'}</span>
                                        </div>
                                    )}
                            </div>
                            <div className='select-dropdown__footer'>
                                <button className='select-dropdown__footer--clear-btn' onClick={handleClearBtnClick} disabled={isClearButtonDisabled()}>
                                    Clear
                                </button>
                                <button className='select-dropdown__footer--save-btn' onClick={handleSaveBtnClick}>
                                    Save
                                </button>

                            </div>
                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div >
    )
}
