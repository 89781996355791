module.exports = {
    black: '#000',
    white: '#fff',
    lightGrey : '#777781',
    lightestGrey:'#e3e3e3',
    lightGreen : '#2ecc71',
    darkGrey : '#36363a',
    primary : '#ffa626',
    primaryLight : '#ffbb59',
    primaryLightest : '#ffd79d',
    primaryDark : '#ff9804',
    gradientBase : '#000',
    gradientColor1 : '#141D32',
    gradientColor2 : '#152441',
    gradientColor3 : '#111A2B',
    darkRed : '#D11A2A',
    darkGreen : '#229624',
    lightestBlueishgGrey : '#e8ecf6',
    darkBlueGradient : '#273963',
    purple : '#8E05C2'
}