import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const useStyles = makeStyles({
    root: {
        textAlign: 'justify',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    collapsed: props => ({
        '-webkit-line-clamp': props.limitLines,

    }),
    expanded: {
        '-webkit-line-clamp': 100,

    },
    expandBtn: {
        textAlign: 'right',
        fontWeight: '500',
        '&:hover': {
            color: Colors.primary,
            cursor: 'pointer'
        }
    }
})

export default function KalabExpandableText({ text, limitLines = 2 }) {
    const classes = useStyles({ limitLines });
    const { height, width } = useWindowDimensions();
    const [isExpanded, setIsExpanded] = useState(false)
    const [isExpandable, setIsExpandable] = useState(false);
    const textComponentRef = useRef(null);
    const isEllipsisActive = (e) => {
        return e.current.offsetHeight < e.current.scrollHeight;
    }

    useEffect(() => {
        setIsExpandable(isEllipsisActive(textComponentRef))
    }, [text, isExpanded, width, height])


    return (
        <>
            <p ref={textComponentRef} className={`${classes.root} ${isExpanded ? classes.expanded : classes.collapsed}`}>
                {text}
            </p>
            {
                (isExpandable || isExpanded) &&
                <div
                    className={classes.expandBtn}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? " Show Less" : " See More"}
                </div>

            }

        </>
    )
}
