import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import KalabListingCard from './KalabListingCard';
import KalabIconButton from '../kalabButtons/KalabIconButton';
import Colors from '../../../constants/Colors';
import { getLabelFromValue } from '../../../utils/Currencies';
import { useHistory } from "react-router-dom";
import KalabSmallButton from '../kalabButtons/KalabSmallButton';
import KalabMenuButton from '../kalabButtons/KalabMenuButton';
import PortaledComponent from '../../elements/portaledComponent/portaledComponent';
import SharePopUp from '../../popUps/SharePopUp';
import { openInNewTab } from '../../../utils/common';

const useStyles = makeStyles({
    root: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        padding: 1,
        borderRadius: 2,
        width: '100%',
        '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
            cursor: 'pointer'
        },

        '& .bottom-block': {
            padding: 20,

            '& .status': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 10,
                fontWeight: '400',
                paddingLeft: 5,
                paddingRight: 5
            },
            '& .active': {
                color: Colors.primary,
                borderColor: Colors.primary,
                borderWidth: 1,
                borderRadius: 5
            },
            '& .inactive': {
                color: Colors.lightGrey,
                borderColor: Colors.lightGrey,
                borderWidth: 1,
                borderRadius: 5
            },
            '& .artist-type': {
                '&__row': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                    '&--title': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 14,
                        fontWeight: '300',
                        marginBottom: 5,
                    },

                    '&--value': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 12,
                        fontWeight: '300',
                        color: Colors.lightGrey
                    }

                }
            },
            '& .buttons': {
                marginBottom: 10,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',

                '& .left': {
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    '&__edit-btn': {
                        marginRight: 10
                    },
                    '&__link-btn': {
                        marginRight: 10
                    }
                },
                '& .right': {
                    textAlign: 'center'
                }
            }
        }

    },
    switch: {
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: Colors.primary,
            '&:hover': {
                backgroundColor: 'rgba(255, 166, 38, 0.04)'
            }

        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: Colors.primaryLight
        }
    }


})

export default function KalabListingCardWithDetails({ listing, changeListingStatus }) {
    const [sharePopUp, setSharePopUp] = useState(false);
    const [linkToShare, setLinkToShare] = useState(null);
    const classes = useStyles();
    let history = useHistory();

    const showSharePopUp = (link) => {
        setLinkToShare(link);
        setSharePopUp(true);
    }

    const hideSharePopUp = () => {
        setSharePopUp(false);
        setLinkToShare(null);

    }
    const handleChangeOfStatus = () => {
        changeListingStatus(listing.isActive === 1 ? false : true, listing.listingId);
    }
    const handleEditButtonClick = () => {
        history.push('/artist/listing/edit', {
            listing: listing
        })

    }

    const handleGeneralBookingLinkButtonClick = () => {
        showSharePopUp(window.location.origin + '/link/' + listing.listingToken);

    }
    const handleSpecificBookingLinkButtonClick = () => {
        history.push('/artist/booking-link', {
            listing: listing
        })

    }
    return (
        <div className={classes.root} >
            <KalabListingCard
                listing={listing}
            />
            <div className="bottom-block">
                <div className="buttons">
                    <div className="left">
                        <div className="left__edit-btn">
                            <KalabSmallButton
                                text='Edit'
                                isLightTheme
                                onClick={handleEditButtonClick}
                            />
                        </div>
                        <div className="left__link-btn">
                            <KalabMenuButton
                                text='booking link'
                                isLightTheme
                                buttons={[
                                    {
                                        text: 'General',
                                        action: handleGeneralBookingLinkButtonClick
                                    },
                                    {
                                        text: 'Customise',
                                        action: handleSpecificBookingLinkButtonClick
                                    }
                                ]}

                            />
                        </div>
                        {/* <div className="left__delete-btn">
                            <KalabIconButton
                                iconName="delete_outline_rounded"
                                isLightTheme
                                noBorder
                                iconSize={15}
                            />
                        </div> */}
                    </div>
                    <div className="right">
                        <div className={`status ${listing.isActive ? ('active') : ('inactive')}`}>
                            {listing.isActive ? 'Active' : 'Inactive'}
                        </div>
                        <Switch className={classes.switch} size="small" checked={listing.isActive === 1 ? true : false} onChange={handleChangeOfStatus} />
                    </div>
                </div>
                <div className="artist-type">
                    <div className="artist-type__row">
                        <div className="artist-type__row--title">
                            Artist Type
                        </div>
                        <div className="artist-type__row--title">
                            Price
                        </div>
                    </div>
                    {listing.artistTypes.map(artistType => (
                        <div className="artist-type__row">
                            <div className="artist-type__row--value">
                                {artistType.artistTypeName}
                            </div>
                            <div className="artist-type__row--value">
                                {getLabelFromValue(artistType.currency)} {parseFloat(artistType.cost).toFixed(2)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {
                sharePopUp &&
                <PortaledComponent
                    modal={
                        <SharePopUp
                        title={'The general booking link to share is the following'}
                            closeFn={hideSharePopUp}
                            linkToShare={linkToShare}
                            okText={'Open'}
                            okFn={() => { openInNewTab(linkToShare) }}
                            okBtnColor={Colors.primary}
                        />
                    }
                />
            }
        </div >
    )
}