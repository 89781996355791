import React from 'react'

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    const containerStyles = {
      height: 3,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      display : 'flex',
      flexDirection : 'row-reverse'
    }
  
    const round = {
     height : 10,
     width : 10,
     backgroundColor: bgcolor,
     borderRadius : 10,
     alignSelf : 'center'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <div style={round}></div>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;