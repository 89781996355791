import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
    withStyles,
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const useStyles = makeStyles({
    root: props => ({
        borderWidth: 0.2,
        borderStyle: 'solid',
        borderColor: Colors.lightestGrey,
        borderRadius: 20,
        padding: 2,
        '& label': {
            display: 'none'
        },

        '& .MuiInput-underline': {
            '&:after': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0,
            },
            '&:before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0,

            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0
            },

        },
        '& input': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 14,
            fontWeight: '400',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& textarea': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 14,
            fontWeight: '400',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
            '&::placeholder': {
                color: props.isLightTheme ? Colors.black : Colors.white,
                fontSize: 14,
                fontWeight: '300',
                textAlign: 'center',
                fontFamily: 'Montserrat, sans-serif',
            }
        },
        transition: 'width 500ms',
        width: '50%',
        minWidth : props.width > 350 ? 200 : 150,
        margin: '0 auto',
        '&:focus-within': {
            width: '100% !important',
            borderColor: Colors.lightGrey
        },
        '& .MuiInputAdornment-positionEnd': {
            margin: 5
        }

    })
});


export default function KalabMessageField({
    className,
    id,
    value,
    onChange,
    onClick,
    placeholder,
    isRequired = false,
    endAdornment = null,
    disabled = false,
    isLightTheme = false
}) {
    const { height, width } = useWindowDimensions();
    const props = ({
        isLightTheme,
        width
    })
    const classes = useStyles(props);
    return (
        <TextField
            className={`${classes.root} ${className}`}
            id={id}
            type={'text'}
            value={value}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder}
            fullWidth
            disabled={disabled}
            required={isRequired}
            InputProps={{
                endAdornment: endAdornment,
                inputProps: {
                    maxLength: 2000
                }
            }}
            InputLabelProps={{ shrink: false }}
            multiline={true}
            rowsMax={3}
        />
    )
}