import React, { useState, useEffect, useContext } from 'react'
import './CreateTemplatePopUp.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { isAfter, isPast, isToday, parse, format } from 'date-fns';
import Colors from '../../constants/Colors';
import { useHistory } from "react-router-dom";
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabIconCheckBox from '../kalab/kalabCheckBoxes/KalabIconCheckBox';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabDatePickerDropDownNormal from '../kalab/kalabDropDown/KalabDatePickerDropDownNormal';
import KalabTimePickerDropDown from '../kalab/kalabDropDown/KalabTimePickerDropDown';
import { addNewEventTemplateRequest, updateEventTemplateRequest } from '../../webservices/Webservice';
import { getLabelFromValue } from '../../utils/Currencies';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { UserContext } from '../../contexts/UserContext';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { InputAdornment } from '@material-ui/core';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';

export default function UpdateTemplatePopUp({ closeFn, templates, templateToUpdate }) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { height, width } = useWindowDimensions();
    const { artistTypes, eventTypes, genres, ambiences, countries, loading } = useContext(DataStoreContext);
    const notify = useNotification();
    const [template, setTemplate] = useState({
        templateName: '',
        eventName: '',
        hours: null,
        dressCode: '',
        comment: '',
        songRequests: '',
        location: '',
        equipmentDetails: '',
        parking: false,
        equipment: false,
        accomodation: false,
        foodBeverage: false,
        transportation: false,
        eventType: null,
        artistType: null,
        genres: [],
    })

    const closePopUp = () => {
        setTemplate(
            {
                templateName: '',
                eventName: '',
                hours: null,
                dressCode: '',
                comment: '',
                songRequests: '',
                location: '',
                equipmentDetails: '',
                parking: false,
                equipment: false,
                accomodation: false,
                foodBeverage: false,
                transportation: false,
                eventType: null,
                artistType: null,
                genres: [],
            }
        )
        closeFn();
    }



    const updateTemplate = () => {
        showLoader();
        updateEventTemplateRequest(
            templateToUpdate.templateId,
            template.templateName.trim(),
            template.artistType ? template.artistType.value : null,
            template.eventType ? template.eventType.value : null,
            template.genres.map(genre => (genre.value)),
            template.eventName.trim() === '' ? null : template.eventName.trim(),
            template.location.trim() === '' ? null : template.location.trim(),
            template.hours ? template.hours.value : null,
            template.transportation,
            template.accomodation,
            template.foodBeverage,
            template.equipment,
            template.equipmentDetails.trim() === '' ? null : template.equipmentDetails.trim(),
            template.parking,
            template.songRequests.trim() === '' ? null : template.songRequests.trim(),
            template.comment.trim() === '' ? null : template.comment.trim(),
            template.dressCode.trim() === '' ? null : template.dressCode.trim(),
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                closePopUp();

            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }

    const genreOptions = () => {
        var genresOptions = [];
        genres.forEach(genre => {
            genresOptions.push({
                value: parseInt(genre.genreId),
                label: genre.genreName
            })

        });
        return genresOptions;
    }

    const artistTypeOptions = () => {
        var artistTypesOptions = [];
        artistTypes.forEach(artistType => {
            artistTypesOptions.push({
                value: parseInt(artistType.artistTypeId),
                label: artistType.artistTypeName
            })

        });
        return artistTypesOptions;
    }

    const eventTypeOptions = () => {
        var eventTypesOptions = [];
        eventTypes.forEach(eventType => {
            eventTypesOptions.push({
                value: parseInt(eventType.eventTypeId),
                label: eventType.eventTypeName
            })

        });
        return eventTypesOptions;

    }
    const hoursOptions = () => {
        var hoursOptions = [];
        for (var i = 1; i <= 12; i++) {
            hoursOptions.push({
                value: i,
                label: `${i} hour${i > 1 ? 's' : ''}`
            })
        }
        return hoursOptions;
    }

    const handleGenreSelectChange = (genreValues) => {
        setTemplate({
            ...template,
            genres: genreValues
        })
    }
    const handleArtistTypeSelectChange = (artistTypeValue) => {
        console.log(artistTypeValue);
        setTemplate({
            ...template,
            artistType: artistTypeValue
        })
    }
    const handleEventTypeSelectChange = (eventTypeValue) => {
        setTemplate({
            ...template,
            eventType: eventTypeValue
        })
    }


    const handleInputTextChange = (e) => {
        var updatedTemplate = {
            ...template
        }
        updatedTemplate[e.target.id] = e.target.value
        setTemplate(updatedTemplate);
    }

    const handleHoursSelectChange = (hourValue) => {
        setTemplate({
            ...template,
            hours: hourValue
        })
    }
    const handleChangeTransporationValue = (value) => {
        setTemplate({
            ...template,
            transportation: value
        })
    }
    const handleChangeAccomadationValue = (value) => {
        setTemplate({
            ...template,
            accomodation: value
        })
    }
    const handleChangeFoodBeverageValue = (value) => {
        setTemplate({
            ...template,
            foodBeverage: value
        })
    }
    const handleChangeParkingValue = (value) => {
        setTemplate({
            ...template,
            parking: value
        })
    }
    const handleChangeEquipmentValue = (value) => {
        setTemplate({
            ...template,
            equipment: value,
            equipmentDetails: value ? template.equipmentDetails : ''
        })
    }


    const handleClickUpdateTemplateBtn = () => {
        updateTemplate();
    }


    const isFormValid = () => {
        if (
            !isTemplateNameDuplicate() &&
            template.templateName.trim() !== '' &&
            (
                (
                    template.equipment &&
                    template.equipmentDetails.trim() !== ''
                ) ||
                !template.equipment
            ) &&
            !checkIfAlreadyFoundTemplate()
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isTemplateNameDuplicate = () => {
        if (
            templates.filter(temp => temp.templateId !== templateToUpdate.templateId).map(temp => temp.templateName ? temp.templateName.toLowerCase() : temp.templateName).includes(template.templateName.toLowerCase())
        ) {
            return true
        }
        else {
            return false
        }
    }

    const isValueChanged = ()=>{
        if(
            templateToUpdate.templateName === template.templateName.trim() &&
            templateToUpdate.eventName === (template.eventName.trim() === '' ? null : template.eventName.trim()) &&
            templateToUpdate.location === (template.location.trim() === '' ? null : template.location.trim()) &&
            templateToUpdate.artistTypeId === (template.artistType !== null ? template.artistType.value : null) &&
            templateToUpdate.eventTypeId === (template.eventType !== null ? template.eventType.value : null) &&
                (templateToUpdate.genres.map(genre => genre.genreId).sort((a, b) => (a - b)).join(',')) === (template.genres.map(genre => (genre.value)).sort((a, b) => (a - b)).join(',')) &&
                templateToUpdate.hours === (template.hours !== null ? template.hours.value : null) &&
                (templateToUpdate.transportation ? true : false) === template.transportation &&
                (templateToUpdate.accomodation ? true : false) === template.accomodation &&
                (templateToUpdate.foodBeverage ? true : false) === template.foodBeverage &&
                (templateToUpdate.equipment ? true : false) === template.equipment &&
                templateToUpdate.equipmentDetails === (template.equipmentDetails.trim() === '' ? null : template.equipmentDetails.trim()) &&
                (templateToUpdate.parking ? true : false) === template.parking &&
                templateToUpdate.songRequests === (template.songRequests.trim() === '' ? null : template.songRequests.trim()) &&
                templateToUpdate.comment === (template.comment.trim() === '' ? null : template.comment.trim()) &&
                templateToUpdate.dressCode === (template.dressCode.trim() === '' ? null : template.dressCode.trim())
        ){
            return false;
        }
        else{
            return true;
        }
    }


    const checkIfAlreadyFoundTemplate = () => {
        var result = false;
        console.log("test")
        templates.forEach(temp => {
            console.log(temp)
            if (
                temp.templateId !== templateToUpdate.templateId &&
                temp.eventName === (template.eventName.trim() === '' ? null : template.eventName.trim()) &&
                temp.location === (template.location.trim() === '' ? null : template.location.trim()) &&
                temp.artistTypeId === (template.artistType !== null ? template.artistType.value : null) &&
                temp.eventTypeId === (template.eventType !== null ? template.eventType.value : null) &&
                (temp.genres.map(genre => genre.genreId).sort((a, b) => (a - b)).join(',')) === (template.genres.map(genre => (genre.value)).sort((a, b) => (a - b)).join(',')) &&
                temp.hours === (template.hours !== null ? template.hours.value : null) &&
                (temp.transportation ? true : false) === template.transportation &&
                (temp.accomodation ? true : false) === template.accomodation &&
                (temp.foodBeverage ? true : false) === template.foodBeverage &&
                (temp.equipment ? true : false) === template.equipment &&
                temp.equipmentDetails === (template.equipmentDetails.trim() === '' ? null : template.equipmentDetails.trim()) &&
                (temp.parking ? true : false) === template.parking &&
                temp.songRequests === (template.songRequests.trim() === '' ? null : template.songRequests.trim()) &&
                temp.comment === (template.comment.trim() === '' ? null : template.comment.trim()) &&
                temp.dressCode === (template.dressCode.trim() === '' ? null : template.dressCode.trim())
            ) {
                result = true;
            }


        });

        console.log(result)
        return result;

    }

    useEffect(() => {
        console.log("test")
        if (templateToUpdate) {
            setTemplate(
                {
                    templateName: templateToUpdate.templateName,
                    eventName: templateToUpdate.eventName ? templateToUpdate.eventName : "",
                    hours: templateToUpdate.hours ? hoursOptions().filter(hoursOption => hoursOption.value === templateToUpdate.hours)[0] : null,
                    dressCode: templateToUpdate.dressCode ? templateToUpdate.dressCode : '',
                    comment: templateToUpdate.comment ? templateToUpdate.comment : '',
                    songRequests: templateToUpdate.songRequests ? templateToUpdate.songRequests : '',
                    location: templateToUpdate.location ? templateToUpdate.location : '',
                    equipmentDetails: templateToUpdate.equipmentDetails ? templateToUpdate.equipmentDetails : '',
                    parking: templateToUpdate.parking ? true : false,
                    equipment: templateToUpdate.equipment ? true : false,
                    accomodation: templateToUpdate.accomodation ? true : false,
                    foodBeverage: templateToUpdate.foodBeverage ? true : false,
                    transportation: templateToUpdate.transportation ? true : false,
                    eventType: templateToUpdate.eventTypeId ? eventTypeOptions().filter(eventTypeOption => eventTypeOption.value === templateToUpdate.eventTypeId)[0] : null,
                    artistType: templateToUpdate.artistTypeId ? artistTypeOptions().filter(artistTypeOption => artistTypeOption.value === templateToUpdate.artistTypeId)[0] : null,
                    genres: genreOptions().filter(genreOption => templateToUpdate.genres.map(genre => genre.genreId).includes(genreOption.value))
                }
            )
        }


    }, [templateToUpdate])





    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='client-template-popup-modal'>
                <div className='client-template-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='client-template-popup-modal__content--top'>
                        <div className='client-template-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closePopUp();
                                }}
                                isLightTheme
                            />
                        </div>

                    </div>
                    <div className='client-template-popup-modal__content--main'>
                        <div className="main-detail">
                            < div className="main-detail--row">
                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="templateName"
                                        placeholder="Template Name"
                                        onChange={handleInputTextChange}
                                        value={template.templateName}
                                        isLightTheme
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <KalabIconWithToolTip
                                                    toolTipText={template.templateName.trim() === "" ? "Enter a unique template name" : isTemplateNameDuplicate() ? 'Already in Use' : "Success"}
                                                    placement='top-start'
                                                    iconName={template.templateName.trim() === "" ? 'fas fa-question' : isTemplateNameDuplicate() ? 'fas fa-exclamation' : 'fas fa-check'}
                                                    iconSize={12}
                                                    isFAIcon={true}
                                                    color={template.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                    borderColor={template.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="eventName"
                                        placeholder="Event Name"
                                        onChange={handleInputTextChange}
                                        value={template.eventName}
                                        isLightTheme
                                    />
                                </div>
                            </div>
                            <div className="main-detail--row">

                                <div className="main-detail--row__element" >
                                    <KalabTextField
                                        type="text"
                                        id="location"
                                        placeholder="Location"
                                        onChange={handleInputTextChange}
                                        value={template.location}
                                        isLightTheme
                                    />
                                </div>
                                <div className="main-detail--row__element" >
                                    <KalabDropDown
                                        placeholder="Performance Duration"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        isMulti={false}
                                        name="hours"
                                        onChange={handleHoursSelectChange}
                                        options={hoursOptions()}
                                        isLightTheme={true}
                                        value={template.hours}
                                    />
                                </div>
                            </div>

                            <div className="main-detail--row">

                                <div className="main-detail--row__element" >
                                    <KalabDropDown
                                        placeholder="Artist Type"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="artistType"
                                        onChange={handleArtistTypeSelectChange}
                                        options={artistTypeOptions()}
                                        isLightTheme={true}
                                        value={template.artistType}
                                    />
                                </div>
                                <div className="main-detail--row__element">
                                    <KalabDropDown
                                        placeholder="Event Type"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="artistType"
                                        onChange={handleEventTypeSelectChange}
                                        options={eventTypeOptions()}
                                        isLightTheme={true}
                                        value={template.eventType}
                                    />
                                </div>


                            </div>
                            <div className="main-detail--row">
                                <div className="main-detail--row__element" >
                                    <KalabDropDown
                                        placeholder="Genres"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        isMulti={true}
                                        name="genres"
                                        onChange={handleGenreSelectChange}
                                        options={genreOptions()}
                                        isLightTheme={true}
                                        value={template.genres}
                                    />
                                </div>
                                <div className="main-detail--row__element" >
                                    <KalabTextField
                                        type="text"
                                        id="dressCode"
                                        placeholder="Dress Code"
                                        onChange={handleInputTextChange}
                                        value={template.dressCode}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>

                            </div>
                            <div className="main-detail--row" >
                                <div className="main-detail--row__element" style={{ textAlign: 'left' }}>
                                    <div className="main-detail--row__title-text">I am willing to provide the following...</div>
                                </div>
                                <div className="main-detail--row__element">
                                </div>
                            </div>
                            <div className="main-detail--row">
                                <div className="main-detail--row__element" style={{ display: 'flex', flexDirection: 'row' }} >
                                    <div className="main-detail--row__element--icon">
                                        <KalabIconCheckBox
                                            iconName="directions_car"
                                            iconText={width <= 500 ? "" : "Transportation"}
                                            value={template.transportation}
                                            onChange={handleChangeTransporationValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">
                                        <KalabIconCheckBox
                                            iconName="local_parking_rounded"
                                            iconText={width <= 500 ? "" : "Parking"}
                                            value={template.parking}
                                            onChange={handleChangeParkingValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="fastfood_rounded"
                                            iconText={width <= 500 ? "" : "Food & Beverage"}
                                            value={template.foodBeverage}
                                            onChange={handleChangeFoodBeverageValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="mic_rounded"
                                            iconText={width <= 500 ? "" : "Equipment"}
                                            value={template.equipment}
                                            onChange={handleChangeEquipmentValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="hotel"
                                            iconText={width <= 500 ? "" : "Accomodation"}
                                            value={template.accomodation}
                                            onChange={handleChangeAccomadationValue}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="main-detail--row" >
                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="songRequests"
                                        placeholder="Song Requests"
                                        onChange={handleInputTextChange}
                                        value={template.songRequests}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>
                                {
                                    template.equipment ?
                                        (
                                            <div className="main-detail--row__element">
                                                <KalabTextField
                                                    type="text"
                                                    id="equipmentDetails"
                                                    placeholder="Venue Equipment"
                                                    onChange={handleInputTextChange}
                                                    value={template.equipmentDetails}
                                                    isLightTheme
                                                    multiline
                                                    maxLength={500}
                                                    rowsMax={6}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="comment"
                                        placeholder="Comment"
                                        onChange={handleInputTextChange}
                                        value={template.comment}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>


                            </div>

                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 30,
                                    marginBottom: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <KalabMainButton
                                    disabled={!(isFormValid()) || !(isValueChanged()) }
                                    onClick={handleClickUpdateTemplateBtn}
                                    text={'Update Template'}
                                    iconName={'update'} />

                                {
                                    checkIfAlreadyFoundTemplate() ?
                                        (
                                            <div style={{ marginLeft: 10 }}>
                                                <KalabIconWithToolTip
                                                    toolTipText="Template is already found"
                                                    placement='top'
                                                    iconName={'fas fa-exclamation'}
                                                    iconSize={12}
                                                    isFAIcon={true}
                                                    color={Colors.darkRed}
                                                    borderColor={Colors.darkRed}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
