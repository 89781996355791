import React, { useState, useEffect, useContext } from 'react';
import Icon from '@material-ui/core/Icon';
import './MainHeader.scss';
import logo from '../../images/logo_round_yellow.png';
import logoWhite from '../../images/LogoWrittenWhite.png';
import logoBlack from '../../images/LogoWrittenBlack.png';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { NavLink } from 'react-router-dom';
import SearchBar from '../searchBar/SearchBar';
import { getImageUrl } from '../../webservices/Webservice';
import { UserContext } from '../../contexts/UserContext';
import PortaledComponent from '../modalPortal/ModalPortal';
import { ClientSignInPopUp } from '../popUps/ClientSignInPopUp';
import { ClientSignUpPopUp } from '../popUps/ClientSignUpPopUp';
import { ArtistSignInPopUp } from "../popUps/ArtistSignInPopUp";
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { useHistory } from "react-router-dom";
import { scroller } from 'react-scroll';

function MainHeader({ onlyLogo = false, logoIsBlack = false, fixed, isVisibleSearch, searchBtnVisible = false, isDarkBackground = false }) {
  const [modal, setModal] = useState(false);
  const [modeMode, setModalMode] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const { logOut, loggedInUserDetails } = useContext(UserContext);
  const { height, width } = useWindowDimensions();
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  let history = useHistory();
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuIsOpen(open);
  };

  const closeModal = () => {
    setModal(false);
    document.body.style.overflow = 'unset';
    if (width <= 900) {
      scroller.scrollTo('main-header', {
        duration: 100,
        delay: 100,
        smooth: 'easeInOutQuart',
        offset: -250,
      })
    }
  }

  const getList = () => {
    if (loggedInUserDetails.user && loggedInUserDetails.role === 'artist') {
      return (
        <div className='drawer'>
          <div className='section'>
            <div className='section__title'>{loggedInUserDetails.user.fullName}</div>
            <NavLink to='/artist/home' className='section__btn'>
              HOME
            </NavLink>
            {/* <NavLink to='/artist/bookings' className='section__btn'>
              Booking
            </NavLink> */}
            <NavLink to='/artist/inbox' className='section__btn'>
              Inbox
            </NavLink>
          </div>
          <div className='section'>
            <div
              className='section__btn'
              onClick={() => {
                setMenuIsOpen(false)
                handleLogOutBtn();
              }}
            >
              Log Out
            </div>
          </div>
        </div>
      )
    }
    else if (loggedInUserDetails.user && loggedInUserDetails.role === 'client') {
      return (
        <div className='drawer'>
          <div className='section'>
            <div className='section__title'>{loggedInUserDetails.user.companyFullName}</div>
            {
              loggedInUserDetails.user.isEnterprise ?
                (
                  <>
                    <NavLink to='/client/home' className='section__btn'>
                      HOME
                    </NavLink>
                    {/* <NavLink to='/client/bookings' className='section__btn'>
                      Booking
                    </NavLink> */}
                    <NavLink to='/client/template' className='section__btn'>
                      Template
                    </NavLink>
                    <NavLink to='/client/inbox' className='section__btn'>
                      Inbox
                    </NavLink>
                    <NavLink to='/client/discover' className='section__btn'>
                      Discover
                    </NavLink>
                    {/* <NavLink to='/client/wishlist' className='section__btn'>
                      Wishlist
                    </NavLink> */}
                  </>
                )
                :
                (
                  <>
                    <NavLink to='/client/home' className='section__btn'>
                      HOME
                    </NavLink>
                    <NavLink to='/client/inbox' className='section__btn'>
                      Inbox
                    </NavLink>
                  </>
                )
            }

          </div>
          <div className='section'>
            <div
              className='section__btn'
              onClick={() => {
                setMenuIsOpen(false)
                handleLogOutBtn();
              }}
            >
              Log Out
            </div>
          </div>
        </div>
      )

    }
    else {
      return (
        <div className='drawer'>
          <div className='section'>
            <div className='section__title'>Client</div>
            <div
              className='section__btn'
              onClick={() => {
                setMenuIsOpen(false)
                openModal('signupClient');
              }}
            >
              Sign Up
            </div>
            <div
              className='section__btn'
              onClick={() => {
                setMenuIsOpen(false)
                openModal('signinClient');
              }}
            >
              Sign In
            </div>
          </div>
          <div className='section'>
            <div className='section__title'>Artist</div>
            <NavLink to='/artist/signup' className='section__btn'>Sign Up</NavLink>
            <div
              className='section__btn'
              onClick={() => {
                setMenuIsOpen(false)
                openModal('signinArtist');
              }}
            >Sign In</div>
          </div>

        </div>
      )
    }

  }

  const handleVisibleSearchBar = () => {
    setIsVisible(!isVisible);
  };
  const openModal = (popUpName) => {
    document.body.style.overflow = 'hidden';
    setModalMode(popUpName);
    setModal(true);
  }

  const handleLogOutBtn = () => {
    logOut();
  }

  const handleSearchBtnClick = () => {
    history.push({
      pathname: '/search-results',
      state: {
        country: null,
        cities: [],
        artistTypes: [],
        genres: [],
        date: null
      }
    })
  }

  useEffect(() => {
    if (!isVisibleSearch) {
      setIsVisible(false);
    }
  }, [isVisibleSearch])

  useEffect(() => {
    if (width < 600) {
      setIsVisible(false)
    }
  }, [width])


  return (
    <>
      <div className={`main-header ${fixed ? 'main-header__is-fixed main-header__is-dark' : ''} ${isDarkBackground ? 'main-header__is-dark' : ''}`}>
        <div className='main-row'>
          <NavLink to="/" className="logo">
            <img
              src={logoIsBlack ? getImageUrl('LogoWrittenBlack'): getImageUrl('LogoWrittenWhite')}
              alt="Kalab Arts Logo"
              className="logo__image"
              onError={(e) => { e.target.onerror = null; e.target.src = logoIsBlack ? logoBlack : logoWhite }} />
          </NavLink>
          {
            !onlyLogo ?
              (
                <>
                  {
                    isVisibleSearch ?
                      (
                        width > 600 ?
                          (
                            <div className='small-search'>
                              <button
                                className="small-search__btn search-visible-animation"
                                onClick={handleVisibleSearchBar}
                              >
                                <div className="small-search__btn--text">Start your search...</div>
                                <div className="small-search__btn--icon-btn">
                                  <KalabIconButton
                                    iconName='search_rounded'
                                    isLightTheme
                                    noBorder
                                    isFilled
                                    height={10}
                                    iconSize={20}
                                  />
                                </div>

                              </button>
                            </div>
                          )
                          :
                          (
                            searchBtnVisible ?
                              (

                                <KalabMainButton
                                  text={'Discover artists'}
                                  onClick={handleSearchBtnClick}
                                  fontSize={width / 40 > 10 ? 10 : width / 40}
                                />

                              )
                              :
                              (
                                null
                              )
                          )

                      )
                      :
                      (
                        null
                      )
                  }

                  <div className='side-btns'>
                    {
                      width > 900 ?
                        (
                          loggedInUserDetails.user && loggedInUserDetails.role === 'artist' ?
                            (
                              <div className='dropdown'>
                                <button className='dropdown__icon-btn'>
                                  <Icon style={{ fontSize: 30 }}>person_outline_rounded</Icon>
                                </button>
                                <div className='dropdown__content'>
                                  <div className='dropdown__content--details'>
                                    <span className='dropdown__content--details__text'>{loggedInUserDetails.user.fullName}</span>
                                  </div>
                                  <NavLink
                                    to='/artist/home'
                                    className='dropdown__content--link'
                                  >
                                    THE HUB
                                  </NavLink>
                                  <div
                                    className='dropdown__content--link'
                                    onClick={() => {
                                      handleLogOutBtn();
                                    }}
                                  >
                                    Log Out
                                  </div>
                                </div>
                              </div>
                            )
                            :
                            (
                              loggedInUserDetails.user && loggedInUserDetails.role === 'client' ?
                                (
                                  <div className='dropdown'>
                                    <button className='dropdown__icon-btn'>
                                      <Icon style={{ fontSize: 30 }}>person_outline_rounded</Icon>
                                    </button>
                                    <div className='dropdown__content'>
                                      <div className='dropdown__content--details'>
                                        <span className='dropdown__content--details__text'>{loggedInUserDetails.user.companyFullName}</span>
                                      </div>
                                      <NavLink
                                        to='/client/home'
                                        className='dropdown__content--link'
                                      >
                                        THE HUB
                                      </NavLink>
                                      <div
                                        className='dropdown__content--link'
                                        onClick={() => {
                                          logOut();
                                        }}
                                      >
                                        Log Out
                                      </div>
                                    </div>
                                  </div>
                                )
                                :
                                (
                                  <>
                                    <div className='dropdown'>
                                      <button className='dropdown__btn'>Client</button>
                                      <div className='dropdown__content'>
                                        <div
                                          className='dropdown__content--link'
                                          onClick={() => {
                                            openModal('signupClient');
                                          }}
                                        >
                                          Sign Up
                                        </div>
                                        <div
                                          className='dropdown__content--link'
                                          onClick={() => {
                                            openModal('signinClient');
                                          }}
                                        >
                                          Sign In
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className='dropdown'>
                                      <button className='dropdown__btn'>Artist</button>
                                      <div className='dropdown__content'>
                                        <NavLink
                                          to='/artist/signup'
                                          className='dropdown__content--link'
                                        >
                                          Sign Up
                                        </NavLink>
                                        <div
                                          className='dropdown__content--link'
                                          onClick={() => {
                                            openModal('signinArtist');
                                          }}
                                        >
                                          Sign In
                                        </div>
                                      </div>
                                    </div> */}
                                  </>
                                )
                            )
                        )
                        :
                        (
                          <div className='dropdown'>
                            <KalabIconButton
                              iconName={loggedInUserDetails.user ? 'person_outline_rounded' : 'menu_rounded'}
                              noBorder
                              iconSize={30}
                              onClick={toggleDrawer(true)}
                            />
                          </div>
                        )
                    }


                  </div>
                </>
              )
              :
              (
                null
              )

          }
        </div>
        <div className={isVisible && isVisibleSearch && width > 600 ? "main-search__is-shown" : "main-search__is-hidden"}>
          {isVisible && isVisibleSearch && width > 600 ? <SearchBar changeColor={true} /> : null}
        </div>

      </div>


      {/* -------------modals from client sign in, sign up, and artist sign in------------------- */}
      {modal && (
        <PortaledComponent
          modal={
            modeMode === 'signinClient' && (
              <ClientSignInPopUp modal={modal} closeModal={closeModal} />
            )
          }
        />
      )}
      {modal && (
        <PortaledComponent
          modal={
            modeMode === 'signupClient' && (
              <ClientSignUpPopUp modal={modal} closeModal={closeModal} />
            )
          }
        />
      )}
      {modal && (
        <PortaledComponent
          modal={
            modeMode === 'signinArtist' && (
              <ArtistSignInPopUp modal={modal} closeModal={closeModal} />
            )
          }
        />
      )}
      {
        width > 900 ?
          (
            null
          )
          :
          (
            <SwipeableDrawer
              anchor={'right'}
              open={menuIsOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {getList()}
            </SwipeableDrawer>
          )
      }
    </>

  );
}

export default MainHeader;
