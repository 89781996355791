import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import {
  getListingFromTokenRequest,
  addListingToClientWishlistRequest,
  getClientRatingsFromListingIdRequest
} from "../../webservices/Webservice";
import { LoaderContext } from "../../contexts/LoaderContext";
import { UserContext } from "../../contexts/UserContext";
import { useNotification } from "../../contexts/NotificationContext";
import "./ListingPage.scss";
import { Icon, LinearProgress } from "@material-ui/core";
import { LocationOnOutlined, ForumOutlined, VerifiedUserOutlined } from "@material-ui/icons";
import CollageImage1 from '../collages/CollageImage1';
import CollageImage2 from '../collages/CollageImage2';
import CollageImage3 from '../collages/CollageImage3';
import CollageImage5 from '../collages/CollageImage5';
import KalabExpandableText from "../kalab/kalabExpandableField/KalabExpandableText";
import KalabExpandableList from "../kalab/kalabExpandableField/KalabExpandableList";
import KalabCalculatorCard from "../kalab/kalabCards/KalabCalculatorCard";
import KalabMusicIFrame from "../kalab/kalabIFrames/KalabMusicIFrame";
import KalabListingCalendar from "../kalab/kalabCalendar/KalabListingCalendar";
import KalabCalculatorUnknownUserCard from "../kalab/kalabCards/KalabCalculatorUnknownUserCard";
import KalabCalculatorArtistCard from "../kalab/kalabCards/KalabCalculatorArtistCard";
import MainHeader from "../header/MainHeader";
import PortaledComponent from "../elements/portaledComponent";
import ClientEventRequestCreatePopUp from "../popUps/ClientEventRequestCreatePopUp";
import KalabIconButton from "../kalab/kalabButtons/KalabIconButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { Helmet } from "react-helmet";
import Colors from "../../constants/Colors";
import KalabClientRatingCard from "../kalab/kalabCards/KalabClientRatingCard";
import KalabSmallButton from "../kalab/kalabButtons/KalabSmallButton";
SwiperCore.use([Pagination, Navigation]);

const ListingPage = (props) => {
  const { token } = useParams();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { loggedInUserDetails } = useContext(UserContext);
  const [listing, setListing] = useState(null);
  const [createEventRequestPopUpShow, setCreateEventRequestPopUpShow] = useState(false);
  const notify = useNotification();

  const [musicSwiper, setMusicSwiper] = useState(null);
  const [musicSwiperDetail, setMusicSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });

  const [valueSwiper, setValueSwiper] = useState(null);
  const [valueSwiperDetail, setValueSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });
  const { height, width } = useWindowDimensions();

  const closeCreateEventRequestPopUp = () => {
    document.body.style.overflow = 'unset';
    setCreateEventRequestPopUpShow(false);
  }

  const showCreateEventRequestPopUp = (eventRequest) => {
    document.body.style.overflow = 'hidden';
    setCreateEventRequestPopUpShow(true);
  }


  const getListing = () => {
    showLoader();
    getListingFromTokenRequest(token,
      (listing) => {
        hideLoader();
        setListing(listing)
        console.log(listing);
      }, (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
        props.history.replace('/')
      })
  }
  const handleShareBtnClick = () => {
    navigator.clipboard.writeText(window.location.href);
    notify({
      type: "SUCCESS",
      message: 'Copied to clipboard',
      title: "Copied!!!"
    })
  }
  const getCollageComponent = () => {
    switch (getImagesFromListing().selectedCollageIdentifier) {
      case 'COL1': return (<CollageImage1 images={getImagesFromListing().images} noCropOrEdit={true} />)
      case 'COL2': return (<CollageImage2 images={getImagesFromListing().images} noCropOrEdit={true} />)
      case 'COL3': return (<CollageImage3 images={getImagesFromListing().images} noCropOrEdit={true} />)
      case 'COL5': return (<CollageImage5 images={getImagesFromListing().images} noCropOrEdit={true} />)
      default: return (null)
    }
  }
  const getImagesFromListing = () => {
    var imageDetails = {
      selectedCollageIdentifier: listing.collageUniqueIdentifier,
      images: []
    }
    listing.listingImages.forEach(listingImage => {
      imageDetails.images.push({
        id: parseInt(listingImage.listingImagePosition),
        collageId: listing.noOfImages,
        image: {
          preview: listingImage.listingImageUrl,
          previewFile: null,
          rawUrl: listingImage.listingImageUrl,
          raw: null
        }

      })
    })
    return imageDetails;
  }
  const moveToWishlist = () => {
    showLoader();
    addListingToClientWishlistRequest(listing.listingId,
      (response) => {
        hideLoader();
        props.history.push('/client/wishlist')
      }, (error) => {
        hideLoader();
        if (error.code === 'DUPLICATE') {
          props.history.push('/client/wishlist')
        }
        else {
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!"
          })
        }
      })
  }
  const handleClickOnRequestToBookBtn = () => {
    // if (loggedInUserDetails && loggedInUserDetails.user.isEnterprise) {
    //   moveToWishlist();
    // }
    // else {
    showCreateEventRequestPopUp();
    // }
  }
  const handleClickOnAddToWishlistBtn = () => {
    showLoader();
    addListingToClientWishlistRequest(listing.listingId,
      (response) => {
        hideLoader();
        notify({
          type: 'SUCCESS',
          message: response,
          title: 'Success!!!',
        });
      }, (error) => {
        hideLoader();
        if (error.code === 'DUPLICATE') {
          notify({
            type: 'SUCCESS',
            message: 'Already added',
            title: 'Success!!!',
          });
        }
        else {
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!"
          })
        }
      })
  }

  const getMusicSlidesPerView = () => {
    var noOfSlides = 3;
    if (width / 350 < 3) {
      noOfSlides = Math.floor(width / 350);
    }
    if (noOfSlides == 0) {
      noOfSlides = 1
    }
    return noOfSlides;
  }

  const setHeaderData = () => {
    if (listing) {
      return (
        <Helmet>
          <title>{listing.listingName}</title>
        </Helmet>
      )
    }
    else {
      return (
        null
      )
    }
  }

  useEffect(() => {
    if (token) {
      getListing();
    }
  }, [token])

  const getTotalAverageRating = () => {
    return (
      (listing.averageClientRatings.avgProfessionalism +
        listing.averageClientRatings.avgCommunication +
        listing.averageClientRatings.avgFriendliness +
        listing.averageClientRatings.avgServiceQuality) / 4
    )
  }

  const getClientRatings = () => {
    showLoader();
    getClientRatingsFromListingIdRequest(listing.listingId,
      (clientRatings) => {
        hideLoader();
        setListing({
          ...listing,
          clientRatings:clientRatings
        })
        console.log(clientRatings);
      }, (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      })
  }
  const handleRatingsMoreBtnClick = ()=>{
    getClientRatings();
  }

  return (
    <div className="listing-page">
      <MainHeader isVisibleSearch={true} isDarkBackground />
      {listing ? (
        <>
          {setHeaderData()}
          <div className="listing-page__first-block">
            <div className="top-view">
              <div className="top-view__left">
                <p className="top-view__left--name">
                  {listing.listingName}
                </p>
                <div className="top-view__left--location">
                  <LocationOnOutlined className="top-view__left--location__icon" />
                  <span className="top-view__left--location__text">{listing.city}, {listing.country}</span>
                </div>
                {
                  listing.isInsured ?
                    (
                      <div className="top-view__left--insured">
                        <VerifiedUserOutlined className="top-view__left--insured__icon" />
                        <span className="top-view__left--insured__text">Insured</span>
                      </div>
                    )
                    :
                    (
                      null
                    )
                }

                <div className="top-view__left--languages">
                  <ForumOutlined className="top-view__left--languages__icon" />
                  <span className="top-view__left--languages__text">{listing.languages.join(', ')}</span>
                </div>
              </div>
              <div className="top-view__right">
                <div className="top-view__right--share-btn" onClick={handleShareBtnClick}>
                  <Icon className="top-view__right--share-btn__icon">share</Icon>
                  <span className="top-view__right--share-btn__text">Share</span>
                </div>
              </div>

            </div>
            <div className="collage-images">
              {getCollageComponent()}
            </div>
            <div className="detail-view">
              <div className="detail-view__left">
                {
                  width > 750 ?
                    (
                      <div className="values">
                        <div className="values__single">
                          <Icon className="values__single--icon">cake_outlined</Icon>
                          <p className="values__single--title">Event Type</p>
                          <p className="values__single--value">
                            <KalabExpandableList
                              list={listing.eventTypes.map(eventType => (
                                eventType.eventTypeName
                              ))}
                              limitLines={2}
                            />
                          </p>
                        </div>
                        <div className="values__single">
                          <Icon className="values__single--icon">query_builder_rounded</Icon>
                          <p className="values__single--title">Repertoire</p>
                          <p className="values__single--value">{listing.repertoire} hrs</p>
                        </div>
                        <div className="values__single">
                          <Icon className="values__single--icon">music_note_outlined</Icon>
                          <p className="values__single--title">Genre</p>
                          <p className="values__single--value">
                            <KalabExpandableList
                              list={listing.genres.map(genre => (
                                genre.genreName
                              ))}
                              limitLines={2}
                            />
                          </p>
                        </div>
                        <div className="values__single">
                          <Icon className="values__single--icon">mood_outlined</Icon>
                          <p className="values__single--title">Ambience</p>
                          <p className="values__single--value">
                            <KalabExpandableList
                              list={listing.ambiences.map(ambience => (
                                ambience.ambienceName
                              ))}
                              limitLines={2}
                            />
                          </p>
                        </div>
                        <div className="values__single">
                          <Icon className="values__single--icon">person_outline_rounded</Icon>
                          <p className="values__single--title">Artist Type</p>
                          <p className="values__single--value">
                            <KalabExpandableList
                              list={listing.artistTypes.map(artistType => (
                                artistType.artistTypeName
                              ))}
                              limitLines={2}
                            />
                          </p>
                        </div>
                        <div className="values__single">
                          <Icon className="values__single--icon">public_outlined</Icon>
                          <p className="values__single--title">Performance Type</p>
                          <p className="values__single--value">{listing.performanceType.performanceTypeName}</p>
                        </div>
                      </div>
                    )
                    :
                    (
                      <div className="value-swiper">
                        <div>
                          <KalabIconButton
                            iconName='keyboard_arrow_left_rounded'
                            isLightTheme
                            disabled={valueSwiperDetail.isBeginning}
                            onClick={() => {
                              valueSwiper.slidePrev();
                            }}
                          />
                        </div>
                        <Swiper
                          onUpdate={(swiper) => {
                            console.log(swiper);
                            setValueSwiper(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            });
                          }}
                          onSwiper={(swiper) => {
                            console.log(swiper);
                            setValueSwiper(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            });
                          }}
                          spaceBetween={1}
                          slidesPerView={Math.floor(width / 150) === 0 ? 1 : Math.floor(width / 150)}
                          freeMode={true}
                          onSlideChange={(swiper) => {
                            console.log(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            })
                          }}
                          className="mySwiper"
                        >
                          <SwiperSlide key='eventTypeValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">cake_outlined</Icon>
                              <p className="value-swiper__single--title">Event Type</p>
                              <p className="value-swiper__single--value">
                                <KalabExpandableList
                                  list={listing.eventTypes.map(eventType => (
                                    eventType.eventTypeName
                                  ))}
                                  limitLines={2}
                                />
                              </p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide key='repertoireValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">query_builder_rounded</Icon>
                              <p className="value-swiper__single--title">Repertoire</p>
                              <p className="value-swiper__single--value">{listing.repertoire} hrs</p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide key='genreValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">music_note_outlined</Icon>
                              <p className="value-swiper__single--title">Genre</p>
                              <p className="value-swiper__single--value">
                                <KalabExpandableList
                                  list={listing.genres.map(genre => (
                                    genre.genreName
                                  ))}
                                  limitLines={2}
                                />
                              </p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide key='ambienceValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">mood_outlined</Icon>
                              <p className="value-swiper__single--title">Ambience</p>
                              <p className="value-swiper__single--value">
                                <KalabExpandableList
                                  list={listing.ambiences.map(ambience => (
                                    ambience.ambienceName
                                  ))}
                                  limitLines={2}
                                />
                              </p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide key='artistTypeValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">person_outline_rounded</Icon>
                              <p className="value-swiper__single--title">Artist Type</p>
                              <p className="value-swiper__single--value">
                                <KalabExpandableList
                                  list={listing.artistTypes.map(artistType => (
                                    artistType.artistTypeName
                                  ))}
                                  limitLines={2}
                                />
                              </p>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide key='performanceTypeValue'>
                            <div className="value-swiper__single">
                              <Icon className="value-swiper__single--icon">public_outlined</Icon>
                              <p className="value-swiper__single--title">Performance Type</p>
                              <p className="value-swiper__single--value">{listing.performanceType.performanceTypeName}</p>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                        <div>
                          <KalabIconButton
                            iconName='keyboard_arrow_right_rounded'
                            isLightTheme
                            disabled={valueSwiperDetail.isEnd}
                            onClick={() => {
                              valueSwiper.slideNext();
                            }}
                          />
                        </div>
                      </div>
                    )
                }

                <div className="description-view">
                  <div className="description-view__title">Biography</div>
                  <KalabExpandableText
                    text={listing.description}
                    limitLines={2}
                  />
                </div>
                {(listing.setList !== null && listing.setList !== '') || listing.pastClients.length > 0 ?
                  (
                    <div className="set-list_past-clients">
                      {
                        (listing.setList !== null && listing.setList !== '') ?
                          (
                            <div className={`set-list ${listing.pastClients.length > 0 ? 'with-past-clients' : ''}`}>
                              <div className="set-list__title">Set List</div>
                              <KalabExpandableText
                                text={listing.setList}
                                limitLines={2}
                              />
                            </div>
                          )
                          :
                          (
                            null
                          )
                      }
                      {
                        listing.pastClients.length > 0 ?
                          (
                            <div className={`past-clients ${(listing.setList !== null && listing.setList !== '') ? 'with-set-list' : ''}`}>
                              <div className="past-clients__title">Past Clients</div>
                              <KalabExpandableList
                                list={listing.pastClients}
                                limitLines={2}
                              />
                            </div>
                          )
                          :
                          (
                            null
                          )
                      }


                    </div>
                  )
                  :
                  (
                    null
                  )}



              </div>
              <div className="detail-view__right">
                {
                  loggedInUserDetails.user ?
                    (
                      loggedInUserDetails.role === 'artist' ?
                        (
                          loggedInUserDetails.user.artistId === listing.artistId ?
                            (
                              <KalabCalculatorCard
                                artistTypes={listing.artistTypes}
                                isPreview={true}
                              />
                            )
                            :
                            (
                              // <KalabCalculatorArtistCard />
                              <KalabCalculatorUnknownUserCard
                              />
                            )


                        )
                        :
                        (
                          <KalabCalculatorCard
                            artistTypes={listing.artistTypes}
                            isPreview={false}
                            addToWishList={handleClickOnAddToWishlistBtn}
                            requestToBook={handleClickOnRequestToBookBtn}
                            isEnterprise={loggedInUserDetails.user.isEnterprise}
                          />
                        )

                    ) : (
                      <KalabCalculatorUnknownUserCard
                      />
                    )
                }

              </div>

            </div>

          </div>
          <div className="listing-page__music-block">
            <div className="listing-page__music-block--title">
              Music
            </div>
            {
              listing.listingMusicLinks.length <= getMusicSlidesPerView() ?
                (
                  <div className="music-view">
                    {listing.listingMusicLinks.map(listingMusicLink => (

                      <div className="music-view__iframe ">
                        <div className="aspectratio ar16-9" style={{ height: '100%' }}>
                          <KalabMusicIFrame
                            url={listingMusicLink.listingMusicLinkUrl}
                            platform={listingMusicLink.listingMusicLinkPlatform}
                          />
                        </div>
                      </div>
                    )
                    )}

                  </div>
                )
                :
                (
                  <div className="music-swiper">
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_left_rounded'
                        disabled={musicSwiperDetail.isBeginning}
                        onClick={() => {
                          musicSwiper.slidePrev();
                        }}
                      />
                    </div>
                    <Swiper
                      onUpdate={(swiper) => {
                        console.log(swiper);
                        setMusicSwiper(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      onSwiper={(swiper) => {
                        console.log(swiper);
                        setMusicSwiper(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      spaceBetween={1}
                      slidesPerView={getMusicSlidesPerView()}
                      freeMode={true}
                      onSlideChange={(swiper) => {
                        console.log(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        })
                      }}
                      className="mySwiper"
                    >
                      {listing.listingMusicLinks.map((listingMusicLink) => (
                        <SwiperSlide key={listingMusicLink.listingId}>
                          <div className="music-swiper__iframe ">
                            <div className="aspectratio ar16-9" style={{ height: '100%' }}>
                              <KalabMusicIFrame
                                url={listingMusicLink.listingMusicLinkUrl}
                                platform={listingMusicLink.listingMusicLinkPlatform}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_right_rounded'
                        disabled={musicSwiperDetail.isEnd}
                        onClick={() => {
                          musicSwiper.slideNext();
                        }}
                      />
                    </div>
                  </div>
                )
            }

          </div>
          <div className="listing-page__bottom-block">
            <div className="tr-calendar-view">
              <div className="technical-rider">
                <div className="technical-rider__title">Technical Rider</div>
                <KalabExpandableText
                  text={listing.technicalRider}
                  limitLines={5}
                />
              </div>
              {/* <div className="calendar">
                <div className="calendar__title">Check Available Dates</div>
                <div className="calendar__view" >
                  <KalabListingCalendar />
                </div>
              </div> */}
            </div>
            {
              listing.averageClientRatings && listing.clientRatings.length > 0
                ?
                (
                  <div className="ratings-section">
                    <div className="ratings-section__title">
                      <Icon className="ratings-section__title--star-icon">star</Icon>
                      <div className="ratings-section__title--rating">{getTotalAverageRating().toFixed(2)}</div>
                      <Icon className="ratings-section__title--dot-icon">fiber_manual_record</Icon>
                      <div className="ratings-section__title--rating">{listing.averageClientRatings.noOfRatings} Rating{listing.averageClientRatings.noOfRatings !== 1 ? 's' :''}</div>
                    </div>
                    <div className="ratings-section__details">
                      <div className="ratings-section__details--row">
                        <div className="detail">
                          <div className="detail__variable">
                            Professionalism
                          </div>
                          <div className="detail__value">
                            <div className="detail__value--progress" >
                              <LinearProgress variant="determinate" value={listing.averageClientRatings.avgProfessionalism / 5 * 100} />
                            </div>
                            <div className="detail__value--label">
                              {listing.averageClientRatings.avgProfessionalism.toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div className="detail">
                          <div className="detail__variable">
                            Friendliness
                          </div>
                          <div className="detail__value">
                            <div className="detail__value--progress" >
                              <LinearProgress variant="determinate" value={listing.averageClientRatings.avgFriendliness / 5 * 100} />
                            </div>
                            <div className="detail__value--label">
                              {listing.averageClientRatings.avgFriendliness.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ratings-section__details--row">
                        <div className="detail">
                          <div className="detail__variable">
                            Communication
                          </div>
                          <div className="detail__value">
                            <div className="detail__value--progress" >
                              <LinearProgress variant="determinate" value={listing.averageClientRatings.avgCommunication / 5 * 100} />
                            </div>
                            <div className="detail__value--label">
                              {listing.averageClientRatings.avgCommunication.toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div className="detail">
                          <div className="detail__variable">
                            Service Quality
                          </div>
                          <div className="detail__value">
                            <div className="detail__value--progress" >
                              <LinearProgress variant="determinate" value={listing.averageClientRatings.avgServiceQuality / 5 * 100} />
                            </div>
                            <div className="detail__value--label">
                              {listing.averageClientRatings.avgServiceQuality.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ratings-section__ratings">
                      {
                        listing.clientRatings.map(clientRating => (
                          <div key={'client_rating_' + clientRating.clientRatingId} className='rating-single'>
                            <KalabClientRatingCard clientRating={clientRating} />
                          </div>
                        ))
                      }
                    </div>
                    {
                      listing.clientRatings.length !== listing.averageClientRatings.noOfRatings ?
                        (
                          <div className="more-btn">
                            <KalabSmallButton
                              text={'See More'}
                              isLightTheme
                              onClick={handleRatingsMoreBtnClick}
                            />
                          </div>

                        )
                        :
                        (
                          null
                        )

                    }
                  </div>
                )
                :
                (
                  null
                )
            }
          </div>
        </>
      ) : (
        null
      )}
      {createEventRequestPopUpShow && listing && <PortaledComponent modal={<ClientEventRequestCreatePopUp closeFn={closeCreateEventRequestPopUp} listing={listing} />} />}
    </div>
  );
};

export default ListingPage;
