import React, { useState, useContext, useEffect } from 'react';
import './ClientSignUpPopUp.scss';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";

import {
  getCountriesRequest,
  getCountryCodesRequest,
  generateOTPRequest,
  checkOTPRequest,
  resendOTPRequest,
} from '../../webservices/Webservice';
import { openInNewTab } from '../../utils/common';


import RegularExpression from '../../utils/RegularExpression';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import { InputAdornment } from '@material-ui/core';

import { registerClientCompanyRequest, registerClientIndividualRequest } from '../../webservices/Webservice';
import { useHistory } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const ClientSignUpPopUp = ({ closeModal }) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const notify = useNotification();
  let history = useHistory();
  const { height, width } = useWindowDimensions();


  const [client, setClient] = useState({
    companyName: '',
    fullName: '',
    contactName: '',
    email: '',
    password: '',
    cPassword: '',
    countryCode: null,
    phone: '',
    otp: '',
    secretKey: '',
  });
  const [loading, setLoading] = useState({
    countryCode: false,
  });

  const [isVerify, setIsVerify] = useState(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [resendCount, setResendCount] = useState(0);
  const [verifiedNumber, setVerifiedNumber] = useState({
    countryCode: null,
    phone: null,
  });
  const [agreePrivacyTerms, setAgreePrivacyTerms] = useState(false);
  const [isEnterprise, setIsEnterprise] = useState(false);

  const registerClientCompany = () => {
    showLoader();
    registerClientCompanyRequest(
      client.companyName,
      client.contactName,
      client.email,
      client.password,
      client.countryCode.value.countryCode,
      client.phone,
      client.secretKey,
      (response) => {
        console.log(response);
        closeModal();
        hideLoader();
        //  props.history.push('/artist/application/result');
        history.push({
          pathname: '/client/registration/result',
          state: {
            breakProtection: true,
            response: response
          }
        })
      }, (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      }
    )

  }
  const registerClientIndividual = () => {
    showLoader();
    registerClientIndividualRequest(
      client.fullName,
      client.email,
      client.password,
      client.countryCode.value.countryCode,
      client.phone,
      (response) => {
        console.log(response);
        closeModal();
        hideLoader();
        //  props.history.push('/artist/application/result');
        history.push({
          pathname: '/client/registration/result',
          state: {
            breakProtection: true,
            response: response
          }
        })
      }, (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      }
    )

  }


  const isFormValid = () => {
    if (
      RegularExpression.email.test(
        client.email
      ) &&
      client.companyName !== '' &&
      client.contactName !== '' &&
      client.countryCode != null &&
      client.phone !== '' &&
      client.secretKey !== '' &&
      passwordValid() &&
      checkPhoneVerified() &&
      agreePrivacyTerms &&
      isEnterprise
    ) {
      return true;
    }
    else if (
      RegularExpression.email.test(
        client.email
      ) &&
      client.fullName !== '' &&
      client.countryCode != null &&
      client.phone !== '' &&
      passwordValid() &&
      checkPhoneVerified() &&
      agreePrivacyTerms &&
      !isEnterprise
    ) {
      return true;
    }

    else {
      return false;
    }
  };
  const passwordValid = () => {
    if (
      client.password === client.cPassword &&
      client.password !== '' &&
      client.password.length >= 6
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAgreeTermsServiceCheckboxClick = () => {
    setAgreePrivacyTerms(!agreePrivacyTerms);
  };

  const handleIndividualBtnClick = () => {
    setIsEnterprise(false);
  };

  const handleEnterpriseBtnClick = () => {
    setIsEnterprise(true);
  };

  const handleClickOnlink = (link) => {
    openInNewTab(window.location.origin + '/' + link);
  }

  const handleCountryCodeSelectChange = (countryCodeValue) => {
    setClient({
      ...client,
      countryCode: countryCodeValue,
    });
    if (isVerify) {
      setIsVerify(false);
    }
  };

  const handleInputTextChange = (e) => {
    var updatedClient = {
      ...client,
    };
    updatedClient[e.target.id] = e.target.value;
    setClient(updatedClient);
  };

  const checkPhoneVerified = () => {
    if (
      verifiedNumber.phone &&
      verifiedNumber.countryCode &&
      client.countryCode &&
      client.phone === verifiedNumber.phone &&
      client.countryCode.value.countryCode ===
      verifiedNumber.countryCode
    ) {
      return true;
    } else {
      return false;
    }
  };
  const checkOTP = () => {
    showLoader();
    checkOTPRequest(
      client.countryCode.value.countryCode,
      client.phone,
      client.otp,
      (response) => {
        hideLoader();
        notify({
          type: 'SUCCESS',
          message: response,
          title: 'Success!!!',
        });
        setVerifiedNumber({
          countryCode: client.countryCode.value.countryCode,
          phone: client.phone,
        });
      },
      (error) => {
        hideLoader();
        notify({
          type: 'ERROR',
          message: error.message ? error.message : error,
          title: error.title ? error.title : 'Error!!!',
        });
      }
    );
  };
  const handleResendOTPButton = (e) => {
    e.preventDefault();
    resendOTP();
  };
  const resendOTP = () => {
    showLoader();
    resendOTPRequest(
      client.countryCode.value.countryCode,
      client.phone,
      (response) => {
        hideLoader();
        notify({
          type: 'SUCCESS',
          message: response,
          title: 'Success!!!',
        });
        var count = resendCount + 1;
        setResendCount(count);
      },
      (error) => {
        hideLoader();
        notify({
          type: 'ERROR',
          message: error.message ? error.message : error,
          title: error.title ? error.title : 'Error!!!',
        });
      }
    );
  };
  const handleOTPCheckButton = (e) => {
    e.preventDefault();
    checkOTP();
  };
  const handleVerifyButton = (e) => {
    e.preventDefault();
    generateOTP();
  };
  const generateOTP = () => {
    showLoader();
    generateOTPRequest(
      client.countryCode.value.countryCode,
      client.phone,
      (response) => {
        hideLoader();
        notify({
          type: 'SUCCESS',
          message: response,
          title: 'Success!!!',
        });
        setIsVerify(true);
        setResendCount(0);
      },
      (error) => {
        hideLoader();
        notify({
          type: 'ERROR',
          message: error.message ? error.message : error,
          title: error.title ? error.title : 'Error!!!',
        });
      }
    );
  };

  const getCountryCodes = () => {
    setLoading({
      ...loading,
      country: true,
    });
    getCountryCodesRequest(
      (countryCodes) => {
        var countryCodeOptionsFromAPI = [];
        countryCodes.forEach((countryCode) => {
          countryCodeOptionsFromAPI.push({
            value: countryCode,
            label: `+${countryCode.countryCode} (${countryCode.countryAsciiName !== ''
              ? countryCode.countryAsciiName
              : countryCode.countryName
              })`,
            countryCode,
          });
        });
        setCountryCodeOptions(countryCodeOptionsFromAPI);
        setLoading({
          ...loading,
          countryCode: false,
        });
      },
      (error) => {
        notify({
          type: 'ERROR',
          message: error.message ? error.message : error,
          title: error.title ? error.title : 'Error!!!',
        });
        setLoading({
          ...loading,
          countryCode: false,
        });
      }
    );
  };

  useEffect(() => {
    getCountryCodes();
  }, []);

  const handleSignUp = (e) => {
    e.preventDefault();
    console.log(client)
    if (isEnterprise) {
      registerClientCompany();
    }
    else {
      registerClientIndividual();
    }



  }
  return (
    <div className='sign-up-background'>
      <form
        onSubmit={handleSignUp}
        className='main-box'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='main-box__btn-row'>
          <KalabIconButton
            iconName={'fas fa-times'}
            iconSize={20}
            isFAIcon
            noDisabled
            height={10}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <div className='main-box__link-row'>
          <div className='link'>
            <div className={`link__text ${!isEnterprise ? 'link__active' : ''}`} onClick={handleIndividualBtnClick} >
              INDIVIDUAL
            </div>
          </div>
          <div className='link'>
            <div className={`link__text ${isEnterprise ? 'link__active' : ''}`} onClick={handleEnterpriseBtnClick}>
              ENTERPRISE
            </div>
          </div>
        </div>


        <div className={`main-box__row ${isEnterprise && width < 800 ? ('main-box__row--is-not-row') : (!isEnterprise && width < 500 ? ('main-box__row--is-not-row') : (''))}`}>
          {isEnterprise ?
            (
              <>
                <div className='input'>
                  <KalabTextField
                    type='text'
                    id='companyName'
                    placeholder='Company Name'
                    value={client.companyName}
                    onChange={handleInputTextChange}
                  />
                </div>
                <div className='input'>
                  <KalabTextField
                    type='text'
                    id='contactName'
                    placeholder='Contact Name'
                    value={client.contactName}
                    onChange={handleInputTextChange}
                  />
                </div>
              </>
            )
            :
            (
              <div className='input'>
                <KalabTextField
                  type='text'
                  id='fullName'
                  placeholder='Full Name'
                  value={client.fullName}
                  onChange={handleInputTextChange}
                />
              </div>
            )
          }
          <div className='input'>
            <KalabTextField
              type='text'
              id='email'
              placeholder='Email'
              value={client.email}
              onChange={handleInputTextChange}
            />
          </div>
        </div>
        <div className={`main-box__row ${isEnterprise && width < 800 ? ('main-box__row--is-not-row') : (!isEnterprise && width < 500 ? ('main-box__row--is-not-row') : (''))}`}>
          <div className='input'>
            <KalabTextField
              type='password'
              id='password'
              placeholder='Password'
              value={client.password}
              onChange={handleInputTextChange}
            />
          </div>
          <div className='input'>
            <KalabTextField
              type='password'
              id='cPassword'
              placeholder='Confirm Password'
              value={client.cPassword}
              onChange={handleInputTextChange}
            />
          </div>
          {isEnterprise ? (
            <>
              {width < 800 ?
                (
                  <div className='input'>
                    {
                      client.password.length > 0 ?
                        (
                          passwordValid() ?
                            (<><i className="fas fa-check-circle register-icon" /><span className='verified-text'>Password Matches</span></>)
                            : (client.password.length < 6 ?
                              (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Need minimum 6 characters</span></>)
                              :
                              (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Passwords are not matching</span></>)
                            )
                        )
                        :
                        ('')
                    }
                  </div>
                )
                :
                (
                  null
                )
              }
              <div className='input'>
                <KalabTextField
                  type='text'
                  id='secretKey'
                  placeholder='Secret Key'
                  value={client.secretKey}
                  onChange={handleInputTextChange}
                  endAdornment={(
                    <InputAdornment position="end">
                      <KalabIconWithToolTip
                        toolTipText="Contact Admin For Secret Key"
                        placement="right"
                        iconName={'fas fa-question'}
                        iconSize={12}
                        isFAIcon={true}
                      />

                    </InputAdornment>
                  )}
                />
              </div>
            </>
          ) : (
            null
          )}
        </div>
        {
          isEnterprise && width < 800 ?
            (
              null
            )
            :
            (
              <div className={`pwd-match-txt`} style={{ paddingLeft: (isEnterprise ? 30 : 45) }}>
                {
                  client.password.length > 0 ?
                    (
                      passwordValid() ?
                        (<><i className="fas fa-check-circle register-icon" /><span className='verified-text'>Password Matches</span></>)
                        : (client.password.length < 6 ?
                          (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Need minimum 6 characters</span></>)
                          :
                          (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Passwords are not matching</span></>)
                        )
                    )
                    :
                    ('')
                }
              </div>
            )

        }



        <div className={`main-box__row ${isEnterprise && width < 800 ? ('main-box__row--is-not-row') : (!isEnterprise && width < 500 ? ('main-box__row--is-not-row') : (''))}`} style={{ alignItems: 'flex-end' }}>
          <div className='input'>
            <KalabDropDown
              placeholder="Country Code"
              isDisabled={false}
              isLoading={loading.countryCode}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="countryCode"
              onChange={handleCountryCodeSelectChange}
              options={countryCodeOptions}
              value={client.countryCode}
            />
          </div>

          <div className='input'>
            <KalabTextField
              type='number'
              id='phone'
              placeholder='Phone'
              value={client.phone}
              onChange={(e) => {
                handleInputTextChange(e);
                if (isVerify) {
                  setIsVerify(false)
                }
              }}
            />
          </div>

          {
            isEnterprise ? (
              checkPhoneVerified() ?
                (
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20, justifyContent: 'center', textAlign: 'center' }}>
                    <i className="fas fa-check-circle register-icon" /><span className='verified-text'>Verified</span>
                  </div>
                )
                :
                (isVerify ?
                  (
                    <div style={{ flex: 1, display: 'flex', marginTop: 20, marginBottom: 20 }}>
                      <div style={{ flex: 1, display: 'flex' }}>
                        <KalabTextField
                          type="number"
                          id="otp"
                          placeholder="OTP"
                          value={client.otp}
                          onChange={handleInputTextChange}
                        />
                      </div>
                      <div style={{ marginLeft: 5, marginRight: 5, alignSelf: 'flex-end' }}>
                        <KalabSmallButton
                          text="check"
                          onClick={handleOTPCheckButton}
                          disabled={client.otp === ""}
                        />
                      </div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <KalabSmallButton
                          text="Resend"
                          onClick={handleResendOTPButton}
                          disabled={resendCount > 2 ? true : false}
                        />
                      </div>
                    </div>
                  )
                  :
                  (
                    <div style={{ flex: 1, marginTop: 20, marginBottom: 20, justifyContent: 'center', textAlign: 'center' }}>
                      <KalabSmallButton
                        text="Verify"
                        onClick={handleVerifyButton}
                        disabled={client.phone === "" || client.countryCode === null}
                      />
                    </div>
                  ))
            ) : (
              null
            )
          }
        </div>
        {
          isEnterprise ?
            (
              null
            )
            :
            (
              <div style={{ flex: 1, display: 'flex' }}>
                {checkPhoneVerified() ?
                  (
                    <>
                      {width < 500 ?
                        (
                          null
                        ) :
                        (
                          <div style={{ flex: 1 }}>
                          </div>
                        )}
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                        <i className="fas fa-check-circle register-icon" /><span className='verified-text'>Verified</span>
                      </div>
                    </>
                  )
                  :
                  (isVerify ?
                    (
                      <>
                        <div style={{ flex: 1, display: 'flex' }}>
                          <KalabTextField
                            type="number"
                            id="otp"
                            placeholder="OTP"
                            value={client.otp}
                            onChange={handleInputTextChange}
                          />
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                          <div style={{ alignSelf: 'flex-end' }}>
                            <KalabSmallButton
                              text="check"
                              onClick={handleOTPCheckButton}
                              disabled={client.otp === ""}
                            />
                          </div>
                          <div style={{ alignSelf: 'flex-end', paddingLeft: 20 }}>
                            <KalabSmallButton
                              text="Resend"
                              onClick={handleResendOTPButton}
                              disabled={resendCount > 2 ? true : false}
                            />
                          </div>
                        </div>
                      </>
                    )
                    :
                    (
                      <>
                        {width < 500 ?
                          (
                            null
                          ) :
                          (
                            <div style={{ flex: 1 }}>
                            </div>
                          )}

                        <div style={{ flex: 1, textAlign: 'center' }}>
                          <KalabSmallButton
                            text="Verify"
                            onClick={handleVerifyButton}
                            disabled={client.phone === "" || client.countryCode === null}
                          />
                        </div>
                      </>
                    ))}

              </div>
            )
        }

        <div
          style={{
            textAlign: 'center',
            marginTop: 20,
            width : '80%',
            marginLeft : 'auto',
            marginRight :'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <KalabCheckBox
            checked={agreePrivacyTerms}
            onChange={handleAgreeTermsServiceCheckboxClick}
            label={
              <div style={{ cursor: 'default' }}>
                I have read and agree to the&nbsp;
                <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('privacy-policy') }}>
                  privacy policy
                </span>
                &nbsp;and&nbsp;
                <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('terms-of-service') }}>
                  terms of service
                </span>
              </div>
            }
          />
        </div>

        <div className='main-box__main-btn-row'>
          <KalabMainButtonWithIcon
            type={'submit'}
            disabled={(!isFormValid())}
            onClick={handleSignUp}
            text={'Sign Up'}
            iconName={'done'}
          />
        </div>
      </form>
    </div>
  );
};
