import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        height: 'auto',
        '& .select-btn': {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: props.showDropDown || props.value !== null ? Colors.black : Colors.lightestGrey,
            borderRadius: 25,
            paddingTop: 5,
            paddingLeft: 10,
            paddingBottom: 5,
            paddingRight: 10,
            width: '100%',
            height: '100%',
            fontFamily: 'Montserrat, sans-serif',
            color: props.showDropDown || props.value !== null ? Colors.black : Colors.lightGrey,
            fontSize: 14,
            '&:hover': {
                borderColor: Colors.black,
                color: Colors.black,
            }

        },
        '& .select-dropdown': {
            position: 'absolute',
            zIndex: 10,
            transform: 'translate(0,10px)',
            backgroundColor: Colors.white,
            border: '1px solid',
            borderColor: Colors.lightestGrey,
            borderRadius: 5,
            display: 'flex',
            padding: 10,
            overflowY: 'auto',
            minWidth: '100%',

            flexDirection: 'column',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '&__calendar': {
                '& .MuiPickersDay-current': {
                    color: Colors.primary
                },
                '& .MuiPickersDay-daySelected': {
                    color: props.date !== null ? Colors.white : Colors.primary,
                    backgroundColor: props.date !== null ? Colors.primary : Colors.white
                },
                '& .MuiTypography-body1': {
                    fontSize: 14,
                    fontWeight: '400',
                    fontFamily: 'Montserrat, sans-serif'
                },
                '& .MuiTypography-body2': {
                    fontSize: 13,
                    fontWeight: '300',
                    fontFamily: 'Montserrat, sans-serif'
                },
                '& .MuiTypography-caption': {
                    fontSize: 13,
                    fontWeight: '300',
                    fontFamily: 'Montserrat, sans-serif'
                },
            },
            '&__footer': {
                borderTopWidth: 1,
                paddingTop: 15,
                paddingBottom: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 5,
                '&--save-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginLeft: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        backgroundColor: Colors.lightGrey,
                        color: Colors.white
                    },

                },
                '&--clear-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginRight: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.black,
                        color: Colors.black
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        color: Colors.lightGrey
                    },
                }
            }

        },

    })
});

export default function KalabFilterDatePickerDropDown({
    onChange,
    value,
    placeholder
}) {
    const wrapperRef = useRef(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const [date, setDate] = useState(null);
    const getBtnTextAccordingToValue = () => {
        if (value) {
            return (format(value, "dd/MM/yyyy"));
        }
        else {
            return (placeholder)
        }
    }


    const handleBtnClick = (e) => {
        e.preventDefault();
        setShowDropDown(!showDropDown);
    }

    const handleDateChangeValue = (value) => {
        setDate(value)
    }

    const intialiseDate = () => {
        setDate(value)
    }
    const handleSaveBtnClick = (e) => {
        e.preventDefault();
        onChange(date);
        setShowDropDown(false);
    }

    const handleClearBtnClick = (e) => {
        e.preventDefault();
        setDate(null)
    }
    const isClearButtonDisabled = () => {
        if (date) {
            return false
        }
        else {
            return true
        }
    }

    const classes = useStyles({ showDropDown, value, date });

    useEffect(() => {
        intialiseDate();
    }, [showDropDown, value])


    const useClickOutsideModal = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowDropDown(false);
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useClickOutsideModal(wrapperRef);

    return (
        <div ref={wrapperRef} className={classes.root}>
            <button className={`${'select-btn'}`} onClick={handleBtnClick}>
                {getBtnTextAccordingToValue()}
            </button>
            {
                showDropDown
                    ?
                    (
                        <div className='select-dropdown'>
                            <div className='select-dropdown__calendar'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disableToolbar
                                        disablePast
                                        orientation="landscape"
                                        variant="static"
                                        openTo="date"
                                        clearable
                                        format='dd/MM/yyyy'
                                        value={date}
                                        onChange={handleDateChangeValue}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className='select-dropdown__footer'>
                                <button className='select-dropdown__footer--clear-btn' onClick={handleClearBtnClick} disabled={isClearButtonDisabled()}>
                                    Clear
                                </button>
                                <button className='select-dropdown__footer--save-btn' onClick={handleSaveBtnClick}>
                                    Save
                                </button>

                            </div>
                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div>
    )
}
