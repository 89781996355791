module.exports = {
    STRIPE_CARD : {
        icon : 'fas fa-credit-card',
        color : '#17a2b8',
        text : 'Card',
        artistText : 'Card Payment'
    },
    DIRECT : {
        icon : 'fas fa-handshake',
        color : '#343a40',
        text : 'Bank Transfer',
        artistText : 'Bank Transfer'
    }
}