import React, { useState, useContext, useEffect } from 'react'
import './PreviewSubmit.scss'
import { scroller } from 'react-scroll';
import CollageImage1 from '../collages/CollageImage1';
import CollageImage2 from '../collages/CollageImage2';
import CollageImage3 from '../collages/CollageImage3';
import CollageImage5 from '../collages/CollageImage5';
import { getLabelFromValue } from '../../utils/Currencies';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import { registerArtistRequest, addNewListingRequest, updateListingRequest } from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";


import { Icon } from "@material-ui/core";
import { LocationOnOutlined, ForumOutlined, VerifiedUserOutlined } from "@material-ui/icons";
import KalabExpandableText from "../kalab/kalabExpandableField/KalabExpandableText";
import KalabExpandableList from "../kalab/kalabExpandableField/KalabExpandableList";
import KalabCalculatorCard from "../kalab/kalabCards/KalabCalculatorCard";
import KalabMusicIFrame from "../kalab/kalabIFrames/KalabMusicIFrame";
import KalabListingCalendar from "../kalab/kalabCalendar/KalabListingCalendar";
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';

import { openInNewTab } from '../../utils/common';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import Colors from '../../constants/Colors';

import KalabIconButton from "../kalab/kalabButtons/KalabIconButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);

function PreviewSubmit({ artist, completed, history, isAddListing = false, isEditListing = false, oldListing = null, listingDetail = null }) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const [musicSwiper, setMusicSwiper] = useState(null);
    const [musicSwiperDetail, setMusicSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const [valueSwiper, setValueSwiper] = useState(null);
    const [valueSwiperDetail, setValueSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });
    const { height, width } = useWindowDimensions();
    // const [agreePrivacyTerms, setAgreePrivacyTerms] = useState(false);
    console.log(artist);

    // const handleAgreeTermsServiceCheckboxClick = () => {
    //     setAgreePrivacyTerms(!agreePrivacyTerms);
    // };

    const handleClickOnlink = (link) => {
        openInNewTab(window.location.origin + '/' + link);
    }
    const addListing = (e) => {
        e.preventDefault();
        showLoader();
        var imageFiles = [];
        console.log(artist.musicImages.images);
        artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
            if (artist.musicImages.selectedCollage === image.collageId) {
                imageFiles.push(image.image.previewFile);
            }
        });
        console.log(imageFiles);
        addNewListingRequest(
            imageFiles,
            artist.artistBasicInfo.repertoire.value,
            artist.artistBasicInfo.clients,
            artist.artistBasicInfo.genres.map(genre => (genre.value)),
            artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
            artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
            artist.artistBasicInfo.artistTypePrices,
            artist.artistBasicInfo.performanceType.value,
            artist.description.description,
            artist.description.setList,
            artist.description.technicalRider,
            artist.description.isInsured,
            artist.musicImages.musicLinks,
            'COL' + artist.musicImages.selectedCollage,
            artist.artistBasicInfo.listingName,
            (response) => {
                console.log(response);
                hideLoader();
                history.push({
                    pathname: '/artist/home',
                })
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const updateListing = (e) => {
        e.preventDefault();
        if (isEditListing && listingDetail && oldListing) {
            showLoader();
            var imageFilesToUploadWithPosition = [];
            var imagesToRetain = [];
            var oldImages = [];
            console.log(artist.musicImages.images);
            artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
                if (artist.musicImages.selectedCollage === image.collageId) {
                    if (image.image.preview === image.image.rawUrl) {
                        listingDetail.listingImages.forEach(listingImage => {
                            if (listingImage.listingImageUrl === image.image.preview) {
                                imagesToRetain.push({
                                    imageUrl: listingImage.listingImageUrl,
                                    imageName: listingImage.listingImageName,
                                    imageSize: listingImage.listingImageSize,
                                    imagePosition: listingImage.listingImagePosition
                                })
                            }
                        })
                    }
                    else {
                        imageFilesToUploadWithPosition.push({
                            file: image.image.previewFile,
                            position: image.id
                        });
                        if (artist.musicImages.selectedCollage === oldListing.musicImages.selectedCollage) {
                            listingDetail.listingImages.forEach(listingImage => {
                                if (parseInt(listingImage.listingImagePosition) === image.id) {
                                    oldImages.push(listingImage.listingImageName)
                                }
                            })
                        }

                    }
                }
            });
            if (artist.musicImages.selectedCollage !== oldListing.musicImages.selectedCollage) {
                listingDetail.listingImages.forEach(listingImage => {
                    oldImages.push(listingImage.listingImageName)
                })
            }
            console.log(imageFilesToUploadWithPosition);
            console.log(imagesToRetain)
            console.log(oldImages)
            updateListingRequest(
                imageFilesToUploadWithPosition,
                imagesToRetain,
                listingDetail.listingId,
                oldImages,
                artist.artistBasicInfo.repertoire.value,
                artist.artistBasicInfo.clients,
                artist.artistBasicInfo.genres.map(genre => (genre.value)),
                artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
                artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
                artist.artistBasicInfo.artistTypePrices,
                artist.artistBasicInfo.performanceType.value,
                artist.description.description,
                artist.description.setList,
                artist.description.technicalRider,
                artist.description.isInsured,
                artist.musicImages.musicLinks,
                'COL' + artist.musicImages.selectedCollage,
                artist.artistBasicInfo.listingName,
                (response) => {
                    console.log(response);
                    hideLoader();
                    history.push({
                        pathname: '/artist/home',
                    })
                }, (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                }
            )
        }
    }

    const isValueChanged = () => {
        if (JSON.stringify(artist) === JSON.stringify(oldListing)) {
            return false;
        }
        else {
            return true;
        }
    }

    const register = (e) => {
        e.preventDefault();
        showLoader();
        var imageFiles = [];
        console.log(artist.musicImages.images);
        artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
            if (artist.musicImages.selectedCollage === image.collageId) {
                imageFiles.push(image.image.previewFile);
            }
        });
        console.log(imageFiles);
        registerArtistRequest(
            imageFiles,
            artist.personalDetails.fullName,
            artist.personalDetails.email,
            artist.personalDetails.password,
            artist.personalDetails.artistName,
            artist.personalDetails.countryCode.value.countryCode,
            artist.personalDetails.languages.map(language => (language.label)),
            artist.personalDetails.city.label,
            artist.personalDetails.country.label,
            artist.personalDetails.city.city.lat,
            artist.personalDetails.city.city.lng,
            artist.personalDetails.phone,
            artist.artistBasicInfo.repertoire.value,
            artist.artistBasicInfo.clients,
            artist.artistBasicInfo.genres.map(genre => (genre.value)),
            artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
            artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
            artist.artistBasicInfo.artistTypePrices,
            artist.artistBasicInfo.performanceType.value,
            artist.description.description,
            artist.description.setList,
            artist.description.technicalRider,
            artist.description.isInsured,
            artist.musicImages.musicLinks,
            'COL' + artist.musicImages.selectedCollage,
            artist.artistBasicInfo.listingName,
            (response) => {
                console.log(response);
                hideLoader();
                //  props.history.push('/artist/application/result');
                history.push({
                    pathname: '/artist/registration/result',
                    state: {
                        breakProtection: true,
                        response: response
                    }
                })
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const getCollageComponent = () => {
        switch (artist.musicImages.selectedCollage) {
            case 1: return (<CollageImage1 images={artist.musicImages.images} noCropOrEdit={true} />)
            case 2: return (<CollageImage2 images={artist.musicImages.images} noCropOrEdit={true} />)
            case 3: return (<CollageImage3 images={artist.musicImages.images} noCropOrEdit={true} />)
            case 5: return (<CollageImage5 images={artist.musicImages.images} noCropOrEdit={true} />)
            default: return (null)
        }
    }

    const scrollToBeginning = () => {
        scroller.scrollTo('preview-page', {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -200,
        });
    };

    const getMusicSlidesPerView = () => {
        var noOfSlides = 3;
        if (width / 350 < 3) {
            noOfSlides = Math.floor(width / 350);
        }
        if (noOfSlides == 0) {
            noOfSlides = 1
        }
        return noOfSlides;
    }

    useEffect(() => {
        scrollToBeginning();
    }, []);
    return (
        <div className="preview-page">
            <div className="preview-page__first-block">

                <div className='main-btn-view'>

                    {isEditListing ?
                        (
                            <KalabMainButton
                                disabled={!isValueChanged()}
                                text={'Save Changes'}
                                iconName={'update'}
                                onClick={updateListing}
                                fontSize={22}
                                isFilled
                            />

                        )
                        :
                        (

                            isAddListing ?
                                (
                                    <KalabMainButton
                                        disabled={completed !== 100}
                                        text={'Save & Publish'}
                                        iconName={'add'}
                                        onClick={addListing}
                                        fontSize={22}
                                        isFilled
                                    />
                                )
                                :
                                (
                                    <KalabMainButton
                                        disabled={completed !== 100}
                                        text={'Save & Publish'}
                                        iconName={'done'}
                                        onClick={register}
                                        fontSize={22}
                                        isFilled
                                    />
                                ))}
                    {
                        isEditListing && !isValueChanged() ?
                            (
                                <div className='error-icon'>
                                    <KalabIconWithToolTip
                                        toolTipText="No Changes"
                                        placement='top'
                                        iconName={'fas fa-exclamation'}
                                        iconSize={12}
                                        isFAIcon={true}
                                        color={Colors.darkRed}
                                        borderColor={Colors.darkRed}
                                    />
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }

                </div>

                <div className="top-view">
                    <div className="top-view__left">
                        <p className="top-view__left--name">
                            {artist.artistBasicInfo.listingName}
                        </p>
                        <div className="top-view__left--location">
                            <LocationOnOutlined className="top-view__left--location__icon" />
                            <span className="top-view__left--location__text">{artist.personalDetails.city.label}, {artist.personalDetails.country.label}</span>
                        </div>
                        {
                            artist.description.isInsured ?
                                (
                                    <div className="top-view__left--insured">
                                        <VerifiedUserOutlined className="top-view__left--insured__icon" />
                                        <span className="top-view__left--insured__text">Insured</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }

                        <div className="top-view__left--languages">
                            <ForumOutlined className="top-view__left--languages__icon" />
                            <span className="top-view__left--languages__text">{artist.personalDetails.languages.map(language => (language.label)).join(', ')}</span>
                        </div>
                    </div>


                </div>
                <div className="collage-images">
                    {getCollageComponent()}
                </div>
                <div className="detail-view">
                    <div className="detail-view__left">
                        {
                            width > 750 ?
                                (
                                    <div className="values">
                                        <div className="values__single">
                                            <Icon className="values__single--icon">cake_outlined</Icon>
                                            <p className="values__single--title">Event Type</p>
                                            <p className="values__single--value">
                                                <KalabExpandableList
                                                    list={artist.artistBasicInfo.eventTypes.map(eventType => (
                                                        eventType.label
                                                    ))}
                                                    limitLines={2}
                                                />
                                            </p>
                                        </div>
                                        <div className="values__single">
                                            <Icon className="values__single--icon">query_builder_rounded</Icon>
                                            <p className="values__single--title">Repertoire</p>
                                            <p className="values__single--value">{artist.artistBasicInfo.repertoire.value} hr{artist.artistBasicInfo.repertoire.value > 1 ? 's' : ''}</p>
                                        </div>
                                        <div className="values__single">
                                            <Icon className="values__single--icon">music_note_outlined</Icon>
                                            <p className="values__single--title">Genre</p>
                                            <p className="values__single--value">
                                                <KalabExpandableList
                                                    list={artist.artistBasicInfo.genres.map(genre => (
                                                        genre.label
                                                    ))}
                                                    limitLines={2}
                                                />
                                            </p>
                                        </div>
                                        <div className="values__single">
                                            <Icon className="values__single--icon">mood_outlined</Icon>
                                            <p className="values__single--title">Ambience</p>
                                            <p className="values__single--value">
                                                <KalabExpandableList
                                                    list={artist.artistBasicInfo.ambiences.map(ambience => (
                                                        ambience.label
                                                    ))}
                                                    limitLines={2}
                                                />
                                            </p>
                                        </div>
                                        <div className="values__single">
                                            <Icon className="values__single--icon">person_outline_rounded</Icon>
                                            <p className="values__single--title">Artist Type</p>
                                            <p className="values__single--value">
                                                <KalabExpandableList
                                                    list={artist.artistBasicInfo.artistTypePrices.map(artistTypePrice => (
                                                        artistTypePrice.artistTypeName
                                                    ))}
                                                    limitLines={2}
                                                />
                                            </p>
                                        </div>
                                        <div className="values__single">
                                            <Icon className="values__single--icon">public_outlined</Icon>
                                            <p className="values__single--title">Performance Type</p>
                                            <p className="values__single--value">{artist.artistBasicInfo.performanceType.label}</p>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="value-swiper">
                                        <div>
                                            <KalabIconButton
                                                iconName='keyboard_arrow_left_rounded'
                                                isLightTheme
                                                disabled={valueSwiperDetail.isBeginning}
                                                onClick={() => {
                                                    valueSwiper.slidePrev();
                                                }}
                                            />
                                        </div>
                                        <Swiper
                                            onUpdate={(swiper) => {
                                                console.log(swiper);
                                                setValueSwiper(swiper);
                                                setValueSwiperDetail({
                                                    isBeginning: swiper.isBeginning,
                                                    isEnd: swiper.isEnd
                                                });
                                            }}
                                            onSwiper={(swiper) => {
                                                console.log(swiper);
                                                setValueSwiper(swiper);
                                                setValueSwiperDetail({
                                                    isBeginning: swiper.isBeginning,
                                                    isEnd: swiper.isEnd
                                                });
                                            }}
                                            spaceBetween={1}
                                            slidesPerView={Math.floor(width / 150) === 0 ? 1 : Math.floor(width / 150)}
                                            freeMode={true}
                                            onSlideChange={(swiper) => {
                                                console.log(swiper);
                                                setValueSwiperDetail({
                                                    isBeginning: swiper.isBeginning,
                                                    isEnd: swiper.isEnd
                                                })
                                            }}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide key='eventTypeValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">cake_outlined</Icon>
                                                    <p className="value-swiper__single--title">Event Type</p>
                                                    <p className="value-swiper__single--value">
                                                        <KalabExpandableList
                                                            list={artist.artistBasicInfo.eventTypes.map(eventType => (
                                                                eventType.label
                                                            ))}
                                                            limitLines={2}
                                                        />
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key='repertoireValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">query_builder_rounded</Icon>
                                                    <p className="value-swiper__single--title">Repertoire</p>
                                                    <p className="value-swiper__single--value">{artist.artistBasicInfo.repertoire.value} hr{artist.artistBasicInfo.repertoire.value > 1 ? 's' : ''}</p>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key='genreValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">music_note_outlined</Icon>
                                                    <p className="value-swiper__single--title">Genre</p>
                                                    <p className="value-swiper__single--value">
                                                        <KalabExpandableList
                                                            list={artist.artistBasicInfo.genres.map(genre => (
                                                                genre.label
                                                            ))}
                                                            limitLines={2}
                                                        />
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key='ambienceValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">mood_outlined</Icon>
                                                    <p className="value-swiper__single--title">Ambience</p>
                                                    <p className="value-swiper__single--value">
                                                        <KalabExpandableList
                                                            list={artist.artistBasicInfo.ambiences.map(ambience => (
                                                                ambience.label
                                                            ))}
                                                            limitLines={2}
                                                        />
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key='artistTypeValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">person_outline_rounded</Icon>
                                                    <p className="value-swiper__single--title">Artist Type</p>
                                                    <p className="value-swiper__single--value">
                                                        <KalabExpandableList
                                                            list={artist.artistBasicInfo.artistTypePrices.map(artistTypePrice => (
                                                                artistTypePrice.artistTypeName
                                                            ))}
                                                            limitLines={2}
                                                        />
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide key='performanceTypeValue'>
                                                <div className="value-swiper__single">
                                                    <Icon className="value-swiper__single--icon">public_outlined</Icon>
                                                    <p className="value-swiper__single--title">Performance Type</p>
                                                    <p className="value-swiper__single--value">{artist.artistBasicInfo.performanceType.label}</p>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div>
                                            <KalabIconButton
                                                iconName='keyboard_arrow_right_rounded'
                                                isLightTheme
                                                disabled={valueSwiperDetail.isEnd}
                                                onClick={() => {
                                                    valueSwiper.slideNext();
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                        }

                        <div className="description-view">
                            <div className="description-view__title">Biography</div>
                            <KalabExpandableText
                                text={artist.description.description}
                                limitLines={2}
                            />
                        </div>
                        {(artist.description.setList !== null && artist.description.setList !== '') || artist.artistBasicInfo.clients.length > 0 ?
                            (
                                <div className="set-list_past-clients">
                                    {
                                        (artist.description.setList !== null && artist.description.setList !== '') ?
                                            (
                                                <div className={`set-list ${artist.artistBasicInfo.clients.length > 0 ? 'with-past-clients' : ''}`}>
                                                    <div className="set-list__title">Set List</div>
                                                    <KalabExpandableText
                                                        text={artist.description.setList}
                                                        limitLines={2}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }
                                    {
                                        artist.artistBasicInfo.clients.length > 0 ?
                                            (
                                                <div className={`past-clients ${(artist.description.setList !== null && artist.description.setList !== '') ? 'with-set-list' : ''}`}>
                                                    <div className="past-clients__title">Past Clients</div>
                                                    <KalabExpandableList
                                                        list={artist.artistBasicInfo.clients}
                                                        limitLines={2}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    }


                                </div>
                            )
                            :
                            (
                                null
                            )}




                    </div>
                    <div className="detail-view__right">
                        <KalabCalculatorCard
                            artistTypes={artist.artistBasicInfo.artistTypePrices}
                            isPreview={true}
                        />
                    </div>

                </div>

            </div>
            <div className="preview-page__music-block">
                <div className="preview-page__music-block--title">
                    Music
                </div>
                {
              artist.musicImages.musicLinks.length <= getMusicSlidesPerView() ?
                (
                    <div className="music-view">
                    {artist.musicImages.musicLinks.map(musicLink => (

                        <div className="music-view__iframe ">
                            <div className="aspectratio ar16-9" style={{ height: '100%' }}>
                                <KalabMusicIFrame
                                    url={musicLink.url}
                                    platform={musicLink.platform}
                                />
                            </div>
                        </div>
                    )
                    )}

                </div>
                )
                :
                (
                  <div className="music-swiper">
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_left_rounded'
                        disabled={musicSwiperDetail.isBeginning}
                        onClick={() => {
                          musicSwiper.slidePrev();
                        }}
                      />
                    </div>
                    <Swiper
                      onUpdate={(swiper) => {
                        console.log(swiper);
                        setMusicSwiper(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      onSwiper={(swiper) => {
                        console.log(swiper);
                        setMusicSwiper(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      spaceBetween={1}
                      slidesPerView={getMusicSlidesPerView()}
                      freeMode={true}
                      onSlideChange={(swiper) => {
                        console.log(swiper);
                        setMusicSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        })
                      }}
                      className="mySwiper"
                    >
                      {artist.musicImages.musicLinks.map((musicLink) => (
                        <SwiperSlide key={musicLink.url}>
                          <div className="music-swiper__iframe ">
                            <div className="aspectratio ar16-9" style={{ height: '100%' }}>
                            <KalabMusicIFrame
                                    url={musicLink.url}
                                    platform={musicLink.platform}
                                />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_right_rounded'
                        disabled={musicSwiperDetail.isEnd}
                        onClick={() => {
                          musicSwiper.slideNext();
                        }}
                      />
                    </div>
                  </div>
                )
            }
               

            </div>
            <div className="preview-page__bottom-block">
                <div className="tr-calendar-view">
                    <div className="technical-rider">
                        <div className="technical-rider__title">Technical Rider</div>
                        <KalabExpandableText
                            text={artist.description.technicalRider}
                            limitLines={5}
                        />
                    </div>
                    {/* <div className="calendar">
                        <div className="calendar__title">Check Available Dates</div>
                        <div className="calendar__view" >
                            <KalabListingCalendar />
                        </div>
                    </div> */}
                </div>
                {/* {
                    isAddListing || isEditListing ? (
                        null
                    ) :
                        (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 30,
                                    marginBottom: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <KalabCheckBox
                                    checked={agreePrivacyTerms}
                                    onChange={handleAgreeTermsServiceCheckboxClick}
                                    label={
                                        <div style={{ cursor: 'default' }}>
                                            I have read and agree to the&nbsp;
                                            <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('privacy-policy') }}>
                                                privacy policy
                                            </span>
                                            &nbsp;and&nbsp;
                                            <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('terms-of-service') }}>
                                                terms of service
                                            </span>
                                        </div>
                                    }
                                />
                            </div>
                        )
                } */}


            </div>
        </div>
    )
}

export default PreviewSubmit
