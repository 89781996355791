import React, { useContext, useEffect, useState } from 'react'
import './CreateBookingLinkPage.scss';
import MainHeader from '../header/MainHeader';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { generateBookingLinkRequest, getCountryCodesRequest, getListingFromTokenRequest } from '../../webservices/Webservice';
import { getDefaultCurrency, getLabelFromValue } from '../../utils/Currencies';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { isAfter, isPast, isToday, parse, format } from 'date-fns';
import { openInNewTab } from '../../utils/common';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDatePickerDropDownNormal from '../kalab/kalabDropDown/KalabDatePickerDropDownNormal';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabTimeDropDown from '../kalab/kalabDropDown/KalabTimeDropDown';
import KalabIconCheckBox from '../kalab/kalabCheckBoxes/KalabIconCheckBox';
import { InputAdornment } from '@material-ui/core';
import KalabCurrencyDropDown from '../kalab/kalabDropDown/KalabCurrencyDropDown';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import PortaledComponent from '../elements/portaledComponent/portaledComponent';
import SharePopUp from '../popUps/SharePopUp';

export default function CreateBookingLinkPage(props) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const { height, width } = useWindowDimensions();
    const { settings } = useContext(DataStoreContext);

    const [listing, setListing] = useState(null);
    const [artistTypes, setArtistTypes] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [genres, setGenres] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([])
    const [loadingCountryCode, setLoadingCountryCode] = useState(false)
    const [sharePopUp, setSharePopUp] = useState(false);
    const [linkToShare, setLinkToShare] = useState(null);

    const [request, setRequest] = useState({
        companyFullName: '',
        email: '',
        countryCode: null,
        phone: '',
        eventName: '',
        hours: null,
        startTime: null,
        endTime: null,
        dressCode: '',
        comment: '',
        songRequests: '',
        location: '',
        equipmentDetails: '',
        parking: false,
        equipment: false,
        accomodation: false,
        foodBeverage: false,
        transportation: false,
        eventType: null,
        artistType: null,
        genres: [],
        date: null,
        artistPrice: '0',
        currency: getDefaultCurrency().value,
    })


    const showSharePopUp = (link) => {
        setLinkToShare(link);
        setSharePopUp(true);

    }

    const hideSharePopUp = () => {
        setSharePopUp(false);
        setLinkToShare(null);
        props.history.push({
            pathname: '/artist/home',
        })

    }

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }

    const getServiceFeeForBookingLink = () => {
        var serviceFeeForBookingLink = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeeForBookingLink') {
                serviceFeeForBookingLink = parseFloat(setting.settingValue);
            }
        })
        return serviceFeeForBookingLink
    }

    const genreOptions = () => {
        var genresOptions = [];
        genres.forEach(genre => {
            genresOptions.push({
                value: parseInt(genre.genreId),
                label: genre.genreName
            })

        });
        return genresOptions;
    }

    const artistTypeOptions = () => {
        var artistTypesOptions = [];
        artistTypes.forEach(artistType => {
            artistTypesOptions.push({
                value: parseInt(artistType.artistTypeId),
                label: artistType.artistTypeName
            })

        });
        return artistTypesOptions;
    }

    const eventTypeOptions = () => {
        var eventTypesOptions = [];
        eventTypes.forEach(eventType => {
            eventTypesOptions.push({
                value: parseInt(eventType.eventTypeId),
                label: eventType.eventTypeName
            })

        });
        return eventTypesOptions;

    }
    const hoursOptions = () => {
        var hoursOptions = [];
        for (var i = 1; i <= 12; i++) {
            hoursOptions.push({
                value: i,
                label: `${i} hour${i > 1 ? 's' : ''}`
            })
        }
        return hoursOptions;
    }

    const handleGenreSelectChange = (genreValues) => {
        setRequest({
            ...request,
            genres: genreValues
        })
    }
    const handleArtistTypeSelectChange = (artistTypeValue) => {
        console.log(artistTypeValue);
        setRequest({
            ...request,
            artistType: artistTypeValue
        })
        // if (artistTypeValue) {
        //     if (artistTypeValue.value === listing.artistTypeId) {
        //         setRequest({
        //             ...request,
        //             artistPrice: parseFloat(listing.artistPrice).toFixed(2),
        //             currency: listing.currency,
        //             artistType: artistTypeValue
        //         })
        //     }
        //     else {
        //         setRequest({
        //             ...request,
        //             artistPrice: parseFloat(artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].cost).toFixed(2),
        //             currency: artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].currency,
        //             artistType: artistTypeValue
        //         })
        //     }
        // }
        // else {
        //     setRequest({
        //         ...request,
        //         artistPrice: null,
        //         currency: null,
        //         artistType: artistTypeValue
        //     })
        // }
    }
    const handleEventTypeSelectChange = (eventTypeValue) => {
        setRequest({
            ...request,
            eventType: eventTypeValue
        })
    }
    const handlePriceInputTextChange = (e) => {
        if (e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
        if (e.target.value === '') {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
    }
    const handleDateChange = (date) => {
        setRequest({
            ...request,
            date: date
        })
    }
    const handleStartTimeChange = (time) => {
        console.log(time)
        if (request.endTime) {
            if (!isAfter(time, request.endTime)) {
                setRequest({
                    ...request,
                    startTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    startTime: request.endTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                startTime: time
            })
        }
    }
    const handleEndTimeChange = (time) => {
        console.log(time);
        if (request.startTime) {
            if (time == null || isAfter(time, request.startTime)) {
                setRequest({
                    ...request,
                    endTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    endTime: request.startTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                endTime: time
            })
        }

    }


    const handleInputTextChange = (e) => {
        var updatedRequest = {
            ...request
        }
        updatedRequest[e.target.id] = e.target.value
        setRequest(updatedRequest);
    }

    const handleHoursSelectChange = (hourValue) => {
        setRequest({
            ...request,
            hours: hourValue
        })
    }
    const handleChangeTransporationValue = (value) => {
        setRequest({
            ...request,
            transportation: value
        })
    }
    const handleChangeInCurrencyValue = (value) => {
        setRequest({
            ...request,
            currency: value
        })
    }
    const handleChangeAccomadationValue = (value) => {
        setRequest({
            ...request,
            accomodation: value
        })
    }
    const handleChangeFoodBeverageValue = (value) => {
        setRequest({
            ...request,
            foodBeverage: value
        })
    }
    const handleChangeParkingValue = (value) => {
        setRequest({
            ...request,
            parking: value
        })
    }
    const handleChangeEquipmentValue = (value) => {
        setRequest({
            ...request,
            equipment: value,
            equipmentDetails: value ? request.equipmentDetails : ''
        })
    }
    const handleCountryCodeSelectChange = (countryCodeValue) => {
        setRequest({
            ...request,
            countryCode: countryCodeValue ? countryCodeValue.value.countryCode : null,
        });

    };
    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + listing.listingToken);
    }

    const getCountryCodeSelectValue = (countryCode) => {
        var countryCodeValues = countryCodeOptions.filter(countryCodeOption => (countryCodeOption.value.countryCode === countryCode));
        return (
            countryCodeValues.length > 0 ? countryCodeValues[0] : null
        )
    }

    const handleGenerateLinkBtn = () => {
        generateBookingLink();
    }

    const isFormValid = () => {
        // if (
        //     request.eventName.trim() !== "" &&
        //     (
        //         (
        //             !request.isResidency &&
        //             request.startTime &&
        //             request.endTime &&
        //             request.hours
        //         ) ||
        //         (
        //             request.isResidency &&
        //             request.endDate &&
        //             checkValidTimings().isValid
        //         )
        //     ) &&

        //     request.location.trim() !== "" &&
        //     request.dressCode.trim() !== "" &&
        //     request.eventType &&
        //     request.artistType &&
        //     request.genres.length > 0 &&
        //     request.date &&
        //     request.artistPrice &&
        //     parseFloat(request.artistPrice) > 0 &&
        //     request.currency &&
        //     (
        //         !isPast(request.date) || isToday(request.date)
        //     ) &&
        //     (
        //         (
        //             request.equipment &&
        //             request.equipmentDetails.trim() !== ''
        //         ) ||
        //         !request.equipment
        //     )
        // ) {
        return true;
        // }
        // else {
        //     return false;
        // }
    }

    const checkMaxDateEnabled = () => {
        return (false)
    }



    const getListing = (listingToken) => {
        showLoader();
        getListingFromTokenRequest(listingToken,
            (listingFromAPI) => {
                hideLoader();
                console.log(listingFromAPI);
                setListing(listingFromAPI);
                setEventTypes(listingFromAPI.eventTypes);
                setArtistTypes(listingFromAPI.artistTypes);
                setGenres(listingFromAPI.genres);

            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })
    }

    const getCountryCodes = () => {
        setLoadingCountryCode(true)
        getCountryCodesRequest(
            (countryCodes) => {
                var countryCodeOptionsFromAPI = [];
                countryCodes.forEach(countryCode => {
                    if (countryCodeOptionsFromAPI.filter(cCodeOption => cCodeOption.countryCode.countryCode === countryCode.countryCode).length === 0) {
                        countryCodeOptionsFromAPI.push({
                            value: countryCode,
                            label: `+${countryCode.countryCode} ( ${countryCodes.filter(cCode => cCode.countryCode === countryCode.countryCode).map(cntCode => cntCode.countryAsciiName !== "" ? cntCode.countryAsciiName : cntCode.countryName).join(', ')} )`,
                            countryCode
                        })
                    }
                });
                setCountryCodeOptions(countryCodeOptionsFromAPI);
                setLoadingCountryCode(false)
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoadingCountryCode(false)
            }
        )
    }

    const generateBookingLink = () => {
        showLoader();
        generateBookingLinkRequest(
            listing.listingId,
            request.eventType ? request.eventType.value : null,
            request.genres.length > 0 ? request.genres.map(genre => (genre.value)) : null,
            request.artistType ? request.artistType.value : null,
            request.songRequests.trim() !== '' ? request.songRequests.trim() : null,
            request.equipment && request.equipmentDetails.trim() !== '' ? request.equipmentDetails.trim() : null,
            request.comment.trim() !== '' ? request.comment.trim() : null,
            request.date ? format(request.date, 'yyyy-MM-dd') : null,
            request.startTime ? format(request.startTime, 'HH:mm:ss') : null,
            request.endTime ? format(request.endTime, 'HH:mm:ss') : null,
            request.eventName.trim() !== '' ? request.eventName.trim() : null,
            request.location.trim() !== '' ? request.location.trim() : null,
            request.hours ? request.hours.value : null,
            request.parking,
            request.accomodation,
            request.foodBeverage,
            request.transportation,
            request.equipment,
            request.dressCode.trim() !== '' ? request.dressCode.trim() : null,
            parseFloat(request.artistPrice) > 0 ? parseFloat(request.artistPrice) : null,
            parseFloat(request.artistPrice) > 0 ? request.currency : null,
            request.companyFullName.trim() !== '' ? request.companyFullName.trim() : null,
            request.email.trim() !== '' ? request.email.trim() : null,
            request.phone !== '' && request.countryCode ? request.countryCode : null,
            request.phone !== '' && request.countryCode ? request.phone : null,
            (bookingLink) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: 'Booking link is genrated successfully',
                    title: 'Success!!!',
                });
                showSharePopUp(window.location.origin + '/link/' + bookingLink.token)

            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }



    useEffect(() => {
        console.log(props);
        if (props.location.state && props.location.state.listing) {
            getCountryCodes();
            getListing(props.location.state.listing.listingToken)
        }
        else {
            props.history.replace('/')
        }

    }, [])

    return (
        <div className='create-booking-link-page'>
            <MainHeader isDarkBackground />
            {
                listing ?
                    (
                        <div className='create-booking-link-page__content'>
                            <div className='create-booking-link-page__content--top'>
                                <div className='create-booking-link-page__content--top__title'>

                                    <div className="listing-details" onClick={handleClickOnListing} >
                                        {listing.listingName}
                                        {
                                            listing.isInsured ?
                                                (
                                                    <div className='insured'>
                                                        <KalabIconWithToolTip
                                                            toolTipText="Insured"
                                                            placement="right"
                                                            iconName='verified_user_outlined'
                                                            iconSize={16}
                                                            noBorder={true}
                                                            color={Colors.lightGrey}
                                                        />
                                                    </div>
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className='create-booking-link-page__content--main'>
                                <div className="main-detail">
                                    <div className="main-detail--row">
                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type="text"
                                                id="companyFullName"
                                                placeholder={"Client Name"}
                                                value={request.companyFullName}
                                                onChange={handleInputTextChange}
                                                isLightTheme={true}
                                            />
                                        </div>



                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type="text"
                                                id="email"
                                                placeholder="Email"
                                                value={request.email}
                                                onChange={handleInputTextChange}
                                                isLightTheme={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='main-detail--row'>

                                        <div className="main-detail--row__element">
                                            <KalabDropDown
                                                placeholder="Country Code"
                                                isLoading={loadingCountryCode}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="countryCode"
                                                onChange={handleCountryCodeSelectChange}
                                                options={countryCodeOptions}
                                                isLightTheme={true}
                                                value={getCountryCodeSelectValue(request.countryCode)}
                                            />

                                        </div>

                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type='number'
                                                id="phone"
                                                placeholder="Phone"
                                                value={request.phone}
                                                onChange={(e) => { handleInputTextChange(e); }}
                                                isLightTheme={true}
                                            />

                                        </div>
                                    </div>
                                    <div className="main-detail--row">

                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type="text"
                                                id="eventName"
                                                placeholder="Event Name"
                                                onChange={handleInputTextChange}
                                                value={request.eventName}
                                                isLightTheme
                                            />
                                        </div>
                                        <div className="main-detail--row__element" >
                                            <KalabTextField
                                                type="text"
                                                id="location"
                                                placeholder="Location"
                                                onChange={handleInputTextChange}
                                                value={request.location}
                                                isLightTheme
                                            />
                                        </div>


                                    </div>

                                    <div className="main-detail--row">

                                        <div className="main-detail--row__element">
                                            <KalabDatePickerDropDownNormal
                                                value={request.date}
                                                onChange={handleDateChange}
                                                isLightTheme
                                                enableMaxDate={checkMaxDateEnabled()}
                                            />
                                        </div>
                                        <div className="main-detail--row__element" >
                                            <KalabDropDown
                                                placeholder="Performance Duration"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                isMulti={false}
                                                name="hours"
                                                onChange={handleHoursSelectChange}
                                                options={hoursOptions()}
                                                isLightTheme={true}
                                                value={request.hours}
                                            />
                                        </div>

                                    </div>


                                    <div className="main-detail--row">


                                        <div className="main-detail--row__element">
                                            <KalabTimeDropDown
                                                value={request.startTime}
                                                onChange={handleStartTimeChange}
                                                isLightTheme
                                                placeholder='Artist Arrival'
                                                endTime={request.endTime}
                                            />
                                        </div>
                                        <div className="main-detail--row__element" >
                                            <KalabTimeDropDown
                                                value={request.endTime}
                                                onChange={handleEndTimeChange}
                                                isLightTheme
                                                placeholder='Artist Departure'
                                                beginTime={request.startTime}
                                            />
                                        </div>

                                    </div>



                                    <div className="main-detail--row">

                                        <div className="main-detail--row__element" >
                                            <KalabDropDown
                                                placeholder="Artist Type"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="artistType"
                                                onChange={handleArtistTypeSelectChange}
                                                options={artistTypeOptions()}
                                                isLightTheme={true}
                                                value={request.artistType}
                                            />
                                        </div>
                                        <div className="main-detail--row__element">
                                            <KalabDropDown
                                                placeholder="Event Type"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="artistType"
                                                onChange={handleEventTypeSelectChange}
                                                options={eventTypeOptions()}
                                                isLightTheme={true}
                                                value={request.eventType}
                                            />
                                        </div>


                                    </div>
                                    <div className="main-detail--row">
                                        <div className="main-detail--row__element" >
                                            <KalabDropDown
                                                placeholder="Genres"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                isMulti={true}
                                                name="genres"
                                                onChange={handleGenreSelectChange}
                                                options={genreOptions()}
                                                isLightTheme={true}
                                                value={request.genres}
                                            />
                                        </div>
                                        <div className="main-detail--row__element" >
                                            <KalabTextField
                                                type="text"
                                                id="dressCode"
                                                placeholder="Dress Code"
                                                onChange={handleInputTextChange}
                                                value={request.dressCode}
                                                isLightTheme
                                                multiline
                                                maxLength={500}
                                                rowsMax={6}
                                            />
                                        </div>

                                    </div>
                                    <div className="main-detail--row" >
                                        <div className="main-detail--row__element" style={{ textAlign: 'left' }}>
                                            <div className="main-detail--row__title-text">I am willing to provide the following...</div>
                                        </div>
                                        <div className="main-detail--row__element">
                                        </div>
                                    </div>
                                    <div className="main-detail--row">
                                        <div className="main-detail--row__element" style={{ display: 'flex', flexDirection: 'row' }} >
                                            <div className="main-detail--row__element--icon">
                                                <KalabIconCheckBox
                                                    iconName="directions_car"
                                                    iconText={width <= 500 ? "" : "Transportation"}
                                                    value={request.transportation}
                                                    onChange={handleChangeTransporationValue}
                                                />

                                            </div>
                                            <div className="main-detail--row__element--icon">
                                                <KalabIconCheckBox
                                                    iconName="local_parking_rounded"
                                                    iconText={width <= 500 ? "" : "Parking"}
                                                    value={request.parking}
                                                    onChange={handleChangeParkingValue}
                                                />

                                            </div>
                                            <div className="main-detail--row__element--icon">

                                                <KalabIconCheckBox
                                                    iconName="fastfood_rounded"
                                                    iconText={width <= 500 ? "" : "Food & Beverage"}
                                                    value={request.foodBeverage}
                                                    onChange={handleChangeFoodBeverageValue}
                                                />

                                            </div>
                                            <div className="main-detail--row__element--icon">

                                                <KalabIconCheckBox
                                                    iconName="mic_rounded"
                                                    iconText={width <= 500 ? "" : "Equipment"}
                                                    value={request.equipment}
                                                    onChange={handleChangeEquipmentValue}
                                                />

                                            </div>
                                            <div className="main-detail--row__element--icon">

                                                <KalabIconCheckBox
                                                    iconName="hotel"
                                                    iconText={width <= 500 ? "" : "Accomodation"}
                                                    value={request.accomodation}
                                                    onChange={handleChangeAccomadationValue}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-detail--row" >
                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type="text"
                                                id="songRequests"
                                                placeholder="Song Requests"
                                                onChange={handleInputTextChange}
                                                value={request.songRequests}
                                                isLightTheme
                                                multiline
                                                maxLength={500}
                                                rowsMax={6}
                                            />
                                        </div>
                                        {
                                            request.equipment ?
                                                (
                                                    <div className="main-detail--row__element">
                                                        <KalabTextField
                                                            type="text"
                                                            id="equipmentDetails"
                                                            placeholder="Venue Equipment"
                                                            onChange={handleInputTextChange}
                                                            value={request.equipmentDetails}
                                                            isLightTheme
                                                            multiline
                                                            maxLength={500}
                                                            rowsMax={6}
                                                        />
                                                    </div>
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }

                                        <div className="main-detail--row__element">
                                            <KalabTextField
                                                type="text"
                                                id="comment"
                                                placeholder="Comment"
                                                onChange={handleInputTextChange}
                                                value={request.comment}
                                                isLightTheme
                                                multiline
                                                maxLength={500}
                                                rowsMax={6}
                                            />
                                        </div>


                                    </div>
                                    <div className="main-detail--row" style={{ alignItems: 'center' }} >
                                        <div className="main-detail--row__element">
                                            <div className="price-text-field">
                                                <KalabTextField
                                                    type="number"
                                                    min='0.00'
                                                    step='0.01'
                                                    isLightTheme
                                                    id="artistPrice"
                                                    placeholder={request.isResidency ? "Proposed Price (Total Residency)" : 'Proposed Price'}
                                                    value={request.artistPrice}
                                                    onChange={handlePriceInputTextChange}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <KalabCurrencyDropDown
                                                                currency={request.currency}
                                                                setCurrency={handleChangeInCurrencyValue}
                                                                isLightTheme
                                                            />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </div>
                                            <div className="price-text-field">
                                                <KalabTextField
                                                    type="text"
                                                    id="artistPrice"
                                                    placeholder="Service Fee"
                                                    disabled
                                                    value={`${getLabelFromValue(request.currency)} ${getServiceFeeForBookingLink().toFixed(2)}`}
                                                    isLightTheme
                                                    multiline
                                                    maxLength={500}
                                                    rowsMax={6}
                                                />
                                            </div>

                                        </div>
                                        <div className="main-detail--row__element">
                                            <div className="total-price">
                                                {getLabelFromValue(request.currency)} {(parseFloat(request.artistPrice ? request.artistPrice : 0) + getServiceFeeForBookingLink()).toFixed(2)}
                                            </div>
                                        </div>


                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: 30,
                                            marginBottom: 10,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <KalabMainButton
                                            disabled={!(isFormValid())}
                                            onClick={handleGenerateLinkBtn}
                                            text={'Generate Booking Link'}
                                            iconName={'send_rounded'} />
                                    </div>


                                </div>
                            </div>

                        </div>
                    )
                    :
                    (
                        null
                    )
            }
            {
                sharePopUp &&
                <PortaledComponent
                    modal={
                        <SharePopUp
                            title={'The general booking link to share is the following'}
                            closeFn={hideSharePopUp}
                            linkToShare={linkToShare}
                            okText={'Open'}
                            okFn={() => { openInNewTab(linkToShare) }}
                            okBtnColor={Colors.primary}
                        />
                    }
                />
            }
        </div>
    )
}
