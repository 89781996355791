import React, { useState, useEffect, useContext } from 'react'
import './ClientRatingsPage.scss';
import MainHeader from "../header/MainHeader";
import { useParams } from 'react-router';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { getClientRatingFromTokenRequest, updateClientRatingRequest } from '../../webservices/Webservice';
import { getFormattedDate, getFormattedDateForStartEndDate } from '../../utils/Date';
import KalabRating from '../kalab/kalabRating/KalabRating';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { openInNewTab } from '../../utils/common';

export default function ClientRatingsPage(props) {
    const { token } = useParams();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [clientRating, setClientRating] = useState(null);
    const notify = useNotification();


    const getClientRating = () => {
        showLoader();
        getClientRatingFromTokenRequest(token,
            (clientRating) => {
                hideLoader();
                setClientRating(clientRating)
                console.log(clientRating);
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })
    }
    const handleChangeInProfessionalismRating = (value) => {
        setClientRating({
            ...clientRating,
            professionalism: value
        })
    }
    const handleChangeInCommunicationRating = (value) => {
        setClientRating({
            ...clientRating,
            communication: value
        })
    }
    const handleChangeInFriendlinessRating = (value) => {
        setClientRating({
            ...clientRating,
            friendliness: value
        })
    }
    const handleChangeInServiceQualityRating = (value) => {
        setClientRating({
            ...clientRating,
            serviceQuality: value
        })
    }

    const handleInputTextChange = (e) => {
        var updatedClientRating = {
            ...clientRating
        }
        updatedClientRating[e.target.id] = e.target.value
        setClientRating(updatedClientRating);
    }

    const handleClickOnListingName = () =>{
        openInNewTab(window.location.origin + '/listing/' + clientRating.listingToken)
    }
    const handleClickOnEventName = () =>{
        openInNewTab(window.location.origin + '/link/' + clientRating.bookingToken)
    }

    const handleRateButtonClick = () => {
        if (isFormValid()) {
            showLoader();
            updateClientRatingRequest(
                clientRating.clientRatingId,
                clientRating.token,
                clientRating.professionalism,
                clientRating.communication,
                clientRating.friendliness,
                clientRating.serviceQuality,
                clientRating.comment,
                (response) => {
                    console.log(response);
                    hideLoader();
                    notify({
                        type: 'SUCCESS',
                        message: response,
                        title: 'Success!!!',
                    });
                    props.history.push('/');
                }, (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                })
        }
    }

    const isFormValid = () => {
        if (
            clientRating.professionalism &&
            clientRating.communication &&
            clientRating.friendliness &&
            clientRating.serviceQuality 
        ) {
            return true;
        }
        else {
            return false;
        }

    }

    useEffect(() => {
        getClientRating();
    }, [])


    return (
        <div className='client-rating-dark'>
            <MainHeader onlyLogo={true}  />
            {
                clientRating ?
                    (
                        <div className='client-rating-page'>
                            <div className='title'>
                                Rate your event <span className='event-details' onClick={handleClickOnEventName}>"{clientRating.eventName}"</span>  {clientRating.isResidency ? `from ${getFormattedDateForStartEndDate(clientRating.date, clientRating.endDate)}` : `on ${getFormattedDate(clientRating.date)}`} with <span className='listing-details' onClick={handleClickOnListingName}>{clientRating.listingName}</span>
                            </div>
                            <div className='description'>
                                So how did it go? Tell us everything and don't hold back
                            </div>
                            <div className='content'>
                                <div className='content__rating'>
                                    <div className='text'>Professionalism</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={clientRating.professionalism}
                                            onChange={handleChangeInProfessionalismRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={clientRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__rating'>
                                    <div className='text'>Communication</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={clientRating.communication}
                                            onChange={handleChangeInCommunicationRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={clientRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__rating'>
                                    <div className='text'>Friendliness</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={clientRating.friendliness}
                                            onChange={handleChangeInFriendlinessRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={clientRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__rating'>
                                    <div className='text'>Service Quality</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={clientRating.serviceQuality}
                                            onChange={handleChangeInServiceQualityRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={clientRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__comment'>
                                    <div className='text-field'>
                                        <KalabTextField
                                            type="text"
                                            id="comment"
                                            placeholder="Share your experience "
                                            onChange={handleInputTextChange}
                                            value={clientRating.comment}
                                            multiline
                                            maxLength={300}
                                            rowsMax={6}
                                            disabled={clientRating.isRated}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                clientRating.isRated ?
                                    (
                                        <div className='already-rated-text'>
                                            Been there, done that. You’ve already submitted your rating😊
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='button'>
                                            <KalabMainButton
                                                text={'Rate Now'}
                                                disabled={!isFormValid()}
                                                onClick={handleRateButtonClick}
                                            />
                                        </div>
                                    )
                            }


                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div>
    )
}
