import moment from 'moment';
import React from 'react'
import KalabDropDown from './KalabDropDown'

export default function KalabTimeDropDown({
    placeholder,
    value,
    onChange,
    isDisabled,
    name,
    isLightTheme = false,
    beginTime = null,
    endTime = null
}) {
    const getTimeOptions = () => {
        const timeOptions = [];
        new Array(24).fill().forEach((acc, index) => {
            if (
                isValidTimeOption(moment({ hour: index }))
            ) {
                timeOptions.push({
                    value: moment({ hour: index }).toDate(),
                    label: moment({ hour: index }).format('hh:mm A')
                })
            }
            if (
                isValidTimeOption(moment({ hour: index, minute: 15 }))
            ) {
                timeOptions.push({
                    value: moment({ hour: index, minute: 15 }).toDate(),
                    label: moment({ hour: index, minute: 15 }).format('hh:mm A')
                })
            }

            if (
                isValidTimeOption(moment({ hour: index, minute: 30 }))
            ) {
                timeOptions.push({
                    value: moment({ hour: index, minute: 30 }).toDate(),
                    label: moment({ hour: index, minute: 30 }).format('hh:mm A')
                })
            }

            if (
                isValidTimeOption(moment({ hour: index, minute: 45 }))
            ) {
                timeOptions.push({
                    value: moment({ hour: index, minute: 45 }).toDate(),
                    label: moment({ hour: index, minute: 45 }).format('hh:mm A')
                })
            }


        })
        return timeOptions
    }

    const isValidTimeOption = (time) => {
        if (
            (
                beginTime &&
                time.isBefore(beginTime)
            )
            ||
            (
                endTime &&
                time.isAfter(endTime)
            )
        ) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <KalabDropDown
            placeholder={placeholder}
            isDisabled={isDisabled}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name={name}
            isMulti={false}
            isLightTheme={isLightTheme}
            options={getTimeOptions()}
            value={value === null ? value : ({
                value: value,
                label: moment(value).format('hh:mm A')
            })}
            onChange={(newValue) => {
                onChange(newValue ? newValue.value : null)
            }}
        />
    )
}
