import React, { useEffect, useState, useContext } from 'react';
import ClientHeader from '../header/ClientHeader';
import './SearchPage.scss';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { DataStoreContext } from "../../contexts/DataStoreContext";
import KalabFilterDatePickerDropDown from '../kalab/kalabDropDown/KalabFilterDatePickerDropDown';
import KalabFilterDropDown from '../kalab/kalabDropDown/KalabFilterDropDown';
import KalabFilterSliderDropDown from '../kalab/kalabDropDown/KalabFilterSliderDropDown';
import KalabFilterDropDownWithSearch from '../kalab/kalabDropDown/KalabFilterDropDownWithSearch';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { getCitiesRequest, getListingsWithFilterRequest } from "../../webservices/Webservice";
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabListingCard from '../kalab/kalabCards/KalabListingCard';
import Pagination from '@material-ui/lab/Pagination';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabFilterDropDownWithText from '../kalab/kalabDropDown/KalabFilterDropDownWithText';

const ClientDiscoverPage = (props) => {
  const notify = useNotification();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { artistTypes, eventTypes, genres, ambiences, countries, loading } = useContext(DataStoreContext);
  const { height, width } = useWindowDimensions();
  const [isVisibleMoreFilter, setIsVisibleMoreFilter] = useState(true);
  const [listings, setListings] = useState([])
  const [pageNo, setPageNo] = useState(1);
  const [totalNoOfPages, setTotalNoOfPages] = useState(1);
  const [totalNoOfListings, setTotalNoOfListings] = useState(0);
  const [searchFilter, setSearchFilter] = useState({
    artistTypes: [],
    eventTypes: [],
    genres: [],
    ambiences: [],
    date: null,
    priceRange: null,
    isInsured: null,
    country: null,
    cities: [],
    listingName :''
  })
  const [insuranceOptions, setInsuranceOptions] = useState([
    {
      value: true,
      label: 'Insured'
    },
    {
      value: false,
      label: 'Not Insured'
    }
  ])
  const [cityOptions, setCityOptions] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false);

  const handleCitySelectChange = (values) => {
    setSearchFilter({
      ...searchFilter,
      cities: values
    })
  }
  const handleInsuranceSelectChange = (value) => {
    setSearchFilter({
      ...searchFilter,
      isInsured: value
    })
  }

  const handleArtistTypeChangeValue = (values) => {
    setSearchFilter({
      ...searchFilter,
      artistTypes: values
    })
  }
  const handleDateChange = (value) => {
    setSearchFilter({
      ...searchFilter,
      date: value
    })
  }
  const handlePriceRangeChange = (value) => {
    setSearchFilter({
      ...searchFilter,
      priceRange: value
    })
  }
  const handleEventTypeChangeValue = (values) => {
    setSearchFilter({
      ...searchFilter,
      eventTypes: values
    })
  }
  const handleGenreChangeValue = (values) => {
    setSearchFilter({
      ...searchFilter,
      genres: values
    })
  }

  const handleListingNameChangeValue = (value) => {
    console.log(value)
    setSearchFilter({
      ...searchFilter,
      listingName: value
    })
  }
  const handleAmbienceChangeValue = (values) => {
    setSearchFilter({
      ...searchFilter,
      ambiences: values
    })
  }
  const handleCountryChangeValue = (value) => {
    setSearchFilter({
      ...searchFilter,
      country: value,
      cities: []
    })
    if (value === null) {
      setCityOptions([]);
    }
    else {
      getCities(value.value);
    }
  }
  const handlePageChange = (event, value) => {
    setPageNo(value);
  }

  const getCities = (countryAlpha3Code) => {
    setLoadingCity(true)
    getCitiesRequest(
      countryAlpha3Code,
      (cities) => {
        var cityOptionsFromAPI = [];
        cities.forEach(city => {
          cityOptionsFromAPI.push({
            value: city.city,
            label: city.city_ascii,
          })

        });
        setCityOptions(cityOptionsFromAPI);
        setLoadingCity(false)
      },
      (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        setLoadingCity(false)
      }
    )
  }

  const getListings = () => {
    showLoader();
    getListingsWithFilterRequest(
      searchFilter.cities.map(city => (city.label)),
      searchFilter.country ? searchFilter.country.label : null,
      searchFilter.artistTypes.map(artistType => (artistType.value)),
      searchFilter.eventTypes.map(eventType => (eventType.value)),
      searchFilter.ambiences.map(ambience => (ambience.value)),
      searchFilter.genres.map(genre => (genre.value)),
      searchFilter.priceRange ? searchFilter.priceRange[0] : null,
      searchFilter.priceRange ? searchFilter.priceRange[1] : null,
      pageNo,
      searchFilter.isInsured ? searchFilter.isInsured.value : null,
      searchFilter.listingName,
      (data) => {
        console.log(data);
        setListings(data.listings);
        setTotalNoOfPages(data.pageDetails.totalPages);
        setTotalNoOfListings(data.pageDetails.totalItems);
        hideLoader();
      },
      (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      }
    )
  }

  const countryOptions = () => {
    var countriesOptions = [];
    countries.forEach(country => {
      countriesOptions.push({
        value: country.countryAlpha3Code,
        label: country.countryAsciiName
      })

    });
    return countriesOptions;
  }

  const artistTypeOptions = () => {
    var artistTypesOptions = [];
    artistTypes.forEach(artistType => {
      artistTypesOptions.push({
        value: artistType.artistTypeId,
        label: artistType.artistTypeName
      })

    });
    return artistTypesOptions;
  }

  const eventTypeOptions = () => {
    var eventTypesOptions = [];
    eventTypes.forEach(eventType => {
      eventTypesOptions.push({
        value: eventType.eventTypeId,
        label: eventType.eventTypeName
      })

    });
    return eventTypesOptions;
  }

  const genreOptions = () => {
    var genresOptions = [];
    genres.forEach(genre => {
      genresOptions.push({
        value: genre.genreId,
        label: genre.genreName
      })

    });
    return genresOptions;
  }

  const ambienceOptions = () => {
    var ambiencesOptions = [];
    ambiences.forEach(ambience => {
      ambiencesOptions.push({
        value: ambience.ambienceId,
        label: ambience.ambienceName
      })

    });
    return ambiencesOptions;
  }
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    console.log(props.location.state);
    console.log(initialRender);
    if (initialRender && props.location && props.location.state) {
      console.log("initial")
      setInitialRender(false);
    }
    else {
      console.log("first")
      getListings();
    }
    setInitialRender(false);
  }, [searchFilter, pageNo])

  useEffect(() => {
    setPageNo(1)
  }, [searchFilter])

  useEffect(() => {
    if (props.location && props.location.state) {
      var searchFilterFromOut = props.location.state
      console.log(searchFilterFromOut);
      if (searchFilterFromOut.country) {
        getCities(searchFilterFromOut.country.value)
      }
      setSearchFilter({
        ...searchFilter,
        ...searchFilterFromOut
      })
    }
  }, [props.location])


  return (
    <div>
       <ClientHeader />
      <div className="search-page">
        <div className="search-page__top-section">
          <p className="search-page__top-section--count">
            {
              totalNoOfListings > 0
                ?
                (
                  (`${totalNoOfListings} listing${totalNoOfListings === 1 ? ('') : ('s')} found`)
                )
                :
                (
                  'No Listings'
                )
            }
          </p>
          <div className={isVisibleMoreFilter ? 'show-filter-animation' : 'hide-filter-animation'}>
            {!isVisibleMoreFilter ?
              (
                <div className='search-page__top-section--more-filter-btn '>
                  <KalabSmallButton
                    text='More Filters'
                    isLightTheme
                    fontSize={12}
                    onClick={() => {
                      setIsVisibleMoreFilter(!isVisibleMoreFilter);
                    }}
                  />
                </div>
              )
              :
              (
                <div className='search-page__top-section--filter'>
                  <div className='filter-section'>
                    {/* <div style={{ flex: 1, padding: 5, paddingLeft: 0, minWidth: 125 }}>
                      <KalabFilterDatePickerDropDown
                        value={searchFilter.date}
                        onChange={handleDateChange}
                        placeholder='Date'
                      />
                    </div> */}
                    <div style={{ flex: 1, padding: 5, paddingLeft: 0, minWidth: 125 }}>
                      <KalabFilterDropDownWithText
                        value={searchFilter.listingName}
                        onChange={handleListingNameChangeValue}
                        placeholder='Name'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, paddingLeft: 0, minWidth: 125 }}>
                      <KalabFilterDropDownWithSearch
                        value={searchFilter.country}
                        onChange={handleCountryChangeValue}
                        options={countryOptions()}
                        placeholder='Country'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDownWithSearch
                        value={searchFilter.cities}
                        onChange={handleCitySelectChange}
                        options={cityOptions}
                        placeholder='City'
                        isMulti
                        multiValuePlaceholder='Cities'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDown
                        value={searchFilter.artistTypes}
                        onChange={handleArtistTypeChangeValue}
                        options={artistTypeOptions()}
                        noValuePlaceholder='Artist Type'
                        multiValuePlaceholder='Artist Types'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDown
                        value={searchFilter.eventTypes}
                        onChange={handleEventTypeChangeValue}
                        options={eventTypeOptions()}
                        noValuePlaceholder='Event Type'
                        multiValuePlaceholder='Event Types'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDown
                        value={searchFilter.genres}
                        onChange={handleGenreChangeValue}
                        options={genreOptions()}
                        noValuePlaceholder='Genre'
                        multiValuePlaceholder='Genres'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDown
                        value={searchFilter.ambiences}
                        onChange={handleAmbienceChangeValue}
                        options={ambienceOptions()}
                        noValuePlaceholder='Ambience'
                        multiValuePlaceholder='Ambiences'
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterDropDownWithSearch
                        value={searchFilter.isInsured}
                        onChange={handleInsuranceSelectChange}
                        options={insuranceOptions}
                        placeholder='Insurance'
                        noSearch
                      />
                    </div>
                    <div style={{ flex: 1, padding: 5, minWidth: 125 }}>
                      <KalabFilterSliderDropDown
                        value={searchFilter.priceRange}
                        onChange={handlePriceRangeChange}
                        placeholder='Price'
                      />
                    </div>
                  </div>
                  <div style={{ padding: 5, paddingRight: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <KalabIconButton
                      iconName='expand_less_rounded'
                      isLightTheme
                      noBorder
                      onClick={() => {
                        setIsVisibleMoreFilter(!isVisibleMoreFilter);
                      }}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div className="search-page__main-section">
          {listings.map((listing) => (
            <div key={listing.listingId} className="search-page__main-section--listing">
              <KalabListingCard
                listing={listing}
              />
            </div>
          ))}
        </div>
        {
          totalNoOfPages > 1
            ?
            (
              <div className="search-page__paging-section">
                <Pagination count={totalNoOfPages} page={pageNo} onChange={handlePageChange} siblingCount={width < 600 ? 0 : 1}  size={width < 600 ? 'small' : 'medium'}/>
              </div>
            )
            :
            (
              null
            )
        }


      </div>
    </div>
  );
};

export default ClientDiscoverPage;
