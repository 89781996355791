import moment from 'moment';
import Colors from '../constants/Colors';

export const dateToShowInMessageList = (dateString) => {
    var date = moment(dateString).local().calendar(null, {
        sameDay: 'hh:mm a',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'MM/DD/YYYY',
        sameElse: 'MM/DD/YYYY'
    });
    return date;
}

export const dateToShowInMessages = (dateString) => {
    var date = moment(dateString).local().calendar(null, {
        sameDay: 'hh:mm a',
        nextDay: '[Tomorrow,] hh:mm a',
        nextWeek: 'ddd, hh:mm a',
        lastDay: '[Yesterday,] hh:mm a',
        lastWeek: 'ddd, hh:mm a',
        sameYear: 'D MMM, hh:mm a',
        sameElse: 'D MMM Y, hh:mm a'
    });
    return date;
}

export const getFormattedDate = (dateString) => {
    var date = moment(dateString, 'YYYY-MM-DD');

    return date.format('dddd, MMMM Do YYYY')
}
export const getFormattedDateForStartEndDate = (startDateString, endDateString) => {
    var startDate = moment(startDateString, 'YYYY-MM-DD');
    var endDate = moment(endDateString, 'YYYY-MM-DD');

    var result = ''

    if(startDate.get('year') === endDate.get('year'))
    {
        if(startDate.get('month')=== endDate.get('month') ){
            result = `${startDate.format('Do')} - ${endDate.format('Do MMMM YYYY')}`
        }
        else{
            result = `${startDate.format('Do MMM')} - ${endDate.format('Do MMM YYYY')}`
        }
    }
    else{
        result = `${startDate.format('Do MMM YY')} - ${endDate.format('Do MMM YY')}`
    }
    return result
}

export const getFormattedDateWithoutYear= (dateString) => {
    var date = moment(dateString, 'YYYY-MM-DD');

    return date.format('Do MMM,ddd')
}

export const getFormattedTime = (timeString) => {
    var date = moment(timeString, 'HH:mm:ss');

    return date.format('h:mm a')
}

export const getFormattedDateTime = (dateTimeString) => {
    var dateTime = moment(dateTimeString).local();

    return dateTime.format('dddd, MMMM Do YYYY h:mm a')
}



export const getFormattedDateForBookingCell = (dateString) => {
    var date = moment(dateString, 'YYYY-MM-DD');

    return date.format('Do MMMM YYYY')
}

export const maxDateForBooking = () => {
    var today = moment()
    return today.add(80, 'days');
}

export const restrictionForResidency = (startDate, endDate) => {
    var sDate1 = moment(startDate)
    var sDate2 = moment(startDate)
    var eDate = moment(endDate);
    var today = moment();
 
    var result = {
        endDateMax : startDate ? sDate1.add(180, 'days').toDate() : null,
        endDateMin : startDate ? sDate2.add(1, 'days').toDate() : today.add(1, 'days').toDate(),
        startDateMax : endDate ? eDate.add(-1, 'days').toDate() : null
    }

    return result;
}

export const isExceedingMaxDays = (dateString) => {
    console.log(dateString);
    var date = moment(dateString, 'YYYY-MM-DD');
    var today = moment();
    var lastDay = today.add(80, 'days');
    return !date.isSameOrBefore(lastDay);
}

export const getTimingsStringFromObject = (timings)=>{
    var timingsStringArray = [];
     timings.forEach(timing => {
        var date = moment(timing.date,'YYYY-MM-DD');
        var startTime = moment(timing.startTime,'HH:mm:ss');
        var endTime = moment(timing.endTime,'HH:mm:ss');

        var timingString = `${date.format('YYYY[y]MM[m]DD-')}${startTime.format('HHmm[s]-')}${endTime.format('HHmm[e]')}-${timing.hours}h`;
        console.log(timingString);
        timingsStringArray.push(timingString);
     });
     return (timingsStringArray.join(','))
}