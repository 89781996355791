import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';
import { useHistory } from "react-router-dom";
import KalabIconButton from '../kalabButtons/KalabIconButton';
import Colors from '../../../constants/Colors';
import { openInNewTab } from '../../../utils/common';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';

const useStyles = makeStyles({
    root: props => ({
        padding: 10,
        transition: '0.3s',
        borderRadius: 5,
        position: 'relative',
        height: '100%',
        '&:hover': {
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            cursor: 'pointer',
            '& .delete-button': {
                visibility: 'visible'
            },
            '& .add-button': {
                visibility: 'visible'
            }

        },
        '& .delete-button': {
            position: 'absolute',
            top: 0,
            right: 0,
            visibility: 'hidden'
        },

        '& .add-button': {
            position: 'absolute',
            top: 0,
            right: 30,
            visibility: 'hidden'
        }

    }),
    image: props => ({
        width: '100%',
        aspectRatio: 1 / 1,
        objectFit: 'cover',
        borderRadius: '50%',
        borderWidth: 2,
        padding: 2,
        opacity: props.isAvailable ? 1 : 0.6,
        borderColor: props.isAvailable ? Colors.black : Colors.lightestGrey,
        '&:hover': {
            borderWidth: 3,
        }
    }),
    name: props => ({
        marginTop: 5,
        display: 'block',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 15,
        fontWeight: '300',

        '& .insured': {
            display: 'inline-block'
        }
    })

})

export default function KalabWishlistBasicListingCard({ listing, deleteFn, addToShortListFn, isAvailable = false }) {

    const handleClickOnCard = () => {
        openInNewTab(window.location.origin + '/listing/' + listing.listingToken);
    }
    const handleDeleteBtnClick = () => {
        deleteFn(listing.listingId)
    }

    const handleAddToShortListBtnClick = () => {
        addToShortListFn(listing.listingId)
    }
    const classes = useStyles({ isAvailable });
    return (
        <div className={classes.root} >
            <div style={{ width: '50%', margin: 'auto' }}>
                <div className="aspectratio ar1-1">
                    <img
                        className={classes.image}
                        src={listing.listingImages[0].listingImageUrl}
                        alt='listing-pic'
                        onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                        onClick={handleClickOnCard}
                    />
                </div>
            </div>
            <span className={classes.name}>
                {listing.listingName}
                {
                    listing.isInsured ?
                        (
                            <div className='insured'>
                                <KalabIconWithToolTip
                                    toolTipText="Insured"
                                    placement="top-start"
                                    iconName='verified_user_outlined'
                                    iconSize={13}
                                    noBorder={true}
                                    color={Colors.lightGrey}
                                />
                            </div>
                        )
                        :
                        (
                            null
                        )
                }
            </span>
            {isAvailable ? (
                <div className='add-button'>
                    <KalabIconButton
                        iconName='check_rounded'
                        isLightTheme
                        noBorder
                        onClick={handleAddToShortListBtnClick}
                    />
                </div>
            ) : (null)}

            <div className='delete-button'>
                <KalabIconButton
                    iconName='delete_outline_rounded'
                    isLightTheme
                    noBorder
                    onClick={handleDeleteBtnClick}
                />
            </div>
        </div>
    )
}