import React from 'react';
import Colors from '../../../constants/Colors';
import Icon from '@material-ui/core/Icon';
import {
    makeStyles
} from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        fontFamily: 'Montserrat, sans-serif',
        display: 'flex',
        alignItems:'center',
        color: Colors.primary,
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: Colors.primary,
        borderRadius: 25,
        fontSize: 16,
        textTransform: 'uppercase',
        paddingTop: 5,
        paddingLeft: 10,
        paddingBottom: 5,
        paddingRight: 10,
        transition: 'max-width 1.5s',
       
        '&:hover:not([disabled])': {
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            color: Colors.white,
            cursor: 'pointer',
            outline: 'none',
            '& .kalab-btn-txt': {
                maxWidth:'400px',
                cursor: 'pointer', 
            },
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey,
        },
        '& .kalab-btn-txt': {
            maxWidth: 0,
            display: 'inline-block',
            transition: 'max-width 1.5s',
            whiteSpace : 'nowrap',
            overflow : 'hidden'
        }
    }
});

export default function KalabMainButtonWithIcon({ className = "", disabled, onClick, text, iconName , type="button"}) {
    const classes = useStyles();
    return (
        <button className={`${classes.root} ${className}`} disabled={disabled} onClick={onClick} type={type}>
            <span className='kalab-btn-txt'>{text}</span>
            <Icon style={{fontSize: 20, marginTop: 5, marginBottom : 5}}>{iconName}</Icon>
        </button>
    )
}
