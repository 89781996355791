import React from 'react';
import Icon from '@material-ui/core/Icon';
import {
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: props => ({
        backgroundColor: 'transparent',
        display: 'flex',
        outline: 'none',
        border: '1px solid',
        borderColor: Colors.lightGrey,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',

        '& span': {
            fontSize: 20,
        }


    }),
    usual: props => ({
        '&:not([disabled])': {
            borderColor: props.isLightTheme ? Colors.black : Colors.white,
            color: props.isLightTheme ? Colors.black : Colors.white
        },
        '&:hover:not([disabled])': {
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey
        },
    }),
    noDisabled: props => ({
        '&:not([disabled])': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey
        },
        '&:hover:not([disabled])': {
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey
        }
    }),
    noDisabledWhite: props => ({
        '&:not([disabled])': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey
        },
        '&:hover:not([disabled])': {
            borderColor: props.isLightTheme ? Colors.black : Colors.white,
            color: props.isLightTheme ? Colors.black : Colors.white
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: props.isLightTheme ? Colors.black : Colors.white,
            color: props.isLightTheme ? Colors.black : Colors.white
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey
        }
    }),
    noDisabledColor: props => ({
        '&:not([disabled])': {
            borderColor: props.disabledColor ? props.disabledColor : Colors.lightGrey,
            color: props.disabledColor ? props.disabledColor : Colors.lightGrey
        },
        '&:hover:not([disabled])': {
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: Colors.primary,
            color: Colors.primary
        },
        '&:disabled': {
            borderColor: props.disabledColor ? props.disabledColor : Colors.lightGrey,
            color: props.disabledColor ? props.disabledColor : Colors.lightGrey
        }
    }),
    filled: props => ({
        '&:not([disabled])': {
            borderColor: Colors.primary,
            backgroundColor: Colors.primary,
            color: Colors.white
        },
        '&:hover:not([disabled])': {
            borderColor: Colors.primaryDark,
            backgroundColor: Colors.primaryDark,
            color: Colors.white
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: Colors.primaryDark,
            backgroundColor: Colors.primaryDark,
            color: Colors.white
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            backgroundColor: Colors.lightGrey,
            color: Colors.white
        },
    }),
});

export default function KalabIconButton({ className = "", disabled, onClick, iconName, iconSize = 20, height = 5, noBorder = false, isFAIcon = false, noDisabled = false, isWhite = false, isLightTheme = false, isFilled = false , type="button", disabledColor = null}) {
    const props = ({ isLightTheme, disabledColor })
    const classes = useStyles(props);

    const classNamesAccordingToProps = () => {
        if (noDisabled) {
            if (isWhite) {
                return (classes.noDisabledWhite);
            }
            else if(disabledColor){
                return (classes.noDisabledColor);
            } 
            else {
                return (classes.noDisabled);
            }
        }
        else if (isFilled) {
            return (classes.filled);
        }
        else {
            return (classes.usual);
        }
    }
    return (

        <button className={`${classes.root} ${classNamesAccordingToProps()} ${className}`} style={{ height: iconSize + height, width: iconSize + height, borderWidth: noBorder ? 0 : 1}} disabled={disabled} onClick={onClick} type={type}>
            {!isFAIcon ? (
                <Icon style={{ fontSize: iconSize }}>{iconName}</Icon>
            ) : (
                <i className={`${iconName}`} style={{ fontSize: iconSize - 2 }}></i>
            )}
        </button>
    )
}
