import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';

export default function KalabAnimatedDiv({className,onClick, fade = true, flyIn = true,delay = 0, children, once=false}) {
    return (
        <motion.div
            initial={{ opacity: fade ? 0 : 1, y: flyIn ? 100 : 0 }}
            whileInView={{ opacity: 1 , y: 0}}
            transition={{ duration: 1 , delay :delay}}
            viewport={{ once: once ? true : false }}
            className={className}
            onClick = {onClick}
        >
            {children}
        </motion.div>
    )
}
