import React from 'react'
import './NotFoundPage.scss';
import { NavLink } from 'react-router-dom';

export default function NotFoundPage() {
    return (
        <div className='nf-page'>
            <div className='nf-page__title'>
                404
            </div>
            <div className='nf-page__description'>
                We are sorry, but the page you requested was not found
            </div>
            <NavLink className='nf-page__home-button' to="/">
                Go Home
            </NavLink>
        </div>
    )
}
