import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const ClientRoute = ({ component: Component, checkEnterprise = false, ...rest }) => {
    const { loggedInUserDetails } = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={(props) => loggedInUserDetails.user && loggedInUserDetails.role === 'client' && ((checkEnterprise && loggedInUserDetails.user.isEnterprise ) ||(!checkEnterprise)) ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        />
    );
}


export default ClientRoute;