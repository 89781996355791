module.exports = {
    EVENT_REQUEST_CREATION : {
        client : 'You have sent a new event request',
        artist : 'You have got a new event request'
    },
    EVENT_REQUEST_UPDATED : {
        client : 'You have updated the event request',
        artist : 'The event request is updated'
    },
    EVENT_REQUEST_ACCEPTED_BY_ARTIST : {
        client : 'Event request is accepted',
        artist : 'You have accepted the event request'
    },
    EVENT_REQUEST_UPDATED_AND_ACCEPTED_BY_ARTIST : {
        client : 'Event request is updated and accepted',
        artist : 'You have updated and accepted the event request'
    },
    EVENT_REQUEST_CANCELLED : {
        client : 'You have cancelled the event request',
        artist : 'Event request is cancelled'
    },
    EVENT_REQUEST_REJECTED : {
        client : 'Event request is rejected',
        artist : 'You have rejected the event request'
    },
    EVENT_REQUEST_REJECTED_BY_ADMIN : {
        client : 'Event request is rejected by admin',
        artist : 'Event request is rejected by admin'
    },
    EVENT_BOOKED:{
        client : 'You have booked an event',
        artist : 'An event is booked'
    },
    BOOKING_COMPLETED:{
        client : 'Your booked event is completed',
        artist : 'Your booked event is completed'
    },
    BOOKING_CANCELLED:{
        client : 'Your booking is cancelled',
        artist : 'The booking you have made is cancelled'
    },
    BOOKING_UPDATED_BY_ADMIN:{
        client : 'Your booking is updated by admin',
        artist : 'Your booking is updated by admin'
    }
    
}