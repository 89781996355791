import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: {
        textAlign: 'center',
    },
    expandBtn: {
        textAlign: 'center',
        fontWeight: '500',
        '&:hover': {
            color: Colors.primary,
            cursor: 'pointer'
        }
    }
})

export default function KalabExpandableList({ list, limitLines = 2 }) {
    const classes = useStyles({ limitLines });
    const [isExpanded, setIsExpanded] = useState(false)
    const isExpandable = list.length > limitLines ? true : false



    return (
        <>
            {list.map((single, index) => {
                if (isExpanded) {
                    return (
                        <div className={classes.root}>
                            {single}
                        </div>
                    )
                }
                else {
                    if (index < limitLines) {
                        return (
                            <div className={classes.root}>
                                {single}
                            </div>
                        )
                    }
                    else {
                        return (null)
                    }
                }

            })}
            {
                (isExpandable) &&
                <div
                    className={classes.expandBtn}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? " Show Less" : " See More"}
                </div>

            }

        </>
    )
}
