import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProtectedRoute from './routes/ProtectedRoute';
import ClientRoute from './routes/ClientRoute';
import ArtistRoute from './routes/ArtistRoute';

import HomePage from './components/pages/HomePage';
import SignUpPage from './components/pages/SignUpPage';
import ApplicationPage from './components/pages/ApplicationPage';
import RegistrationPage from './components/pages/RegistrationPage';
import NotFoundPage from './components/pages/NotFoundPage';
import ArtistHomePage from "./components/pages/ArtistHomePage";
import ArtistInboxPage from './components/pages/ArtistInboxPage';
import ClientInboxPage from './components/pages/ClientInboxPage';
import ClientHomePage from './components/pages/ClientHomePage';
import ClientBookingsPage from './components/pages/ClientBookingsPage';
import ArtistBookingsPage from './components/pages/ArtistBookingsPage';
import WishlistPage from './components/pages/WishlistPage';
import ResultPage from './components/pages/ResultPage';
import ClientEmailVerificationPage from './components/pages/ClientEmailVerificationPage';
import TermsOfServicePage from './components/pages/TermsOfServicePage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import AboutPage from './components/pages/AboutPage';
import AddListingPage from './components/pages/AddListingPage';
import EditListingPage from './components/pages/EditListingPage';
import SearchPage from './components/pages/SearchPage';
import ListingPage from './components/pages/ListingPage';
import ClientRatingsPage from './components/pages/ClientRatingsPage';
import ArtistRatingsPage from './components/pages/ArtistRatingsPage';
import TemplatePage from './components/pages/TemplatePage';
import LandingPage from './components/pages/LandingPage';
import ClientDiscoverPage from './components/pages/ClientDiscoverPage';
import CreateBookingLinkPage from './components/pages/CreateBookingLinkPage';
import LinkPage from './components/pages/LinkPage';


const App = () => {
  return (
    <div>
      <BrowserRouter>
        {/*<MainHeader/>*/}
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='/landing' component={LandingPage} />
          <Route path='/home' component={HomePage} />
          <Route path="/search-results" component={SearchPage} />
          <Route path="/listing/:token" component={ListingPage} />
          <Route path="/link/:token" component={LinkPage} />
          <Route path="/terms-of-service" component={TermsOfServicePage} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/about" component={AboutPage} />

          <Route path='/artist/signup' component={SignUpPage} />
          <Route exact path='/artist/application' component={ApplicationPage} />
          <ProtectedRoute path='/artist/application/result' component={ResultPage} />
          <ProtectedRoute path='/artist/registration/result' component={ResultPage} />
          <Route path='/artist/registration/:token' component={RegistrationPage} />
          <ArtistRoute path='/artist/home' component={ArtistHomePage} />
          <ArtistRoute path='/artist/bookings' component={ArtistBookingsPage} />
          <ArtistRoute path="/artist/inbox" component={ArtistInboxPage} />
          <ArtistRoute path="/artist/listing/add" component={AddListingPage} />
          <ArtistRoute path="/artist/listing/edit" component={EditListingPage} />
          <ArtistRoute path="/artist/booking-link" component={CreateBookingLinkPage} />
          <Route path='/artist/rating/:token' component={ArtistRatingsPage} />

          <ProtectedRoute path='/client/registration/result' component={ResultPage} />
          <Route path='/client/email-verification/:mailVerificationToken' component={ClientEmailVerificationPage} />
          <ClientRoute path='/client/home' component={ClientHomePage} />
          <ClientRoute path='/client/template' component={TemplatePage} checkEnterprise={true}/>
          <ClientRoute path='/client/wishlist' component={WishlistPage} checkEnterprise={true}/>
          <ClientRoute path='/client/bookings' component={ClientBookingsPage} checkEnterprise={true}/>
          <ClientRoute path='/client/discover' component={ClientDiscoverPage} checkEnterprise={true}/>
          <ClientRoute path="/client/inbox" component={ClientInboxPage} />
          <Route path='/client/rating/:token' component={ClientRatingsPage} />

          <Route path="*" component={NotFoundPage} />
        </Switch>

      </BrowserRouter>

    </div>
  );
}

export default App;
