import React from 'react';
import Colors from '../../../constants/Colors';
import {
    makeStyles
} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';


const useStyles = makeStyles({
    button: props => ({
        fontFamily: 'Montserrat, sans-serif',
        color: props.noDisabled ? Colors.lightGrey : props.isLightTheme ? Colors.black : Colors.white,
        backgroundColor: 'transparent',
        border: props.noBorder ? 'none' : '1px solid',
        borderColor: props.noDisabled ? Colors.lightGrey : props.isLightTheme ? Colors.black : Colors.white,
        borderRadius: 25,
        fontSize: props.fontSize,
        textTransform: 'uppercase',
        paddingTop: 5,
        paddingLeft: 5,
        paddingBottom: 5,
        paddingRight: 5,
        '&:hover:not([disabled])': {
            borderColor: props.color,
            color: props.color,
            cursor: 'pointer',
            outline: 'none'
        },
        '&:disabled': {
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey,
        }
    }),
    menu: props => ({
        '& ul':{
            padding : 0,
            '& li': {
                fontFamily: 'Montserrat, sans-serif',
                color: props.noDisabled ? Colors.lightGrey : props.isLightTheme ? Colors.black : Colors.white,
                fontSize: props.fontSize,
                textTransform: 'uppercase',
            }
        }
        
    }
    )
});

export default function KalabMenuButton({ className = "", disabled, buttons, text, fontSize = 10, isLightTheme = false, color = Colors.primary, noDisabled = false, noBorder = false, type = "button" }) {
    const props = { isLightTheme, fontSize, color, noDisabled, noBorder }
    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <button
                className={`${classes.button} ${className}`}
                disabled={disabled}
                onClick={handleClick}
                type={type}
            >
                {text}
            </button>
            <Menu
                className={`${classes.menu}`}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {
                    buttons.map(button => (
                        <MenuItem onClick={() => { handleClose(); button.action(); }}>
                            {button.text}
                        </MenuItem>
                    ))
                }

            </Menu>
        </div>
    )
}