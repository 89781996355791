import React, { useState, useEffect, useContext } from 'react'
import { isAfter, format } from 'date-fns';
import './WishlistPage.scss';
import ClientHeader from "../header/ClientHeader";
import { UserContext } from "../../contexts/UserContext";
import { DataStoreContext } from "../../contexts/DataStoreContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import {
    getClientWishlistListingsRequest,
    deleteClientWishlistListingRequest,
    getAvailableListingsRequest,
    createEventRequestsRequest,
    getEventTemplatesRequest
} from '../../webservices/Webservice'
import KalabWishlistBasicListingCard from '../kalab/kalabCards/KalabWishlistBasicListingCard';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabDatePickerDropDownNormal from '../kalab/kalabDropDown/KalabDatePickerDropDownNormal';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import TimeOfEvents from '../../constants/TimeOfEvents';
import KalabWishlistAdvancedListingCard from '../kalab/kalabCards/KalabWishlistAdvancedListingCard';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabTimePickerDropDown from '../kalab/kalabDropDown/KalabTimePickerDropDown';
import KalabIconCheckBox from '../kalab/kalabCheckBoxes/KalabIconCheckBox';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import { getDefaultCurrency, getLabelFromValue } from '../../utils/Currencies';
// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import { Icon, InputAdornment } from '@material-ui/core';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import Colors from '../../constants/Colors';
import KalabTimeDropDown from '../kalab/kalabDropDown/KalabTimeDropDown';
import { getFormattedDate, getFormattedDateWithoutYear, getFormattedTime, restrictionForResidency } from '../../utils/Date';
import moment from 'moment';
import KalabResidencyCalendar from '../kalab/kalabCalendar/KalabResidencyCalendar';
import KalabCurrencyDropDown from '../kalab/kalabDropDown/KalabCurrencyDropDown';

export default function WishlistPage(props) {

    const [listings, setListings] = useState([]);
    const [availableListings, setAvailableListings] = useState([]);
    const [selectedListings, setSelectedListings] = useState([]);
    const [isCheckedAvailable, setIsCheckedAvailable] = useState(false);
    const [showRequest, setShowRequest] = useState(false);
    const [availabilityFilter, setAvaliabilityFilter] = useState({
        artistType: null,
        eventType: null,
        genres: [],
        date: null,
        timeOfEvents: [],
        isResidency: false,
        endDate: null
    })
    const [listingSwiper, setListingSwiper] = useState(null);
    const [listingSwiperDetail, setListingSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const [request, setRequest] = useState({
        eventName: '',
        hours: null,
        startTime: null,
        endTime: null,
        dressCode: '',
        comments: '',
        songRequest: '',
        location: '',
        equipmentDetails: '',
        parking: false,
        equipment: false,
        accomodation: false,
        foodBeverage: false,
        transportation: false,
        templateName: '',
        timings: [],
        artistPrice: '0',
        currency: getDefaultCurrency().value
    })

    const [residencyCalendar, setResidencyCalendar] = useState({
        date: null,
        startTime: null,
        endTime: null,
        hours: null,
        isEdit: false,
        weekDays: []
    })

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showTemplateSection, setShowTemplateSection] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { artistTypes, eventTypes, genres, ambiences, countries, loading, settings } = useContext(DataStoreContext);
    const notify = useNotification();
    const { loggedInUserDetails } = useContext(UserContext)

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }

    const getAvailableListings = () => {
        showLoader();
        getAvailableListingsRequest(
            availabilityFilter.eventType.value,
            availabilityFilter.artistType.value,
            availabilityFilter.genres.map(genre => (genre.value)),
            availabilityFilter.date,
            availabilityFilter.timeOfEvents.map(timeOfEvent => (timeOfEvent.value)),
            availabilityFilter.isResidency ? availabilityFilter.endDate : null,
            availabilityFilter.isResidency,
            (availableListings) => {
                hideLoader();
                setAvailableListings(availableListings);
                setSelectedListings(availableListings);
                setIsCheckedAvailable(true);
                console.log(availableListings);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }

    const checkMaxDateEnabled = () => {
        console.log(loggedInUserDetails)
        if (
            loggedInUserDetails &&
            loggedInUserDetails.user &&
            loggedInUserDetails.user.paymentCapabilities &&
            loggedInUserDetails.user.paymentCapabilities.includes('DIRECT')
        ) {
            return (false)
        }
        else {
            return (true)
        }
    }

    const isResidencyEnabled = () => {
        console.log(loggedInUserDetails)
        if (
            loggedInUserDetails &&
            loggedInUserDetails.user &&
            loggedInUserDetails.user.paymentCapabilities &&
            loggedInUserDetails.user.paymentCapabilities.includes('DIRECT')
        ) {
            return (true)
        }
        else {
            return (false)
        }
    }

    const removeListingFromWishlist = (listingId) => {
        showLoader();
        deleteClientWishlistListingRequest(
            listingId,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                getWishlistListings();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }
    const sendEventRequest = () => {
        showLoader();
        createEventRequestsRequest(
            selectedListings.map(selectedListing => (selectedListing.listingId)),
            availabilityFilter.eventType.value,
            availabilityFilter.genres.map(genre => (genre.value)),
            availabilityFilter.artistType.value,
            request.songRequest.trim(),
            request.comments.trim(),
            format(availabilityFilter.date, 'yyyy-MM-dd'),
            availabilityFilter.isResidency ? null : format(request.startTime, 'HH:mm:ss'),
            availabilityFilter.isResidency ? null : format(request.endTime, 'HH:mm:ss'),
            request.eventName.trim(),
            request.location.trim(),
            availabilityFilter.isResidency ? null : request.hours.value,
            request.parking,
            request.accomodation,
            request.foodBeverage,
            request.transportation,
            request.equipment,
            request.dressCode.trim(),
            request.equipment ? request.equipmentDetails.trim() : '',
            checkIfAlreadyFoundTemplate() ? false : saveTemplate,
            checkIfAlreadyFoundTemplate() ? "" : request.templateName.trim(),
            availabilityFilter.isResidency,
            availabilityFilter.isResidency ? format(availabilityFilter.endDate, 'yyyy-MM-dd') : null,
            availabilityFilter.isResidency ?
                request.timings.map(timing => ({
                    date: format(timing.date, 'yyyy-MM-dd'),
                    startTime: format(timing.startTime, 'HH:mm:ss'),
                    endTime: format(timing.endTime, 'HH:mm:ss'),
                    hours: timing.hours.value
                })
                )
                :
                null,
            request.currency,
            parseFloat(request.artistPrice),
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                props.history.push('/client/inbox')
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }
    const getWishlistListings = () => {
        showLoader();
        getClientWishlistListingsRequest(
            (listings) => {
                hideLoader();
                setListings(listings);
                console.log(listings);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }

    const handleTemplateSectionBtnClick = () => {
        if (showTemplateSection) {
            setSelectedTemplate(null);
        }
        setShowTemplateSection(!showTemplateSection)

    }

    const getTemplates = () => {
        showLoader();
        getEventTemplatesRequest(
            (eventTemplates) => {
                console.log(eventTemplates);
                setTemplates(eventTemplates);
                hideLoader();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const templatesOptions = () => {
        var templatesOptions = [];
        templates.forEach(template => {
            templatesOptions.push({
                label: template.templateName.toUpperCase(),
                value: template.templateId,
                template: template
            })
        })
        return templatesOptions;
    }
    const genreOptions = () => {
        var genresOptions = [];
        genres.forEach(genre => {
            genresOptions.push({
                value: genre.genreId,
                label: genre.genreName
            })

        });
        return genresOptions;
    }

    const artistTypeOptions = () => {
        var artistTypesOptions = [];
        artistTypes.forEach(artistType => {
            artistTypesOptions.push({
                value: artistType.artistTypeId,
                label: artistType.artistTypeName
            })

        });
        return artistTypesOptions;
    }

    const eventTypeOptions = () => {
        var eventTypesOptions = [];
        eventTypes.forEach(eventType => {
            eventTypesOptions.push({
                value: eventType.eventTypeId,
                label: eventType.eventTypeName
            })

        });
        return eventTypesOptions;

    }
    const hoursOptions = () => {
        var hoursOptions = [];
        for (var i = 1; i <= 12; i++) {
            hoursOptions.push({
                value: i,
                label: `${i} hour${i > 1 ? 's' : ''}`
            })
        }
        return hoursOptions;
    }

    const weekDayOptions = () => {
        var weekDayOptions = [];
        for (var i = 0; i < 7; i++) {
            weekDayOptions.push({
                value: i,
                label: `${moment.weekdays(i)}s`
            })
        }
        return weekDayOptions;
    }
    const handlePriceInputTextChange = (e) => {
        if (e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
        if (e.target.value === '') {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
    }
    const handleChangeInCurrencyValue = (value) => {
        setRequest({
            ...request,
            currency: value
        })
    }

    const handleTemplatesSelectChange = (template) => {
        setSelectedTemplate(template)
    }
    const handleIsResidencyChange = (isResidency) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            isResidency: isResidency
        })
    }
    const handleGenreSelectChange = (genreValues) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            genres: genreValues
        })
    }
    const handleArtistTypeSelectChange = (artistTypeValue) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            artistType: artistTypeValue
        })
    }
    const handleEventTypeSelectChange = (eventTypeValue) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            eventType: eventTypeValue
        })
    }
    const handleTimeOfEventSelectChange = (timeOfEventValues) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            timeOfEvents: timeOfEventValues.sort((a, b) => (a.id - b.id))
        })
    }
    const handleDateChange = (date) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            date: date
        })
    }
    const handleEndDateChange = (date) => {
        setAvaliabilityFilter({
            ...availabilityFilter,
            endDate: date
        })
    }
    const handleStartTimeChange = (time) => {
        if (request.endTime) {
            if (!isAfter(time, request.endTime)) {
                setRequest({
                    ...request,
                    startTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    startTime: request.endTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                startTime: time
            })
        }
    }
    const handleEndTimeChange = (time) => {
        console.log(time);
        if (request.startTime) {
            if (time == null || isAfter(time, request.startTime)) {
                setRequest({
                    ...request,
                    endTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    endTime: request.startTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                endTime: time
            })
        }

    }
    const handleResidencyCalendarStartTimeChange = (time) => {
        if (residencyCalendar.endTime) {
            if (!isAfter(time, residencyCalendar.endTime)) {
                setResidencyCalendar({
                    ...residencyCalendar,
                    startTime: time
                })
            }
            else {
                setResidencyCalendar({
                    ...residencyCalendar,
                    startTime: residencyCalendar.endTime
                })
            }
        }
        else {
            setResidencyCalendar({
                ...residencyCalendar,
                startTime: time
            })
        }
    }
    const handleResidencyCalendarEndTimeChange = (time) => {
        console.log(time);
        if (residencyCalendar.startTime) {
            if (time == null || isAfter(time, residencyCalendar.startTime)) {
                setResidencyCalendar({
                    ...residencyCalendar,
                    endTime: time
                })
            }
            else {
                setResidencyCalendar({
                    ...residencyCalendar,
                    endTime: residencyCalendar.startTime
                })
            }
        }
        else {
            setResidencyCalendar({
                ...residencyCalendar,
                endTime: time
            })
        }

    }

    const handleResidencyCalendarHoursSelectChange = (hourValue) => {
        setResidencyCalendar({
            ...residencyCalendar,
            hours: hourValue
        })
    }

    const handleResidencyCalendarClick = (date) => {
        console.log(date);
        var timingsFilter = request.timings.filter(timing => format(timing.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
        var isUnselect = residencyCalendar.date && format(residencyCalendar.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        setResidencyCalendar({
            ...residencyCalendar,
            date: isUnselect ? null : date,
            startTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].startTime : null,
            endTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].endTime : null,
            hours: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].hours : null,
            isEdit: timingsFilter.length > 0 && !isUnselect ? true : false,
            weekDays: []
        })
    }

    const handleClearTimingsBtn = () => {
        setRequest({
            ...request,
            timings: []
        })
    }

    const handleTimingDeleteBtnPress = (date) => {
        setRequest({
            ...request,
            timings: request.timings.filter(timing => format(timing.date, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd'))
        })
    }

    const handleWeekDaysSelectChange = (weekDaysValue) => {
        setResidencyCalendar({
            ...residencyCalendar,
            weekDays: weekDaysValue
        })
    }

    const handleCheckAvailableBtnClick = () => {
        getAvailableListings();
    }

    const handleInputTextChange = (e) => {
        var updatedRequest = {
            ...request
        }
        updatedRequest[e.target.id] = e.target.value
        setRequest(updatedRequest);
    }

    const handleHoursSelectChange = (hourValue) => {
        setRequest({
            ...request,
            hours: hourValue
        })
    }
    const handleChangeTransporationValue = (value) => {
        setRequest({
            ...request,
            transportation: value
        })
    }
    const handleChangeAccomadationValue = (value) => {
        setRequest({
            ...request,
            accomodation: value
        })
    }
    const handleChangeFoodBeverageValue = (value) => {
        setRequest({
            ...request,
            foodBeverage: value
        })
    }
    const handleChangeParkingValue = (value) => {
        setRequest({
            ...request,
            parking: value
        })
    }
    const handleChangeEquipmentValue = (value) => {
        setRequest({
            ...request,
            equipment: value,
            equipmentDetails: value ? request.equipmentDetails : ''
        })
    }

    const handleSendEventRequest = () => {
        sendEventRequest();
    }


    const getListingsInWishlist = () => {
        return listings.filter(listing => !(selectedListings.map(selectedListing => selectedListing.listingId).includes(listing.listingId)));
    }

    const isAvailableFilterValid = () => {
        if (
            availabilityFilter.artistType &&
            availabilityFilter.eventType &&
            availabilityFilter.genres.length > 0 &&
            (
                (
                    !availabilityFilter.isResidency &&
                    availabilityFilter.date &&
                    availabilityFilter.timeOfEvents.length > 0
                ) ||
                (
                    availabilityFilter.isResidency &&
                    availabilityFilter.date &&
                    availabilityFilter.endDate
                )
            )

        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isFormValid = () => {
        if (
            isAvailableFilterValid() &&
            (
                (
                    !availabilityFilter.isResidency &&
                    request.startTime &&
                    request.endTime &&
                    request.hours
                ) ||
                (
                    availabilityFilter.isResidency &&
                    checkValidTimings().isValid
                )
            ) &&
            request.eventName.trim() !== "" &&
            request.location.trim() !== "" &&
            request.artistPrice &&
            parseFloat(request.artistPrice) > 0 &&
            request.currency &&
            request.dressCode.trim() !== "" &&
            selectedListings.length > 0 &&
            (
                (request.equipment && request.equipmentDetails.trim() !== '') ||
                !request.equipment
            )
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isResidencyCalendarFilterValid = () => {
        if (
            (
                residencyCalendar.date ||
                residencyCalendar.weekDays.length > 0
            ) &&
            residencyCalendar.startTime &&
            residencyCalendar.endTime &&
            residencyCalendar.hours
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isListingAvailable = (listing) => {
        var result = false;
        availableListings.forEach(availableListing => {
            if (availableListing.listingId === listing.listingId) {
                result = true;
            }
        })
        return (result);

    }

    const removeFromShortList = (listingId) => {
        var newSelectedListings = [];
        selectedListings.forEach(selectedListing => {
            if (listingId === selectedListing.listingId) {

            }
            else {
                newSelectedListings.push(selectedListing)
            }
        })
        setSelectedListings(newSelectedListings);
    }

    const addToShortList = (listingId) => {
        var newSelectedListings = [];
        selectedListings.forEach(selectedListing => {
            newSelectedListings.push(selectedListing)
        })
        availableListings.forEach(availableListing => {
            if (availableListing.listingId === listingId) {
                newSelectedListings.push(availableListing)
            }
        })
        setSelectedListings(newSelectedListings);
    }

    useEffect(() => {
        getWishlistListings();
        getTemplates();
    }, [])

    useEffect(() => {
        console.log(selectedListings);
    }, [selectedListings])

    useEffect(() => {
        if (isCheckedAvailable) {
            setIsCheckedAvailable(false);
            setAvailableListings([]);
            setSelectedListings([]);
            setShowRequest(false);
            setRequest({
                eventName: '',
                hours: null,
                startTime: null,
                endTime: null,
                dressCode: '',
                comments: '',
                songRequest: '',
                location: '',
                equipmentDetails: '',
                parking: false,
                equipment: false,
                accomodation: false,
                foodBeverage: false,
                transportation: false,
                templateName: "",
                timings: [],
                artistPrice: '0',
                currency: getDefaultCurrency().value
            })
            setResidencyCalendar({
                date: null,
                startTime: null,
                endTime: null,
                hours: null,
                isEdit: false,
                weekDays: []
            })
        }
    }, [availabilityFilter])

    const addResidencyCalendarFilter = () => {
        var timings = request.timings.filter(timing => (format(timing.date, 'yyyy-MM-dd') !== format(residencyCalendar.date, 'yyyy-MM-dd')));
        timings.push({
            date: residencyCalendar.date,
            startTime: residencyCalendar.startTime,
            endTime: residencyCalendar.endTime,
            hours: residencyCalendar.hours,
        });
        setRequest({
            ...request,
            timings: timings.sort((a, b) => (a.date - b.date))
        })
        setResidencyCalendar({
            date: null,
            startTime: null,
            endTime: null,
            hours: null,
            isEdit: false,
            weekDays: []
        })
    }

    const applyResidencyCalendarWeekDaysFilter = () => {
        var sDate = moment(availabilityFilter.date);
        var eDate = moment(availabilityFilter.endDate);
        var dates = [];

        console.log('sDate', sDate);

        while (sDate.isSameOrBefore(eDate, 'date')) {
            console.log('sDate1', sDate.toDate());
            if (residencyCalendar.weekDays.map(weekDay => weekDay.value).includes(sDate.toDate().getDay())) {
                dates.push(sDate.toDate());
            }
            sDate = sDate.add(1, 'days');
        }
        var timings = request.timings.filter(timing => !dates.map(date => format(date, 'yyyy-MM-dd')).includes(format(timing.date, 'yyyy-MM-dd')));
        console.log('timings', timings);
        dates.forEach(date => {
            timings.push({
                date: date,
                startTime: residencyCalendar.startTime,
                endTime: residencyCalendar.endTime,
                hours: residencyCalendar.hours,
            })
        })
        console.log('timings2', timings);
        setRequest({
            ...request,
            timings: timings.sort((a, b) => (a.date - b.date))
        })
        setResidencyCalendar({
            date: null,
            startTime: null,
            endTime: null,
            hours: null,
            isEdit: false,
            weekDays: []
        })
    }


    const applyTemplate = () => {

        console.log(selectedTemplate.template);
        setRequest({
            ...request,
            eventName: selectedTemplate.template.eventName ? selectedTemplate.template.eventName : request.eventName,
            hours: selectedTemplate.template.hours ? hoursOptions().filter(hoursOption => hoursOption.value === selectedTemplate.template.hours)[0] : request.hours,
            dressCode: selectedTemplate.template.dressCode ? selectedTemplate.template.dressCode : request.dressCode,
            comments: selectedTemplate.template.comment ? selectedTemplate.template.comment : request.comments,
            songRequest: selectedTemplate.template.songRequests ? selectedTemplate.template.songRequests : request.songRequest,
            location: selectedTemplate.template.location ? selectedTemplate.template.location : request.location,
            equipmentDetails: selectedTemplate.template.equipmentDetails ? selectedTemplate.template.equipmentDetails : (selectedTemplate.template.equipment ? request.equipmentDetails : ""),
            parking: selectedTemplate.template.parking ? true : false,
            equipment: selectedTemplate.template.equipment ? true : false,
            accomodation: selectedTemplate.template.accomodation ? true : false,
            foodBeverage: selectedTemplate.template.foodBeverage ? true : false,
            transportation: selectedTemplate.template.transportation ? true : false
        })

        setSelectedTemplate(null);
        setShowTemplateSection(false)
    }

    const checkIfAlreadyFoundTemplate = () => {
        var result = false;
        console.log("test")
        templates.forEach(temp => {
            console.log(temp)
            console.log(request)
            if (
                temp.eventName === (request.eventName.trim() === '' ? null : request.eventName.trim()) &&
                temp.location === (request.location.trim() === '' ? null : request.location.trim()) &&
                temp.hours === (request.hours !== null ? request.hours.value : null) &&
                (temp.transportation ? true : false) === request.transportation &&
                (temp.accomodation ? true : false) === request.accomodation &&
                (temp.foodBeverage ? true : false) === request.foodBeverage &&
                (temp.equipment ? true : false) === request.equipment &&
                temp.equipmentDetails === (request.equipmentDetails.trim() === '' ? null : request.equipmentDetails.trim()) &&
                (temp.parking ? true : false) === request.parking &&
                temp.songRequests === (request.songRequest.trim() === '' ? null : request.songRequest.trim()) &&
                temp.comment === (request.comments.trim() === '' ? null : request.comments.trim()) &&
                temp.dressCode === (request.dressCode.trim() === '' ? null : request.dressCode.trim())
            ) {
                result = true;
            }
        });

        console.log(result)
        return result;

    }

    const isTemplateNameDuplicate = () => {
        if (
            templates.map(temp => temp.templateName ? temp.templateName.toLowerCase() : temp.templateName).includes(request.templateName.toLowerCase())
        ) {
            return true
        }
        else {
            return false
        }
    }

    const isSaveTemplateValid = () => {
        var result = true;
        if (!checkIfAlreadyFoundTemplate()) {
            if (saveTemplate) {
                if (request.templateName.trim() === "" || isTemplateNameDuplicate()) {
                    result = false
                }
            }
        }
        return result;
    }

    const checkValidTimings = () => {
        var isValid = true;
        var isStartDate = false;
        var isEndDate = false;
        var reasons = [];
        request.timings.forEach(timing => {
            if (moment(timing.date).isBefore(moment(availabilityFilter.date), 'date') || moment(timing.date).isAfter(moment(availabilityFilter.endDate), 'date')) {
                isValid = false;
                reasons.push('Invalid dates in events')
            }
            if (moment(timing.date).isSame(moment(availabilityFilter.date), 'date')) {
                isStartDate = true
            }
            if (moment(timing.date).isSame(moment(availabilityFilter.endDate), 'date')) {
                isEndDate = true
            }

        })

        if (!(isStartDate && isEndDate)) {
            isValid = false;
            reasons.push('Start & end dates events are mandatory')
        }

        return ({
            isValid,
            reasons
        })


    }




    return (
        <>
            <ClientHeader />
            <div className="wishlist-page">
                <div className="request-section">
                    <div className={isCheckedAvailable ? 'animatedShowShortList' : 'animatedHideShortList'}>
                        {isCheckedAvailable ? (
                            <div className="short-list">
                                <div className="short-list__title">Shortlist ({selectedListings.length})</div>
                                <div className="short-list-swiper">
                                    <div>
                                        <KalabIconButton
                                            iconName='keyboard_arrow_left_rounded'
                                            disabled={listingSwiperDetail.isBeginning}
                                            onClick={() => {
                                                listingSwiper.slidePrev();
                                            }}
                                            isLightTheme
                                        />
                                    </div>
                                    <Swiper
                                        onUpdate={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiper(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            });
                                        }}
                                        onSwiper={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiper(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            });
                                        }}
                                        spaceBetween={5}
                                        slidesPerView={selectedListings.length > 0 ? 4 : 1}
                                        freeMode={true}
                                        onSlideChange={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            })
                                        }}
                                        className="mySwiper"
                                    >
                                        {selectedListings.length > 0 ? (selectedListings.map((listing) => (
                                            <SwiperSlide key={listing.listingId}>
                                                <KalabWishlistAdvancedListingCard
                                                    listing={listing}
                                                    removeFn={removeFromShortList}
                                                />
                                            </SwiperSlide>
                                        )))
                                            :
                                            (
                                                <SwiperSlide>
                                                    <div className='no-listing-shortlist'>{availableListings.length > 0 ? 'No Listings Selected' : 'No Available Listings'}</div>
                                                </SwiperSlide>
                                            )
                                        }

                                    </Swiper>
                                    <div>
                                        <KalabIconButton
                                            iconName='keyboard_arrow_right_rounded'
                                            disabled={listingSwiperDetail.isEnd}
                                            isLightTheme
                                            onClick={() => {
                                                listingSwiper.slideNext();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (null)}
                    </div>
                    <div className="request-form">
                        <div className="request-form__availability" >
                            {
                                isResidencyEnabled() ?
                                    (
                                        <div className="request-form__availability--row" >
                                            <div className="request-form__availability--row__element">
                                                <div style={{ textAlign: 'center' }}>
                                                    <KalabCheckBox
                                                        label={'SINGLE BOOKING'}
                                                        checked={!availabilityFilter.isResidency}
                                                        onChange={() => { handleIsResidencyChange(false) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="request-form__availability--row__element">
                                                <div style={{ textAlign: 'center' }}>
                                                    <KalabCheckBox
                                                        label={'RESIDENCY'}
                                                        checked={availabilityFilter.isResidency}
                                                        onChange={() => { handleIsResidencyChange(true) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }

                            <div className="request-form__availability--row" >
                                <div className="request-form__availability--row__element">
                                    <div style={{ width: '85%', margin: 'auto' }}>
                                        <KalabDropDown
                                            placeholder="Artist Type"
                                            isDisabled={false}
                                            isLoading={loading.artistType}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="artistType"
                                            onChange={handleArtistTypeSelectChange}
                                            options={artistTypeOptions()}
                                            isLightTheme={true}
                                            value={availabilityFilter.artistType}
                                        />
                                    </div>
                                </div>
                                <div className="request-form__availability--row__element">
                                    <div style={{ width: '85%', margin: 'auto' }}>
                                        <KalabDropDown
                                            placeholder="Event Type"
                                            isDisabled={false}
                                            isLoading={loading.eventType}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="artistType"
                                            onChange={handleEventTypeSelectChange}
                                            options={eventTypeOptions()}
                                            isLightTheme={true}
                                            value={availabilityFilter.eventType}
                                        />
                                    </div>
                                </div>
                                <div className="request-form__availability--row__element">
                                    <div style={{ width: '85%', margin: 'auto' }}>
                                        <KalabDropDown
                                            placeholder="Genres"
                                            isDisabled={false}
                                            isLoading={loading.genre}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            isMulti={true}
                                            name="genres"
                                            onChange={handleGenreSelectChange}
                                            options={genreOptions()}
                                            isLightTheme={true}
                                            value={availabilityFilter.genres}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="request-form__availability--row" >
                                <div className="request-form__availability--row__element" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <div style={{ width: '85%', margin: 'auto' }}>
                                        {
                                            availabilityFilter.isResidency ?
                                                (
                                                    <KalabDatePickerDropDownNormal
                                                        value={availabilityFilter.date}
                                                        onChange={handleDateChange}
                                                        isLightTheme
                                                        label='Start Date'
                                                        maxDate={restrictionForResidency(availabilityFilter.date, availabilityFilter.endDate).startDateMax}
                                                        helperText={'Please insert the date of the first event'}
                                                    />
                                                )
                                                :
                                                (
                                                    <KalabDropDown
                                                        placeholder="Time of Event"
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isSearchable={true}
                                                        isMulti={true}
                                                        name="timeOfEvent"
                                                        onChange={handleTimeOfEventSelectChange}
                                                        options={TimeOfEvents.options}
                                                        isLightTheme={true}
                                                        value={availabilityFilter.timeOfEvents}
                                                    />
                                                )
                                        }

                                    </div>
                                </div>
                                <div className="request-form__availability--row__element" style={{ margin: '0 auto', textAlign: 'center' }}>
                                    <div style={{ width: '85%', margin: 'auto' }}>
                                        {
                                            availabilityFilter.isResidency ?
                                                (
                                                    <KalabDatePickerDropDownNormal
                                                        value={availabilityFilter.endDate}
                                                        onChange={handleEndDateChange}
                                                        isLightTheme
                                                        maxDate={restrictionForResidency(availabilityFilter.date, availabilityFilter.endDate).endDateMax}
                                                        minDate={restrictionForResidency(availabilityFilter.date, availabilityFilter.endDate).endDateMin}
                                                        label='End Date'
                                                        helperText={'Please insert the date of the last event'}
                                                    />
                                                )
                                                :
                                                (
                                                    <KalabDatePickerDropDownNormal
                                                        value={availabilityFilter.date}
                                                        onChange={handleDateChange}
                                                        enableMaxDate={checkMaxDateEnabled()}
                                                        isLightTheme
                                                    />
                                                )
                                        }

                                    </div>
                                </div>
                                {isCheckedAvailable && showRequest ?
                                    (null)
                                    :
                                    (
                                        <div className="request-form__availability--row__element" style={{ margin: '0 auto', textAlign: 'center' }} >
                                            {!isCheckedAvailable ? (
                                                <KalabMainButton
                                                    text={'Check Availablity'}
                                                    disabled={!isAvailableFilterValid()}
                                                    onClick={handleCheckAvailableBtnClick}
                                                />
                                            ) : (
                                                <KalabMainButton
                                                    text={'Create Request'}
                                                    disabled={availableListings.length === 0}
                                                    onClick={() => { setShowRequest(true) }}
                                                />
                                            )}
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className={showRequest ? 'animatedShowRequest' : 'animatedHideRequest'}>
                            {
                                showRequest ?
                                    (
                                        <div className="request-form__request" >
                                            {
                                                templates.length > 0 ?
                                                    (
                                                        <div className='request-form__templates-section'>
                                                            <div className='request-form__templates-section--heading'>
                                                                <div className='text'>Use Templates</div>
                                                                <KalabIconButton
                                                                    isLightTheme
                                                                    iconName={showTemplateSection ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}
                                                                    noBorder
                                                                    noDisabled
                                                                    onClick={handleTemplateSectionBtnClick}
                                                                />
                                                            </div>
                                                            <div className={showTemplateSection ? 'template-show-animate' : 'template-hide-animate'}>
                                                                {
                                                                    showTemplateSection ?
                                                                        (
                                                                            <div className="request-form__templates-section--row">

                                                                                <div className="request-form__templates-section--row__element">
                                                                                    <div style={{ margin: 'auto' }}>
                                                                                        <KalabDropDown
                                                                                            placeholder="Select a Template"
                                                                                            isDisabled={false}
                                                                                            isLoading={false}
                                                                                            isClearable={true}
                                                                                            isRtl={false}
                                                                                            isSearchable={true}
                                                                                            name="template"
                                                                                            onChange={handleTemplatesSelectChange}
                                                                                            options={templatesOptions()}
                                                                                            isLightTheme={true}
                                                                                            value={selectedTemplate}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="request-form__templates-section--row__btn">
                                                                                    <KalabSmallButton
                                                                                        text={'Apply'}
                                                                                        disabled={selectedTemplate === null}
                                                                                        isLightTheme
                                                                                        onClick={applyTemplate}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            null
                                                                        )
                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }

                                            <div className="request-form__request--row" >
                                                <div className="request-form__request--row__element">
                                                    <div style={{ width: '85%', margin: 'auto' }}>
                                                        <KalabTextField
                                                            type="text"
                                                            id="eventName"
                                                            placeholder="Event Name"
                                                            onChange={handleInputTextChange}
                                                            value={request.eventName}
                                                            isLightTheme
                                                        />
                                                    </div>
                                                </div>
                                                <div className="request-form__request--row__element" >
                                                    <div style={{ width: '85%', margin: 'auto' }}>
                                                        <KalabTextField
                                                            type="text"
                                                            id="location"
                                                            placeholder="Location"
                                                            onChange={handleInputTextChange}
                                                            value={request.location}
                                                            isLightTheme
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            {
                                                availabilityFilter.isResidency ?
                                                    (
                                                        <div className="request-form__request--residency">
                                                            <div className='edit-section'>
                                                                <div className='edit-section__row'>
                                                                    <div className='edit-section__row--element'>
                                                                        {
                                                                            residencyCalendar.date ?
                                                                                (
                                                                                    <div className='date'>{getFormattedDateWithoutYear(residencyCalendar.date)}</div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <KalabDropDown
                                                                                        placeholder="Week Days"
                                                                                        isDisabled={false}
                                                                                        isLoading={loading.artistType}
                                                                                        isClearable={true}
                                                                                        isRtl={false}
                                                                                        isSearchable={true}
                                                                                        isMulti={true}
                                                                                        name="weekDays"
                                                                                        onChange={handleWeekDaysSelectChange}
                                                                                        options={weekDayOptions()}
                                                                                        isLightTheme={true}
                                                                                        value={residencyCalendar.weekDays}
                                                                                    />

                                                                                )
                                                                        }

                                                                    </div>
                                                                    <div className='edit-section__row--element'>
                                                                        <KalabTimeDropDown
                                                                            value={residencyCalendar.startTime}
                                                                            onChange={handleResidencyCalendarStartTimeChange}
                                                                            isLightTheme
                                                                            placeholder='Arrival'
                                                                            endTime={residencyCalendar.endTime}
                                                                        />
                                                                    </div>
                                                                    <div className='edit-section__row--element'>
                                                                        <KalabTimeDropDown
                                                                            value={residencyCalendar.endTime}
                                                                            onChange={handleResidencyCalendarEndTimeChange}
                                                                            isLightTheme
                                                                            placeholder='Departure'
                                                                            beginTime={residencyCalendar.startTime}
                                                                        />
                                                                    </div>
                                                                    <div className='edit-section__row--element'>
                                                                        <KalabDropDown
                                                                            placeholder="Duration"
                                                                            isDisabled={false}
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isRtl={false}
                                                                            isSearchable={true}
                                                                            isMulti={false}
                                                                            name="hours"
                                                                            onChange={handleResidencyCalendarHoursSelectChange}
                                                                            options={hoursOptions()}
                                                                            isLightTheme={true}
                                                                            value={residencyCalendar.hours}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='edit-section__row'>
                                                                    <div className='edit-section__row--element'>
                                                                        {
                                                                            residencyCalendar.date ?
                                                                                (
                                                                                    <KalabSmallButton
                                                                                        text={residencyCalendar.isEdit ? 'Update' : 'Add'}
                                                                                        disabled={!isResidencyCalendarFilterValid()}
                                                                                        isLightTheme
                                                                                        onClick={addResidencyCalendarFilter}
                                                                                    />
                                                                                )
                                                                                :
                                                                                (
                                                                                    <KalabSmallButton
                                                                                        text={'Apply'}
                                                                                        disabled={!isResidencyCalendarFilterValid()}
                                                                                        isLightTheme
                                                                                        onClick={applyResidencyCalendarWeekDaysFilter}
                                                                                    />
                                                                                )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='calendar-section'>
                                                                <KalabResidencyCalendar
                                                                    startDate={availabilityFilter.date}
                                                                    endDate={availabilityFilter.endDate}
                                                                    timings={request.timings}
                                                                    selectedDate={residencyCalendar.date}
                                                                    onChange={handleResidencyCalendarClick}
                                                                />
                                                            </div>

                                                            <div className='timings-section'>
                                                                <div className='timings-section__title' >
                                                                    <div className='timings-section__title--text' >
                                                                        Events ({request.timings.length})
                                                                        {
                                                                            checkValidTimings().isValid ?
                                                                                (
                                                                                    null
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div className='error-icon'>
                                                                                        <KalabIconWithToolTip
                                                                                            toolTipText={checkValidTimings().reasons.join('. ')}
                                                                                            placement='top'
                                                                                            iconName={'fas fa-exclamation'}
                                                                                            iconSize={12}
                                                                                            isFAIcon={true}
                                                                                            color={Colors.darkRed}
                                                                                            borderColor={Colors.darkRed}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className='timings-section__title--clear' >
                                                                        {
                                                                            request.timings.length > 0 ?
                                                                                (
                                                                                    <KalabSmallButton
                                                                                        text={'Clear All'}
                                                                                        noBorder
                                                                                        isLightTheme
                                                                                        onClick={handleClearTimingsBtn}
                                                                                    />
                                                                                ) :
                                                                                (
                                                                                    null
                                                                                )
                                                                        }

                                                                    </div>
                                                                </div>
                                                                {
                                                                    request.timings.length > 0 ?
                                                                        (
                                                                            <div className='timings-section__timings'>
                                                                                {
                                                                                    request.timings.map((timing, index) => (
                                                                                        <div className={`timings-section__timings--single ${index % 2 === 0 ? 'left-border' : ''} `}>
                                                                                            <div className='date'>
                                                                                                {getFormattedDateWithoutYear(timing.date)}
                                                                                            </div>
                                                                                            <div className='time'>
                                                                                                {getFormattedTime(format(timing.startTime, 'HH:mm:ss'))} - {getFormattedTime(format(timing.endTime, 'HH:mm:ss'))}
                                                                                                <span className='hours'>
                                                                                                    ({timing.hours.label})
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='delete'>
                                                                                                <KalabIconButton
                                                                                                    onClick={() => { handleTimingDeleteBtnPress(timing.date) }}
                                                                                                    iconName={'fas fa-times'}
                                                                                                    isFAIcon
                                                                                                    iconSize={12}
                                                                                                    isLightTheme
                                                                                                    noBorder
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }

                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className='timings-section__no-timings'>
                                                                                No events added
                                                                            </div>
                                                                        )
                                                                }


                                                            </div>
                                                            

                                                        </div>
                                                    ) :
                                                    (
                                                        <div className="request-form__request--row" >
                                                            <div className="request-form__request--row__element">
                                                                <div style={{ width: '85%', margin: 'auto' }}>
                                                                    {/* <KalabTimePickerDropDown
                                                            value={request.startTime}
                                                            onChange={handleStartTimeChange}
                                                            isLightTheme
                                                            label='Artist Arrival'
                                                        /> */}
                                                                    <KalabTimeDropDown
                                                                        value={request.startTime}
                                                                        onChange={handleStartTimeChange}
                                                                        isLightTheme
                                                                        placeholder='Artist Arrival'
                                                                        endTime={request.endTime}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="request-form__request--row__element">
                                                                <div style={{ width: '85%', margin: 'auto' }}>
                                                                    {/* <KalabTimePickerDropDown
                                                            value={request.endTime}
                                                            onChange={handleEndTimeChange}
                                                            isLightTheme
                                                            label='Artist Departure'
                                                        /> */}
                                                                    <KalabTimeDropDown
                                                                        value={request.endTime}
                                                                        onChange={handleEndTimeChange}
                                                                        isLightTheme
                                                                        placeholder='Artist Departure'
                                                                        beginTime={request.startTime}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            }

                                            <div className="request-form__request--row" >
                                                {
                                                    availabilityFilter.isResidency ?
                                                        (
                                                            null
                                                        )
                                                        :
                                                        (
                                                            <div className="request-form__request--row__element">
                                                                <div style={{ width: '85%', margin: 'auto' }}>
                                                                    <KalabDropDown
                                                                        placeholder="Performance Duration"
                                                                        isDisabled={false}
                                                                        isLoading={loading.genre}
                                                                        isClearable={true}
                                                                        isRtl={false}
                                                                        isSearchable={true}
                                                                        isMulti={false}
                                                                        name="hours"
                                                                        onChange={handleHoursSelectChange}
                                                                        options={hoursOptions()}
                                                                        isLightTheme={true}
                                                                        value={request.hours}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                }

                                                <div className="request-form__request--row__element">
                                                    <div style={{ width: availabilityFilter.isResidency ? '50%' : '85%', margin: 'auto' }}>
                                                        <KalabTextField
                                                            type="text"
                                                            id="dressCode"
                                                            placeholder="Dress Code"
                                                            onChange={handleInputTextChange}
                                                            value={request.dressCode}
                                                            isLightTheme
                                                            multiline
                                                            maxLength={500}
                                                            rowsMax={6}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="request-form__request--row" >
                                                <div className="request-form__request--row__element" style={{ textAlign: 'left' }}>
                                                    <div className="request-form__request--row__title-text">I am willing to provide the following...</div>
                                                </div>
                                                <div className="request-form__request--row__element">
                                                </div>
                                            </div>
                                            <div className="request-form__request--row" >
                                                <div className="request-form__request--row__element" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div className="request-form__request--row__element--icon">
                                                        <KalabIconCheckBox
                                                            iconName="directions_car"
                                                            iconText="Transportation"
                                                            value={request.transportation}
                                                            onChange={handleChangeTransporationValue}
                                                        />

                                                    </div>
                                                    <div className="request-form__request--row__element--icon">
                                                        <KalabIconCheckBox
                                                            iconName="local_parking_rounded"
                                                            iconText="Parking"
                                                            value={request.parking}
                                                            onChange={handleChangeParkingValue}
                                                        />

                                                    </div>
                                                    <div className="request-form__request--row__element--icon">

                                                        <KalabIconCheckBox
                                                            iconName="fastfood_rounded"
                                                            iconText="Food & Beverage"
                                                            value={request.foodBeverage}
                                                            onChange={handleChangeFoodBeverageValue}
                                                        />

                                                    </div>
                                                    <div className="request-form__request--row__element--icon">

                                                        <KalabIconCheckBox
                                                            iconName="mic_rounded"
                                                            iconText="Equipment"
                                                            value={request.equipment}
                                                            onChange={handleChangeEquipmentValue}
                                                        />

                                                    </div>
                                                    <div className="request-form__request--row__element--icon">

                                                        <KalabIconCheckBox
                                                            iconName="hotel"
                                                            iconText="Accomodation"
                                                            value={request.accomodation}
                                                            onChange={handleChangeAccomadationValue}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="request-form__request--row" >

                                                <div className="request-form__request--row__element">
                                                    <div style={{ width: '85%', margin: 'auto' }}>
                                                        <KalabTextField
                                                            type="text"
                                                            id="songRequest"
                                                            placeholder="Song Request"
                                                            onChange={handleInputTextChange}
                                                            value={request.songRequest}
                                                            isLightTheme
                                                            multiline
                                                            maxLength={500}
                                                            rowsMax={6}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    request.equipment ?
                                                        (
                                                            <div className="request-form__request--row__element">
                                                                <div style={{ width: '85%', margin: 'auto' }}>
                                                                    <KalabTextField
                                                                        type="text"
                                                                        id="equipmentDetails"
                                                                        placeholder="Venue Equipment"
                                                                        onChange={handleInputTextChange}
                                                                        value={request.equipmentDetails}
                                                                        isLightTheme
                                                                        multiline
                                                                        maxLength={500}
                                                                        rowsMax={6}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            null
                                                        )
                                                }

                                                <div className="request-form__request--row__element">
                                                    <div style={{ width: '85%', margin: 'auto' }}>
                                                        <KalabTextField
                                                            type="text"
                                                            id="comments"
                                                            placeholder="Comments"
                                                            onChange={handleInputTextChange}
                                                            value={request.comments}
                                                            isLightTheme
                                                            multiline
                                                            maxLength={500}
                                                            rowsMax={6}
                                                        />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="request-form__request--row" style={{ alignItems: 'center' }} >
                                                <div className="request-form__request--row__element">
                                                    <div className="price-text-field">
                                                        <KalabTextField
                                                            type="number"
                                                            min='0.00'
                                                            step='0.01'
                                                            isLightTheme
                                                            id="artistPrice"
                                                            placeholder={availabilityFilter.isResidency ? 'Proposed Price (Total Residency)' : 'Proposed Price'}
                                                            value={request.artistPrice}
                                                            onChange={handlePriceInputTextChange}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <KalabCurrencyDropDown
                                                                        currency={request.currency}
                                                                        setCurrency={handleChangeInCurrencyValue}
                                                                        isLightTheme
                                                                    />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </div>
                                                    <div className="price-text-field">
                                                        <KalabTextField
                                                            type="text"
                                                            id="artistPrice"
                                                            placeholder="Service Fee"
                                                            disabled
                                                            value={`${getLabelFromValue(request.currency)} ${(parseFloat(request.artistPrice ? request.artistPrice : 0) * getServiceFeePercentage() / 100).toFixed(2)}`}
                                                            isLightTheme
                                                            multiline
                                                            maxLength={500}
                                                            rowsMax={6}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="request-form__request--row__element">
                                                    <div className="total-price">
                                                        {getLabelFromValue(request.currency)} {(parseFloat(request.artistPrice ? request.artistPrice : 0) * (100 + getServiceFeePercentage()) / 100).toFixed(2)}
                                                    </div>
                                                </div>


                                            </div>
                                            {
                                                !checkIfAlreadyFoundTemplate() ?
                                                    (
                                                        <div className="request-form__request--template-save" >
                                                            <div className='check-box'>
                                                                <KalabCheckBox
                                                                    checked={saveTemplate}
                                                                    onChange={() => {
                                                                        setSaveTemplate(!saveTemplate);
                                                                        setRequest({
                                                                            ...request,
                                                                            templateName: ""
                                                                        })

                                                                    }}
                                                                    label={"Do you want to save this as a template?"}
                                                                />
                                                            </div>
                                                            <div className={saveTemplate ? 'show-template-name' : 'hide-template-name'}>

                                                                {
                                                                    saveTemplate ?
                                                                        (
                                                                            <div className="template-name">
                                                                                <div style={{ width: '50%', margin: 'auto' }}>
                                                                                    <KalabTextField
                                                                                        type="text"
                                                                                        id="templateName"
                                                                                        placeholder="Template Name"
                                                                                        onChange={handleInputTextChange}
                                                                                        value={request.templateName}
                                                                                        isLightTheme
                                                                                        endAdornment={
                                                                                            <InputAdornment position="end">
                                                                                                <KalabIconWithToolTip
                                                                                                    toolTipText={request.templateName.trim() === "" ? "Enter a unique template name" : isTemplateNameDuplicate() ? 'Already in Use' : "Success"}
                                                                                                    placement='top-start'
                                                                                                    iconName={request.templateName.trim() === "" ? 'fas fa-question' : isTemplateNameDuplicate() ? 'fas fa-exclamation' : 'fas fa-check'}
                                                                                                    iconSize={12}
                                                                                                    isFAIcon={true}
                                                                                                    color={request.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                                                                    borderColor={request.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            null
                                                                        )
                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }

                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                    marginBottom: 30,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <KalabMainButton
                                                    disabled={(!isFormValid() || !isSaveTemplateValid())}
                                                    onClick={handleSendEventRequest}
                                                    text={'Send Request'}
                                                    iconName={'send_rounded'} />
                                            </div>
                                        </div>
                                    )
                                    :
                                    (null)
                            }
                        </div>
                    </div>
                </div>
                <div className="wishlist-section" style={{ height: isCheckedAvailable && showRequest ? '130vh' : '80vh' }}>
                    <div className="wishlist-section__title">
                        Wishlist ({getListingsInWishlist().length})
                    </div>
                    <div className="wishlist-section__listings">
                        {getListingsInWishlist().length > 0 ? (
                            getListingsInWishlist().map(listing => (
                                <div className="listing">
                                    <KalabWishlistBasicListingCard listing={listing} deleteFn={removeListingFromWishlist} isAvailable={isListingAvailable(listing)} addToShortListFn={addToShortList} />
                                </div>
                            ))
                        ) : (
                            <div className='no-listing-message'>{selectedListings.length > 0 ? 'No Listings Remaining' : 'Your wishlist is empty'}</div>
                        )}

                    </div>

                </div>
            </div>
        </>
    )
}
