import React from 'react'
import Select, { components } from 'react-select';
import Colors from '../../../constants/Colors';
import { makeStyles } from '@material-ui/core';
import makeAnimated from 'react-select/animated';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const animatedComponents = makeAnimated();
const styles = {
    placeholder: (base, state) => ({
        ...base,
        opacity: 1,
        display:
            state.isFocused || state.isSelected || state.selectProps.inputValue
                ? 'block'
                : 'block',
    }),
}
const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        backgroundColor: 'transparent',
        color: Colors.lightGrey,
        '& .kalab_select__control': {
            backgroundColor: 'transparent',
            borderBottomColor: Colors.lightGrey,
            borderBottomWidth: 0.2,
            borderRadius: 0,
            borderWidth: 0,
            boxShadow: 'none',
            width: '75%',
            transition: 'width 500ms',
            margin: '0 auto',
            '&:hover': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2,
            },
            position: 'unset'
        },
        '& .kalab_select__control--is-disabled': {
            borderBottomStyle: 'none'
        },
        '& .kalab_select__control--menu-is-open': {
            borderBottomColor: Colors.lightGrey,
            borderBottomWidth: 0.2,
            width: '100%',
            '& .kalab_select__placeholder': {
                color: props.isLightTheme ? Colors.black : Colors.white,
                padding : 0,
                transform: 'translate(0, -18px) scale(0.75)',
                transformOrigin: 'top',
                top: 0
            }

        },
        '& .kalab_select__value-container': {
            justifyContent: 'center',
            position: 'inherit'
        },
        '& .kalab_select__single-value': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
            position: 'unset',
            top : 'unset',
            transform: 'unset',
            flex : 1,
            textAlign: 'center'
        },
        '& .kalab_select__input': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& .kalab_select__placeholder': {
            color: Colors.lightGrey,
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase',
            fontWeight: '300',
            fontSize: 15,
            paddingRight : 20,
            paddingLeft: 20,
            position: 'absolute',
            transition: 'color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
        },
        '& .kalab_select__value-container--has-value ': {
            '& .kalab_select__placeholder': {
                padding : 0,
                transformOrigin: 'top',
                top: 0,
                transform: 'translate(0, -18px) scale(0.75)',
                transition: 'color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            }
        },
        '& .kalab_select__indicators': {
            '& .kalab_select__indicator-separator': {
                display: props.isDisabled ? 'none' : 'unset',
                backgroundColor: Colors.lightGrey
            },
            '& .kalab_select__indicator': {
                '&:hover': {
                    color: props.isLightTheme ? Colors.black : Colors.white,
                },
                color: Colors.lightGrey,
                display: props.isDisabled ? 'none' : 'unset',
            }
        },
        '& .kalab_select__menu-list': {
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '& .kalab_select__option': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 16,
                fontWeight: '300'
            },
            '& .kalab_select__option--is-focused': {
                backgroundColor: Colors.primaryLightest
            },
            '& .kalab_select__option--is-selected': {
                backgroundColor: Colors.primaryLight
            }

        },
        '& .kalab_select__multi-value': {
            backgroundColor: 'transparent',
            '& .kalab_select__multi-value__label': {
                color: props.isLightTheme ? Colors.black : Colors.white,
                fontSize: 16,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            '& .kalab_select__multi-value__remove': {
                backgroundColor: 'transparent',
                '&:hover': {
                    color: props.isLightTheme ? Colors.black : Colors.white,
                },
                display: props.isDisabled ? 'none' : 'unset',
            }
        }

    })
});

export default function KalabDropDown({
    className = "",
    placeholder,
    isDisabled,
    isLoading,
    isClearable,
    isRtl,
    isSearchable,
    name,
    onChange,
    value,
    options,
    isMulti = false,
    isLightTheme = false
}) {
    const props = { isLightTheme , isDisabled}
    const classes = useStyles(props);
    return (
        <Select
            className={`${classes.root} ${className}`}
            classNamePrefix="kalab_select"
            styles={styles}
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect = {false}
            components={{ ValueContainer: CustomValueContainer }}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name={name}
            onChange={onChange}
            value={value}
            options={options}
            isMulti={isMulti}
        />

    )
}
