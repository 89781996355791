import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Icon from '@material-ui/core/Icon';
import {
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: props =>( {
        color : Colors.lightGrey,
        
        '& .MuiCheckbox-root':{
            color : Colors.lightGrey
        },
        '& .Mui-checked':{
            color : Colors.primary
        },
        '& .MuiTypography-root':{
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '300',
            fontSize: props.labelSize,
        }
    })
});

export default function KalabCheckBox({ checked, onChange, label, labelPlacement, labelSize = 15, disabled=false} ) {

    const classes = useStyles({labelSize});

    return (
        <FormControlLabel
            className={classes.root}
            control={
                <Checkbox
                    checked={checked}
                    onClick={onChange}
                    disabled = {disabled}
                    icon={<Icon style={{fontSize:16}}>check_box_outline_blank_outlined</Icon>}
                    checkedIcon={<Icon style={{fontSize:16}}>check_box_outlined</Icon>}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
            style={{color: checked ? Colors.primary : Colors.lightGrey}}
        />
    )
}
