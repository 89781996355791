import React, { useState, useEffect } from 'react'
import './ArtistBasicInfo.scss';
import Select from 'react-select';
import { getAmbiencesRequest, getEventTypesRequest, getPerformanceTypesRequest, getGenresRequest, getArtistTypesRequest } from '../../webservices/Webservice';
import { useNotification } from "../../contexts/NotificationContext";
import Colors from '../../constants/Colors';
import InputAdornment from '@material-ui/core/InputAdornment';

import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabCurrencyDropDown from '../kalab/kalabDropDown/KalabCurrencyDropDown';
import { getDefaultCurrency, getLabelFromValue } from '../../utils/Currencies';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';

function ArtistBasicInfo({ application, artistBasicInfoDetails, setArtistBasicInfoDetails, completed, checkCompletedValue = 25 }) {
    const [artistBasicInfo, setArtistBasicInfo] = useState({
        listingName: '',
        clients: [],
        newArtistType: null,
        eventTypes: [],
        performanceType: null,
        repertoire: null,
        genres: [],
        ambiences: [],
        newPrice: '',
        artistTypePrices: [],
        newClientName: '',
        alreadyAddedArtistTypePrices: [],
        currency : getDefaultCurrency().value
    })
    const [loading, setLoading] = useState({
        ambience: false,
        eventType: false,
        performanceType: false,
        artistType: false,
        genre: false,
        repertoire: false
    })

    const [artistTypeOptions, setArtistTypeOptions] = useState([]);
    const [genreOptions, setGenreOptions] = useState([]);
    const [performanceTypeOptions, setPerformanceTypeOptions] = useState([]);
    const [eventTypeOptions, setEventTypeOptions] = useState([]);
    const [ambienceOptions, setAmbienceOptions] = useState([]);
    const [repertoireOptions, setRepertoireOptions] = useState([]);
    const [currency, setCurrency] = useState(null);
    const notify = useNotification();

    const getRepertoire = () => {
        setLoading({
            ...loading,
            repertoire: true
        });
        var repertoireOptionsGenerated = []
        for (let hour = 1; hour <= 12; hour++) {
            repertoireOptionsGenerated.push({
                label: `${hour} ${hour === 1 ? 'hour' : 'hours'}`,
                value: hour
            })
        }
        setRepertoireOptions(repertoireOptionsGenerated);
        setLoading({
            ...loading,
            repertoire: false
        });
    }
    const getArtistTypes = () => {
        setLoading({
            ...loading,
            artistType: true
        });
        getArtistTypesRequest(
            (artistTypes) => {
                var artistTypesFromAPI = [];
                artistTypes.forEach(artistType => {
                    artistTypesFromAPI.push({
                        value: artistType.artistTypeId,
                        label: artistType.artistTypeName
                    })

                });
                setArtistTypeOptions(artistTypesFromAPI);
                setLoading({
                    ...loading,
                    artistType: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    artistType: false
                })
            }
        )
    }
    const getEventTypes = () => {
        setLoading({
            ...loading,
            eventType: true
        });
        getEventTypesRequest(
            (eventTypes) => {
                var eventTypesFromAPI = [];
                eventTypes.forEach(eventType => {
                    eventTypesFromAPI.push({
                        value: eventType.eventTypeId,
                        label: eventType.eventTypeName
                    })

                });
                setEventTypeOptions(eventTypesFromAPI);
                setLoading({
                    ...loading,
                    eventType: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    eventType: false
                })
            }
        )
    }
    const getPerformanceTypes = () => {
        setLoading({
            ...loading,
            performanceType: true
        });
        getPerformanceTypesRequest(
            (performanceTypes) => {
                var performanceTypesFromAPI = [];
                performanceTypes.forEach(performanceType => {
                    performanceTypesFromAPI.push({
                        value: performanceType.performanceTypeId,
                        label: performanceType.performanceTypeName
                    })

                });
                setPerformanceTypeOptions(performanceTypesFromAPI);
                setLoading({
                    ...loading,
                    performanceType: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    performanceType: false
                })
            }
        )
    }
    const getAmbiences = () => {
        setLoading({
            ...loading,
            ambience: true
        });
        getAmbiencesRequest(
            (ambiences) => {
                var ambiencesFromAPI = [];
                ambiences.forEach(ambience => {
                    ambiencesFromAPI.push({
                        value: ambience.ambienceId,
                        label: ambience.ambienceName
                    })

                });
                setAmbienceOptions(ambiencesFromAPI);
                setLoading({
                    ...loading,
                    ambience: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    ambience: false
                })
            }
        )
    }
    const getGenres = () => {
        setLoading({
            ...loading,
            genre: true
        });
        getGenresRequest(
            (genres) => {
                var genresFromAPI = [];
                genres.forEach(genre => {
                    genresFromAPI.push({
                        value: genre.genreId,
                        label: genre.genreName
                    })

                });
                setGenreOptions(genresFromAPI);
                setLoading({
                    ...loading,
                    genre: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    genre: false
                })
            }
        )
    }
    const handleInputTextChange = (e) => {
        var updatedArtist = {
            ...artistBasicInfo
        }
        updatedArtist[e.target.id] = e.target.value
        setArtistBasicInfo(updatedArtist);
    }

    const handlePriceInputTextChange = (e) => {
        if (e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            updateWithNewArtistTypePrice(artistBasicInfo.newArtistType, e.target.value, artistBasicInfo.currency)
        }
        if (e.target.value === '') {
            updateWithNewArtistTypePrice(artistBasicInfo.newArtistType, e.target.value, artistBasicInfo.currency)
        }
    }
    const handleGenresSelectChange = (generesValue) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            genres: generesValue
        })
    }
    const handleEventTypesSelectChange = (eventTypesValue) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            eventTypes: eventTypesValue
        })
    }
    const handleAmbiencesSelectChange = (ambiencesValue) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            ambiences: ambiencesValue
        })
    }
    const handlePerformanceTypeSelectChange = (performanceTypeValue) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            performanceType: performanceTypeValue
        })
    }
    const handleRepertoireSelectChange = (repertoireValue) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            repertoire: repertoireValue
        })
    }
    const handleArtistTypeSelectChange = (artistTypeValue) => {
        updateWithNewArtistTypePrice(artistTypeValue, artistBasicInfo.newPrice, artistBasicInfo.currency)
    }
    const handleAddArtistTypePriceBtnPress = (e) => {
        e.preventDefault();
        if (isAddArtistTypePriceBtnValid()) {
            var artistTypePrices = [];
            artistBasicInfo.alreadyAddedArtistTypePrices.forEach(aTypePrice => {
                artistTypePrices.push(aTypePrice);
            });
            artistTypePrices.push({
                artistTypeId: artistBasicInfo.newArtistType.value,
                artistTypeName: artistBasicInfo.newArtistType.label,
                price: parseFloat(artistBasicInfo.newPrice).toFixed(2),
                currency: artistBasicInfo.currency
            })
            setArtistBasicInfo({
                ...artistBasicInfo,
                artistTypePrices: artistTypePrices,
                alreadyAddedArtistTypePrices: artistTypePrices,
                newPrice: '',
                newArtistType: null
            })
        }
    }
    const handleDeleteClientBtnPress = (clientName) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            clients: artistBasicInfo.clients.filter(client => (client !== clientName))
        })
    }
    const handleDeleteArtistTypePriceBtnPress = (artistTypeId) => {
        setArtistBasicInfo({
            ...artistBasicInfo,
            artistTypePrices: artistBasicInfo.artistTypePrices.filter(artistTypePrice => (artistTypePrice.artistTypeId !== artistTypeId)),
            alreadyAddedArtistTypePrices: artistBasicInfo.alreadyAddedArtistTypePrices.filter(artistTypePrice => (artistTypePrice.artistTypeId !== artistTypeId))
        })
    }
    const handleAddClientBtnPress = (e) => {
        e.preventDefault();
        if (isAddClientBtnValid()) {
            var clients = [];
            artistBasicInfo.clients.forEach(client => {
                clients.push(client);
            });
            clients.push(artistBasicInfo.newClientName);
            setArtistBasicInfo({
                ...artistBasicInfo,
                newClientName: '',
                clients: clients
            })
        }
    }
    const handleChangeInPastClientsText = (e) => {
        var clientValue = e.target.value;
        var pastClients = clientValue.split(',')
        var pastClientsFormatted = pastClients.filter(pastClient => (pastClient.trim() !== "")).map(pastClient => (pastClient.trim()))

        setArtistBasicInfo({
            ...artistBasicInfo,
            newClientName: clientValue,
            clients: pastClientsFormatted
        })
    }
    const handleContinueButtonClick = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            setArtistBasicInfoDetails(artistBasicInfo);
        }
    }
    const isAddArtistTypePriceBtnValid = () => {
        if (artistBasicInfo.newArtistType && artistBasicInfo.newPrice !== "" && artistBasicInfo.currency) {
            return (true);
        }
        else {
            return false;
        }
    }
    const isAddClientBtnValid = () => {
        if (artistBasicInfo.newClientName !== "" && !artistBasicInfo.clients.includes(artistBasicInfo.newClientName) && artistBasicInfo.clients.length < 5) {
            return (true);
        }
        else {
            return false;
        }
    }
    const getFilteredArtistTypeOptions = () => {
        return (artistTypeOptions.filter(artistTypeOption => {
            var result = true;
            artistBasicInfo.artistTypePrices.forEach(artistTypePrice => {
                if (artistTypePrice.artistTypeId === artistTypeOption.value) {
                    result = false;
                }
            })
            return (result);
        }))
    }

    const getArtistTypeValue = (id) => {
        var resultArray = artistTypeOptions.filter(artistTypeOption => (parseInt(artistTypeOption.value) === parseInt(id)))
        return (resultArray.length > 0 ? resultArray[0] : null)
    }
    const isFormValid = () => {
        if (
            artistBasicInfo.listingName !== '' &&
            artistBasicInfo.genres.length > 0 &&
            artistBasicInfo.eventTypes.length > 0 &&
            artistBasicInfo.ambiences.length > 0 &&
            artistBasicInfo.performanceType !== null &&
            artistBasicInfo.repertoire !== null &&
            artistBasicInfo.artistTypePrices.length > 0
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    const isValueChanged = () => {
        if (artistBasicInfoDetails && JSON.stringify(artistBasicInfoDetails) === JSON.stringify(artistBasicInfo)) {
            return false
        }
        else {
            return true
        }
    }
    useEffect(() => {
        getArtistTypes();
        getGenres();
        getEventTypes();
        getPerformanceTypes();
        getAmbiences();
        getRepertoire();
    }, [])

    useEffect(() => {
        if (artistBasicInfoDetails) {
            setArtistBasicInfo(artistBasicInfoDetails);
        }
        else {
            if (application) {
                setArtistBasicInfo({
                    ...artistBasicInfo,
                    listingName: application.artistName
                })
            }
        }
    }, [])
    useEffect(() => {
        console.log('test')
        setArtistBasicInfoDetails(artistBasicInfo, isFormValid());
    }, [artistBasicInfo])

    // useEffect(() => {
    //     var artistTypePrices = [];
    //     artistBasicInfo.alreadyAddedArtistTypePrices.forEach(aTypePrice => {
    //         artistTypePrices.push(aTypePrice);
    //     });
    //     if (artistBasicInfo.newArtistType && artistBasicInfo.newPrice !== "" && currency) {
    //         artistTypePrices.push({
    //             artistTypeId: artistBasicInfo.newArtistType.value,
    //             artistTypeName: artistBasicInfo.newArtistType.label,
    //             price: parseFloat(artistBasicInfo.newPrice).toFixed(2),
    //             currency: currency
    //         })
    //     }
    //     setArtistBasicInfo({
    //         ...artistBasicInfo,
    //         artistTypePrices: artistTypePrices
    //     })
    // }, [artistBasicInfo.newArtistType, artistBasicInfo.newPrice, currency])

    const updateWithNewArtistTypePrice = (newArtistType, newPrice, curr)=>{
        var artistTypePrices = [];
        artistBasicInfo.alreadyAddedArtistTypePrices.forEach(aTypePrice => {
            artistTypePrices.push(aTypePrice);
        });
        if (newArtistType && newPrice !== "" && curr) {
            artistTypePrices.push({
                artistTypeId: newArtistType.value,
                artistTypeName: newArtistType.label,
                price: parseFloat(newPrice).toFixed(2),
                currency: curr
            })
        }
        setArtistBasicInfo({
            ...artistBasicInfo,
            artistTypePrices: artistTypePrices,
            newArtistType: newArtistType,
            newPrice: newPrice,
            currency : curr
        })
        setCurrency(curr);
    }

    return (
            <div className="artist-basic-info">
                <div className="input-group">
                    <div className="input">
                        <KalabTextField
                            type="text"
                            id="listingName"
                            placeholder="Listing Name"
                            onChange={handleInputTextChange}
                            value={artistBasicInfo.listingName}
                        />
                    </div>
                    <div className="input">
                        <KalabDropDown
                            placeholder="Genres"
                            isDisabled={false}
                            isLoading={loading.genre}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="genres"
                            value={artistBasicInfo.genres}
                            onChange={handleGenresSelectChange}
                            options={genreOptions}
                            isMulti
                        />
                    </div>
                    <div className="input">
                        <KalabDropDown
                            placeholder="Event Types"
                            isDisabled={false}
                            isLoading={loading.eventType}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="eventTypes"
                            value={artistBasicInfo.eventTypes}
                            onChange={handleEventTypesSelectChange}
                            options={eventTypeOptions}
                            isMulti
                        />
                    </div>
                </div>
                <div className="input-group">
                    <div className="input">
                        <KalabDropDown
                            placeholder="Ambiences"
                            isDisabled={false}
                            isLoading={loading.ambience}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="ambiences"
                            value={artistBasicInfo.ambiences}
                            onChange={handleAmbiencesSelectChange}
                            options={ambienceOptions}
                            isMulti
                        />
                    </div>
                    <div className="input">
                        <KalabDropDown
                            placeholder="Performance Type"
                            isDisabled={false}
                            isLoading={loading.performanceType}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="performanceType"
                            value={artistBasicInfo.performanceType}
                            onChange={handlePerformanceTypeSelectChange}
                            options={performanceTypeOptions}
                        />
                    </div>
                    <div className="input">
                        <KalabDropDown
                            placeholder="Repertoire"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="repertoire"
                            value={artistBasicInfo.repertoire}
                            onChange={handleRepertoireSelectChange}
                            options={repertoireOptions}
                        />
                    </div>
                </div>
                <div className="input-group" style={{ alignItems: 'flex-start' }}>
                    <div className='type-price-section'>
                        {
                            artistBasicInfo.alreadyAddedArtistTypePrices.map(addedArtistTypePrice => (
                                <div className="type-price-input-group" id={addedArtistTypePrice.artistTypeId}>
                                    <div className="type-price-input">
                                        <KalabDropDown
                                            placeholder="Artist Type"
                                            isDisabled={true}
                                            isLoading={loading.artistType}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name={"artistType" + addedArtistTypePrice.artistTypeId}
                                            value={getArtistTypeValue(addedArtistTypePrice.artistTypeId)}
                                            options={artistTypeOptions}
                                        />
                                    </div>
                                    <div className="type-price-input">
                                        <KalabTextField
                                            className={'added-price'}
                                            type="number"
                                            min='0.00'
                                            step='0.01'
                                            id="listingName"
                                            placeholder="Price"
                                            value={addedArtistTypePrice.price}
                                            disabled
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <KalabIconButton
                                                        onClick={() => { handleDeleteArtistTypePriceBtnPress(addedArtistTypePrice.artistTypeId) }}
                                                        iconName={'fas fa-times'}
                                                        isFAIcon
                                                        iconSize={12}
                                                    />
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <KalabCurrencyDropDown
                                                        currency={addedArtistTypePrice.currency}
                                                        disabled
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                </div>
                            ))
                        }

                        <div className="type-price-input-group">
                            <div className="type-price-input">
                                <KalabDropDown
                                    placeholder="Artist Type"
                                    isDisabled={false}
                                    isLoading={loading.artistType}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="artistType"
                                    value={artistBasicInfo.newArtistType}
                                    onChange={handleArtistTypeSelectChange}
                                    options={getFilteredArtistTypeOptions()}
                                />
                            </div>
                            <div className="type-price-input">
                                <KalabTextField
                                    type="number"
                                    min='0.00'
                                    step='0.01'
                                    id="listingName"
                                    placeholder="Price"
                                    value={artistBasicInfo.newPrice}
                                    onChange={handlePriceInputTextChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <KalabIconWithToolTip
                                                toolTipText="Only numerical values no words"
                                                placement='top'
                                                iconName={'fas fa-question'}
                                                iconSize={12}
                                                isFAIcon={true}
                                            />
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <KalabCurrencyDropDown
                                                currency={artistBasicInfo.currency}
                                                setCurrency={(value)=>{updateWithNewArtistTypePrice(artistBasicInfo.newArtistType, artistBasicInfo.newPrice, value)}}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </div>

                        {
                            artistBasicInfo.alreadyAddedArtistTypePrices.length < (artistTypeOptions.length - 1)
                                ?
                                (
                                    <div className="add-btn">
                                        <KalabSmallButton
                                            text={'Add More'}
                                            disabled={!isAddArtistTypePriceBtnValid()}
                                            onClick={handleAddArtistTypePriceBtnPress}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                    </div>

                    <div className="input">
                        <KalabTextField
                            multiline
                            type="text"
                            id="newClientName"
                            placeholder="Past Clients"
                            value={artistBasicInfo.newClientName}
                            maxLength={200}
                            rowsMax={6}
                            onChange={handleChangeInPastClientsText}
                            endAdornment={
                                <InputAdornment position="end">
                                    <KalabIconWithToolTip
                                        toolTipText="Use comma(,) to separate the clients"
                                        placement='top-start'
                                        iconName={'fas fa-question'}
                                        iconSize={12}
                                        isFAIcon={true}
                                    />
                                </InputAdornment>
                            }
                        />
                    </div>
                </div>

               
                {/* <div
                        style={{
                            textAlign: 'center',
                            marginBottom: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <KalabMainButtonWithIcon
                            disabled={(!isFormValid() || (!isValueChanged())) || completed < checkCompletedValue}
                            onClick={handleContinueButtonClick}
                            text={artistBasicInfoDetails ? ('Next ') : ('Next ')}
                            iconName={'arrow_forward'}
                        />
                    </div> */}
            </div>
    )
}

export default ArtistBasicInfo
