import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';

const useStyles = makeStyles({
    root: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 300ms, box-shadow 300ms',
        borderRadius: 5,
        width: '100%',
        '@media (hover: hover)': {
            '&:hover': {
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                cursor: 'pointer',
                transform: 'scale(1.05)'
            }
        }

    },
    image: {
        width: '100%',
        aspectRatio: 4 / 3,
        objectFit: 'cover',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
    title: {
        marginTop: 15,
        marginBottom: 15,
        display: 'block',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 17,
        fontWeight: '400',
    },

})

export default function KalabValueCard({ imageUrl, title, onClick }) {
    const classes = useStyles();
    return (
        <div className={classes.root} onClick={onClick} >
            <div className='aspectratio ar4-3' >
                <img
                    className={classes.image}
                    src={imageUrl}
                    alt='card-pic'
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                />
            </div>
            <span className={classes.title}>{title}</span>
        </div>
    )
}
