import React, { createContext, useState, useEffect } from 'react'
import { setUserSession , removeUserSession, getUser, getRole, setUserIpDetails, getUserIpDetails} from '../session/user';
import { checkTokenRequest, getDetailsFromIP } from '../webservices/Webservice';

export const UserContext = createContext();

export default function UserContextProvider({ children }) {
    const [loggedInUserDetails, setLoggedInUserDetails] = useState({
        user : getUser(),
        role : getRole()
    });

    const login = (token,user,role,refreshToken,expiresIn) => {
        setUserSession(token,user,role,refreshToken,expiresIn)
        setLoggedInUserDetails({
            user: user,
            role: role
        })
    }

    const logOut = () => {
        removeUserSession();
    }

    const getIpDetails = () =>{
        getDetailsFromIP(
            (data) =>{
                console.log(data)
                setUserIpDetails({
                    country : data.country,
                    currency : data.currency
                })
            },
            (err) =>{
                console.log(err)
            }
        )
    }

    useEffect(() => {
        setLoggedInUserDetails({
            user: getUser(),
            role: getRole() 
        })
        if(loggedInUserDetails.user && loggedInUserDetails.role){
            checkTokenRequest(
                (response) =>{
                    console.log(response)
                },
                (err)=>{
                    console.log(err)
                }
            )
        }
        if(getUserIpDetails() === null){
            getIpDetails();
        }
    }, [])

    useEffect(() => {
        window.addEventListener('storage',e => {
            console.log(e);
            setLoggedInUserDetails({
                user: getUser(),
                role: getRole() 
            })
        })
        return () => {
            window.removeEventListener('storage',e => console.log(e))
        }
    }, [])

    return (
        <UserContext.Provider value={{ login, logOut, loggedInUserDetails }}>
            {children}
        </UserContext.Provider>
    )
}