import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Colors from '../../../constants/Colors';



const useStyles = makeStyles({
    switch: {
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: Colors.primary,
            '&:hover': {
                backgroundColor: 'rgba(255, 166, 38, 0.04)'
            }

        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: Colors.primaryLight
        }
    }
})

export default function KalabSwitch({ size = "small", checked, onChange }) {
    const classes = useStyles();
    return (
        <Switch className={classes.switch} size={size} checked={checked} onClick={onChange}  />
    )
}