import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AddRounded } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        width: '100%',
        aspectRatio: '4 / 3',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 300ms, box-shadow 300ms',
        borderRadius: 5,
        '@media (hover: hover)': {
            '&:hover': {
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                cursor: 'pointer',
                transform: 'scale(1.05)'
            }
        },
        '& .template-card': {
            position: 'relative',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            display :'flex',
            flexDirection : 'column',
            justifyContent : 'center',
            alignItems : 'center'

        }
    },
    icon: {
        fontSize: '70px'
    },
    text:{
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 15,
        fontWeight:'200',  
    }
})

export default function KalabNewTemplateCard({ action }) {
    const classes = useStyles();

    return (
        <div className="aspectratio ar4-3">
            <div className={classes.root} onClick={action}>
                <div className="template-card">
                    <AddRounded className={classes.icon} />
                    <div className={classes.text}>New Template</div>
                </div>
            </div>
        </div>
    )
}
