import React, { useState, useContext } from 'react';
import Colors from '../../constants/Colors';
import { makeStyles } from '@material-ui/core';
import KalabSearchDropDown from '../kalab/kalabDropDown/KalabSearchDropDown';
import KalabDatePickerDropDown from '../kalab/kalabDropDown/KalabDatePickerDropDown';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import { DataStoreContext } from "../../contexts/DataStoreContext";
import { getCitiesRequest } from "../../webservices/Webservice";
import { useNotification } from "../../contexts/NotificationContext";
import { useHistory } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',

  },
  bar: props=>({
    display: 'flex',
    flexDirection: 'row',
    width: props.width > 900 ?'80%' : '95%',
    borderColor: Colors.lightGrey,
    borderWidth: 1,
    borderRadius: 100,
    margin: '0 auto',
    alignItems: 'center',
    padding: 5
  })
}));

const SearchBar = () => {
  const { artistTypes, genres, countries, loading } = useContext(DataStoreContext);
  const [selectedDate, handleDateChange] = useState(null);
  const [searchFilter, setSearchFilter] = useState({
    country: null,
    city: null,
    genre: null,
    artistType: null
  });
  const [cityOptions, setCityOptions] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false);
  const notify = useNotification();
  let history = useHistory();
  const { height, width } = useWindowDimensions();
  const artistTypeOptions = () => {
    var artistTypesOptions = [];
    artistTypes.forEach(artistType => {
      artistTypesOptions.push({
        value: artistType.artistTypeId,
        label: artistType.artistTypeName
      })

    });
    return artistTypesOptions;
  }

  const genreOptions = () => {
    var genresOptions = [];
    genres.forEach(genre => {
      genresOptions.push({
        value: genre.genreId,
        label: genre.genreName
      })

    });
    return genresOptions;
  }

  const countryOptions = () => {
    var countriesOptions = [];
    countries.forEach(country => {
      countriesOptions.push({
        value: country.countryAlpha3Code,
        label: country.countryAsciiName,
        country: country
      })

    });
    return countriesOptions;
  }

  const getCities = (countryAlpha3Code) => {
    setLoadingCity(true)
    getCitiesRequest(
      countryAlpha3Code,
      (cities) => {
        var cityOptionsFromAPI = [];
        cities.forEach(city => {
          cityOptionsFromAPI.push({
            value: city.city,
            label: city.city_ascii,
            city
          })

        });
        setCityOptions(cityOptionsFromAPI);
        setLoadingCity(false)
      },
      (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        setLoadingCity(false)
      }
    )
  }

  const handleGenreSelectChange = (genreValue) => {
    setSearchFilter({
      ...searchFilter,
      genre: genreValue
    })
  }

  const handleArtistTypeSelectChange = (artistTypeValue) => {
    setSearchFilter({
      ...searchFilter,
      artistType: artistTypeValue
    })
  }

  const handleCountrySelectChange = (countryValue) => {
    setSearchFilter({
      ...searchFilter,
      country: countryValue,
      city: null
    })
    if (countryValue === null) {
      setCityOptions([]);
    }
    else {
      getCities(countryValue.value);
    }
  }
  const handleCitySelectChange = (cityValue) => {
    setSearchFilter({
      ...searchFilter,
      city: cityValue
    })
  }
  const handleSearchBtnClick = () =>{
      history.push({
        pathname:'/search-results',
        state:{
          country : searchFilter.country ? searchFilter.country : null,
          cities : searchFilter.city ? [searchFilter.city] : [],
          artistTypes: searchFilter.artistType ? [searchFilter.artistType] : [],
          genres : searchFilter.genre ? [searchFilter.genre] : [],
          date : selectedDate
        }
      })
  }

  const props = {width}
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <div style={{ flex: 1 }}>
          <KalabSearchDropDown
            placeholder="Country"
            isDisabled={false}
            isLoading={loading.country}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="country"
            onChange={handleCountrySelectChange}
            value={searchFilter.country}
            options={countryOptions()}
          />
        </div>
        <div style={{backgroundColor: Colors.lightGrey, width:1,height:30}}></div>


        <div style={{ flex: 1}}>
          <KalabSearchDropDown
            placeholder="City"
            isDisabled={false}
            isLoading={loadingCity}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="city"
            onChange={handleCitySelectChange}
            value={searchFilter.city}
            options={cityOptions}
          />
        </div>
        <div style={{backgroundColor: Colors.lightGrey, width:1,height:30}}></div>


        <div style={{ flex: 1}}>
          <KalabSearchDropDown
            placeholder="Genre"
            isDisabled={false}
            isLoading={loading.genre}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="genre"
            onChange={handleGenreSelectChange}
            value={searchFilter.genre}
            options={genreOptions()}

          />
        </div>

        <div style={{backgroundColor: Colors.lightGrey, width:1,height:30}}></div>

        <div style={{ flex: 1}}>
          <KalabSearchDropDown
            placeholder="Artist Type"
            isDisabled={false}
            isLoading={loading.artistType}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="artistType"
            onChange={handleArtistTypeSelectChange}
            value={searchFilter.artistType}
            options={artistTypeOptions()}

          />
        </div>

        <div style={{backgroundColor: Colors.lightGrey, width:1,height:30}}></div>
        {/* <div style={{ flex: 1, height: '100%' }} >
          <KalabDatePickerDropDown
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <div style={{backgroundColor: Colors.lightGrey, width:1,height:30}}></div> */}

        <div style={{ paddingLeft: 10, paddingRight: 5 }}>
          <KalabIconButton
            iconName='search'
            noBorder
            iconSize={25}
            isFilled
            height={10}
            onClick={handleSearchBtnClick}
          />
        </div>

      </div>
    </div>
  );
};

export default SearchBar;
