import React, { createContext, useState, useEffect, useContext } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getPublishableKeyRequest, getPublishableKeyWithoutLoginRequest } from '../webservices/Webservice';
import { useNotification } from './NotificationContext';
import { LoaderContext } from "./LoaderContext";

export const StripeContext = createContext();

export default function StripeContextProvider({children}) {
    const notify = useNotification();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [publishableKey, setPublishableKey] = useState('');
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        console.log('test');
        getPublishableKey();
    }, [])

    const getPublishableKey = () =>{
        showLoader();
        getPublishableKeyWithoutLoginRequest(
            (key) => {
                hideLoader();
                console.log(key);
                setPublishableKey(key);
                setStripePromise(loadStripe(key))
            },
            (error) => {
                hideLoader();
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
            }
        )
    }

    return (
        <StripeContext.Provider>
            <Elements stripe={stripePromise}>
            {children}
            </Elements>
        </StripeContext.Provider>
    )
}
