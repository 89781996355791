import React, { useState, useEffect, useContext } from 'react'
import './SharePopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { useNotification } from '../../contexts/NotificationContext';

export default function SharePopUp({
    closeFn,
    title,
    linkToShare,
    okText,
    okBtnColor,
    okFn
}) {
    const notify = useNotification();

    const handleOkClick = () => {
        okFn()
    }

    const handleShareBtnClick = () => {
        navigator.clipboard.writeText(linkToShare);
        notify({
            type: "SUCCESS",
            message: 'Copied to clipboard',
            title: "Copied!!!"
        })
    }


    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='share-popup-modal'>
                <div className='share-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='share-popup-modal__content--top'>
                        <div className='share-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='share-popup-top__title'>
                            <span className='share-popup-top__title--text'>{title}</span>
                        </div>
                        <div className='link'>
                            <div className='text'>
                                {linkToShare}
                            </div>
                            <div>
                                <KalabSmallButton
                                    text='Copy'
                                    isLightTheme
                                    noBorder
                                    onClick={handleShareBtnClick}
                                />
                            </div>
                        </div>


                    </div>
                    <div className='share-popup-modal__content--main'>

                        <div className="share-popup-button">
                            <KalabMainButton
                                text={okText}
                                color={okBtnColor}
                                onClick={handleOkClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
