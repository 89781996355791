import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';

const useStyles = makeStyles({
    root: {
        boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderRadius: 5,
        height: '100%',
        '&:hover' :{
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
            cursor : 'pointer'
        }
    
    },
    image: {
        width: '100%',
        aspectRatio: 16 / 9,
        objectFit:'cover',
        borderTopRightRadius : 5,
        borderTopLeftRadius : 5
    },
    title: {
        marginTop: 15,
        display:'block',
        textAlign:'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 20,
        fontWeight:'600',
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight:'300',
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 10,
        paddingLeft: 10,
        display:'block',
    }

})

export default function KalabCard({ imageUrl, title, description, onClick }) {
    const classes = useStyles();
    return (
        <div className={classes.root} onClick={onClick} >
            <div className="aspectratio ar16-9">
            <img
                className={classes.image}
                src={imageUrl}
                alt='card-pic'
                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
            />
            </div>
            <span className={classes.title}>{title}</span>
            <span className={classes.description}>{description}</span>
        </div>
    )
}
