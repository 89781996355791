import moment from 'moment';
import Colors from '../constants/Colors';

export const getStatusOfEvent = (booking) => {
    var date = moment(booking.date, 'YYYY-MM-DD');
    if (date.isBefore(moment(), 'date')) {
        if (booking.isCompleted) {
            return ({
                text: 'completed',
                color: Colors.darkGreen
            })

        }
        else if (booking.isCancelled) {
            return ({
                text: 'cancelled',
                color: Colors.darkRed
            })

        }
        else {
            return ({
                text: 'ended',
                color: Colors.lightGreen
            })
        }

    }
    else {
        if (booking.isCancelled) {
            return ({
                text: 'cancelled',
                color: Colors.darkRed
            })

        }
        else if (booking.isCompleted) {
            return ({
                text: 'completed',
                color: Colors.darkGreen
            })

        }
        else {
            return ({
                text: 'upcoming',
                color: Colors.primary
            })
        }

    }
}

export const getPaymentStatusOfBookingForClient = (booking, isLightTheme = true) => {
    if (booking.isCancelled) {
        if (booking.isRefunded) {
            return ({
                text: 'Refunded',
                color: Colors.lightGreen
            })
        }
        else {
            if (booking.isPaid) {
                return ({
                    text: 'Refund Pending',
                    color: isLightTheme ? Colors.darkBlueGradient : Colors.lightestBlueishgGrey
                })
            }
            else {
                return ({
                    text: 'No Payment',
                    color: Colors.darkGrey
                })
            }
        }
    }
    else {
        if (booking.isPaid) {
            return ({
                text: 'Paid',
                color: Colors.darkGreen
            })
        }
        else {
            return ({
                text: 'Pending',
                color: Colors.primaryDark
            })
        }
    }
}

export const getPaymentStatusOfBookingForArtist = (booking, isLightTheme = true) => {
    if (booking.isCancelled) {
        return ({
            text: 'Cancelled',
            color: Colors.darkRed
        })

    }
    else {
        if (booking.isPaidOut) {
            return ({
                text: 'Paid',
                color: Colors.darkGreen
            })
        }
        else {
            if (booking.isPaid) {
                if (booking.isCompleted) {
                    return ({
                        text: 'Pending',
                        color: Colors.primaryDark
                    })
                }
                else{
                    return ({
                        text: 'Event Not Completed',
                        color: isLightTheme ? Colors.darkBlueGradient : Colors.lightestBlueishgGrey
                    })
                }
            }
            else{
                return ({
                    text: 'Client Payment Pending',
                    color: Colors.primaryDark
                })
            }
        }
    }
}