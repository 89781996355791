import React, { useState, useEffect, useContext } from 'react'
import './MusicImages.scss'
import CollageImage1 from '../collages/CollageImage1';
import CollageImage2 from '../collages/CollageImage2';
import CollageImage3 from '../collages/CollageImage3';
import CollageImage5 from '../collages/CollageImage5';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import RegularExpression from '../../utils/RegularExpression';
import InputAdornment from '@material-ui/core/InputAdornment';
import Colors from '../../constants/Colors';
import { registerArtistRequest, addNewListingRequest, updateListingRequest } from '../../webservices/Webservice';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import { checkLinkPlatform, isMusicLinkSupported } from '../../utils/SupportedMusicLinks';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { openInNewTab } from '../../utils/common';

function MusicImages({
    application,
    musicImages,
    setMusicImages,
    completed,
    checkCompletedValue = 75,
    artist,
    history,
    isAddListing = false,
    isEditListing = false,
    oldListing = null,
    listingDetail = null
}) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const [artistMusicImages, setArtistMusicImages] = useState({
        musicLinks: [],
        alreadyAddedMusicLinks: [],
        newMusicLink: '',
        selectedCollage: null,
        images: [],
        agreePrivacyTerms: false
    })
    const collages = [1, 2, 3, 5];
    const handleCollageSelection = (collage) => {
        setArtistMusicImages({
            ...artistMusicImages,
            selectedCollage: (collage === artistMusicImages.selectedCollage ? null : collage)
        })
    }
    const setImages = (images) => {
        setArtistMusicImages({
            ...artistMusicImages,
            images
        })
    }

    const handleAgreeTermsServiceCheckboxClick = () => {
        setArtistMusicImages({
            ...artistMusicImages,
            agreePrivacyTerms: !artistMusicImages.agreePrivacyTerms
        })
    };

    const handleClickOnlink = (link) => {
        openInNewTab(window.location.origin + '/' + link);
    }
    const handleInputTextChange = (e) => {
        var updatedArtist = {
            ...artistMusicImages
        }
        updatedArtist[e.target.id] = e.target.value
        setArtistMusicImages(updatedArtist);
    }

    const handleClickOnCompressor = () => {
        openInNewTab('https://www.compress2go.com/compress-to-jpg');
    }

    const handleChangeInMusicLink = (e) => {
        var links = [];
        artistMusicImages.alreadyAddedMusicLinks.forEach(musicLink => {
            links.push(musicLink)
        })
        if (isNewMusicLinkValid(e.target.value)) {
            links.push({
                url: e.target.value.trim(),
                platform: checkLinkPlatform(e.target.value.trim()).platform
            });

        }
        setArtistMusicImages({
            ...artistMusicImages,
            musicLinks: links,
            newMusicLink: e.target.value
        })
    }

    const handleLinkAddBtnPress = (e) => {
        e.preventDefault();
        if (isNewMusicLinkValid()) {
            var links = [];
            artistMusicImages.alreadyAddedMusicLinks.forEach(musicLink => {
                links.push(musicLink)
            })
            links.push({
                url: artistMusicImages.newMusicLink,
                platform: checkLinkPlatform(artistMusicImages.newMusicLink).platform
            });
            setArtistMusicImages({
                ...artistMusicImages,
                musicLinks: links,
                alreadyAddedMusicLinks: links,
                newMusicLink: ''
            })
        }
    }
    const handleLinkDeleteBtnPress = (url) => {
        setArtistMusicImages({
            ...artistMusicImages,
            musicLinks: artistMusicImages.musicLinks.filter(musicLink => (musicLink.url !== url)),
            alreadyAddedMusicLinks: artistMusicImages.musicLinks.filter(musicLink => (musicLink.url !== url))
        })
    }
    const handleContinueButtonClick = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            setMusicImages(artistMusicImages, isFormValid(), true);
        }
    }
    const getCollageComponent = () => {
        switch (artistMusicImages.selectedCollage) {
            case 1: return (<CollageImage1 images={artistMusicImages.images} setImages={setImages} />)
            case 2: return (<CollageImage2 images={artistMusicImages.images} setImages={setImages} />)
            case 3: return (<CollageImage3 images={artistMusicImages.images} setImages={setImages} />)
            case 5: return (<CollageImage5 images={artistMusicImages.images} setImages={setImages} />)
            default: return (null)
        }
    }

    const isNewMusicLinkValid = (newMusicLink = artistMusicImages.newMusicLink) => {
        var isMusicLinkAlreadyAdded = false;
        artistMusicImages.alreadyAddedMusicLinks.forEach(musicLink => {
            if (musicLink.url === newMusicLink.trim()) {
                isMusicLinkAlreadyAdded = true
            }
        })
        if (newMusicLink.trim() !== ""
            && !isMusicLinkAlreadyAdded
            && RegularExpression.url.test(newMusicLink.trim())
            && artistMusicImages.musicLinks.length < 6
            && isMusicLinkSupported(newMusicLink.trim())
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isFormValid = () => {
        if (
            (artistMusicImages.agreePrivacyTerms || (isAddListing || isEditListing)) &&
            artistMusicImages.musicLinks.length > 0 &&
            artistMusicImages.musicLinks.length < 6 &&
            artistMusicImages.selectedCollage !== null &&
            artistMusicImages.images.filter(image => image.collageId === artistMusicImages.selectedCollage && image.image.preview !== '').length === artistMusicImages.selectedCollage
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    // const isValueChanged = () => {
    //     if (musicImages && JSON.stringify(musicImages) === JSON.stringify(artistMusicImages) && JSON.stringify(musicImages.musicLinks) === JSON.stringify(artistMusicImages.musicLinks)) {
    //         return false
    //     }
    //     else {
    //         return true
    //     }
    // }

    const addListing = (e) => {
        e.preventDefault();
        showLoader();
        var imageFiles = [];
        console.log(artist.musicImages.images);
        artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
            if (artist.musicImages.selectedCollage === image.collageId) {
                imageFiles.push(image.image.previewFile);
            }
        });
        console.log(imageFiles);
        addNewListingRequest(
            imageFiles,
            artist.artistBasicInfo.repertoire.value,
            artist.artistBasicInfo.clients,
            artist.artistBasicInfo.genres.map(genre => (genre.value)),
            artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
            artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
            artist.artistBasicInfo.artistTypePrices,
            artist.artistBasicInfo.performanceType.value,
            artist.description.description,
            artist.description.setList,
            artist.description.technicalRider,
            artist.description.isInsured,
            artist.musicImages.musicLinks,
            'COL' + artist.musicImages.selectedCollage,
            artist.artistBasicInfo.listingName,
            (response) => {
                console.log(response);
                hideLoader();
                history.push({
                    pathname: '/artist/home',
                })
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const updateListing = (e) => {
        e.preventDefault();
        if (isEditListing && listingDetail && oldListing) {
            showLoader();
            var imageFilesToUploadWithPosition = [];
            var imagesToRetain = [];
            var oldImages = [];
            console.log(artist.musicImages.images);
            artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
                if (artist.musicImages.selectedCollage === image.collageId) {
                    if (image.image.preview === image.image.rawUrl) {
                        listingDetail.listingImages.forEach(listingImage => {
                            if (listingImage.listingImageUrl === image.image.preview) {
                                imagesToRetain.push({
                                    imageUrl: listingImage.listingImageUrl,
                                    imageName: listingImage.listingImageName,
                                    imageSize: listingImage.listingImageSize,
                                    imagePosition: listingImage.listingImagePosition
                                })
                            }
                        })
                    }
                    else {
                        imageFilesToUploadWithPosition.push({
                            file: image.image.previewFile,
                            position: image.id
                        });
                        if (artist.musicImages.selectedCollage === oldListing.musicImages.selectedCollage) {
                            listingDetail.listingImages.forEach(listingImage => {
                                if (parseInt(listingImage.listingImagePosition) === image.id) {
                                    oldImages.push(listingImage.listingImageName)
                                }
                            })
                        }

                    }
                }
            });
            if (artist.musicImages.selectedCollage !== oldListing.musicImages.selectedCollage) {
                listingDetail.listingImages.forEach(listingImage => {
                    oldImages.push(listingImage.listingImageName)
                })
            }
            console.log(imageFilesToUploadWithPosition);
            console.log(imagesToRetain)
            console.log(oldImages)
            updateListingRequest(
                imageFilesToUploadWithPosition,
                imagesToRetain,
                listingDetail.listingId,
                oldImages,
                artist.artistBasicInfo.repertoire.value,
                artist.artistBasicInfo.clients,
                artist.artistBasicInfo.genres.map(genre => (genre.value)),
                artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
                artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
                artist.artistBasicInfo.artistTypePrices,
                artist.artistBasicInfo.performanceType.value,
                artist.description.description,
                artist.description.setList,
                artist.description.technicalRider,
                artist.description.isInsured,
                artist.musicImages.musicLinks,
                'COL' + artist.musicImages.selectedCollage,
                artist.artistBasicInfo.listingName,
                (response) => {
                    console.log(response);
                    hideLoader();
                    history.push({
                        pathname: '/artist/home',
                    })
                }, (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                }
            )
        }
    }

    const isValueChanged = () => {
        if (JSON.stringify(artist) === JSON.stringify(oldListing)) {
            return false;
        }
        else {
            return true;
        }
    }

    const register = (e) => {
        e.preventDefault();
        showLoader();
        var imageFiles = [];
        console.log(artist.musicImages.images);
        artist.musicImages.images.sort((a, b) => (a.id - b.id)).forEach(image => {
            if (artist.musicImages.selectedCollage === image.collageId) {
                imageFiles.push(image.image.previewFile);
            }
        });
        console.log(imageFiles);
        registerArtistRequest(
            imageFiles,
            artist.personalDetails.fullName,
            artist.personalDetails.email,
            artist.personalDetails.password,
            artist.personalDetails.artistName,
            artist.personalDetails.countryCode.value.countryCode,
            artist.personalDetails.languages.map(language => (language.label)),
            artist.personalDetails.city.label,
            artist.personalDetails.country.label,
            artist.personalDetails.city.city.lat,
            artist.personalDetails.city.city.lng,
            artist.personalDetails.phone,
            artist.artistBasicInfo.repertoire.value,
            artist.artistBasicInfo.clients,
            artist.artistBasicInfo.genres.map(genre => (genre.value)),
            artist.artistBasicInfo.ambiences.map(ambience => (ambience.value)),
            artist.artistBasicInfo.eventTypes.map(eventType => (eventType.value)),
            artist.artistBasicInfo.artistTypePrices,
            artist.artistBasicInfo.performanceType.value,
            artist.description.description,
            artist.description.setList,
            artist.description.technicalRider,
            artist.description.isInsured,
            artist.musicImages.musicLinks,
            'COL' + artist.musicImages.selectedCollage,
            artist.artistBasicInfo.listingName,
            (response) => {
                console.log(response);
                hideLoader();
                //  props.history.push('/artist/application/result');
                history.push({
                    pathname: '/artist/registration/result',
                    state: {
                        breakProtection: true,
                        response: response
                    }
                })
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }







    useEffect(() => {
        if (musicImages) {
            setArtistMusicImages(musicImages);
        }
        else {
            if (application) {
                var musicLinks = application.musicLinks.filter(musicLink => (isMusicLinkSupported(musicLink.url)))
                if (musicLinks.length > 0) {
                    setArtistMusicImages({
                        ...artistMusicImages,
                        musicLinks: musicLinks,
                        alreadyAddedMusicLinks: musicLinks.filter((musiclink, index) => index !== 0),
                        newMusicLink: musicLinks[0].url
                    });
                }
            }
        }

    }, [])

    useEffect(() => {
        console.log(artistMusicImages);
        setMusicImages(artistMusicImages, isFormValid(), false);
    }, [artistMusicImages])

    // useEffect(() => {
    //     var links = [];
    //     artistMusicImages.alreadyAddedMusicLinks.forEach(musicLink => {
    //         links.push(musicLink)
    //     })
    //     if (isNewMusicLinkValid()) {

    //         links.push({
    //             url: artistMusicImages.newMusicLink,
    //             platform: checkLinkPlatform(artistMusicImages.newMusicLink).platform
    //         });

    //     }
    //     setArtistMusicImages({
    //         ...artistMusicImages,
    //         musicLinks: links
    //     })
    // }, [artistMusicImages.newMusicLink])

    return (
        <>
            <div className="music-images">
                <div className='input-group'>
                    <div className='input-text'>
                        <i className="fas fa-info-circle icon" />
                        <span className='text'>
                            Please add up to 5 links of your Youtube, Apple Music, Spotify, Soundcloud & Mixcloud links. Make sure to select individual songs. No playlists or profiles accepted.
                        </span>
                    </div>
                </div>

                {
                    artistMusicImages.alreadyAddedMusicLinks.map(musicLink =>
                    (
                        <div className="input-group link" >
                            <div className='input'>
                                <KalabTextField
                                    type="text"
                                    id={"musicLink" + musicLink.url}
                                    placeholder="Music Link"
                                    disabled
                                    value={musicLink.url}
                                    startAdornment={
                                        (

                                            <InputAdornment position="start">
                                                <KalabIconWithToolTip
                                                    toolTipText={checkLinkPlatform(musicLink.url).platform.toLocaleUpperCase()}
                                                    placement='top-start'
                                                    iconName={checkLinkPlatform(musicLink.url).className}
                                                    iconSize={18}
                                                    isFAIcon={true}
                                                    noBorder
                                                    color={Colors.white}
                                                />
                                            </InputAdornment>

                                        )
                                    }
                                    endAdornment={
                                        (<InputAdornment position="end">
                                            <KalabIconButton
                                                onClick={() => { handleLinkDeleteBtnPress(musicLink.url) }}
                                                iconName={'fas fa-times'}
                                                isFAIcon
                                                iconSize={12}
                                            />
                                        </InputAdornment>
                                        )

                                    }

                                />
                            </div>
                        </div>
                    )
                    )
                }

                <div className="input-group link" >
                    <div className='input'>
                        <KalabTextField
                            type="text"
                            id="newMusicLink"
                            placeholder="Music Link"
                            value={artistMusicImages.newMusicLink}
                            onChange={handleChangeInMusicLink}
                            startAdornment={
                                isNewMusicLinkValid()
                                    ?
                                    (
                                        <InputAdornment position="start">
                                            <KalabIconWithToolTip
                                                toolTipText={checkLinkPlatform(artistMusicImages.newMusicLink.trim()).platform.toLocaleUpperCase()}
                                                placement='top-start'
                                                iconName={checkLinkPlatform(artistMusicImages.newMusicLink.trim()).className}
                                                iconSize={18}
                                                isFAIcon={true}
                                                noBorder
                                                color={Colors.white}
                                            />
                                        </InputAdornment>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                            endAdornment={
                                !isNewMusicLinkValid() && artistMusicImages.newMusicLink.trim() !== ''
                                    ?
                                    (<InputAdornment position="end">
                                        <KalabIconWithToolTip
                                            toolTipText="Please add a valid link from Youtube, Apple Music, Spotify, Soundcloud & Mixcloud "
                                            placement='top-start'
                                            iconName={'fas fa-exclamation'}
                                            iconSize={12}
                                            isFAIcon={true}
                                            color={Colors.darkRed}
                                            borderColor={Colors.darkRed}
                                        />
                                    </InputAdornment>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }

                        />
                    </div>
                </div>
                {
                    artistMusicImages.alreadyAddedMusicLinks.length < 4
                        ?
                        (
                            <div className='input-group'>
                                <div className='input'>
                                    <KalabSmallButton
                                        text={'Add More'}
                                        disabled={!isNewMusicLinkValid()}
                                        onClick={handleLinkAddBtnPress}

                                    />

                                </div>
                            </div>
                        )
                        :
                        (
                            null
                        )
                }

                <div className='image-section'>
                    <div className='input-group'>
                        <div className='input-text'>
                            <i className="fas fa-info-circle icon" />
                            <span className='text'>
                                Please add images (.jpg,.png) less than 1MB. Use the following compressor : <span className='compressor-link' onClick={handleClickOnCompressor}>compress2go</span>
                            </span>
                        </div>
                    </div>


                    <div className='collage-group'>
                        {collages.map(collageNo => {
                            return (
                                <div className="collage-select-box">
                                    <KalabCheckBox
                                        checked={collageNo === artistMusicImages.selectedCollage ? true : false}
                                        onChange={() => { handleCollageSelection(collageNo) }}
                                        label={`${collageNo} ${collageNo === 1 ? 'image  ' : 'images'}`}
                                        labelPlacement={'end'}
                                    />


                                </div>
                            )
                        })}
                    </div>

                    {getCollageComponent()}


                </div>
                {
                    !(isAddListing || isEditListing) ?
                        (
                            <div className='agree-terms-btn'>
                                <KalabCheckBox
                                    checked={artistMusicImages.agreePrivacyTerms}
                                    onChange={handleAgreeTermsServiceCheckboxClick}
                                    label={
                                        <div style={{ cursor: 'default' }}>
                                            I have read and agree to the&nbsp;
                                            <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('privacy-policy') }}>
                                                privacy policy
                                            </span>
                                            &nbsp;and&nbsp;
                                            <span style={{ fontWeight: '500', cursor: 'pointer' }} onClick={() => { handleClickOnlink('terms-of-service') }}>
                                                terms of service
                                            </span>
                                        </div>
                                    }
                                />
                            </div>
                        )
                        :
                        (
                            null
                        )
                }


                <div className='main-btn-view'>

                    {/* <KalabMainButton
                        disabled={!isFormValid() || completed < checkCompletedValue}
                        onClick={handleContinueButtonClick}
                        text={'Preview'}
                    /> */}
                    {
                        isEditListing ?
                            (
                                <KalabMainButton
                                    disabled={!isFormValid() || completed < checkCompletedValue || !isValueChanged()}
                                    text={'Save Changes'}
                                    iconName={'update'}
                                    onClick={updateListing}
                                    fontSize={22}

                                />

                            )
                            :
                            (

                                isAddListing ?
                                    (
                                        <KalabMainButton
                                            disabled={!isFormValid() || completed !== 100}
                                            text={'Save & Publish'}
                                            iconName={'add'}
                                            onClick={addListing}
                                            fontSize={22}

                                        />
                                    )
                                    :
                                    (
                                        <KalabMainButton
                                            disabled={!isFormValid() || completed !== 100}
                                            text={'Save & Publish'}
                                            iconName={'done'}
                                            onClick={register}
                                            fontSize={22}
                                        />
                                    )
                            )
                    }
                    {
                        isEditListing && !isValueChanged() ?
                            (
                                <div className='error-icon'>
                                    <KalabIconWithToolTip
                                        toolTipText="No Changes"
                                        placement='top'
                                        iconName={'fas fa-exclamation'}
                                        iconSize={12}
                                        isFAIcon={true}
                                        color={Colors.darkRed}
                                        borderColor={Colors.darkRed}
                                    />
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }
                </div>
                {
                    isFormValid() && completed === 100 ?
                        (
                            <div className='preview-btn' onClick={handleContinueButtonClick}>
                                Want to review your listing first?
                            </div>
                        )
                        :
                        (
                            null
                        )
                }

            </div>
        </>
    )
}

export default MusicImages
