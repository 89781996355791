import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
    withStyles,
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: props => ({
        '& label': {
            '&.MuiInputLabel-animated': {
                transition: 'color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            },

            '&.Mui-focused':
            {
                color: props.isLightTheme ? Colors.black : Colors.white

            },
            '&.MuiInputLabel-shrink': {
                transform: 'translate(-50%, 0px) scale(0.75)',
                transformOrigin: 'top',
                left: '50%'
            },
            '&.Mui-disabled': {
                color: Colors.lightGrey,
            },
            color: Colors.lightGrey,
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase',
            fontWeight: '300',
            fontSize: 15,
            left: '50%',
            transform: 'translate(-50%, 24px) scale(1)',
            border: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },

        '& .MuiInput-underline': {
            '&:after': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2,
            },
            '&:before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2,

            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2
            },
            '&.Mui-disabled:before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0,
                borderBottomStyle : 'none'
            },

        },
        '& input': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& textarea': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        },
        transition: 'width 500ms',
        width: props.disabled ? '100%' :'75%',
        margin: '0 auto',
        '&:focus-within': {
            width: '100% !important'
        },
        '&.Mui-disabled':{
            width: '100% !important'
        },
        '& .MuiFormHelperText-root' :{
            fontSize: 10,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        }

    })
});


export default function KalabTextField({
    className,
    min,
    step,
    type,
    maxLength,
    id,
    multiline = false,
    rowsMax,
    value,
    onChange,
    placeholder,
    isRequired = false,
    endAdornment = null,
    startAdornment = null,
    disabled = false,
    isLightTheme = false,
    helperText = null
}) {

    const props = ({
        isLightTheme,
        disabled
    })
    const classes = useStyles(props);
    return (
        <TextField
            className={`${classes.root} ${className}`}
            id={id}
            type={type ? type : 'text'}
            value={value}
            onChange={onChange}
            label={placeholder}
            fullWidth
            InputLabelProps={{shrink : value === "" || value === null ? undefined : true}}
            disabled={disabled}
            required={isRequired}
            InputProps={{
                startAdornment: startAdornment,
                endAdornment: endAdornment,
                inputProps: {
                    min: min,
                    step: step,
                    maxLength: maxLength
                }
            }}
            onWheel={event => type === 'number' && event.target.blur()}
            multiline={multiline}
            rowsMax={rowsMax}
            helperText={helperText}
        />
    )
}
