import React from 'react';
import { Route,Redirect } from 'react-router-dom';


const ProtectedRoute = ({component : Component , ...rest}) =>{
    return(
        <Route 
            {...rest}
            render = {(props)=> props.location.state?.breakProtection ? <Component {...props} /> : <Redirect to={{pathname : '/home'}}/>}
            />
    );
}


export default ProtectedRoute;