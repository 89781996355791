import React, { useState, useContext } from 'react';
import { LoaderContext } from '../../contexts/LoaderContext';
import { UserContext } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext';
import './SignInPopUp.scss';
import RegularExpression from '../../utils/RegularExpression';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { artistLoginRequest, forgotArtistPasswordRequest } from '../../webservices/Webservice';
import { useHistory } from "react-router-dom";

export const ArtistSignInPopUp = ({ closeModal }) => {
  let history = useHistory();

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { login } = useContext(UserContext)
  const notify = useNotification();

  const [artist, setArtist] = useState({
    email: '',
    password: '',
  });
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  const handleInputTextChange = (e) => {
    var updatedArtist = {
      ...artist,
    };
    updatedArtist[e.target.id] = e.target.value;
    setArtist(updatedArtist);
  };

  const handleForgotPasswordBtnPressed = () => {
    setIsForgotPassword(true);
  }

  const handleBackForgotPasswordBtnPressed = () => {
    setIsForgotPassword(false);
  }


  const isFormValid = () => {
    if (
      RegularExpression.email.test(artist.email)
      &&
      artist.password !== ''
    ) {
      return true;
    } else return false;
  };

  const isForgotPasswordFormValid = () => {
    if (
      RegularExpression.email.test(artist.email)
    ) {
      return true;
    } else return false;
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (isForgotPasswordFormValid()) {
      showLoader();
      forgotArtistPasswordRequest(artist.email,
        (response) => {
          notify({
            type: 'SUCCESS',
            message: response,
            title: 'Success!!!',
          })
          hideLoader();
          closeModal();
        },
        (error) => {
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!"
          })
          hideLoader();
        });
    }
  }


  const handleSignIn = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      showLoader();
      artistLoginRequest(artist.email, artist.password,
        (response) => {
          console.log(response.data);
          var loginData = response.data;
          login(loginData.access_token, loginData.user, loginData.role, loginData.refresh_token, loginData.expires_in)
          hideLoader();
          closeModal();
          history.push('/artist/home')
        },
        (error) => {
          notify({
            type: "ERROR",
            message: error.message ? error.message : error,
            title: error.title ? error.title : "Error!!!"
          })
          hideLoader();
        });
    }
  };
  return (
    <div
      className='sign-background'
    >
      <form
        onSubmit={!isForgotPassword ? handleSignIn : handleForgotPassword}
        className='main-box'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='main-box__btn-row' style={{ justifyContent: isForgotPassword ? 'space-between' : 'flex-end' }}>
          {isForgotPassword ? (
            <KalabIconButton
              iconName={'fas fa-arrow-left'}
              iconSize={20}
              isFAIcon
              noDisabled
              height={10}
              onClick={() => {
                handleBackForgotPasswordBtnPressed();
              }}
            />
          )
            :
            (
              null
            )
          }
          <KalabIconButton
            iconName={'fas fa-times'}
            iconSize={20}
            isFAIcon
            noDisabled
            height={10}
            onClick={() => {
              closeModal();
            }}
          />
        </div>

        <div className='main-box__row'>
          <KalabTextField
            type="text"
            id="email"
            placeholder="Artist Email"
            value={artist.email}
            onChange={handleInputTextChange}
          />
        </div>
        <div className={!isForgotPassword ? 'show-password-section' : 'hide-password-section'}>
          {isForgotPassword ?
            (
              null
            )
            :
            (
              <>
                <div className='main-box__row'>
                  <KalabTextField
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={artist.password}
                    onChange={handleInputTextChange}
                  />
                </div>
                <div className='main-box__frgt-pwd'>
                  <KalabSmallButton
                    text={'Forgot Password?'}
                    noBorder
                    noDisabled
                    onClick={handleForgotPasswordBtnPressed}
                  />
                </div>
              </>
            )
          }

        </div>

        <div className='main-box__main-btn-row' >
          {isForgotPassword ?
            (
              <KalabMainButtonWithIcon
                type={'submit'}
                disabled={(!isForgotPasswordFormValid())}
                onClick={handleForgotPassword}
                text={'Forgot Password'}
                iconName={'enhanced_encryption'}
              />
            ) :
            (
              <KalabMainButtonWithIcon
                type={'submit'}
                disabled={(!isFormValid())}
                onClick={handleSignIn}
                text={'Sign In'}
                iconName={'arrow_forward'}
              />
            )}
        </div>

      </form>
    </div>
  );
};
