import React, { useState, useEffect, useContext } from 'react'
import './PartnerPopUp.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabPartnerCard from '../kalab/kalabCards/KalabPartnerCard';
import { getPartnersRequest } from '../../webservices/Webservice'
import Colors from '../../constants/Colors';

export default function PartnerPopUp({ closeFn }) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const [partners, setPartners] = useState([]);

    const getPartners = () => {
        showLoader();
        getPartnersRequest(
            (partners) => {
                hideLoader();
                setPartners(partners)
                console.log(partners);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }

    useEffect(() => {
        getPartners();
    }, [])

    return (
        <div className='modal-bg' onClick={() => { closeFn(); }}>
            <div className='partner-popup-modal'>
                <div className='partner-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='partner-popup-modal__content--top'>
                        <div className='partner-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='partner-popup-top__title'>
                            <span className='partner-popup-top__title--text'>Our Partners</span>
                        </div>

                    </div>
                    <div className='partner-popup-modal__content--main'>
                        {partners.map(partner => {
                            return (
                                <div className='partner-popup-country__section'>
                                    <div className='partner-popup-country__section--title'>
                                        <span style={{borderBottomWidth: 1,padding : 5, borderBottomColor:Colors.primary}}>{partner.country}</span>
                                    </div>
                                    <div className='partner-popup-country__section--logos'>
                                        {partner.partners.map((eachPartner, index) => {
                                            return (
                                                <div className='partner-view'>
                                                    <KalabPartnerCard
                                                        name={eachPartner.partnerName}
                                                        category={eachPartner.partnerCategory}
                                                        url={eachPartner.partnerUrl}
                                                        imageUrl={eachPartner.partnerImageUrl}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )

}
