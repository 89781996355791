import React, { useState, useContext } from 'react'
import {  makeStyles } from '@material-ui/core/styles';
import KalabMainButton from '../kalabButtons/KalabMainButton';
import KalabIconButton from '../kalabButtons/KalabIconButton';

const useStyles = makeStyles({
    root: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        height: 'fit-content',
        border: '1px solid rgb(227, 227, 227)',
        borderRadius: 10,
        padding: 30,
        '& .title': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&__text': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '1.6vw',
                fontWeight: '500',
                '@media only screen and (max-width: 850px)' : {
                    fontSize: 16
                }
            }

        },
        '& .btn-view': {
            paddingTop: 30,
            paddingBottom: 20,
            '& .btns': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '&__add': {
                    '&--btn': {
                        padding: 10
                    }
                }
            },

        },


    },
    animatedShow: {
        animation: "$showBtnView 1s ease-in-out"
    },

    animatedHide: {
        animation: "$hideBtnView 1s ease"
    },
    '@keyframes showBtnView': {
        '0%': {
            opacity: 0,
            maxHeight: 0
        },
        '100%': {
            opacity: 1,
            maxHeight: 1000
        }
    },
    '@keyframes hideBtnView': {
        '0%': {
            opacity: 1,
            height: 100
        },
        '100%': {
            opacity: 0,
            height: 0
        }
    }
})

export default function KalabCalculatorArtistCard() {
    const [showBtns, setShowBtns] = useState(false)
    const classes = useStyles();


    const handleShowBtnClick = () => {
        setShowBtns(!showBtns);
    }

    return (
            <div className={classes.root}>
                <div className="title">
                    <div className="title__text" >
                        Chat with the artist
                    </div>
                    <KalabIconButton
                        iconName={showBtns ? "keyboard_arrow_up_rounded" : "keyboard_arrow_down_rounded"}
                        noBorder
                        isLightTheme
                        onClick={handleShowBtnClick}
                    />

                </div>

                <div className={showBtns ? classes.animatedShow : classes.animatedHide}>
                    {showBtns ?
                        (
                            <div className="btn-view">
                                <div className='btns'>
                                    <div className='btns__add'>
                                        <KalabMainButton
                                            text='Send A Message'
                                            fontSize={13}
                                            className='btns__add--btn'
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            null
                        )}


                </div>
            </div>

    )
}
