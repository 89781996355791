import React, { useState, useEffect , useContext} from 'react'
import ResultPage from './ResultPage'
import { useParams } from 'react-router-dom';
import { verifyEmailRequest } from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";

export default function ClientEmailVerificationPage(props) {
    const [resultMessage, setResultMessage] = useState(null);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { mailVerificationToken } = useParams();
    const notify = useNotification();
    const verifyClientEmail = () => {
        showLoader();
        verifyEmailRequest(mailVerificationToken,
            (response) => {
                hideLoader();
                setResultMessage(response.data.resultMessage)
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })

    }
    useEffect(() => {
        if (mailVerificationToken) {
            verifyClientEmail();
        }

    }, [mailVerificationToken])
    return (
        <>
            {resultMessage ? <ResultPage resultMessage={resultMessage}/> : (null)}

        </>

    )
}
