import React, { useState, useEffect, useContext } from 'react'
import './AddListingPage.scss';
import MainHeader from '../header/MainHeader';
import ProgressBar from '../elements/ProgressBar';
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import ArtistBasicInfo from '../forms/ArtistBasicInfo';
import PersonalDetails from '../forms/PersonalDetails';
import Description from '../forms/Description';
import MusicImages from '../forms/MusicImages';
import PreviewSubmit from '../forms/PreviewSubmit'
import {
    getArtistDetailsRequest
} from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';



export default function AddListingPage(props) {
    const [activePage, setActivePage] = useState(1);
    const { height, width } = useWindowDimensions();
    const [listing, setListing] = useState({
        personalDetails: null,
        artistBasicInfo: null,
        description: null,
        musicImages: null
    })
    const [completedStatus, setCompletedStatus] = useState({
        personalDetails: false,
        artistBasicInfo: false,
        description: false,
        musicImages: false
    })

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();


    const getArtist = () => {
        showLoader();
        getArtistDetailsRequest(
            (artist) => {
                console.log(artist);
                hideLoader();
                setListing({
                    ...listing,
                    personalDetails: {
                        ...artist,
                        city: {
                            label: artist.city,
                            value: artist.city
                        },
                        country: {
                            label: artist.country,
                            value: artist.country
                        },
                        languages: artist.languages.map(language => ({
                            label: language,
                            value: language
                        }))
                    }
                })
                setCompletedStatus({
                    ...completedStatus,
                    personalDetails: true
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }
    const handleLinkButtonClick = (pageNo) => {
        setActivePage(pageNo);

    }
    const setArtistBasicInfoDetails = (artistBasicInfo, completed = false) => {
        setListing({
            ...listing,
            artistBasicInfo
        });
        setCompletedStatus({
            ...completedStatus,
            artistBasicInfo: completed
        })
    }
    const setDescription = (description, completed = false) => {
        setListing({
            ...listing,
            description
        });
        setCompletedStatus({
            ...completedStatus,
            description: completed
        })
    }
    const setMusicImages = (musicImages, completed = false, next = false) => {
        setListing({
            ...listing,
            musicImages
        });
        setCompletedStatus({
            ...completedStatus,
            musicImages: completed
        })
        if (next) {
            setActivePage(4)
        }
    }
    const getCompletedPercentage = () => {
        var resultValue = 0;
        if (listing.artistBasicInfo && completedStatus.artistBasicInfo) {
            resultValue = 100 / 3
            if (listing.description && completedStatus.description) {
                resultValue = 200 / 3
                if (listing.musicImages && completedStatus.musicImages) {
                    resultValue = 300 / 3
                }
            }
        }
        return (resultValue);
    }

    const getSelectedHeading = () => {
    if (activePage === 1) {
            return (
                {
                    title: 'Listing Basic Info',
                    isCompleted: completedStatus.artistBasicInfo
                }
            )
        }
        else if (activePage === 2) {
            return (
                {
                    title: 'Description',
                    isCompleted: completedStatus.description
                }
            )
        }
        else if (activePage === 3) {
            return (
                {
                    title: 'Music & Images',
                    isCompleted: completedStatus.musicImages
                }
            )
        }
        else {
            return (
                {
                    title: 'Preview',
                    isCompleted: true
                }
            )
        }
    }

    const selectedForm = () => {
        switch (activePage) {
            case 1: return (<ArtistBasicInfo application={null} artistBasicInfoDetails={listing.artistBasicInfo} setArtistBasicInfoDetails={setArtistBasicInfoDetails} completed={getCompletedPercentage()} checkCompletedValue={0} />)
            case 2: return (<Description description={listing.description} setDescription={setDescription} completed={getCompletedPercentage()} checkCompletedValue={33} />)
            case 3: return (<MusicImages application={null} musicImages={listing.musicImages} setMusicImages={setMusicImages} artist={listing}  completed={getCompletedPercentage()} checkCompletedValue={66} history={props.history} isAddListing={true} />)
            case 4: return (<PreviewSubmit artist={listing} completed={getCompletedPercentage()} history={props.history} isAddListing={true} />)
            default: return (<ArtistBasicInfo application={null} artistBasicInfoDetails={listing.artistBasicInfo} setArtistBasicInfoDetails={setArtistBasicInfoDetails} completed={getCompletedPercentage()} />)
        }
    }
    useEffect(() => {
        getArtist();
    }, [])

    return (
        <div className="add-listing-page">
            <MainHeader />
            <div className="navigation-bar">
                <div className="progress-bar">
                    <ProgressBar bgcolor={Colors.primary} completed={getCompletedPercentage()} />
                </div>
                {
                    width > 749
                        ?
                        (
                            <div className="nav-button-group">
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 1 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(1); }}>
                                        <span className='text'>
                                            Listing Basic Info
                                        </span>

                                        {listing.artistBasicInfo && !completedStatus.artistBasicInfo && activePage !== 1 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.artistBasicInfo && completedStatus.artistBasicInfo
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 2 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(2); }}>
                                        <span className='text'>
                                            Description
                                        </span>
                                        {listing.description && !completedStatus.description && activePage !== 2 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.description && completedStatus.description
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 3 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(3); }}>
                                        <span className='text'>
                                            Music &amp; Images
                                        </span>
                                        {listing.musicImages && !completedStatus.musicImages && activePage !== 3 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.musicImages && completedStatus.musicImages
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>

                            </div>
                        )
                        :
                        (
                            <div className='navigation-bar-small'>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_left_rounded'
                                        disabled={activePage === 1}
                                        onClick={() => {
                                            setActivePage(activePage - 1);
                                        }}
                                    />
                                </div>
                                <div className='title'>

                                    <span className='text'>{getSelectedHeading().title}</span>
                                    {getSelectedHeading().isCompleted ?
                                        (
                                            <div style={{ marginLeft: 5 }}>
                                                <KalabIconWithToolTip
                                                    toolTipText="Completed"
                                                    placement='top'
                                                    iconName={'fas fa-check'}
                                                    iconSize={12}
                                                    isFAIcon={true}
                                                    color={Colors.darkGreen}
                                                    borderColor={Colors.darkGreen}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                    }

                                </div>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_right_rounded'
                                        disabled={activePage >= 3}
                                        onClick={() => {
                                            setActivePage(activePage + 1);
                                        }}
                                    />
                                </div>

                            </div>
                        )
                }

            </div>
            {listing.personalDetails && completedStatus.personalDetails ? selectedForm() : null}
        </div>
    )
}
