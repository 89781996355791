import React, { useContext, useState } from 'react'
import './Rejection.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../constants/Colors';
import {
    LocationOnOutlined,
    HourglassEmptyRounded,
    ScheduleRounded,
    CakeOutlined,
    PersonOutlineRounded,
    MusicNoteRounded,
    CreateRounded,
    UpdateRounded
} from '@material-ui/icons';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { openInNewTab } from '../../utils/common';
import { dateToShowInMessages, getFormattedDate, getFormattedDateForStartEndDate, getFormattedTime } from '../../utils/Date';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabIconCheckBox from '../kalab/kalabCheckBoxes/KalabIconCheckBox';
import KalabExpandableText from '../kalab/kalabExpandableField/KalabExpandableText';
import { getLabelFromValue } from '../../utils/Currencies';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import PortaledComponent from '../modalPortal/ModalPortal';
import TimingPopUp from '../popUps/TimingPopUp';


const useStyles = makeStyles({
    root: (props) => ({
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        padding: 5,
        backgroundColor: '#f6f6f6',
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 2,
        width: '100%',
        maxHeight: 'calc(100vh - 100px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '& .updation-details': {

            '& .detail': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '& .icon': {
                    color: Colors.lightGrey,
                    fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 10 ? 10 : Math.floor(props.width / 100 * 2)),
                    marginRight: 5
                },
                '& .value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 800 ? 13 : (props.width / 100 * 1.5 < 8 ? 8 : Math.floor(props.width / 100 * 1.5)),
                    fontWeight: '300',
                    color: Colors.lightGrey
                }
            }
        },
        '& .main-detail': {
            '& .event-name': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 800 ? 30 : (props.width / 100 * 3.5 < 16 ? 16 : Math.floor(props.width / 100 * 3.5)),
                fontWeight: '300',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
            '& .event-between-detail': {
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 800 ? 20 : (props.width / 100 * 2.5 < 12 ? 12 : Math.floor(props.width / 100 * 2.5)),
                fontWeight: '400',
                color: Colors.lightGrey,
                textTransform: 'capitalize',
                textAlign: 'center',
                borderBottomWidth: 1,
                borderBottomColor: Colors.lightestGrey,
                paddingBottom: 5,

                '& .client-details': {
                    marginLeft: 10,
                    marginRight: 10
                },
                '& .listing-details': {
                    marginLeft: 10,
                    marginRight: 10,
                    '&:hover': {
                        color: Colors.primaryDark,
                        cursor: 'pointer'
                    },
                    '& .insured': {
                        display: 'inline-block'
                    }
                }
            },
            '& .date': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 800 ? 18 : (props.width / 100 * 2.5 < 12 ? 12 : Math.floor(props.width / 100 * 2.5)),
                fontWeight: '300',
                textTransform: 'capitalize',
                textAlign: 'center',
                color: Colors.lightGrey,
                paddingTop: 5,
                '& .residency-icon': {
                    display: 'inline-block',
                    verticalAlign: 'text-bottom',
                }
            },
            '& .row-details': {
                paddingTop: 2,
                paddingBottom: 2,
                '& .row-value': {
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 5,
                    marginBottom: 5,
                    '& .single-data': {
                        flex: 1,
                        marginRight: 10,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        '& .icon-value': {
                            flex: 1,
                            color: Colors.lightGrey,
                            fontSize: props.width > 800 ? 18 : (props.width / 100 * 2.5 < 13 ? 13 : Math.floor(props.width / 100 * 2.5)),
                            textAlign: 'center'
                        },
                        '& .text-value': {
                            flex: 2,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 800 ? 17 : (props.width / 100 * 2.4 < 11 ? 11 : Math.floor(props.width / 100 * 2.4)),
                            fontWeight: '300',
                            color: Colors.lightGrey,
                            '& .timing-btn': {
                                display: 'inline-block',
                                marginLeft: 2,
                                verticalAlign: 'text-bottom'
                            }
                        },
                        '& .timing-txt': {
                            textDecoration: 'underline',
                            fontWeight: '500',
                            '&:hover': {
                                color: Colors.primaryDark,
                                cursor: 'pointer'
                            },
                        }

                    },
                    '& .kalab-icon-checkbox': {
                        flex: 1,
                        padding: 2
                    },
                    '& .provide-text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 11 ? 11 : Math.floor(props.width / 100 * 2)),
                        fontWeight: '300',
                        color: Colors.black
                    }
                }
            },
            '& .other-details': {
                borderTopWidth: 1,
                borderTopColor: Colors.lightestGrey,
                paddingTop: 2,
                paddingBottom: 2,

                '& .single-detail': {
                    flex: 1,
                    padding: 2,
                    '& .title': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 10 ? 10 : Math.floor(props.width / 100 * 2)),
                        fontWeight: '400',
                        color: Colors.black
                    },
                    '& .value': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 10 ? 10 : Math.floor(props.width / 100 * 2)),
                        fontWeight: '300',
                        color: Colors.lightGrey,
                        textAlign: 'justify'
                    }


                }
            },
            '& .price-details': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                borderTopWidth: 1,
                borderTopColor: Colors.lightestGrey,
                paddingTop: 5,
                paddingBottom: 5,
                '& .other': {
                    flex: 1.5,
                    '& .price': {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        '& .title': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 11 ? 11 : Math.floor(props.width / 100 * 2)),
                            fontWeight: '400',
                            color: Colors.black
                        },
                        '& .value': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 11 ? 11 : Math.floor(props.width / 100 * 2)),
                            fontWeight: '400',
                            color: Colors.lightGrey,
                            textAlign: 'right'
                        }
                    }

                },
                '& .total-price': {
                    flex: 1,
                    '& .value': {
                        flex: 1,
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 800 ? 22 : (props.width / 100 * 2.8 < 14 ? 14 : Math.floor(props.width / 100 * 2.8)),
                        fontWeight: '500',
                        color: Colors.primary,
                        textAlign: 'right'

                    }
                }
            },
            '& .buttons': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                paddingTop: 5,
                paddingBottom: 5,
                '& .button': {
                    padding: 5
                }
            },
            '& .status': {
                marginTop: 15,
                marginBottom: 5,
                '& .status-value': {
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 800 ? 15 : (props.width / 100 * 2 < 11 ? 11 : Math.floor(props.width / 100 * 2)),
                        fontWeight: '400',
                        textTransform: 'uppercase',
                        color: Colors.darkRed,
                        borderColor: Colors.darkRed,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5
                    }
                },
                '& .reason': {
                    marginTop: 10,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 800 ? 13 : (props.width / 100 * 1.5 < 8 ? 8 : Math.floor(props.width / 100 * 1.5)),
                    fontWeight: '300',
                }
            }

        }

    }
    )

}
)
export default function Rejection({ rejection, history, refresh }) {

    const { height, width } = useWindowDimensions();
    const classes = useStyles({ width });
    const [showTimings, setShowTimings] = useState(false);

    const showTimingPopUp = () => {
        document.body.style.overflow = 'hidden';
        setShowTimings(true);
    }

    const closeTimingPopUp = () => {
        document.body.style.overflow = 'unset';
        setShowTimings(false);
    }

    const getPriceDetails = () => {
        var price = parseFloat(rejection.artistPrice);
        var totalPrice = parseFloat(rejection.totalPrice);
        var serviceFeePercentage = (totalPrice - price) / price * 100;
        var serviceFee = totalPrice - price;
        return {
            isPriceUpdated: rejection.isUpdated && (rejection.lastUpdatedColumns.includes('artistPrice') || rejection.lastUpdatedColumns.includes('currency')),
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }

    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + rejection.listingToken);
    }

    return (
        <div className='rejection'>
            <div className={classes.root} >
                {/* <div className='updation-details'>
                <div className='detail'>
                    <CreateRounded className='icon' />
                    <div className='value'>
                        {dateToShowInMessages(rejection.createdDate)}
                    </div>
                </div>
            </div> */}
                <div className="main-detail">
                    <div className="event-name">
                        {rejection.eventName}
                    </div>
                    <div className="event-between-detail">
                        <div className="client-details">
                            {rejection.clientCompanyFullName}
                        </div>
                        x
                        <div className="listing-details" onClick={handleClickOnListing} >
                            {rejection.listingName}
                            {
                                rejection.listingIsInsured ?
                                    (
                                        <div className='insured'>
                                            <KalabIconWithToolTip
                                                toolTipText="Insured"
                                                placement="right"
                                                iconName='verified_user_outlined'
                                                iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                noBorder={true}
                                                color={Colors.lightGrey}
                                            />
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                        </div>
                    </div>
                    <div className="date">
                        {rejection.isResidency ? getFormattedDateForStartEndDate(rejection.date, rejection.endDate) : getFormattedDate(rejection.date)}
                        {/* <div className='residency-icon'>
                        <KalabIconWithToolTip
                            toolTipText={rejection.isResidency ? "Residency" : "Single Booking"}
                            placement="bottom-start"
                            iconName={rejection.isResidency ? 'date_range_rounded' : 'event_rounded'}
                            iconSize={width > 500 ? 14 : (width / 100 * 2.8 < 11 ? 11 : Math.floor(width / 100 * 2.8))}
                            noBorder={true}
                            color={Colors.lightGrey}
                        />
                    </div> */}
                    </div>
                    <div className="row-details">
                        <div className="row-value">
                            <div className="single-data">
                                <LocationOnOutlined className="icon-value" />
                                <div className="text-value" style={{ textTransform: 'capitalize' }} >
                                    {rejection.location}
                                </div>
                            </div>
                        </div>
                        {
                            !rejection.isResidency ?
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <div className="icon-value">
                                                <KalabIconWithToolTip
                                                    toolTipText="Artist Arrival - Departure"
                                                    placement="right"
                                                    iconName='schedule_rounded'
                                                    iconSize={width > 800 ? 18 : (width / 100 * 2.5 < 13 ? 13 : Math.floor(width / 100 * 2.5))}
                                                    noBorder={true}
                                                    color={Colors.lightGrey}
                                                />
                                            </div>
                                            {/* < ScheduleRounded className="icon-value" /> */}
                                            <div className="text-value" >{getFormattedTime(rejection.startTime)} - {getFormattedTime(rejection.endTime)}</div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <div className="icon-value">
                                                <KalabIconWithToolTip
                                                    toolTipText="No of Events"
                                                    placement="right"
                                                    iconName='library_music_rounded'
                                                    iconSize={width > 800 ? 18 : (width / 100 * 2.5 < 13 ? 13 : Math.floor(width / 100 * 2.5))}
                                                    noBorder={true}
                                                    color={Colors.lightGrey}
                                                />
                                            </div>
                                            {/* < ScheduleRounded className={`icon-value ${isDataUpdated('startTime') || isDataUpdated('endTime') ? 'update' : ''}`} /> */}
                                            <div className={`text-value timing-txt`} onClick={() => { showTimingPopUp() }} >{rejection.noOfPerformances} Events
                                                {/* <div className='timing-btn'>
                                                <KalabIconButton
                                                    iconName={'fas fa-calendar-alt'}
                                                    iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                    isFAIcon
                                                    isLightTheme
                                                    noBorder
                                                    noDisabled
                                                    onClick={() => { showTimingPopUp() }}
                                                />
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                        }

                        {
                            !rejection.isResidency ?
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <HourglassEmptyRounded className="icon-value" />
                                            <div className="text-value" >{rejection.hours} hour{rejection.hours > 1 ? 's' : ''} set</div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }


                        <div className="row-value">
                            <div className="single-data">
                                <CakeOutlined className="icon-value" />
                                <div className="text-value" >{rejection.eventTypeName}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <PersonOutlineRounded className="icon-value" />
                                <div className="text-value" >{rejection.artistTypeName}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <MusicNoteRounded className="icon-value" />
                                <div className="text-value" >{rejection.genres.map(genre => genre.genreName).join(', ')}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <i className="icon-value fas fa-tshirt" style={{ fontSize: width > 800 ? 18 : (width / 100 * 2.5 < 13 ? 13 : Math.floor(width / 100 * 2.5)) }} />
                                <div className="text-value" >{rejection.dressCode}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row-details">
                        <div className="row-value">
                            <div className="provide-text">
                                {'Client will provide the following...'}
                            </div>
                        </div>
                        <div className="row-value" style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="kalab-icon-checkbox">
                                <KalabIconCheckBox
                                    iconName="directions_car"
                                    iconText={width < 500 ? '' : `Transportation`}
                                    value={rejection.transportation}
                                    disabled
                                    iconSize={width > 800 ? 22 : (width / 100 * 2.5 < 20 ? 20 : Math.floor(width / 100 * 2.5))}
                                    textSize={width > 800 ? 10 : (width / 100 * 1 < 8 ? 8 : Math.floor(width / 100 * 1))}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">
                                <KalabIconCheckBox
                                    iconName="local_parking_rounded"
                                    iconText={width < 500 ? '' : `Parking`}
                                    value={rejection.parking}
                                    disabled
                                    iconSize={width > 800 ? 22 : (width / 100 * 2.5 < 20 ? 20: Math.floor(width / 100 * 2.5))}
                                    textSize={width > 800 ? 10 : (width / 100 * 1 < 8 ? 8 : Math.floor(width / 100 * 1))}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="fastfood_rounded"
                                    iconText={width < 500 ? '' : `Food & Beverage`}
                                    value={rejection.foodBeverage}
                                    disabled
                                    iconSize={width > 800 ? 22 : (width / 100 * 2.5 < 20 ? 20: Math.floor(width / 100 * 2.5))}
                                    textSize={width > 800 ? 10 : (width / 100 * 1 < 8 ? 8 : Math.floor(width / 100 * 1))}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="mic_rounded"
                                    iconText={width < 500 ? '' : `Equipment`}
                                    value={rejection.equipment}
                                    disabled
                                    iconSize={width > 800 ? 22 : (width / 100 * 2.5 < 20 ? 20: Math.floor(width / 100 * 2.5))}
                                    textSize={width > 800 ? 10 : (width / 100 * 1 < 8 ? 8 : Math.floor(width / 100 * 1))}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="hotel"
                                    iconText={width < 500 ? '' : `Accomodation`}
                                    value={rejection.accomodation}
                                    disabled
                                    iconSize={width > 800 ? 22 : (width / 100 * 2.5 < 20 ? 20: Math.floor(width / 100 * 2.5))}
                                    textSize={width > 800 ? 10 : (width / 100 * 1 < 8 ? 8 : Math.floor(width / 100 * 1))}
                                />

                            </div>
                        </div>
                    </div>
                    {
                        (
                            (rejection.songRequests === null || rejection.songRequests === '') &&
                            (rejection.comment === null || rejection.comment === '') &&
                            (rejection.equipmentDetails === null || rejection.equipmentDetails === '')
                        )
                            ?
                            (
                                null
                            )
                            :
                            (
                                <div className="other-details">
                                    {
                                        (rejection.equipmentDetails === null || rejection.equipmentDetails === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className="title">Venue Equipment</div>
                                                    <div className="value">
                                                        <KalabExpandableText
                                                            text={rejection.equipmentDetails}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        (rejection.comment === null || rejection.comment === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className="title">Comment</div>
                                                    <div className="value">
                                                        <KalabExpandableText
                                                            text={rejection.comment}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        (rejection.songRequests === null || rejection.songRequests === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className="title">Song Requests</div>
                                                    <div className="value">
                                                        <KalabExpandableText
                                                            text={rejection.songRequests}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            )
                    }
                    <div className="price-details">

                        <div className="other">
                            <div className="price">
                                <div className="title">Proposed Price</div>
                                <div className="value">{getLabelFromValue(rejection.currency)} {getPriceDetails().price}</div>
                            </div>
                            <div className="price">
                                <div className="title">Service Fee</div>
                                <div className="value">{getLabelFromValue(rejection.currency)} {getPriceDetails().serviceFee}</div>
                            </div>
                        </div>
                        <div className="total-price">
                            <div className="value">{getLabelFromValue(rejection.currency)} {getPriceDetails().totalPrice}</div>
                        </div>
                    </div>
                    <div className="status">
                        <div className="status-value">
                            <div className="text">
                                {rejection.isRejectedByAdmin ? 'Rejected by Kalab' : (rejection.isRejectedByClient ? `Cancelled` : `Rejected`)}
                            </div>
                        </div>
                        <div className="reason">
                            Reason : {rejection.rejectionReason} {rejection.rejectionComment && rejection.rejectionComment !== '' ? `(${rejection.rejectionComment})` : null}
                        </div>

                    </div>
                </div>
                {showTimings && <PortaledComponent modal={<TimingPopUp closeFn={closeTimingPopUp} request={rejection} />} />}
            </div >
        </div>
    )
}
