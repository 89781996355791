import React from 'react'
import ImageWithCrop from '../elements/ImageWithCrop'
import defaultImage from '../../images/default-img.png';

export default function CollageImage5({ images, setImages, noCropOrEdit }) {
    const setImage = (id, image) => {
        console.log(image);
        var updatedImages = [];
        images.forEach(image => {
            if (image.id !== id || image.collageId !== 5) {
                updatedImages.push(image)
            }
        });
        updatedImages.push({
            collageId: 5,
            id,
            image
        })
        setImages(updatedImages);
    }
    const getImage = (id) => {
        var resultImage = {
            preview: '',
            rawUrl: '',
            previewFile: null,
            raw: null
        }
        images.forEach(image => {
            if (image.id === id && image.collageId === 5) {
                resultImage = image.image
            }
        });
        return (resultImage);
    }
    return (
        <div className="aspectratio ar3-1">
            <div className="image-box">
                <div style={{ flex: 1, height: '100%', position: 'relative', padding: '0.5%' }}>
                    {noCropOrEdit ? (
                        <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(1).preview !== "") ? getImage(1).preview : defaultImage} />
                    ) : (
                        <ImageWithCrop cropRatio={3 / 2} id={1} image={getImage(1)} setImage={setImage} />
                    )}
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }}>
                    <div style={{ display: 'flex', flex: 1, position: 'relative', height: '50%' }}>
                        <div style={{ flex: 1, position: 'relative', padding: '0.5%' }}>
                            {noCropOrEdit ? (
                                <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(2).preview !== "") ? getImage(2).preview : defaultImage} />
                            ) : (
                                <ImageWithCrop cropRatio={3 / 2} id={2} image={getImage(2)} setImage={setImage} />
                            )}
                        </div>
                        <div style={{ flex: 1, position: 'relative', padding: '0.5%' }}>
                            {noCropOrEdit ? (
                                <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(3).preview !== "") ? getImage(3).preview : defaultImage} />
                            ) : (
                                <ImageWithCrop cropRatio={3 / 2} id={3} image={getImage(3)} setImage={setImage} />
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, position: 'relative', height: '50%' }}>
                        <div style={{ flex: 1, position: 'relative', padding: '0.5%' }}>
                            {noCropOrEdit ? (
                                <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(4).preview !== "") ? getImage(4).preview : defaultImage} />
                            ) : (
                                <ImageWithCrop cropRatio={3 / 2} id={4} image={getImage(4)} setImage={setImage} />
                            )}
                        </div>
                        <div style={{ flex: 1, position: 'relative', padding: '0.5%' }}>
                            {noCropOrEdit ? (
                                <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(5).preview !== "") ? getImage(5).preview : defaultImage} />
                            ) : (
                                <ImageWithCrop cropRatio={3 / 2} id={5} image={getImage(5)} setImage={setImage} />
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
