import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Colors from '../../../constants/Colors';

const LightToolTip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: 'Montserrat, sans-serif',
    },
    arrow: {
        color: theme.palette.common.white,
    },
    '& ion-icon': {
        color: `${Colors.lightGrey} !important`
    }
}))(Tooltip);



const useStyles = makeStyles({
    root: props => ({
        backgroundColor: 'transparent',
        display: 'flex',
        outline: 'none',
        border: props.noBorder ? 'none':'0.2px solid',
        borderColor: props.borderColor,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        margin:'0 auto'
    }),
    icon: props => ({
        color: props.color
    })

});

export default function KalabIconWithToolTip({ iconName, toolTipText, iconSize, placement, isFAIcon = false, noBorder = false, color = Colors.lightGrey, borderColor = Colors.lightGrey }) {

    const classes = useStyles({ noBorder, color, borderColor });
    return (
        <LightToolTip arrow title={toolTipText} placement={placement} enterTouchDelay={0}>
            <div className={classes.root} style={{ height: iconSize + 5, width: iconSize + 5 }}>
                {!isFAIcon ? (
                    <Icon className={classes.icon} style={{ fontSize: iconSize }}>{iconName}</Icon>
                ) : (
                    <i className={`${classes.icon} ${iconName}`} style={{ fontSize: iconSize - 2 }}></i>
                )}
            </div>
        </LightToolTip>
    )
}
