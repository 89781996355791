import React, { useState, useEffect, useContext } from 'react'
import './ArtistRatingsPage.scss';
import MainHeader from "../header/MainHeader";
import { useParams } from 'react-router';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { getArtistRatingFromTokenRequest, updateArtistRatingRequest } from '../../webservices/Webservice';
import { getFormattedDate, getFormattedDateForStartEndDate } from '../../utils/Date';
import KalabRating from '../kalab/kalabRating/KalabRating';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { openInNewTab } from '../../utils/common';

export default function ArtistRatingsPage(props) {
    const { token } = useParams();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [artistRating, setArtistRating] = useState(null);
    const notify = useNotification();


    const getArtistRating = () => {
        showLoader();
        getArtistRatingFromTokenRequest(token,
            (artistRating) => {
                hideLoader();
                setArtistRating(artistRating)
                console.log(artistRating);
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })
    }
    const handleChangeInProfessionalismRating = (value) => {
        setArtistRating({
            ...artistRating,
            professionalism: value
        })
    }
    const handleChangeInCommunicationRating = (value) => {
        setArtistRating({
            ...artistRating,
            communication: value
        })
    }

    const handleInputTextChange = (e) => {
        var updatedArtistRating = {
            ...artistRating
        }
        updatedArtistRating[e.target.id] = e.target.value
        setArtistRating(updatedArtistRating);
    }

    const handleClickOnListingName = () =>{
        openInNewTab(window.location.origin + '/listing/' + artistRating.listingToken)
    }

    const handleClickOnEventName = () =>{
        openInNewTab(window.location.origin + '/link/' + artistRating.bookingToken)
    }

    const handleRateButtonClick = () => {
        if (isFormValid()) {
            showLoader();
            updateArtistRatingRequest(
                artistRating.artistRatingId,
                artistRating.token,
                artistRating.professionalism,
                artistRating.communication,
                artistRating.comment,
                (response) => {
                    console.log(response);
                    hideLoader();
                    notify({
                        type: 'SUCCESS',
                        message: response,
                        title: 'Success!!!',
                    });
                    props.history.push('/');
                }, (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                })
        }
    }

    const isFormValid = () => {
        if (
            artistRating.professionalism &&
            artistRating.communication 
        ) {
            return true;
        }
        else {
            return false;
        }

    }

    useEffect(() => {
        getArtistRating();
    }, [])


    return (
        <div className='artist-rating-dark'>
            <MainHeader onlyLogo={true} />
            {
                artistRating ?
                    (
                        <div className='artist-rating-page'>
                            <div className='title'>
                            Rate your event <span className='event-details' onClick={handleClickOnEventName}>"{artistRating.eventName}"</span> {artistRating.isResidency ? `from ${getFormattedDateForStartEndDate(artistRating.date, artistRating.endDate)}` : `on ${getFormattedDate(artistRating.date)}`} with {artistRating.clientCompanyFullName}
                            </div>
                            <div className='description'>
                                So how did it go? Tell us everything and don't hold back
                            </div>
                            <div className='content'>
                                <div className='content__rating'>
                                    <div className='text'>Professionalism</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={artistRating.professionalism}
                                            onChange={handleChangeInProfessionalismRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={artistRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__rating'>
                                    <div className='text'>Communication</div>
                                    <div className='rating'>
                                        <KalabRating
                                            value={artistRating.communication}
                                            onChange={handleChangeInCommunicationRating}
                                            fontSize={30}
                                            padding={2}
                                            readOnly={artistRating.isRated}
                                        />

                                    </div>
                                </div>
                                <div className='content__comment'>
                                    <div className='text-field'>
                                        <KalabTextField
                                            type="text"
                                            id="comment"
                                            placeholder="Share your experience "
                                            onChange={handleInputTextChange}
                                            value={artistRating.comment}
                                            multiline
                                            maxLength={300}
                                            rowsMax={6}
                                            disabled={artistRating.isRated}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                artistRating.isRated ?
                                    (
                                        <div className='already-rated-text'>
                                            Been there, done that. You’ve already submitted your rating😊
                                        </div>
                                    )
                                    :
                                    (
                                        <div className='button'>
                                            <KalabMainButton
                                                text={'Rate Now'}
                                                disabled={!isFormValid()}
                                                onClick={handleRateButtonClick}
                                            />
                                        </div>
                                    )
                            }


                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div>
    )
}
