import React from 'react';
import Colors from '../../../constants/Colors';
import {
    makeStyles
} from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: props => ({
        fontFamily: 'Montserrat, sans-serif',
        color: props.noDisabled ? Colors.lightGrey : props.isLightTheme ? Colors.black : Colors.white,
        backgroundColor: 'transparent',
        border: props.noBorder ? 'none':'1px solid',
        borderColor: props.noDisabled ? Colors.lightGrey :  props.isLightTheme ? Colors.black : Colors.white,
        borderRadius: 25,
        fontSize: props.fontSize,
        textTransform: 'uppercase',
        paddingTop : 5,
        paddingLeft : 5,
        paddingBottom : 5,
        paddingRight: 5,
        '&:hover:not([disabled])': {
            borderColor: props.color,
            color: props.color,
            cursor: 'pointer',
            outline: 'none'
        },
        '&:disabled' :{
            borderColor: Colors.lightGrey,
            color: Colors.lightGrey,
        }
    })
});

export default function KalabSmallButton({ className = "", disabled, onClick, text, fontSize=10 ,isLightTheme=false , color=Colors.primary, noDisabled=false , noBorder = false, type="button"}) {
    const props = {isLightTheme, fontSize, color, noDisabled, noBorder}
    const classes = useStyles(props);
    return (
        <button className={`${classes.root} ${className}`} disabled={disabled} onClick={onClick} type={type}>
            {text}
        </button>
    )
}