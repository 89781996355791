import React, { useState, useContext } from 'react';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import './Header.scss';
import logo from '../../images/logo_round_yellow.png';
import logoWhite from '../../images/LogoWrittenWhite.png';
import logoBlack from '../../images/LogoWrittenBlack.png';

import { NavLink } from 'react-router-dom';
import { getImageUrl } from '../../webservices/Webservice';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../contexts/UserContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';


export default function ArtistHeader({ fixed }) {
    const { logOut, loggedInUserDetails } = useContext(UserContext);
    const { height, width } = useWindowDimensions();
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    let history = useHistory();
    const handleLogOutBtnPress = () => {
        logOut()
    }
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenuIsOpen(open);
    };
    const getList = () => {
        return (
            <div className='drawer'>
                <div className='section'>
                    <div className='section__title'>{loggedInUserDetails.user.fullName}</div>
                    <NavLink to='/artist/home' className='section__btn'>
                        HOME
                    </NavLink>
                    {/* <NavLink to='/artist/bookings' className='section__btn'>
                        Booking
                    </NavLink> */}
                    <NavLink to='/artist/inbox' className='section__btn'>
                        Inbox
                    </NavLink>
                </div>
                <div className='section'>
                    <NavLink
                        to='/landing'
                        className='section__btn'
                    >
                        MAIN PAGE
                    </NavLink>
                    <div
                        className='section__btn'
                        onClick={() => {
                            setMenuIsOpen(false)
                            handleLogOutBtnPress();
                        }}
                    >
                        Log Out
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className={`header ${fixed ? 'header__is-fixed header__is-dark' : 'header__is-dark'}`}>
                <div className="main-row">
                    <NavLink to="/" className="logo">
                        <img
                            src={getImageUrl('LogoWrittenWhite')}
                            alt="Kalab Arts Logo"
                            className="logo__image"
                            onError={(e) => { e.target.onerror = null; e.target.src = logoWhite }} />
                    </NavLink>
                    {
                        width > 900 ?
                            (
                                <div className="main-row__nav">
                                    <div className='link'>
                                        <NavLink to='/artist/home' className='link__text'>
                                            Home
                                        </NavLink>
                                    </div>
                                    <div className='link'>
                                        <NavLink to='/artist/inbox' className='link__text'>
                                            Inbox
                                        </NavLink>
                                    </div>
                                    <div className='link'>
                                        <NavLink to='/artist/bookings' className='link__text'>
                                            Bookings
                                        </NavLink>
                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }


                    <div className='side-btns'>
                        {width > 900 ?
                            (
                                <div className='dropdown'>
                                    <button className='dropdown__icon-btn'>
                                        <Icon style={{ fontSize: 30 }}>person_outline_rounded</Icon>
                                    </button>
                                    <div className='dropdown__content'>
                                        <div className='dropdown__content--details'>
                                            <span className='dropdown__content--details__text'>{loggedInUserDetails.user.fullName}</span>
                                        </div>
                                        <NavLink to='/' className='dropdown__content--link'>
                                            MAIN PAGE
                                        </NavLink>
                                        <div
                                            className='dropdown__content--link'
                                            onClick={() => {
                                                handleLogOutBtnPress();
                                            }}
                                        >
                                            Log Out
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className='dropdown'>
                                    <KalabIconButton
                                        iconName={'person_outline_rounded'}
                                        noBorder
                                        iconSize={30}
                                        onClick={toggleDrawer(true)}
                                    />
                                </div>

                            )
                        }
                    </div>

                </div>
            </div>
            {
                width > 900 ?
                    (
                        null
                    )
                    :
                    (
                        <SwipeableDrawer
                            anchor={'right'}
                            open={menuIsOpen}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                        >
                            {getList()}
                        </SwipeableDrawer>
                    )
            }
        </>
    )
}