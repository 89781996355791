import React, { useState, useContext } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import KalabMainButton from '../kalabButtons/KalabMainButton';
import KalabIconButton from '../kalabButtons/KalabIconButton';
import PortaledComponent from '../../modalPortal/ModalPortal';
import { scroller } from 'react-scroll';
import { ClientSignInPopUp } from '../../popUps/ClientSignInPopUp';
import { ClientSignUpPopUp } from '../../popUps/ClientSignUpPopUp';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const useStyles = makeStyles({
    root: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        height: 'fit-content',
        border: '1px solid rgb(227, 227, 227)',
        borderRadius: 10,
        padding: 30,
        '& .title': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&__text': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '1.6vw',
                fontWeight: '500',
                '@media only screen and (max-width: 850px)' : {
                    fontSize: 16
                }
            }

        },
        '& .btn-view': {
            paddingTop: 30,
            paddingBottom: 20,
            '& .btns': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '&__book-now': {
                    marginRight: 10,
                    '&--btn': {
                        padding: 10
                    }
                },
                '&__add-to-wishlist': {
                    marginLeft: 10,
                    '&--btn': {
                        padding: 10
                    }

                }
            },

        },


    },
    animatedShow: {
        animation: "$showBtnView 1s ease-in-out"
    },

    animatedHide: {
        animation: "$hideBtnView 1s ease"
    },
    '@keyframes showBtnView': {
        '0%': {
            opacity: 0,
            maxHeight: 0
        },
        '100%': {
            opacity: 1,
            maxHeight: 1000
        }
    },
    '@keyframes hideBtnView': {
        '0%': {
            opacity: 1,
            height: 100
        },
        '100%': {
            opacity: 0,
            height: 0
        }
    }
})

export default function KalabCalculatorUnknownUserCard() {
    const [modal, setModal] = useState(false);
    const [modeMode, setModalMode] = useState('');
    const [showBtns, setShowBtns] = useState(false)
    const classes = useStyles();
    const { height, width } = useWindowDimensions();

    const openModal = (popUpName) => {
        setModalMode(popUpName);
        setModal(true);
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setModal(false);
        document.body.style.overflow = 'unset';
        if (width <= 900) {
          scroller.scrollTo('main-header', {
            duration: 100,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -250,
          })
        }
      }

    const handleShowBtnClick = () => {
        setShowBtns(!showBtns);
    }

    return (
        <>
            <div className={classes.root}>
                <div className="title">
                    <div className="title__text" >
                        Get an instant quote
                    </div>
                    <KalabIconButton
                        iconName={showBtns ? "keyboard_arrow_up_rounded" : "keyboard_arrow_down_rounded"}
                        noBorder
                        isLightTheme
                        onClick={handleShowBtnClick}
                    />

                </div>

                <div className={showBtns ? classes.animatedShow : classes.animatedHide}>
                    {showBtns ?
                        (
                            <div className="btn-view">
                                <div className='btns'>
                                    <div className='btns__book-now'>
                                        <KalabMainButton
                                            text='Sign In'                           
                                            className='btns__book-now--btn'
                                            fontSize={13}
                                            onClick={() => {
                                                openModal('signinClient');
                                            }}
                                        />
                                    </div>
                                    <div className='btns__add-to-wishlist'>
                                        <KalabMainButton
                                            text='Sign Up'
                                            className='btns__add-to-wishlist--btn'
                                            fontSize={13}
                                            onClick={() => {
                                                openModal('signupClient');
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            null
                        )}


                </div>
            </div>
            {/* -------------modals from client sign in, sign up------------------- */}
            {modal && (
                <PortaledComponent
                    modal={
                        modeMode === 'signinClient' && (
                            <ClientSignInPopUp modal={modal} closeModal={closeModal} />
                        )
                    }
                />
            )}
            {modal && (
                <PortaledComponent
                    modal={
                        modeMode === 'signupClient' && (
                            <ClientSignUpPopUp modal={modal} closeModal={closeModal} />
                        )
                    }
                />
            )}
        </>

    )
}
