import React, { useContext, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';
import { getLabelFromValue } from '../../../utils/Currencies';
import { dateToShowInMessages, getFormattedDate, getFormattedDateForStartEndDate, getFormattedTime, getStatusOfEvent } from '../../../utils/Date';
import { openInNewTab } from '../../../utils/common';
import { DataStoreContext } from '../../../contexts/DataStoreContext';
import KalabIconCheckBox from '../kalabCheckBoxes/KalabIconCheckBox';
import {
    LocationOnOutlined,
    HourglassEmptyRounded,
    ScheduleRounded,
    CakeOutlined,
    PersonOutlineRounded,
    MusicNoteRounded,
    CreateRounded
} from '@material-ui/icons';
import KalabSmallButton from '../kalabButtons/KalabSmallButton';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import KalabExpandableText from '../kalabExpandableField/KalabExpandableText';
import KalabIconButton from '../kalabButtons/KalabIconButton';
import TimingPopUp from '../../popUps/TimingPopUp';
import PortaledComponent from '../../elements/portaledComponent/portaledComponent';

const useStyles = makeStyles({
    root: (props) => ({
        transition: '0.3s',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 2,
        margin: '0 auto',
        widthToConsider: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '& .updation-details': {

            '& .detail': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '& .icon': {
                    color: Colors.black,
                    fontSize: 12,
                    marginRight: 5
                },
                '& .value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 10,
                    fontWeight: '300',
                    color: Colors.black
                }
            }
        },
        '& .main-detail': {
            '& .event-name': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.widthToConsider > 500 ? 20 : (props.widthToConsider / 100 * 4 < 16 ? 16 : Math.floor(props.widthToConsider / 100 * 4)),
                fontWeight: '200',
                textTransform: 'capitalize',
                textAlign: 'center',
                color: Colors.white,
            },
            '& .event-between-detail': {
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.widthToConsider > 500 ? 15 : (props.widthToConsider / 100 * 3 < 12 ? 12 : Math.floor(props.widthToConsider / 100 * 3)),
                fontWeight: '300',
                color: Colors.white,
                textTransform: 'capitalize',
                textAlign: 'center',
                borderBottomWidth: 1,
                borderBottomColor: Colors.darkGrey,
                paddingBottom: 5,

                '& .client-details': {
                    marginLeft: 10,
                    marginRight: 10
                },
                '& .listing-details': {
                    marginLeft: 10,
                    marginRight: 10,
                    '&:hover': {
                        color: Colors.primaryDark,
                        cursor: 'pointer'
                    },
                    '& .insured': {
                        display: 'inline-block'
                    }
                }
            },
            '& .date': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.widthToConsider > 500 ? 15 : (props.widthToConsider / 100 * 3 < 12 ? 12 : Math.floor(props.widthToConsider / 100 * 3)),
                fontWeight: '200',
                textTransform: 'capitalize',
                textAlign: 'center',
                color: Colors.white,
                paddingTop: 5,
                '& .residency-icon': {
                    display: 'inline-block',
                    verticalAlign: 'text-bottom',
                }
            },
            '& .row-details': {
                paddingTop: 2,
                paddingBottom: 2,
                '& .row-value': {
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 5,
                    marginBottom: 5,
                    '& .single-data': {
                        flex: 1,
                        marginRight: 10,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        '& .icon-value': {
                            flex: 1,
                            color: Colors.lightestGrey,
                            fontSize: props.widthToConsider > 500 ? 15 : (props.widthToConsider / 100 * 3 < 13 ? 13 : Math.floor(props.widthToConsider / 100 * 3)),
                            textAlign: 'center'
                        },
                        '& .text-value': {
                            flex: 2,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.widthToConsider > 500 ? 13 : (props.widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(props.widthToConsider / 100 * 2.6)),
                            fontWeight: '200',
                            color: Colors.white,
                            '& .timing-btn': {
                                display: 'inline-block',
                                marginLeft: 2,
                                verticalAlign: 'text-bottom'
                            }

                        }

                    },
                    '& .kalab-icon-checkbox': {
                        flex: 1,
                        padding: 2
                    },
                    '& .provide-text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.widthToConsider > 500 ? 13 : (props.widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(props.widthToConsider / 100 * 2.6)),
                        fontWeight: '300',
                        color: Colors.lightestGrey
                    }
                }
            },
            '& .other-details': {

                borderTopWidth: 1,
                borderTopColor: Colors.darkGrey,
                paddingTop: 2,
                paddingBottom: 2,

                '& .single-detail': {
                    flex: 1,
                    padding: 2,
                    '& .title': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.widthToConsider > 500 ? 12 : (props.widthToConsider / 100 * 2.4 < 10 ? 10 : Math.floor(props.widthToConsider / 100 * 2.4)),
                        fontWeight: '200',
                        color: Colors.lightestGrey
                    },
                    '& .value': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.widthToConsider > 500 ? 11 : (props.widthToConsider / 100 * 2.2 < 9 ? 9 : Math.floor(props.widthToConsider / 100 * 2.2)),
                        fontWeight: '200',
                        color: Colors.white,
                        textAlign: 'justify'
                    }

                }
            },
            '& .price-details': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                borderTopWidth: 1,
                borderTopColor: Colors.darkGrey,
                paddingTop: 5,
                paddingBottom: 5,
                '& .other': {
                    flex: 1.5,
                    '& .price': {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        '& .title': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.widthToConsider > 500 ? 13 : (props.widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(props.widthToConsider / 100 * 2.6)),
                            fontWeight: '200',
                            color: Colors.lightestGrey
                        },
                        '& .value': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.widthToConsider > 500 ? 13 : (props.widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(props.widthToConsider / 100 * 2.6)),
                            fontWeight: '400',
                            color: Colors.white,
                            textAlign: 'right'
                        }
                    }

                },
                '& .total-price': {
                    flex: 1,
                    '& .value': {
                        flex: 1,
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.widthToConsider > 500 ? 17 : (props.widthToConsider / 100 * 3.4 < 14 ? 14 : Math.floor(props.widthToConsider / 100 * 3.4)),
                        fontWeight: '500',
                        color: Colors.primary,
                        textAlign: 'right'

                    }
                }
            },
            '& .buttons': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                paddingTop: 5,
                paddingBottom: 5,
                '& .button': {
                    padding: 5
                }
            },
            '& .status': {
                marginTop: 15,
                marginBottom: 5,
                '& .status-value': {
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 15,
                        fontWeight: '400',
                        textTransform: 'uppercase',
                        color: Colors.primary,
                        borderColor: Colors.primary,
                        borderwidthToConsider: 1,
                        borderStyle: 'solid',
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5
                    }
                },
                '& .reason': {
                    marginTop: 10,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 12,
                    fontWeight: '300',
                }
            }


        }

    }
    )
}
)

export default function KalabEventRequestDetailElement({ eventRequest, isArtist = false, widthPercentage = 100 }) {
    const { height, width } = useWindowDimensions();
    const widthToConsider = width * widthPercentage / 100;
    const classes = useStyles({ widthToConsider });
    const { settings } = useContext(DataStoreContext);
    const [showTimings, setShowTimings] = useState(false);

    const showTimingPopUp = () => {
        document.body.style.overflow = 'hidden';
        setShowTimings(true);
    }

    const closeTimingPopUp = () => {
        document.body.style.overflow = 'unset';
        setShowTimings(false);
    }


    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }

    const getServiceFeeForBookingLink = () => {
        var serviceFeeForBookingLink = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeeForBookingLink') {
                serviceFeeForBookingLink = parseFloat(setting.settingValue);
            }
        })
        return serviceFeeForBookingLink
    }

    const getPriceDetails = () => {
        var price = parseFloat(eventRequest.artistPrice);
        var serviceFeePercentage = eventRequest.createdFromLink ? 0 :  getServiceFeePercentage();
        var serviceFee = eventRequest.createdFromLink ? getServiceFeeForBookingLink() : price * serviceFeePercentage / 100;
        var totalPrice = price + serviceFee;


        return {
            isPriceUpdated: eventRequest.isUpdated && (eventRequest.lastUpdatedColumns.includes('artistPrice') || eventRequest.lastUpdatedColumns.includes('currency')),
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }

    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + eventRequest.listingToken);
    }
    return (
        <div className={classes.root} >
            {/* <div className='updation-details'>
                <div className='detail'>
                    <CreateRounded className='icon' />
                    <div className='value'>
                        {dateToShowInMessages(eventRequest.createdDate)}
                    </div>
                </div>
            </div> */}
            <div className="main-detail">
                <div className="event-name">
                    {eventRequest.eventName}
                </div>
                <div className="event-between-detail">
                    <div className="client-details">
                        {eventRequest.clientCompanyFullName}
                    </div>
                    x
                    <div className="listing-details" onClick={handleClickOnListing} >
                        {eventRequest.listingName}
                        {
                            eventRequest.listingIsInsured ?
                                (
                                    <div className='insured'>
                                        <KalabIconWithToolTip
                                            toolTipText="Insured"
                                            placement="right"
                                            iconName='verified_user_outlined'
                                            iconSize={widthToConsider > 500 ? 13 : (widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(widthToConsider / 100 * 2.6))}
                                            noBorder={true}
                                            color={Colors.lightestGrey}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                    </div>
                </div>
                <div className="date">
                    {eventRequest.isResidency ? getFormattedDateForStartEndDate(eventRequest.date, eventRequest.endDate) : getFormattedDate(eventRequest.date)}
                    {/* <div className='residency-icon'>
                        <KalabIconWithToolTip
                            toolTipText={eventRequest.isResidency ? "Residency" : "Single Booking"}
                            placement="bottom-start"
                            iconName={eventRequest.isResidency ? 'date_range_rounded' : 'event_rounded'}
                            iconSize={widthToConsider > 500 ? 14 : (widthToConsider / 100 * 2.8 < 11 ? 11 : Math.floor(widthToConsider / 100 * 2.8))}
                            noBorder={true}
                            color={Colors.lightestGrey}
                        />
                    </div> */}
                </div>
                <div className="row-details">
                    <div className="row-value">
                        <div className="single-data">
                            <LocationOnOutlined className="icon-value" />
                            <div className="text-value" style={{ textTransform: 'capitalize' }} >
                                {eventRequest.location}
                            </div>
                        </div>
                    </div>
                    {
                        !eventRequest.isResidency ?
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <div className="icon-value">
                                            <KalabIconWithToolTip
                                                toolTipText="Artist Arrival - Departure"
                                                placement="left"
                                                iconName='schedule_rounded'
                                                iconSize={widthToConsider > 500 ? 15 : (widthToConsider / 100 * 3 < 13 ? 13 : Math.floor(widthToConsider / 100 * 3))}
                                                noBorder={true}
                                                color={Colors.lightestGrey}
                                            />
                                        </div>
                                        {/* < ScheduleRounded className="icon-value" /> */}
                                        <div className="text-value" >{getFormattedTime(eventRequest.startTime)} - {getFormattedTime(eventRequest.endTime)}</div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <div className="icon-value">
                                            <KalabIconWithToolTip
                                                toolTipText="No of Events"
                                                placement="right"
                                                iconName='library_music_rounded'
                                                iconSize={widthToConsider > 500 ? 15 : (widthToConsider / 100 * 3 < 13 ? 13 : Math.floor(widthToConsider / 100 * 3))}
                                                noBorder={true}
                                                color={Colors.lightestGrey}
                                            />
                                        </div>
                                        {/* < ScheduleRounded className={`icon-value ${isDataUpdated('startTime') || isDataUpdated('endTime') ? 'update' : ''}`} /> */}
                                        <div className={`text-value `} >{eventRequest.noOfPerformances} Events
                                            {/* <div className='timing-btn'>
                                                <KalabIconButton
                                                    iconName={'fas fa-calendar-alt'}
                                                    iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                    isFAIcon

                                                    noBorder
                                                    noDisabled
                                                    onClick={() => { showTimingPopUp() }}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                    }

                    {
                        !eventRequest.isResidency ?
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <HourglassEmptyRounded className="icon-value" />
                                        <div className="text-value" >{eventRequest.hours} hour{eventRequest.hours > 1 ? 's' : ''} set</div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }


                    <div className="row-value">
                        <div className="single-data">
                            <CakeOutlined className="icon-value" />
                            <div className="text-value" >{eventRequest.eventTypeName}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <PersonOutlineRounded className="icon-value" />
                            <div className="text-value" >{eventRequest.artistTypeName}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <MusicNoteRounded className="icon-value" />
                            <div className="text-value" >{eventRequest.genres.map(genre => genre.genreName).join(', ')}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <i className="icon-value fas fa-tshirt" style={{ fontSize: widthToConsider > 500 ? 13 : (widthToConsider / 100 * 2.6 < 11 ? 11 : Math.floor(widthToConsider / 100 * 2.6)) }} />
                            <div className="text-value" >{eventRequest.dressCode}</div>
                        </div>
                    </div>
                </div>
                <div className="row-details">
                    <div className="row-value">
                        <div className="provide-text">
                            {isArtist ? 'Client will provide the following...' : 'I am willing to provide the following...'}
                        </div>
                    </div>
                    <div className="row-value" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="kalab-icon-checkbox">
                            <KalabIconCheckBox
                                iconName="directions_car"
                                iconText={widthToConsider < 500 ? '' : `Transportation`}
                                value={eventRequest.transportation}
                                disabled
                                iconSize={widthToConsider < 500 ? 20 : 22}
                                textSize={10}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">
                            <KalabIconCheckBox
                                iconName="local_parking_rounded"
                                iconText={widthToConsider < 500 ? '' : `Parking`}
                                value={eventRequest.parking}
                                disabled
                                iconSize={widthToConsider < 500 ? 20 : 22}
                                textSize={10}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="fastfood_rounded"
                                iconText={widthToConsider < 500 ? '' : `Food & Beverage`}
                                value={eventRequest.foodBeverage}
                                disabled
                                iconSize={widthToConsider < 500 ? 20 : 22}
                                textSize={10}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="mic_rounded"
                                iconText={widthToConsider < 500 ? '' : `Equipment`}
                                value={eventRequest.equipment}
                                disabled
                                iconSize={widthToConsider < 500 ? 20 : 22}
                                textSize={10}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="hotel"
                                iconText={widthToConsider < 500 ? '' : `Accomodation`}
                                value={eventRequest.accomodation}
                                disabled
                                iconSize={widthToConsider < 500 ? 20 : 22}
                                textSize={10}
                            />

                        </div>
                    </div>
                </div>
                {
                    (
                        (eventRequest.songRequests === null || eventRequest.songRequests === '') &&
                        (eventRequest.comment === null || eventRequest.comment === '') &&
                        (eventRequest.equipmentDetails === null || eventRequest.equipmentDetails === '')
                    )
                        ?
                        (
                            null
                        )
                        :
                        (
                            <div className="other-details">
                                {
                                    (eventRequest.equipmentDetails === null || eventRequest.equipmentDetails === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Venue Equipment</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={eventRequest.equipmentDetails}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    (eventRequest.comment === null || eventRequest.comment === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Comment</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={eventRequest.comment}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    (eventRequest.songRequests === null || eventRequest.songRequests === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Song Requests</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={eventRequest.songRequests}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        )
                }

                <div className="price-details">

                    <div className="other">
                        <div className="price">
                            <div className="title">Proposed Price</div>
                            <div className="value">{getLabelFromValue(eventRequest.currency)} {getPriceDetails().price}</div>
                        </div>
                        <div className="price">
                            <div className="title">Service Fee</div>
                            <div className="value">{getLabelFromValue(eventRequest.currency)} {getPriceDetails().serviceFee}</div>
                        </div>
                    </div>
                    <div className="total-price">
                        <div className="value">{getLabelFromValue(eventRequest.currency)} {getPriceDetails().totalPrice}</div>
                    </div>
                </div>
            </div>
            {showTimings && <PortaledComponent modal={<TimingPopUp closeFn={closeTimingPopUp} request={eventRequest} />} />}
        </div >
    )
}