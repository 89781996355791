import React, { useState, useContext } from 'react'
import './ApplicationPage.scss';


import MainHeader from '../header/MainHeader';
import Colors from '../../constants/Colors';
import RegularExpression from '../../utils/RegularExpression';

import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { applicationRequest } from '../../webservices/Webservice';
import { checkLinkPlatform } from '../../utils/SupportedMusicLinks';

import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';



import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';





function ApplicationPage(props) {
    const [application, setApplication] = useState({
        email: "",
        fullName: "",
        artistName: "",
        musicLinks: [],
        newMusicLink: "",
        instaAccount: "",
        fbAccount: ""
    })
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();

    const handleApplyButton = (e) => {
        e.preventDefault();
        if (isFormvalid()) {
            showLoader();
            applicationRequest(application.fullName,
                application.artistName,
                application.email,
                application.instaAccount,
                application.fbAccount,
                application.musicLinks,
                (response) => {
                    console.log(response);
                    hideLoader();
                    props.history.push({
                        pathname: '/artist/application/result',
                        state: {
                            breakProtection: true,
                            response: response
                        }
                    })
                    //  props.history.push('/artist/application/result');
                }, (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                })
        }
    }
    const handleInputTextChange = (e) => {
        var updatedApplication = {
            ...application
        }
        updatedApplication[e.target.id] = e.target.value
        setApplication(updatedApplication);
    }
    const handleClickOnAddBtn = (e) => {
        e.preventDefault();
        if (isNewMusicLinkValid()) {
            var musicLinks = application.musicLinks;
            musicLinks.push({
                url: application.newMusicLink,
                platform: checkLinkPlatform(application.newMusicLink).platform
            });
            setApplication({
                ...application,
                newMusicLink: "",
                musicLinks: musicLinks
            })
        }
    }


    const isFormvalid = () => {
        if (application.musicLinks.length > 0
            && RegularExpression.email.test(application.email)
            && application.fullName !== ""
            && application.artistName !== ""
            && (application.instaAccount !== "" || application.fbAccount !== "")) {
            return true;
        }
        else {
            return false
        }
    }
    const handleDeleteBtn = (linkUrl) => {
        var musicLinks = application.musicLinks;
        var newMusicLinks = []
        musicLinks.forEach(musicLink => {
            if (musicLink.url !== linkUrl) {
                newMusicLinks.push(musicLink)
            }
        })
        setApplication({
            ...application,
            musicLinks: newMusicLinks
        })
    }

    const isNewMusicLinkValid = () => {
        var isMusicLinkAlreadyAdded = false;
        application.musicLinks.forEach(musicLink => {
            if (musicLink.url === application.newMusicLink) {
                isMusicLinkAlreadyAdded = true
            }
        })
        if (application.newMusicLink !== ""
            && !isMusicLinkAlreadyAdded
            && RegularExpression.url.test(application.newMusicLink)) {
            return true;
        }
        else {
            return false;
        }
    }


    return (
        <div className="dark-background">
            <MainHeader onlyLogo />
            <section>
                <form className="form-application">
                    <div className="div-field-application">
                        <KalabTextField
                            type="text" id="email"
                            placeholder="Email"
                            value={application.email}
                            onChange={handleInputTextChange}
                        />
                    </div>
                    <div className="div-field-application">
                        <KalabTextField
                            type="text"
                            id="fullName"
                            placeholder="Full Name"
                            value={application.fullName}
                            onChange={handleInputTextChange}
                        />
                    </div>

                    <div className="div-field-application">
                        <KalabTextField
                            type="text"
                            id="artistName"
                            placeholder="Artist Name"
                            value={application.artistName}
                            onChange={handleInputTextChange}
                        />
                    </div>
                    <div className="div-field-application">
                        <KalabTextField
                            type="text"
                            id="instaAccount"
                            placeholder="Instagram"
                            value={application.instaAccount}
                            onChange={handleInputTextChange}
                            endAdornment={(application.instaAccount !== "") ? (
                                <InputAdornment position="end">
                                    <KalabIconWithToolTip
                                        toolTipText="Instagram Handle"
                                        placement="right"
                                        iconName={'fas fa-question'}
                                        iconSize={12}
                                        isFAIcon={true}
                                    />

                                </InputAdornment>
                            ) : (null)}
                        />
                    </div>
                    <div className="div-field-application">
                        <KalabTextField
                            type="text"
                            id="fbAccount"
                            placeholder="Facebook"
                            value={application.fbAccount}
                            onChange={handleInputTextChange}
                            endAdornment={(application.fbAccount !== "") ? (
                                <InputAdornment position="end">
                                    <KalabIconWithToolTip
                                        toolTipText="Facebook Link"
                                        placement="right"
                                        iconName={'fas fa-question'}
                                        iconSize={12}
                                        isFAIcon={true}
                                    />

                                </InputAdornment>
                            ) : (null)}
                        />
                    </div>

                    {application.musicLinks.length < 3 ? (<div className="div-field-application">
                        <KalabTextField
                            type="text"
                            id="newMusicLink"
                            placeholder="Music Links"
                            value={application.newMusicLink}
                            onChange={handleInputTextChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <KalabIconButton
                                        disabled={!isNewMusicLinkValid()}
                                        onClick={handleClickOnAddBtn}
                                        iconName={'add_outline_rounded'}
                                        iconSize={12}
                                    />
                                </InputAdornment>
                            }

                        />
                    </div>) : (null)}

                    {application.musicLinks.length > 0 ? (
                        application.musicLinks.map((musicLink, index) => {
                            return (
                                <div className="div-field-application" style={{alignItems:'center'}} key={index.toString()} >
                                    <i className={checkLinkPlatform(musicLink.url).className + ' link-icon'} />
                                    <span className="span-link">{musicLink.url}</span>
                                    <KalabIconButton
                                        onClick={(e) => { e.preventDefault(); handleDeleteBtn(musicLink.url) }}
                                        iconName={'fas fa-times'}
                                        isFAIcon
                                        noBorder={true}
                                        iconSize={15}
                                    />
                                </div>
                            )
                        })

                    ) : (null)}

                    <div className='apply-btn-view'>
                        <KalabMainButton
                            disabled={!isFormvalid()}
                            onClick={handleApplyButton}
                            text={'Apply'}
                        />
                    </div>

                </form>

            </section>
        </div>
    )
}

export default ApplicationPage
