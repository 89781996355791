import React, { useEffect, useState, } from 'react'
import './TimingPopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getFormattedDateWithoutYear, getFormattedTime } from '../../utils/Date';
import KalabResidencyCalendar from '../kalab/kalabCalendar/KalabResidencyCalendar';
import { format, parse } from 'date-fns';
import moment from 'moment';

export default function TimingPopUp({ closeFn, request }) {
    const { height, width } = useWindowDimensions();
    const [residencyCalendar, setResidencyCalendar] = useState({
        date: null,
        startTime: null,
        endTime: null,
        hours: null,
    })

    const [timings, setTimings] = useState([]);

    const handleResidencyCalendarClick = (date) => {
        console.log(date);
        var timingsFilter = timings.filter(timing => format(timing.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
        var isUnselect = residencyCalendar.date && format(residencyCalendar.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        setResidencyCalendar({
            ...residencyCalendar,
            date: isUnselect ? null : date,
            startTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].startTime : null,
            endTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].endTime : null,
            hours: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].hours : null,
        })
    }

    useEffect(() => {
        setTimings(
            request.timings.map(timing => (
                {
                    date: parse(timing.date, 'yyyy-MM-dd', new Date()),
                    startTime: timing.startTime,
                    endTime: timing.endTime,
                    hours: parseInt(timing.hours)
                }
            ))
        )

    }, [request])



    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='timing-popup-modal'>
                <div className='timing-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='timing-popup-modal__content--top'>
                        <div className='timing-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                    </div>
                    <div className='timing-popup-modal__content--main'>
                        <div className="main-detail">
                            {
                                request.isResidency ?
                                    (
                                        request.date && request.endDate ?
                                            (
                                                <div className="main-detail--residency">
                                                    <div className='timings-section'>
                                                        <div className='timings-section__title' >
                                                            <div className='timings-section__title--text' >
                                                                Events ({request.timings.length})
                                                            </div>
                                                            <div className='timings-section__title--clear' >
                                                            </div>
                                                        </div>
                                                        {
                                                            timings.length > 0 ?
                                                                (
                                                                    <div className='timings-section__timings'>
                                                                        {
                                                                            timings.map((timing, index) => (
                                                                                <div className={`timings-section__timings--single ${index % 2 === 0 ? 'left-border' : ''} `}>
                                                                                    <div className='date'>
                                                                                        {getFormattedDateWithoutYear(timing.date)}
                                                                                    </div>
                                                                                    <div className='time'>
                                                                                        {getFormattedTime(timing.startTime)} - {getFormattedTime(timing.endTime)}
                                                                                        <span className='hours'>
                                                                                            ({timing.hours} hour{timing.hours > 1 ? 's' : ''})
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div className='timings-section__no-timings'>
                                                                        No events added
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                    <div className='calendar-section'>
                                                        <KalabResidencyCalendar
                                                            startDate={moment(request.date).toDate()}
                                                            endDate={moment(request.endDate).toDate()}
                                                            timings={timings}
                                                            selectedDate={residencyCalendar.date}
                                                            onChange={handleResidencyCalendarClick}
                                                            isSmall={width < 600 ? true : false}
                                                        />
                                                    </div>
                                                    {
                                                        residencyCalendar.date ?
                                                            (
                                                                <div className='edit-section'>
                                                                    <div className='edit-section__row'>
                                                                        <div className='edit-section__row--element'>
                                                                            <div className='date'>{getFormattedDateWithoutYear(residencyCalendar.date)}</div>
                                                                        </div>
                                                                        <div className='edit-section__row--element'>
                                                                            <div className='text'>
                                                                                {
                                                                                    residencyCalendar.startTime && residencyCalendar.endTime && residencyCalendar.hours ?
                                                                                        (
                                                                                            `${getFormattedTime(residencyCalendar.startTime)} - ${getFormattedTime(residencyCalendar.endTime)} (${residencyCalendar.hours} hour${residencyCalendar.hours> 1 ?'s' :''})`
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            'No Event for the day'
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </div>






                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                null
                                                            )
                                                    }

                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
