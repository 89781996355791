import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import Slider from '@material-ui/core/Slider';
const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        height: 'auto',
        '& .select-btn': {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: props.showDropDown || props.value !== null ? Colors.black : Colors.lightestGrey,
            borderRadius: 25,
            paddingTop: 5,
            paddingLeft: 10,
            paddingBottom: 5,
            paddingRight: 10,
            width: '100%',
            height: '100%',
            fontFamily: 'Montserrat, sans-serif',
            color: props.showDropDown || props.value !== null ? Colors.black : Colors.lightGrey,
            fontSize: 14,
            '&:hover': {
                borderColor: Colors.black,
                color: Colors.black,
            }

        },
        '& .select-dropdown': {
            position: 'absolute',
            zIndex: 10,
            left: '50%',
            transform: 'translate(-50%,10px)',
            backgroundColor: Colors.white,
            border: '1px solid',
            borderColor: Colors.lightestGrey,
            borderRadius: 5,
            display: 'flex',
            padding: 10,
            overflowY: 'auto',
            minWidth: '100%',
            flexDirection: 'column',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '&__slider': {
                padding: 10,
                paddingTop: 30,
                '& .MuiSlider-root': {
                    color: Colors.primary
                },
                '& .MuiSlider-valueLabel': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 10
                },
                '& .MuiSlider-thumb.MuiSlider-active': {
                    boxShadow: '0px 0px 0px 14px rgb(255 166 38 / 16%)'
                },
                '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
                    boxShadow: '0px 0px 0px 8px rgb(255 166 38 / 16%)'
                }

            },
            '&__footer': {
                borderTopWidth: 1,
                paddingTop: 15,
                paddingBottom: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 5,
                '&--save-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginLeft: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        color: Colors.white
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        backgroundColor: Colors.lightGrey,
                        color: Colors.white
                    },

                },
                '&--clear-btn': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 13,
                    marginRight: 20,
                    padding: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderColor: Colors.black,
                    color: Colors.black,
                    borderRadius: 50,
                    borderWidth: 1,
                    '&:not([disabled])': {
                        borderColor: Colors.black,
                        color: Colors.black
                    },
                    '&:hover:not([disabled])': {
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:hover': {
                        cursor: 'pointer',
                        borderColor: Colors.primary,
                        color: Colors.primary
                    },
                    '&:disabled': {
                        borderColor: Colors.lightGrey,
                        color: Colors.lightGrey
                    },
                }
            }

        },

    })
});

export default function KalabFilterSliderDropDown({
    onChange,
    value,
    placeholder
}) {
    const wrapperRef = useRef(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const [sliderValue, setSliderValue] = useState([100, 10000]);
    const getBtnTextAccordingToValue = () => {
        if (value) {
            return (`${value[0]} - ${value[1]}`);
        }
        else {
            return (placeholder)
        }
    }


    const handleBtnClick = (e) => {
        e.preventDefault();
        setShowDropDown(!showDropDown);
    }

    const handleSliderChangeValue = (event, value) => {
        setSliderValue(value)
    }

    const intialiseSlider = () => {
        if (value) {
            setSliderValue(value)
        }
        else {
            setSliderValue([100, 10000])
        }
    }
    const handleSaveBtnClick = (e) => {
        e.preventDefault();
        onChange(sliderValue);
        setShowDropDown(false);
    }

    const handleClearBtnClick = (e) => {
        e.preventDefault();
        onChange(null)
        setShowDropDown(false);
    }
    const isClearButtonDisabled = () => {

        return false

    }

    const classes = useStyles({ showDropDown, value });

    useEffect(() => {
        intialiseSlider();
    }, [showDropDown, value])


    const useClickOutsideModal = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowDropDown(false);
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useClickOutsideModal(wrapperRef);

    return (
        <div ref={wrapperRef} className={classes.root}>
            <button className={`${'select-btn'}`} onClick={handleBtnClick}>
                {getBtnTextAccordingToValue()}
            </button>
            {
                showDropDown
                    ?
                    (
                        <div className='select-dropdown'>
                            <div className='select-dropdown__slider'>
                                <Slider
                                    min={100}
                                    max={10000}
                                    step={100}
                                    value={sliderValue}
                                    onChange={handleSliderChangeValue}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"

                                />
                            </div>
                            <div className='select-dropdown__footer'>
                                <button className='select-dropdown__footer--clear-btn' onClick={handleClearBtnClick} disabled={isClearButtonDisabled()}>
                                    Clear
                                </button>
                                <button className='select-dropdown__footer--save-btn' onClick={handleSaveBtnClick}>
                                    Save
                                </button>

                            </div>
                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div>
    )
}
