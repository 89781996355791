import React, { useState, useEffect } from 'react'
import './Description.scss';
import InputAdornment from '@material-ui/core/InputAdornment';

import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';


function Description({ description, setDescription, completed, checkCompletedValue = 50 }) {
    const [artistDescription, setArtistDescription] = useState({
        description: '',
        setList: '',
        technicalRider: '',
        isInsured: false
    })
    const [charCount, setCharCount] = useState({
        description: description && description.description ? description.description.length : 0,
        setList: description && description.setList ? description.setList.length : 0,
        technicalRider: description && description.technicalRider ? description.technicalRider.length : 0
    })

    const handleMultilineInputChange = (e) => {
        var updatedArtist = {
            ...artistDescription
        }
        var updatedCharCount = {
            ...charCount
        }
        updatedArtist[e.target.id] = e.target.value
        updatedCharCount[e.target.id] = e.target.value.length
        setArtistDescription(updatedArtist);
        setCharCount(updatedCharCount)
    }

    const handleChangeInInsuranceCheckBox = () => {
        setArtistDescription({
            ...artistDescription,
            isInsured: !artistDescription.isInsured
        })
    }

    const handleContinueButtonClick = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            setDescription(artistDescription);
        }
    }

    const isFormValid = () => {
        if (
            artistDescription.description !== "" &&
            artistDescription.technicalRider !== ""
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const isValueChanged = () => {
        if (description && JSON.stringify(description) === JSON.stringify(artistDescription)) {
            return false
        }
        else {
            return true
        }
    }
    useEffect(() => {
        if (description) {
            setArtistDescription(description);
        }
    }, [])

    useEffect(() => {
        setDescription(artistDescription, isFormValid());
    }, [artistDescription])

    return (
            <div className="description">
                    <div className="input-group" >
                        <div className='input'>
                            <KalabTextField
                                type="text"
                                id="description"
                                placeholder="Description"
                                multiline
                                maxLength={500}
                                rowsMax={6}
                                value={artistDescription.description}
                                onChange={handleMultilineInputChange}
                            />

                        </div>
                        <span className='count-text'>{charCount.description}/500</span>

                    </div>

                    <div className="input-group">
                        <div className='input'>
                            <KalabTextField
                                multiline
                                type="text"
                                id="setList"
                                placeholder="Set List (For Cover Artists)"
                                maxLength={500}
                                rowsMax={6}
                                value={artistDescription.setList}
                                onChange={handleMultilineInputChange}
                            />
                        </div>
                        <span className='count-text'>{charCount.setList}/500</span>
                    </div>

                    <div className="input-group">
                        <div className='input'>
                            <KalabTextField
                                multiline
                                type="text"
                                id="technicalRider"
                                placeholder="Technical Rider"
                                maxLength={1000}
                                rowsMax={8}
                                value={artistDescription.technicalRider}
                                onChange={handleMultilineInputChange}
                            />
                        </div>
                        <span className='count-text'>{charCount.technicalRider}/1000</span>
                    </div>

                    <div className="input-group">
                        <div className='input' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <KalabCheckBox
                                checked={artistDescription.isInsured}
                                onChange={handleChangeInInsuranceCheckBox}
                                label={'I have public liability insurance'}
                            />
                            <div>
                                <KalabIconWithToolTip
                                    toolTipText="Clients may request proof of insurance, please be prepared to show your documents."
                                    placement="right"
                                    iconName={'fas fa-question'}
                                    iconSize={12}
                                    isFAIcon={true}
                                />
                            </div>

                        </div>

                    </div>

                    {/* <div
                        style={{
                            textAlign: 'center',
                            marginBottom: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <KalabMainButtonWithIcon
                            disabled={(!isFormValid() || (!isValueChanged())) || completed < checkCompletedValue}
                            onClick={handleContinueButtonClick}
                            text={description ? ('Next ') : ('Next ')}
                            iconName={'arrow_forward'}
                        />
                    </div> */}

            </div>
    )
}

export default Description
