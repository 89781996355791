const PROD = "production";
const DEV = "development";
const LOCAL = "local"
const selectedEnv = 1;

function getEnvironment() {
    if (selectedEnv === 1) {
        return PROD
    }
    else if (selectedEnv === 2) {
        return DEV
    }
    else {
        return LOCAL
    }
}

module.exports = {
    serverUrl: (
        getEnvironment() === PROD ?
            'https://api.kalabarts.com'
            :
            (
                getEnvironment() === DEV ?
                    'https://api-dev.kalabarts.com'
                    :
                    'http://localhost:8000'
            )
    ),
    gtmId: (
        getEnvironment() === PROD ?
            'G-YZ556WMTP0'
            :
            'G-J44TV0JDV7'

    ),
    mailId :'info@kalabarts.com',
    title: (
        getEnvironment() === PROD ?
            'KALAB'
            :
            (
                getEnvironment() === DEV ?
                    'KALAB Arts Development'
                    :
                    'KALAB Arts Local'
            )
    ),

}