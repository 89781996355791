import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Colors from '../../../constants/Colors';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import KalabIconButton from '../kalabButtons/KalabIconButton';
const useStyles = makeStyles({
    root: {
        '& .MuiInput-underline': {
            '&:after': {
                borderBottomWidth: 0,
            },
            '&:before': {
                
                borderBottomWidth: 0,

            },
            '&:hover:not(.Mui-disabled):before': {
               
                borderBottomWidth: 0
            },

        },
        '& label': {
            '&.Mui-focused': {
                color: Colors.lightGrey,
            },
            '&.MuiFormLabel-filled': {
                display: 'none'
            },
            '& + .MuiInput-formControl': {
                marginTop: 0
            },
            color: Colors.lightGrey,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '300',
            fontSize: 14,
            border: 0,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(1)',
        },
        '& input': {
            color: Colors.white,
            fontSize: 14,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& textarea': {
            color: Colors.white,
            fontSize: 14,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        },
        '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        }



    }
});

const defaultMaterialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: 16,
                fontWeight: '400',
                fontFamily: 'Montserrat, sans-serif'
            },
            body2: {
                fontSize: 15,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            caption:{
                fontSize: 15,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            }
        },
        MuiPickersDay:{
            current : {
                color:Colors.primary
            },
            daySelected :{
                backgroundColor : Colors.primary,
                '&:hover': {
                    backgroundColor : Colors.primary
                }
            }
        }

    },
})
export default function KalabDatePickerDropDown({ value, onChange }) {
    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                    className={classes.root}
                    autoOk
                    disablePast
                    disableToolbar
                    format='dd/MM/yyyy'
                    variant="inline"
                    label="Date"
                    clearable
                    value={value}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: false
                    }}
                    InputProps={{
                        endAdornment:
                            <>
                                {value !== null ? (
                                    <div style={{ padding: 6 }}>
                                        <KalabIconButton
                                            noBorder
                                            iconName="clear"
                                            iconSize={16}
                                            noDisabled={true}
                                            isWhite={true}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onChange(null);
                                            }}
                                        />
                                    </div>
                                ) : (null)}

                                <div div style={{ padding: 6 }}>
                                    <KalabIconButton
                                        noBorder
                                        iconName="expand_more_rounded"
                                        iconSize={24}
                                        noDisabled={true}
                                        isWhite={true}
                                    />
                                </div>
                            </>
                    }}

                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}
