import React, { useContext, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';
import { getLabelFromValue } from '../../../utils/Currencies';
import { dateToShowInMessages, getFormattedDate, getFormattedDateForStartEndDate, getFormattedTime } from '../../../utils/Date';
import { openInNewTab } from '../../../utils/common';
import { DataStoreContext } from '../../../contexts/DataStoreContext';
import KalabIconCheckBox from '../kalabCheckBoxes/KalabIconCheckBox';
import {
    LocationOnOutlined,
    HourglassEmptyRounded,
    ScheduleRounded,
    CakeOutlined,
    PersonOutlineRounded,
    MusicNoteRounded,
    CreateRounded,
    UpdateRounded
} from '@material-ui/icons';
import KalabSmallButton from '../kalabButtons/KalabSmallButton';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import KalabExpandableText from '../kalabExpandableField/KalabExpandableText';
import KalabIconButton from '../kalabButtons/KalabIconButton';
import TimingPopUp from '../../popUps/TimingPopUp';
import PortaledComponent from '../../elements/portaledComponent/portaledComponent';
import SharePopUp from '../../popUps/SharePopUp';

const useStyles = makeStyles({
    root: (props) => ({
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 2,
        width: '100%',



        '& .scrollable': {
            maxHeight: 'calc(100vh - 220px)',
            overflowX: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '& .updation-details': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                '& .detail': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    '& .icon': {
                        color: Colors.lightGrey,
                        fontSize: 10,
                        marginRight: 5
                    },
                    '& .value': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 8,
                        fontWeight: '300',
                        color: Colors.lightGrey
                    }
                }
            },
            '& .main-detail': {
                '& .update': {
                    color: Colors.purple + ' !important',
                    fontWeight: '500 !important'
                },
                '& .event-name': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 20 : (props.width / 100 * 4 < 16 ? 16 : Math.floor(props.width / 100 * 4)),
                    fontWeight: '300',
                    textTransform: 'capitalize',
                    textAlign: 'center',
                },
                '& .event-between-detail': {
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 12 ? 12 : Math.floor(props.width / 100 * 3)),
                    fontWeight: '400',
                    color: Colors.lightGrey,
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.lightestGrey,
                    paddingBottom: 5,

                    '& .client-details': {
                        marginLeft: 10,
                        marginRight: 10
                    },
                    '& .listing-details': {
                        marginLeft: 10,
                        marginRight: 10,
                        '&:hover': {
                            color: Colors.primaryDark,
                            cursor: 'pointer'
                        },
                        '& .insured': {
                            display: 'inline-block'
                        }
                    }
                },
                '& .date': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 12 ? 12 : Math.floor(props.width / 100 * 3)),
                    fontWeight: '300',
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    color: Colors.lightGrey,
                    paddingTop: 5,
                    '& .residency-icon': {
                        display: 'inline-block',
                        verticalAlign: 'text-bottom',
                    }
                },
                '& .row-details': {
                    paddingTop: 2,
                    paddingBottom: 2,
                    '& .row-value': {
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 5,
                        marginBottom: 5,
                        '& .single-data': {
                            flex: 1,
                            marginRight: 10,
                            marginLeft: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',

                            '& .icon-value': {
                                flex: 1,
                                color: Colors.lightGrey,
                                fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 13 ? 13 : Math.floor(props.width / 100 * 3)),
                                textAlign: 'center'
                            },
                            '& .text-value': {
                                flex: 2,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                                fontWeight: '300',
                                color: Colors.lightGrey,
                                '& .timing-btn': {
                                    display: 'inline-block',
                                    marginLeft: 2,
                                    verticalAlign: 'text-bottom'
                                }

                            },
                            '& .timing-txt': {
                                textDecoration: 'underline',
                                fontWeight: '500',
                                '&:hover': {
                                    color: Colors.primaryDark,
                                    cursor: 'pointer'
                                },
                            }

                        },
                        '& .kalab-icon-checkbox': {
                            flex: 1,
                            padding: 2
                        },
                        '& .provide-text': {
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                            fontWeight: '300',
                            color: Colors.black
                        }
                    }
                },
                '& .other-details': {
                    borderTopWidth: 1,
                    borderTopColor: Colors.lightestGrey,
                    paddingTop: 2,
                    paddingBottom: 2,

                    '& .single-detail': {
                        flex: 1,
                        padding: 2,
                        '& .title': {
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 12 : (props.width / 100 * 2.4 < 10 ? 10 : Math.floor(props.width / 100 * 2.4)),
                            fontWeight: '400',
                            color: Colors.black
                        },
                        '& .value': {
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                            fontWeight: '300',
                            color: Colors.lightGrey,
                            textAlign: 'justify'
                        }

                    }
                },
                '& .price-details': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderTopWidth: 1,
                    borderTopColor: Colors.lightestGrey,
                    '& .other': {
                        flex: 1.5,
                        '& .price': {
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            '& .title': {
                                flex: 1,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                                fontWeight: '400',
                                color: Colors.black
                            },
                            '& .value': {
                                flex: 1,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                                fontWeight: '400',
                                color: Colors.lightGrey,
                                textAlign: 'right'
                            }
                        }

                    },
                    '& .total-price': {
                        flex: 1,
                        '& .value': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 17 : (props.width / 100 * 3.4 < 14 ? 14 : Math.floor(props.width / 100 * 3.4)),
                            fontWeight: '500',
                            color: Colors.primary,
                            textAlign: 'right'

                        }
                    }
                },


            }
        },
        '& .buttons': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingTop: 5,
            paddingBottom: 5,
            '& .button': {
                padding: 5
            }
        }



    }
    )
}
)

export default function KalabEventRequestCard({ eventRequest, createNewOffer, cancelEventRequest, acceptRequest, book, isArtist = false }) {
    const { height, width } = useWindowDimensions();
    const classes = useStyles({ width });
    const { settings } = useContext(DataStoreContext);
    const [showTimings, setShowTimings] = useState(false);
    const [sharePopUp, setSharePopUp] = useState(false);

    const showSharePopUp = () => {
        document.body.style.overflow = 'hidden';
        setSharePopUp(true);
    }

    const closeSharePopUp = () => {
        document.body.style.overflow = 'unset';
        setSharePopUp(false);
    }


    const showTimingPopUp = () => {
        document.body.style.overflow = 'hidden';
        setShowTimings(true);
    }

    const closeTimingPopUp = () => {
        document.body.style.overflow = 'unset';
        setShowTimings(false);
    }

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }
    const getServiceFeeForBookingLink = () => {
        var serviceFeeForBookingLink = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeeForBookingLink') {
                serviceFeeForBookingLink = parseFloat(setting.settingValue);
            }
        })
        return serviceFeeForBookingLink
    }

    const isDataUpdated = (column) => {
        return (
            eventRequest.isUpdated && eventRequest.lastUpdatedColumns.includes(column)
                ?
                true
                :
                false
        )
    }

    const getPriceDetails = () => {
        var price = parseFloat(eventRequest.artistPrice);
        var serviceFeePercentage = eventRequest.createdFromLink ? 0 :  getServiceFeePercentage();
        var serviceFee = eventRequest.createdFromLink ? getServiceFeeForBookingLink() : price * serviceFeePercentage / 100;
        var totalPrice = price + serviceFee;


        return {
            isPriceUpdated: eventRequest.isUpdated && (eventRequest.lastUpdatedColumns.includes('artistPrice') || eventRequest.lastUpdatedColumns.includes('currency')),
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }

    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + eventRequest.listingToken);
    }
    return (
        <div className={classes.root} >
            <div className='scrollable'>
                <div className='updation-details'>
                    <div className='left'>
                        <KalabIconButton
                            iconName={'fas fa-external-link-alt'}
                            iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                            isFAIcon
                            isLightTheme
                            noBorder
                            noDisabled
                            onClick={() => { showSharePopUp()}}

                        />
                    </div>
                    <div className='right'>
                        <div className='detail'>
                            <CreateRounded className='icon' />
                            <div className='value'>
                                {dateToShowInMessages(eventRequest.createdDate)}
                            </div>
                        </div>
                        {eventRequest.isUpdated ?
                            (
                                <div className='detail'>
                                    <UpdateRounded className='icon' />

                                    <div className='value'>
                                        ({eventRequest.lastUpdatedByArtist ? eventRequest.artistName : eventRequest.clientCompanyFullName})
                                        &nbsp;
                                        {dateToShowInMessages(eventRequest.updatedDate)}
                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                        }
                    </div>
                </div>
                <div className="main-detail">
                    <div className={`event-name ${isDataUpdated('eventName') ? 'update' : ''}`}>
                        {eventRequest.eventName}
                    </div>
                    <div className="event-between-detail">
                        <div className="client-details">
                            {eventRequest.clientCompanyFullName}
                        </div>
                        x
                        <div className="listing-details" onClick={handleClickOnListing} >
                            {eventRequest.listingName}
                            {
                                eventRequest.listingIsInsured ?
                                    (
                                        <div className='insured'>
                                            <KalabIconWithToolTip
                                                toolTipText="Insured"
                                                placement="right"
                                                iconName='verified_user_outlined'
                                                iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                noBorder={true}
                                                color={Colors.lightGrey}
                                            />
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                        </div>
                    </div>
                    <div className={`date ${isDataUpdated('date') || isDataUpdated('endDate') ? 'update' : ''}`}>
                        {eventRequest.isResidency ? getFormattedDateForStartEndDate(eventRequest.date, eventRequest.endDate) : getFormattedDate(eventRequest.date)}
                        {/* <div className='residency-icon'>
                        <KalabIconWithToolTip
                            toolTipText={eventRequest.isResidency ? "Residency" : "Single Booking"}
                            placement="bottom-start"
                            iconName={eventRequest.isResidency ? 'date_range_rounded' : 'event_rounded'}
                            iconSize={width > 500 ? 14 : (width / 100 * 2.8 < 11 ? 11 : Math.floor(width / 100 * 2.8))}
                            noBorder={true}
                            color={isDataUpdated('isResidency') ? Colors.purple : Colors.lightGrey}
                        />
                    </div> */}

                    </div>
                    <div className="row-details">
                        <div className="row-value">
                            <div className="single-data">
                                <LocationOnOutlined className={`icon-value ${isDataUpdated('location') ? 'update' : ''}`} />
                                <div className={`text-value ${isDataUpdated('location') ? 'update' : ''}`} style={{ textTransform: 'capitalize' }} >
                                    {eventRequest.location}
                                </div>
                            </div>
                        </div>
                        {
                            !eventRequest.isResidency ?
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <div className="icon-value">
                                                <KalabIconWithToolTip
                                                    toolTipText="Artist Arrival - Departure"
                                                    placement="right"
                                                    iconName='schedule_rounded'
                                                    iconSize={width > 500 ? 15 : (width / 100 * 3 < 13 ? 13 : Math.floor(width / 100 * 3))}
                                                    noBorder={true}
                                                    color={isDataUpdated('startTime') || isDataUpdated('endTime') ? Colors.purple : Colors.lightGrey}
                                                />
                                            </div>
                                            {/* < ScheduleRounded className={`icon-value ${isDataUpdated('startTime') || isDataUpdated('endTime') ? 'update' : ''}`} /> */}
                                            <div className="text-value" >
                                                <span className={isDataUpdated('startTime') ? 'update' : ''}>{getFormattedTime(eventRequest.startTime)}</span> - <span className={isDataUpdated('endTime') ? 'update' : ''}>{getFormattedTime(eventRequest.endTime)}</span></div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <div className="icon-value">
                                                <KalabIconWithToolTip
                                                    toolTipText="No of Events"
                                                    placement="right"
                                                    iconName='library_music_rounded'
                                                    iconSize={width > 500 ? 15 : (width / 100 * 3 < 13 ? 13 : Math.floor(width / 100 * 3))}
                                                    noBorder={true}
                                                    color={isDataUpdated('noOfPerformances') || isDataUpdated('timings') ? Colors.purple : Colors.lightGrey}
                                                />
                                            </div>
                                            {/* < ScheduleRounded className={`icon-value ${isDataUpdated('startTime') || isDataUpdated('endTime') ? 'update' : ''}`} /> */}
                                            <div className={`text-value timing-txt ${isDataUpdated('noOfPerformances') || isDataUpdated('timings') ? 'update' : ''}`} onClick={() => { showTimingPopUp() }} >{eventRequest.noOfPerformances} Events
                                                {/* <div className='timing-btn'>
                                                <KalabIconButton
                                                    iconName={'fas fa-calendar-alt'}
                                                    iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                    isFAIcon
                                                    isLightTheme
                                                    noBorder
                                                    noDisabled
                                                    onClick={()=>{showTimingPopUp()}}
                                                    disabledColor = {isDataUpdated('timings') ? Colors.purple: null }
                                                />
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                        }

                        {
                            !eventRequest.isResidency ?
                                (
                                    <div className="row-value">
                                        <div className="single-data">
                                            <HourglassEmptyRounded className={`icon-value ${isDataUpdated('hours') ? 'update' : ''}`} />
                                            <div className={`text-value ${isDataUpdated('hours') ? 'update' : ''}`} >{eventRequest.hours} hour{eventRequest.hours > 1 ? 's' : ''} set</div>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }

                        <div className="row-value">
                            <div className="single-data">
                                <CakeOutlined className={`icon-value ${isDataUpdated('eventTypeId') ? 'update' : ''}`} />
                                <div className={`text-value ${isDataUpdated('eventTypeId') ? 'update' : ''}`} >{eventRequest.eventTypeName}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <PersonOutlineRounded className={`icon-value ${isDataUpdated('artistTypeId') ? 'update' : ''}`} />
                                <div className={`text-value ${isDataUpdated('artistTypeId') ? 'update' : ''}`} >{eventRequest.artistTypeName}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <MusicNoteRounded className={`icon-value ${isDataUpdated('genreIds') ? 'update' : ''}`} />
                                <div className={`text-value ${isDataUpdated('genreIds') ? 'update' : ''}`} >{eventRequest.genres.map(genre => genre.genreName).join(', ')}</div>
                            </div>
                        </div>
                        <div className="row-value">
                            <div className="single-data">
                                <i className={`icon-value fas fa-tshirt`} style={{ fontSize: width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6)), color: isDataUpdated('dressCode') ? Colors.darkBlueGradient : Colors.lightGrey }} />
                                <div className={`text-value ${isDataUpdated('dressCode') ? 'update' : ''}`} >{eventRequest.dressCode}{isDataUpdated('dressCode')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row-details">
                        <div className="row-value">
                            <div className="provide-text">
                                {isArtist ? 'Client will provide the following...' : 'I am willing to provide the following...'}
                            </div>
                        </div>
                        <div className="row-value" style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="kalab-icon-checkbox">
                                <KalabIconCheckBox
                                    iconName="directions_car"
                                    iconText={width < 500 ? '' : `Transportation`}
                                    value={eventRequest.transportation}
                                    disabled
                                    iconSize={20}
                                    textSize={8}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">
                                <KalabIconCheckBox
                                    iconName="local_parking_rounded"
                                    iconText={width < 500 ? '' : `Parking`}
                                    value={eventRequest.parking}
                                    disabled
                                    iconSize={20}
                                    textSize={8}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="fastfood_rounded"
                                    iconText={width < 500 ? '' : `Food & Beverage`}
                                    value={eventRequest.foodBeverage}
                                    disabled
                                    iconSize={20}
                                    textSize={8}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="mic_rounded"
                                    iconText={width < 500 ? '' : `Equipment`}
                                    value={eventRequest.equipment}
                                    disabled
                                    iconSize={20}
                                    textSize={8}
                                />

                            </div>
                            <div className="kalab-icon-checkbox">

                                <KalabIconCheckBox
                                    iconName="hotel"
                                    iconText={width < 500 ? '' : `Accomodation`}
                                    value={eventRequest.accomodation}
                                    disabled
                                    iconSize={20}
                                    textSize={8}
                                />

                            </div>
                        </div>
                    </div>
                    {
                        (
                            (eventRequest.songRequests === null || eventRequest.songRequests === '') &&
                            (eventRequest.comment === null || eventRequest.comment === '') &&
                            (eventRequest.equipmentDetails === null || eventRequest.equipmentDetails === '')
                        )
                            ?
                            (
                                null
                            )
                            :
                            (
                                <div className="other-details">
                                    {
                                        (eventRequest.equipmentDetails === null || eventRequest.equipmentDetails === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className={`title ${isDataUpdated('equipmentDetails') ? 'update' : ''}`}>Venue Equipment</div>
                                                    <div className={`value ${isDataUpdated('equipmentDetails') ? 'update' : ''}`}>
                                                        <KalabExpandableText
                                                            text={eventRequest.equipmentDetails}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }

                                    {
                                        (eventRequest.comment === null || eventRequest.comment === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className={`title ${isDataUpdated('comment') ? 'update' : ''}`}>Comment</div>
                                                    <div className={`value ${isDataUpdated('comment') ? 'update' : ''}`}>
                                                        <KalabExpandableText
                                                            text={eventRequest.comment}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        (eventRequest.songRequests === null || eventRequest.songRequests === '') ?
                                            (
                                                null
                                            )
                                            :
                                            (
                                                <div className="single-detail">
                                                    <div className={`title ${isDataUpdated('songRequests') ? 'update' : ''}`}>Song Requests</div>
                                                    <div className={`value ${isDataUpdated('songRequests') ? 'update' : ''}`}>
                                                        <KalabExpandableText
                                                            text={eventRequest.songRequests}
                                                            limitLines={1}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    }


                                </div>
                            )
                    }
                    <div className="price-details">

                        <div className="other">
                            <div className="price">
                                <div className={`title ${getPriceDetails().isPriceUpdated ? 'update' : ''}`}>Proposed Price</div>
                                <div className={`value ${getPriceDetails().isPriceUpdated ? 'update' : ''}`}>{getLabelFromValue(eventRequest.currency)} {getPriceDetails().price}</div>
                            </div>
                            <div className="price">
                                <div className="title">Service Fee</div>
                                <div className="value">{getLabelFromValue(eventRequest.currency)} {getPriceDetails().serviceFee}</div>
                            </div>
                        </div>
                        <div className="total-price">
                            <div className="value">{getLabelFromValue(eventRequest.currency)} {getPriceDetails().totalPrice}</div>
                        </div>


                    </div>




                </div>
            </div>
            <div className="buttons">
                <div className="button">
                    <KalabSmallButton
                        text={isArtist ? 'Reject' : 'Cancel Request'}
                        isLightTheme
                        color={Colors.darkRed}
                        onClick={cancelEventRequest}
                    />
                </div>
                <div className="button">
                    <KalabSmallButton
                        text={isArtist ? 'Update Offer' : 'Update offer'}
                        isLightTheme
                        onClick={createNewOffer}
                    />
                </div>

                {isArtist ? (
                    !eventRequest.isAcceptedByArtist ? (
                        <div className="button">
                            <KalabSmallButton
                                text='Accept'
                                isLightTheme
                                color={Colors.darkGreen}
                                onClick={acceptRequest}
                            />
                        </div>
                    ) : (null)
                ) : (
                    eventRequest.isAcceptedByArtist ? (
                        <div className="button">
                            <KalabSmallButton
                                text='Book'
                                isLightTheme
                                color={Colors.darkGreen}
                                onClick={book}
                            />
                        </div>
                    ) : (null)
                )
                }
            </div>
            {showTimings && <PortaledComponent modal={<TimingPopUp closeFn={closeTimingPopUp} request={eventRequest} />} />}
            {sharePopUp &&
                <PortaledComponent
                    modal={
                        <SharePopUp
                            title={'The link of the event request is the following'}
                            closeFn={closeSharePopUp}
                            linkToShare={window.location.origin + '/link/' + eventRequest.token}
                            okText={'Open'}
                            okFn={() => { openInNewTab(window.location.origin + '/link/' + eventRequest.token); closeSharePopUp(); }}
                            okBtnColor={Colors.primary}
                        />
                    }
                />
            }
        </div >
    )
}