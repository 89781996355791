import React, { useState, useEffect, useContext } from 'react'
import './WhatsappUpdatePopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';

import {
    getCountryCodesRequest,
    checkOTPRequest,
    generateWhatsappOTPRequest,
    resendWhatsappOTPRequest,
    updateClientWhatsappDetailsRequest,
    updateArtistWhatsappDetailsRequest
} from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import KalabSwitch from '../kalab/kalabSwitch/KalabSwitch';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

export default function WhatsappUpdatePopUp({
    user,
    isArtist,
    closeFn,
}) {
    const [whatsappActive, setWhatsappActive] = useState(false);
    const [details, setDetails] = useState({
        whatsappCountryCode: null,
        whatsappPhone: '',
        otp: null
    })
    const [isVerify, setIsVerify] = useState(false);
    const [verifiedNumbers, setVerifiedNumbers] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [countryCodeLoading, setCountryCodeLoading] = useState(false)
    const [resendCount, setResendCount] = useState(0);

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const { height, width } = useWindowDimensions();

    const handleOkClick = () => {
        if(isArtist){
            updateArtistWhatsappDetails();
        }
        else{
            updateClientWhatsappDetails();
        }
    }

    const updateClientWhatsappDetails = ()=>{
        showLoader();
        updateClientWhatsappDetailsRequest(
            checkIfSame() ? null : (whatsappActive ? details.whatsappPhone : null),
            checkIfSame() ? null : (whatsappActive ? details.whatsappCountryCode : null),
            whatsappActive ? (checkIfSame() ? true : false) : false,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                closeFn();
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        )
    }

    const updateArtistWhatsappDetails = ()=>{
        showLoader();
        updateArtistWhatsappDetailsRequest(
            checkIfSame() ? null : (whatsappActive ? details.whatsappPhone : null),
            checkIfSame() ? null : (whatsappActive ? details.whatsappCountryCode : null),
            whatsappActive ? (checkIfSame() ? true : false) : false,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                closeFn();
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        )
    }

    const getCountryCodes = () => {
        setCountryCodeLoading(true)
        getCountryCodesRequest(
            (countryCodes) => {
                var countryCodeOptionsFromAPI = [];
                countryCodes.forEach(countryCode => {
                    if (countryCodeOptionsFromAPI.filter(cCodeOption => cCodeOption.countryCode.countryCode === countryCode.countryCode).length === 0) {
                        countryCodeOptionsFromAPI.push({
                            value: countryCode,
                            label: `+${countryCode.countryCode} ( ${countryCodes.filter(cCode => cCode.countryCode === countryCode.countryCode).map(cntCode => cntCode.countryAsciiName !== "" ? cntCode.countryAsciiName : cntCode.countryName).join(', ')} )`,
                            countryCode
                        })
                    }
                });
                setCountryCodeOptions(countryCodeOptionsFromAPI);
                setCountryCodeLoading(false)
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setCountryCodeLoading(false)
            }
        )
    }
    const handleCountryCodeSelectChange = (countryCodeValue) => {
        setDetails({
            ...details,
            whatsappCountryCode: countryCodeValue ? countryCodeValue.value.countryCode : null,
        });
        if (isVerify) {
            setIsVerify(false);
        }
    };

    const handleSameNumberBtnPress = () => {
        setDetails({
            ...details,
            whatsappCountryCode: user.countryCode,
            whatsappPhone: user.phone
        })
    }

    const checkIfSame = () => {
        if (
            details.whatsappCountryCode === user.countryCode &&
            details.whatsappPhone === user.phone
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const getCountryCodeSelectValue = (countryCode) => {
        var countryCodeValues = countryCodeOptions.filter(countryCodeOption => (countryCodeOption.value.countryCode === countryCode));
        return (
            countryCodeValues.length > 0 ? countryCodeValues[0] : null
        )
    }

    const checkOTP = () => {
        showLoader();
        checkOTPRequest(
            details.whatsappCountryCode,
            details.whatsappPhone,
            details.otp,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var vNumbers = verifiedNumbers;
                vNumbers.push({
                    countryCode: details.whatsappCountryCode,
                    phone: details.whatsappPhone
                });
                setVerifiedNumbers(vNumbers);
                setDetails({
                    ...details,
                    otp: null
                })
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleResendOTPButton = (e) => {
        e.preventDefault();
        resendOTP();
    };

    const handleChangeInActiveBtn = () => {
        if(whatsappActive){
            setDetails({
                whatsappCountryCode: user.whatsappCountryCode,
                whatsappPhone: user.whatsappPhone ? user.whatsappPhone : '',
                otp: ''
            })
        }
        setWhatsappActive(!whatsappActive)
        if (isVerify) {
            setIsVerify(false);
        }
    }
    const handleInputTextChange = (e) => {
        var updatedDetails = {
            ...details,
        };
        updatedDetails[e.target.id] = e.target.value;
        setDetails(updatedDetails);
    };

    const resendOTP = () => {
        showLoader();
        resendWhatsappOTPRequest(
            details.whatsappCountryCode,
            details.whatsappPhone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var count = resendCount + 1;
                setResendCount(count);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleOTPCheckButton = (e) => {
        e.preventDefault();
        checkOTP();
    };
    const handleVerifyButton = (e) => {
        e.preventDefault();
        generateOTP();
    };
    const generateOTP = () => {
        showLoader();
        generateWhatsappOTPRequest(
            details.whatsappCountryCode,
            details.whatsappPhone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                setIsVerify(true);
                setResendCount(0);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };

    const checkPhoneVerified = () => {
        if (
            verifiedNumbers.filter(
                verifiedNumber =>
                (
                    verifiedNumber.countryCode === details.whatsappCountryCode &&
                    verifiedNumber.phone === details.whatsappPhone
                )
            ).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isFormValid = () => {
        if (
            (
                whatsappActive &&
                details.whatsappCountryCode !== null &&
                details.whatsappPhone.trim() !== '' &&
                checkPhoneVerified()
            ) ||
            (
                !whatsappActive
            )
        ) {
            return true
        }
        else {
            return false
        }
    }

    const isValueChanged = () => {
        if(user.whatsappIsSame === null){
            return true
        }
       else if (
            (user.whatsappCountryCode && user.whatsappPhone ? true : false) !== whatsappActive ||
            details.whatsappCountryCode !== user.whatsappCountryCode ||
            details.whatsappPhone.trim() !== (user.whatsappPhone ? user.whatsappPhone : '')
        ) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        console.log(user);
        if (user) {

            getCountryCodes();
            setDetails({
                whatsappCountryCode: user.whatsappCountryCode,
                whatsappPhone: user.whatsappPhone ? user.whatsappPhone : '',
                otp: ''
            })
            var vNumbers = verifiedNumbers;
            vNumbers.push({
                countryCode: user.countryCode,
                phone: user.phone
            });
            if (!user.whatsappIsSame && user.whatsappCountryCode && user.whatsappPhone) {
                vNumbers.push({
                    countryCode: user.whatsappCountryCode,
                    phone: user.whatsappPhone
                });
            }
            setVerifiedNumbers(vNumbers);
            if (user.whatsappPhone && user.whatsappCountryCode) {
                setWhatsappActive(true);
            }
            else {
                setWhatsappActive(false);
            }
        }

    }, [user])



    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='whatsapp-update-popup-modal'>
                <div className='whatsapp-update-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='whatsapp-update-popup-modal__content--top'>
                        <div className='whatsapp-update-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='whatsapp-update-popup-top__title--text'>
                            <WhatsAppIcon className='icon' />
                            Update WhatsApp Details
                        </div>
                    </div>
                    <div className='whatsapp-update-popup-modal__content--main'>
                        <div className='whatsapp-update-popup-modal__content--row' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className='whatsapp-update-popup-modal__content--row__text'>Enable WhatsApp Notifications</div>
                            <div className='whatsapp-update-popup-modal__content--row__check'>
                                <KalabSwitch
                                    checked={whatsappActive}
                                    onChange={handleChangeInActiveBtn}
                                />
                            </div>
                        </div>
                        {
                            whatsappActive ?
                                (
                                    <>
                                        <div className={`whatsapp-update-popup-modal__content--row ${width < 550 ? ('whatsapp-update-popup-modal__content--row--is-not-row') : ('')}`} style={{ alignItems: 'flex-end' }}>
                                            <div className='input'>
                                                <KalabDropDown
                                                    placeholder="Country Code"
                                                    isDisabled={false}
                                                    isLoading={countryCodeLoading}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    isLightTheme
                                                    name="whatsappCountryCode"
                                                    onChange={handleCountryCodeSelectChange}
                                                    options={countryCodeOptions}
                                                    value={getCountryCodeSelectValue(details.whatsappCountryCode)}
                                                />
                                            </div>

                                            <div className='input'>
                                                <KalabTextField
                                                    type='number'
                                                    id='whatsappPhone'
                                                    placeholder='Phone'
                                                    isLightTheme
                                                    value={details.whatsappPhone}
                                                    onChange={(e) => {
                                                        handleInputTextChange(e);
                                                        if (isVerify) {
                                                            setIsVerify(false)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={`whatsapp-update-popup-modal__content--row ${width < 550 ? ('whatsapp-update-popup-modal__content--row--is-not-row') : ('')}`} style={{ alignItems: 'flex-end' }}>
                                            <div style={{ flex: 1, marginTop: 10, marginBottom: 10, justifyContent: 'center', textAlign: 'center' }}>
                                                {
                                                    !checkIfSame() ?
                                                        (
                                                            <KalabSmallButton
                                                                text="Use Same Number"
                                                                isLightTheme
                                                                onClick={handleSameNumberBtnPress}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            null
                                                        )
                                                }

                                            </div>
                                            {
                                                checkPhoneVerified() ?
                                                    (
                                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, justifyContent: 'center', textAlign: 'center' }}>
                                                            <i className="fas fa-check-circle register-icon" /><span className='verified-text'>Verified</span>
                                                        </div>
                                                    )
                                                    :
                                                    (isVerify ?
                                                        (
                                                            <div style={{ flex: 1, display: 'flex', marginTop: 10, marginBottom: 10 }}>
                                                                <div style={{ flex: 1, display: 'flex' }}>
                                                                    <KalabTextField
                                                                        type="number"
                                                                        id="otp"
                                                                        isLightTheme
                                                                        placeholder="OTP"
                                                                        value={details.otp}
                                                                        onChange={handleInputTextChange}
                                                                    />
                                                                </div>
                                                                <div style={{ marginLeft: 5, marginRight: 5, alignSelf: 'flex-end' }}>
                                                                    <KalabSmallButton
                                                                        text="check"
                                                                        isLightTheme
                                                                        onClick={handleOTPCheckButton}
                                                                        disabled={details.otp === ""}
                                                                    />
                                                                </div>
                                                                <div style={{ alignSelf: 'flex-end' }}>
                                                                    <KalabSmallButton
                                                                        text="Resend"
                                                                        isLightTheme
                                                                        onClick={handleResendOTPButton}
                                                                        disabled={resendCount > 2 ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div style={{ flex: 1, marginTop: 10, marginBottom: 10, justifyContent: 'center', textAlign: 'center' }}>
                                                                <KalabSmallButton
                                                                    text="Verify"
                                                                    isLightTheme
                                                                    onClick={handleVerifyButton}
                                                                    disabled={details.whatsappPhone === "" || details.whatsappCountryCode === null}
                                                                />
                                                            </div>
                                                        ))

                                            }
                                        </div>

                                    </>
                                )
                                :
                                (
                                    null
                                )
                        }



                    </div>
                    <div className='whatsapp-update-popup-modal__content--main' style={{ textAlign: 'center' }}>
                        <div className="whatsapp-update-popup-button">
                            <KalabMainButton
                                text={'Update'}
                                onClick={handleOkClick}
                                disabled={!isFormValid() || !isValueChanged()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
