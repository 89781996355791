import React, { useState, useEffect, useContext } from 'react'
import './ClientEventRequestUpdatePopUp.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import { isAfter, isPast, isToday, parse, format } from 'date-fns';
import Colors from '../../constants/Colors';
import { useHistory } from "react-router-dom";
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabIconCheckBox from '../kalab/kalabCheckBoxes/KalabIconCheckBox';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabDatePickerDropDownNormal from '../kalab/kalabDropDown/KalabDatePickerDropDownNormal';
import KalabTimePickerDropDown from '../kalab/kalabDropDown/KalabTimePickerDropDown';
import { createEventRequestsRequest, getEventTemplatesRequest } from '../../webservices/Webservice';
import { openInNewTab } from '../../utils/common';
import { getDefaultCurrency, getLabelFromValue } from '../../utils/Currencies';
import { DataStoreContext } from '../../contexts/DataStoreContext';
import { UserContext } from '../../contexts/UserContext';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { Icon, InputAdornment } from '@material-ui/core';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import KalabTimeDropDown from '../kalab/kalabDropDown/KalabTimeDropDown';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import moment from 'moment';
import { getFormattedDateWithoutYear, getFormattedTime, restrictionForResidency } from '../../utils/Date';
import KalabResidencyCalendar from '../kalab/kalabCalendar/KalabResidencyCalendar';
import KalabCurrencyDropDown from '../kalab/kalabDropDown/KalabCurrencyDropDown';

export default function ClientEventRequestCreatePopUp({ closeFn, listing }) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { height, width } = useWindowDimensions();
    const { settings } = useContext(DataStoreContext);
    const { loggedInUserDetails } = useContext(UserContext);
    const history = useHistory();
    const notify = useNotification();
    const [artistTypes, setArtistTypes] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [genres, setGenres] = useState([]);
    const [request, setRequest] = useState({
        eventName: '',
        hours: null,
        startTime: null,
        endTime: null,
        dressCode: '',
        comment: '',
        songRequests: '',
        location: '',
        equipmentDetails: '',
        parking: false,
        equipment: false,
        accomodation: false,
        foodBeverage: false,
        transportation: false,
        eventType: null,
        artistType: null,
        genres: [],
        date: null,
        artistPrice: '0',
        currency: getDefaultCurrency().value,
        templateName: '',
        isResidency: false,
        endDate: null,
        timings: [],
    })

    const [residencyCalendar, setResidencyCalendar] = useState({
        date: null,
        startTime: null,
        endTime: null,
        hours: null,
        isEdit: false,
        weekDays: []
    })

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showTemplateSection, setShowTemplateSection] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }


    const sendEventRequest = () => {
        showLoader();
        createEventRequestsRequest(
            [listing.listingId],
            request.eventType.value,
            request.genres.map(genre => (genre.value)),
            request.artistType.value,
            request.songRequests,
            request.comment.trim(),
            format(request.date, 'yyyy-MM-dd'),
            request.isResidency ? null : format(request.startTime, 'HH:mm:ss'),
            request.isResidency ? null : format(request.endTime, 'HH:mm:ss'),
            request.eventName.trim(),
            request.location.trim(),
            request.isResidency ? null : request.hours.value,
            request.parking,
            request.accomodation,
            request.foodBeverage,
            request.transportation,
            request.equipment,
            request.dressCode.trim(),
            request.equipment ? request.equipmentDetails.trim() : '',
            loggedInUserDetails.user.isEnterprise && !checkIfAlreadyFoundTemplate() ? saveTemplate : false,
            loggedInUserDetails.user.isEnterprise && !checkIfAlreadyFoundTemplate() ? request.templateName.trim() : "",
            request.isResidency,
            request.isResidency ? format(request.endDate, 'yyyy-MM-dd') : null,
            request.isResidency ?
                request.timings.map(timing => ({
                    date: format(timing.date, 'yyyy-MM-dd'),
                    startTime: format(timing.startTime, 'HH:mm:ss'),
                    endTime: format(timing.endTime, 'HH:mm:ss'),
                    hours: timing.hours.value
                })
                )
                :
                null,
            request.currency,
            parseFloat(request.artistPrice),
            (response) => {
                hideLoader();
                document.body.style.overflow = 'unset';
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                history.push('/client/inbox')
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }

    const handleTemplateSectionBtnClick = () => {
        if (showTemplateSection) {
            setSelectedTemplate(null);
        }
        setShowTemplateSection(!showTemplateSection)

    }

    const getTemplates = () => {
        showLoader();
        getEventTemplatesRequest(
            (eventTemplates) => {
                console.log(eventTemplates);
                setTemplates(eventTemplates);
                hideLoader();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const templatesOptions = () => {
        var templatesOptions = [];
        templates.forEach(template => {
            templatesOptions.push({
                label: template.templateName.toUpperCase(),
                value: template.templateId,
                template: template
            })
        })
        return templatesOptions;
    }

    const genreOptions = () => {
        var genresOptions = [];
        genres.forEach(genre => {
            genresOptions.push({
                value: parseInt(genre.genreId),
                label: genre.genreName
            })

        });
        return genresOptions;
    }

    const artistTypeOptions = () => {
        var artistTypesOptions = [];
        artistTypes.forEach(artistType => {
            artistTypesOptions.push({
                value: parseInt(artistType.artistTypeId),
                label: artistType.artistTypeName
            })

        });
        return artistTypesOptions;
    }

    const eventTypeOptions = () => {
        var eventTypesOptions = [];
        eventTypes.forEach(eventType => {
            eventTypesOptions.push({
                value: parseInt(eventType.eventTypeId),
                label: eventType.eventTypeName
            })

        });
        return eventTypesOptions;

    }
    const hoursOptions = () => {
        var hoursOptions = [];
        for (var i = 1; i <= 12; i++) {
            hoursOptions.push({
                value: i,
                label: `${i} hour${i > 1 ? 's' : ''}`
            })
        }
        return hoursOptions;
    }

    const weekDayOptions = () => {
        var weekDayOptions = [];
        for (var i = 0; i < 7; i++) {
            weekDayOptions.push({
                value: i,
                label: `${moment.weekdays(i)}s`
            })
        }
        return weekDayOptions;
    }

    const handleGenreSelectChange = (genreValues) => {
        setRequest({
            ...request,
            genres: genreValues
        })
    }
    const handleArtistTypeSelectChange = (artistTypeValue) => {
        console.log(artistTypeValue);
        setRequest({
            ...request,
            artistType: artistTypeValue
        })
        // if (artistTypeValue) {
        //     if (artistTypeValue.value === listing.artistTypeId) {
        //         setRequest({
        //             ...request,
        //             artistPrice: parseFloat(listing.artistPrice).toFixed(2),
        //             currency: listing.currency,
        //             artistType: artistTypeValue
        //         })
        //     }
        //     else {
        //         setRequest({
        //             ...request,
        //             artistPrice: parseFloat(artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].cost).toFixed(2),
        //             currency: artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].currency,
        //             artistType: artistTypeValue
        //         })
        //     }
        // }
        // else {
        //     setRequest({
        //         ...request,
        //         artistPrice: null,
        //         currency: null,
        //         artistType: artistTypeValue
        //     })
        // }
    }
    const handleEventTypeSelectChange = (eventTypeValue) => {
        setRequest({
            ...request,
            eventType: eventTypeValue
        })
    }
    const handlePriceInputTextChange = (e) => {
        if (e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
        if (e.target.value === '') {
            setRequest({
                ...request,
                artistPrice: e.target.value
            })
        }
    }
    const handleDateChange = (date) => {
        setRequest({
            ...request,
            date: date,
            timings: date ? request.timings.filter(timing => moment(timing.date).isSameOrAfter(moment(date), 'date')) : []
        })
    }
    const handleStartTimeChange = (time) => {
        console.log(time)
        if (request.endTime) {
            if (!isAfter(time, request.endTime)) {
                setRequest({
                    ...request,
                    startTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    startTime: request.endTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                startTime: time
            })
        }
    }
    const handleEndTimeChange = (time) => {
        console.log(time);
        if (request.startTime) {
            if (time == null || isAfter(time, request.startTime)) {
                setRequest({
                    ...request,
                    endTime: time
                })
            }
            else {
                setRequest({
                    ...request,
                    endTime: request.startTime
                })
            }
        }
        else {
            setRequest({
                ...request,
                endTime: time
            })
        }

    }


    const handleInputTextChange = (e) => {
        var updatedRequest = {
            ...request
        }
        updatedRequest[e.target.id] = e.target.value
        setRequest(updatedRequest);
    }

    const handleHoursSelectChange = (hourValue) => {
        setRequest({
            ...request,
            hours: hourValue
        })
    }
    const handleChangeTransporationValue = (value) => {
        setRequest({
            ...request,
            transportation: value
        })
    }
    const handleChangeInCurrencyValue = (value) => {
        setRequest({
            ...request,
            currency: value
        })
    }
    const handleChangeAccomadationValue = (value) => {
        setRequest({
            ...request,
            accomodation: value
        })
    }
    const handleChangeFoodBeverageValue = (value) => {
        setRequest({
            ...request,
            foodBeverage: value
        })
    }
    const handleChangeParkingValue = (value) => {
        setRequest({
            ...request,
            parking: value
        })
    }
    const handleChangeEquipmentValue = (value) => {
        setRequest({
            ...request,
            equipment: value,
            equipmentDetails: value ? request.equipmentDetails : ''
        })
    }
    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + listing.listingToken);
    }

    const handleClickSendEventRequestBtn = () => {
        sendEventRequest();
    }

    const handleTemplatesSelectChange = (template) => {
        setSelectedTemplate(template)
    }

    const handleIsResidencyChange = (isResidency) => {
        setRequest({
            ...request,
            isResidency: isResidency
        })
    }

    const handleEndDateChange = (date) => {
        setRequest({
            ...request,
            endDate: date,
            timings: date ? request.timings.filter(timing => moment(timing.date).isSameOrBefore(moment(date), 'date')) : []
        })
    }

    const handleResidencyCalendarStartTimeChange = (time) => {
        if (residencyCalendar.endTime) {
            if (!isAfter(time, residencyCalendar.endTime)) {
                setResidencyCalendar({
                    ...residencyCalendar,
                    startTime: time
                })
            }
            else {
                setResidencyCalendar({
                    ...residencyCalendar,
                    startTime: residencyCalendar.endTime
                })
            }
        }
        else {
            setResidencyCalendar({
                ...residencyCalendar,
                startTime: time
            })
        }
    }
    const handleResidencyCalendarEndTimeChange = (time) => {
        console.log(time);
        if (residencyCalendar.startTime) {
            if (time == null || isAfter(time, residencyCalendar.startTime)) {
                setResidencyCalendar({
                    ...residencyCalendar,
                    endTime: time
                })
            }
            else {
                setResidencyCalendar({
                    ...residencyCalendar,
                    endTime: residencyCalendar.startTime
                })
            }
        }
        else {
            setResidencyCalendar({
                ...residencyCalendar,
                endTime: time
            })
        }

    }

    const handleResidencyCalendarHoursSelectChange = (hourValue) => {
        setResidencyCalendar({
            ...residencyCalendar,
            hours: hourValue
        })
    }

    const handleResidencyCalendarClick = (date) => {
        console.log(date);
        var timingsFilter = request.timings.filter(timing => format(timing.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'));
        var isUnselect = residencyCalendar.date && format(residencyCalendar.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        setResidencyCalendar({
            ...residencyCalendar,
            date: isUnselect ? null : date,
            startTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].startTime : null,
            endTime: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].endTime : null,
            hours: timingsFilter.length > 0 && !isUnselect ? timingsFilter[0].hours : null,
            isEdit: timingsFilter.length > 0 && !isUnselect ? true : false,
            weekDays: []
        })
    }

    const handleClearTimingsBtn = () => {
        setRequest({
            ...request,
            timings: []
        })
    }

    const handleTimingDeleteBtnPress = (date) => {
        setRequest({
            ...request,
            timings: request.timings.filter(timing => format(timing.date, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd'))
        })
    }

    const handleWeekDaysSelectChange = (weekDaysValue) => {
        setResidencyCalendar({
            ...residencyCalendar,
            weekDays: weekDaysValue
        })
    }

    const addResidencyCalendarFilter = () => {
        var timings = request.timings.filter(timing => (format(timing.date, 'yyyy-MM-dd') !== format(residencyCalendar.date, 'yyyy-MM-dd')));
        timings.push({
            date: residencyCalendar.date,
            startTime: residencyCalendar.startTime,
            endTime: residencyCalendar.endTime,
            hours: residencyCalendar.hours,
        });
        setRequest({
            ...request,
            timings: timings.sort((a, b) => (a.date - b.date))
        })
        setResidencyCalendar({
            date: null,
            startTime: null,
            endTime: null,
            hours: null,
            isEdit: false,
            weekDays: []
        })
    }

    const applyResidencyCalendarWeekDaysFilter = () => {
        var sDate = moment(request.date);
        var eDate = moment(request.endDate);
        var dates = [];

        console.log('sDate', sDate);

        while (sDate.isSameOrBefore(eDate, 'date')) {
            console.log('sDate1', sDate.toDate());
            if (residencyCalendar.weekDays.map(weekDay => weekDay.value).includes(sDate.toDate().getDay())) {
                dates.push(sDate.toDate());
            }
            sDate = sDate.add(1, 'days');
        }
        var timings = request.timings.filter(timing => !dates.map(date => format(date, 'yyyy-MM-dd')).includes(format(timing.date, 'yyyy-MM-dd')));
        console.log('timings', timings);
        dates.forEach(date => {
            timings.push({
                date: date,
                startTime: residencyCalendar.startTime,
                endTime: residencyCalendar.endTime,
                hours: residencyCalendar.hours,
            })
        })
        console.log('timings2', timings);
        setRequest({
            ...request,
            timings: timings.sort((a, b) => (a.date - b.date))
        })
        setResidencyCalendar({
            date: null,
            startTime: null,
            endTime: null,
            hours: null,
            isEdit: false,
            weekDays: []
        })
    }





    const isFormValid = () => {
        if (
            request.eventName.trim() !== "" &&
            (
                (
                    !request.isResidency &&
                    request.startTime &&
                    request.endTime &&
                    request.hours
                ) ||
                (
                    request.isResidency &&
                    request.endDate &&
                    checkValidTimings().isValid
                )
            ) &&

            request.location.trim() !== "" &&
            request.dressCode.trim() !== "" &&
            request.eventType &&
            request.artistType &&
            request.genres.length > 0 &&
            request.date &&
            request.artistPrice &&
            parseFloat(request.artistPrice) > 0 &&
            request.currency &&
            (
                !isPast(request.date) || isToday(request.date)
            ) &&
            (
                (
                    request.equipment &&
                    request.equipmentDetails.trim() !== ''
                ) ||
                !request.equipment
            )
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkMaxDateEnabled = () => {
        console.log(loggedInUserDetails)
        if (
            loggedInUserDetails &&
            loggedInUserDetails.user &&
            loggedInUserDetails.user.paymentCapabilities &&
            loggedInUserDetails.user.paymentCapabilities.includes('DIRECT')
        ) {
            return (false)
        }
        else {
            return (true)
        }
    }

    const isResidencyEnabled = () => {
        console.log(loggedInUserDetails)
        if (
            loggedInUserDetails &&
            loggedInUserDetails.user &&
            loggedInUserDetails.user.isEnterprise &&
            loggedInUserDetails.user.paymentCapabilities &&
            loggedInUserDetails.user.paymentCapabilities.includes('DIRECT')
        ) {
            return (true)
        }
        else {
            return (false)
        }
    }

    useEffect(() => {
        console.log(listing);
        if (loggedInUserDetails.user.isEnterprise) {
            getTemplates();
        }
        setEventTypes(listing.eventTypes);
        setArtistTypes(listing.artistTypes);
        setGenres(listing.genres);
    }, [])

    const applyTemplate = () => {

        console.log(selectedTemplate.template);
        var artistTypeValue = selectedTemplate.template.artistTypeId ?
            (
                artistTypeOptions().filter(artistTypeOption => artistTypeOption.value === selectedTemplate.template.artistTypeId).length > 0
                    ?
                    artistTypeOptions().filter(artistTypeOption => artistTypeOption.value === selectedTemplate.template.artistTypeId)[0]
                    :
                    null
            )
            :
            null;


        setRequest({
            ...request,
            eventName: selectedTemplate.template.eventName ? selectedTemplate.template.eventName : request.eventName,
            hours: selectedTemplate.template.hours ? hoursOptions().filter(hoursOption => hoursOption.value === selectedTemplate.template.hours)[0] : request.hours,
            dressCode: selectedTemplate.template.dressCode ? selectedTemplate.template.dressCode : request.dressCode,
            comment: selectedTemplate.template.comment ? selectedTemplate.template.comment : request.comment,
            songRequests: selectedTemplate.template.songRequests ? selectedTemplate.template.songRequests : request.songRequests,
            location: selectedTemplate.template.location ? selectedTemplate.template.location : request.location,
            equipmentDetails: selectedTemplate.template.equipmentDetails ? selectedTemplate.template.equipmentDetails : (selectedTemplate.template.equipment ? request.equipmentDetails : ""),
            parking: selectedTemplate.template.parking ? true : false,
            equipment: selectedTemplate.template.equipment ? true : false,
            accomodation: selectedTemplate.template.accomodation ? true : false,
            foodBeverage: selectedTemplate.template.foodBeverage ? true : false,
            transportation: selectedTemplate.template.transportation ? true : false,
            artistType: artistTypeValue,
            // artistPrice: artistTypeValue ?
            //     (
            //         artistTypeValue.value === listing.artistTypeId ?
            //             parseFloat(listing.artistPrice).toFixed(2)
            //             :
            //             parseFloat(artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].cost).toFixed(2)
            //     )
            //     :
            //     null,
            // currency: artistTypeValue ?
            //     (
            //         artistTypeValue.value === listing.artistTypeId ?
            //             listing.currency
            //             :
            //             artistTypes.filter(artistType => (parseInt(artistType.artistTypeId) === artistTypeValue.value))[0].currency
            //     ) :
            //     null,
            eventType: selectedTemplate.template.eventTypeId ?
                (
                    eventTypeOptions().filter(eventTypeOption => eventTypeOption.value === selectedTemplate.template.eventTypeId).length > 0
                        ?
                        eventTypeOptions().filter(eventTypeOption => eventTypeOption.value === selectedTemplate.template.eventTypeId)[0]
                        : null
                )
                :
                null,
            genres: genreOptions().filter(genreOption => selectedTemplate.template.genres.map(genre => genre.genreId).includes(genreOption.value))
        })

        setSelectedTemplate(null);
        setShowTemplateSection(false)
    }

    const checkIfAlreadyFoundTemplate = () => {
        var result = false;
        console.log("test")
        templates.forEach(temp => {
            console.log(temp)
            console.log(request)
            if (
                temp.eventName === (request.eventName.trim() === '' ? null : request.eventName.trim()) &&
                temp.location === (request.location.trim() === '' ? null : request.location.trim()) &&
                temp.hours === (request.hours !== null ? request.hours.value : null) &&
                (temp.transportation ? true : false) === request.transportation &&
                (temp.accomodation ? true : false) === request.accomodation &&
                (temp.foodBeverage ? true : false) === request.foodBeverage &&
                (temp.equipment ? true : false) === request.equipment &&
                temp.equipmentDetails === (request.equipmentDetails.trim() === '' ? null : request.equipmentDetails.trim()) &&
                (temp.parking ? true : false) === request.parking &&
                temp.songRequests === (request.songRequests.trim() === '' ? null : request.songRequests.trim()) &&
                temp.comment === (request.comment.trim() === '' ? null : request.comment.trim()) &&
                temp.dressCode === (request.dressCode.trim() === '' ? null : request.dressCode.trim()) &&
                temp.artistTypeId === (request.artistType !== null ? request.artistType.value : null) &&
                temp.eventTypeId === (request.eventType !== null ? request.eventType.value : null) &&
                (temp.genres.map(genre => genre.genreId).sort((a, b) => (a - b)).join(',')) === (request.genres.map(genre => (genre.value)).sort((a, b) => (a - b)).join(','))
            ) {
                result = true;
            }
        });

        console.log(result)
        return result;

    }

    const isResidencyCalendarFilterValid = () => {
        if (
            (
                residencyCalendar.date ||
                residencyCalendar.weekDays.length > 0
            ) &&
            residencyCalendar.startTime &&
            residencyCalendar.endTime &&
            residencyCalendar.hours
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkValidTimings = () => {
        var isValid = true;
        var isStartDate = false;
        var isEndDate = false;
        var reasons = [];
        request.timings.forEach(timing => {
            if (moment(timing.date).isBefore(moment(request.date), 'date') || moment(timing.date).isAfter(moment(request.endDate), 'date')) {
                isValid = false;
                reasons.push('Invalid dates in events')
            }
            if (moment(timing.date).isSame(moment(request.date), 'date')) {
                isStartDate = true
            }
            if (moment(timing.date).isSame(moment(request.endDate), 'date')) {
                isEndDate = true
            }

        })

        if (!(isStartDate && isEndDate)) {
            isValid = false;
            reasons.push('Start & end dates events are mandatory')
        }

        return ({
            isValid,
            reasons
        })


    }

    const isTemplateNameDuplicate = () => {
        if (
            templates.map(temp => temp.templateName ? temp.templateName.toLowerCase() : temp.templateName).includes(request.templateName.toLowerCase())
        ) {
            return true
        }
        else {
            return false
        }
    }

    const isSaveTemplateValid = () => {
        var result = true;
        if (loggedInUserDetails.user.isEnterprise && !checkIfAlreadyFoundTemplate()) {
            if (saveTemplate) {
                if (request.templateName.trim() === "" || isTemplateNameDuplicate()) {
                    result = false
                }
            }
        }
        return result;
    }

    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='client-event-request-popup-modal'>
                <div className='client-event-request-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='client-event-request-popup-modal__content--top'>
                        <div className='client-event-request-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='client-event-request-popup-top__title'>

                            <div className="client-details">
                                {loggedInUserDetails.user.companyFullName}
                            </div>
                            -
                            <div className="listing-details" onClick={handleClickOnListing} >
                                {listing.listingName}
                                {
                                    listing.isInsured ?
                                        (
                                            <div className='insured'>
                                                <KalabIconWithToolTip
                                                    toolTipText="Insured"
                                                    placement="right"
                                                    iconName='verified_user_outlined'
                                                    iconSize={16}
                                                    noBorder={true}
                                                    color={Colors.lightGrey}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }
                            </div>


                        </div>
                    </div>
                    <div className='client-event-request-popup-modal__content--main'>
                        <div className="main-detail">
                            {
                                isResidencyEnabled() ?
                                    (
                                        <div className="main-detail--residency-row" >
                                            <div className="main-detail--residency-row__element">
                                                <div style={{ textAlign: 'center' }}>
                                                    <KalabCheckBox
                                                        label={'SINGLE BOOKING'}
                                                        labelSize={width < 500 ? Math.max(width / 40, 10) : 15}
                                                        checked={!request.isResidency}
                                                        onChange={() => { handleIsResidencyChange(false) }}
                                                        labelPlacement={width < 500 ? 'bottom' : 'end'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="main-detail--residency-row__element">
                                                <div style={{ textAlign: 'center' }}>
                                                    <KalabCheckBox
                                                        label={'RESIDENCY'}
                                                        labelSize={width < 500 ? Math.max(width / 40, 10) : 15}
                                                        checked={request.isResidency}
                                                        onChange={() => { handleIsResidencyChange(true) }}
                                                        labelPlacement={width < 500 ? 'bottom' : 'end'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }


                            {
                                loggedInUserDetails.user.isEnterprise && templates.length > 0 ?
                                    (
                                        <div className='main-detail__templates-section'>
                                            <div className='main-detail__templates-section--heading'>
                                                <div className='text'>Use Templates</div>
                                                <KalabIconButton
                                                    isLightTheme
                                                    iconName={showTemplateSection ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}
                                                    noBorder
                                                    noDisabled
                                                    onClick={handleTemplateSectionBtnClick}
                                                />
                                            </div>
                                            <div className={showTemplateSection ? 'template-show-animate' : 'template-hide-animate'}>
                                                {
                                                    showTemplateSection ?
                                                        (
                                                            <div className="main-detail__templates-section--row">

                                                                <div className="main-detail__templates-section--row__element">
                                                                    <div style={{ margin: 'auto' }}>
                                                                        <KalabDropDown
                                                                            placeholder="Select a Template"
                                                                            isDisabled={false}
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isRtl={false}
                                                                            isSearchable={true}
                                                                            name="template"
                                                                            onChange={handleTemplatesSelectChange}
                                                                            options={templatesOptions()}
                                                                            isLightTheme={true}
                                                                            value={selectedTemplate}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="main-detail__templates-section--row__btn">
                                                                    <KalabSmallButton
                                                                        text={'Apply'}
                                                                        disabled={selectedTemplate === null}
                                                                        isLightTheme
                                                                        onClick={applyTemplate}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            null
                                                        )
                                                }
                                            </div>

                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                            <div className="main-detail--row">
                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="eventName"
                                        placeholder="Event Name"
                                        onChange={handleInputTextChange}
                                        value={request.eventName}
                                        isLightTheme
                                    />
                                </div>
                                <div className="main-detail--row__element" >
                                    <KalabTextField
                                        type="text"
                                        id="location"
                                        placeholder="Location"
                                        onChange={handleInputTextChange}
                                        value={request.location}
                                        isLightTheme
                                    />
                                </div>


                            </div>
                            {
                                request.isResidency ?
                                    (
                                        <div className="main-detail--row">

                                            <div className="main-detail--row__element">
                                                <KalabDatePickerDropDownNormal
                                                    value={request.date}
                                                    onChange={handleDateChange}
                                                    isLightTheme
                                                    label='Start Date'
                                                    helperText={'Please insert the date of the first event'}
                                                    maxDate={restrictionForResidency(request.date, request.endDate).startDateMax}
                                                />
                                            </div>
                                            <div className="main-detail--row__element" >
                                                <KalabDatePickerDropDownNormal
                                                    value={request.endDate}
                                                    onChange={handleEndDateChange}
                                                    isLightTheme
                                                    maxDate={restrictionForResidency(request.date, request.endDate).endDateMax}
                                                    minDate={restrictionForResidency(request.date, request.endDate).endDateMin}
                                                    label='End Date'
                                                    helperText={'Please insert the date of the last event'}
                                                />
                                            </div>

                                        </div>
                                    )
                                    :
                                    (
                                        <div className="main-detail--row">

                                            <div className="main-detail--row__element">
                                                <KalabDatePickerDropDownNormal
                                                    value={request.date}
                                                    onChange={handleDateChange}
                                                    isLightTheme
                                                    enableMaxDate={checkMaxDateEnabled()}
                                                />
                                            </div>
                                            <div className="main-detail--row__element" >
                                                <KalabDropDown
                                                    placeholder="Performance Duration"
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    name="hours"
                                                    onChange={handleHoursSelectChange}
                                                    options={hoursOptions()}
                                                    isLightTheme={true}
                                                    value={request.hours}
                                                />
                                            </div>

                                        </div>
                                    )
                            }
                            {
                                request.isResidency ?
                                    (
                                        request.date && request.endDate ?
                                            (
                                                <div className="main-detail--residency">

                                                    <div className='edit-section'>
                                                        <div className='edit-section__row'>
                                                            <div className='edit-section__row--element'>
                                                                {
                                                                    residencyCalendar.date ?
                                                                        (
                                                                            <div className='date'>{getFormattedDateWithoutYear(residencyCalendar.date)}</div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <KalabDropDown
                                                                                placeholder="Week Days"
                                                                                isDisabled={false}
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isRtl={false}
                                                                                isSearchable={true}
                                                                                isMulti={true}
                                                                                name="weekDays"
                                                                                onChange={handleWeekDaysSelectChange}
                                                                                options={weekDayOptions()}
                                                                                isLightTheme={true}
                                                                                value={residencyCalendar.weekDays}
                                                                            />

                                                                        )
                                                                }

                                                            </div>
                                                            <div className='edit-section__row--element'>
                                                                <KalabTimeDropDown
                                                                    value={residencyCalendar.startTime}
                                                                    onChange={handleResidencyCalendarStartTimeChange}
                                                                    isLightTheme
                                                                    placeholder='Arrival'
                                                                    endTime={residencyCalendar.endTime}
                                                                />
                                                            </div>
                                                            <div className='edit-section__row--element'>
                                                                <KalabTimeDropDown
                                                                    value={residencyCalendar.endTime}
                                                                    onChange={handleResidencyCalendarEndTimeChange}
                                                                    isLightTheme
                                                                    placeholder='Departure'
                                                                    beginTime={residencyCalendar.startTime}
                                                                />
                                                            </div>
                                                            <div className='edit-section__row--element'>
                                                                <KalabDropDown
                                                                    placeholder="Duration"
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isRtl={false}
                                                                    isSearchable={true}
                                                                    isMulti={false}
                                                                    name="hours"
                                                                    onChange={handleResidencyCalendarHoursSelectChange}
                                                                    options={hoursOptions()}
                                                                    isLightTheme={true}
                                                                    value={residencyCalendar.hours}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='edit-section__row'>
                                                            <div className='edit-section__row--element'>
                                                                {
                                                                    residencyCalendar.date ?
                                                                        (
                                                                            <KalabSmallButton
                                                                                text={residencyCalendar.isEdit ? 'Update' : 'Add'}
                                                                                disabled={!isResidencyCalendarFilterValid()}
                                                                                isLightTheme
                                                                                onClick={addResidencyCalendarFilter}
                                                                            />
                                                                        )
                                                                        :
                                                                        (
                                                                            <KalabSmallButton
                                                                                text={'Apply'}
                                                                                disabled={!isResidencyCalendarFilterValid()}
                                                                                isLightTheme
                                                                                onClick={applyResidencyCalendarWeekDaysFilter}
                                                                            />
                                                                        )
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='calendar-section'>
                                                        <KalabResidencyCalendar
                                                            startDate={request.date}
                                                            endDate={request.endDate}
                                                            timings={request.timings}
                                                            selectedDate={residencyCalendar.date}
                                                            onChange={handleResidencyCalendarClick}
                                                            isSmall={width < 600 ? true : false}
                                                        />
                                                    </div>

                                                    <div className='timings-section'>
                                                        <div className='timings-section__title' >
                                                            <div className='timings-section__title--text' >
                                                                Events ({request.timings.length})
                                                                {
                                                                    checkValidTimings().isValid ?
                                                                        (
                                                                            null
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className='error-icon'>
                                                                                <KalabIconWithToolTip
                                                                                    toolTipText={checkValidTimings().reasons.join('. ')}
                                                                                    placement='top'
                                                                                    iconName={'fas fa-exclamation'}
                                                                                    iconSize={12}
                                                                                    isFAIcon={true}
                                                                                    color={Colors.darkRed}
                                                                                    borderColor={Colors.darkRed}
                                                                                />
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                            <div className='timings-section__title--clear' >
                                                                {
                                                                    request.timings.length > 0 ?
                                                                        (
                                                                            <KalabSmallButton
                                                                                text={'Clear All'}
                                                                                noBorder
                                                                                isLightTheme
                                                                                onClick={handleClearTimingsBtn}
                                                                            />
                                                                        ) :
                                                                        (
                                                                            null
                                                                        )
                                                                }

                                                            </div>
                                                        </div>
                                                        {
                                                            request.timings.length > 0 ?
                                                                (
                                                                    <div className='timings-section__timings'>
                                                                        {
                                                                            request.timings.map((timing, index) => (
                                                                                <div className={`timings-section__timings--single ${index % 2 === 0 ? 'left-border' : ''} `}>
                                                                                    <div className='date'>
                                                                                        {getFormattedDateWithoutYear(timing.date)}
                                                                                    </div>
                                                                                    <div className='time'>
                                                                                        {getFormattedTime(format(timing.startTime, 'HH:mm:ss'))} - {getFormattedTime(format(timing.endTime, 'HH:mm:ss'))}
                                                                                        <span className='hours'>
                                                                                            ({timing.hours.label})
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='delete'>
                                                                                        <KalabIconButton
                                                                                            onClick={() => { handleTimingDeleteBtnPress(timing.date) }}
                                                                                            iconName={'fas fa-times'}
                                                                                            isFAIcon
                                                                                            iconSize={12}
                                                                                            isLightTheme
                                                                                            noBorder
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div className='timings-section__no-timings'>
                                                                        No events added
                                                                    </div>
                                                                )
                                                        }


                                                    </div>



                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                    )
                                    :
                                    (
                                        <div className="main-detail--row">


                                            <div className="main-detail--row__element">
                                                {/* <KalabTimePickerDropDown
        value={request.startTime}
        onChange={handleStartTimeChange}
        isLightTheme
        label='Artist Arrival'
    /> */}
                                                <KalabTimeDropDown
                                                    value={request.startTime}
                                                    onChange={handleStartTimeChange}
                                                    isLightTheme
                                                    placeholder='Artist Arrival'
                                                    endTime={request.endTime}
                                                />
                                            </div>
                                            <div className="main-detail--row__element" >
                                                {/* <KalabTimePickerDropDown
        value={request.endTime}
        onChange={handleEndTimeChange}
        isLightTheme
        label='Artist Departure'
    /> */}

                                                <KalabTimeDropDown
                                                    value={request.endTime}
                                                    onChange={handleEndTimeChange}
                                                    isLightTheme
                                                    placeholder='Artist Departure'
                                                    beginTime={request.startTime}
                                                />
                                            </div>

                                        </div>
                                    )
                            }


                            <div className="main-detail--row">

                                <div className="main-detail--row__element" >
                                    <KalabDropDown
                                        placeholder="Artist Type"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="artistType"
                                        onChange={handleArtistTypeSelectChange}
                                        options={artistTypeOptions()}
                                        isLightTheme={true}
                                        value={request.artistType}
                                    />
                                </div>
                                <div className="main-detail--row__element">
                                    <KalabDropDown
                                        placeholder="Event Type"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="artistType"
                                        onChange={handleEventTypeSelectChange}
                                        options={eventTypeOptions()}
                                        isLightTheme={true}
                                        value={request.eventType}
                                    />
                                </div>


                            </div>
                            <div className="main-detail--row">
                                <div className="main-detail--row__element" >
                                    <KalabDropDown
                                        placeholder="Genres"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        isMulti={true}
                                        name="genres"
                                        onChange={handleGenreSelectChange}
                                        options={genreOptions()}
                                        isLightTheme={true}
                                        value={request.genres}
                                    />
                                </div>
                                <div className="main-detail--row__element" >
                                    <KalabTextField
                                        type="text"
                                        id="dressCode"
                                        placeholder="Dress Code"
                                        onChange={handleInputTextChange}
                                        value={request.dressCode}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>

                            </div>
                            <div className="main-detail--row" >
                                <div className="main-detail--row__element" style={{ textAlign: 'left' }}>
                                    <div className="main-detail--row__title-text">I am willing to provide the following...</div>
                                </div>
                                <div className="main-detail--row__element">
                                </div>
                            </div>
                            <div className="main-detail--row">
                                <div className="main-detail--row__element" style={{ display: 'flex', flexDirection: 'row' }} >
                                    <div className="main-detail--row__element--icon">
                                        <KalabIconCheckBox
                                            iconName="directions_car"
                                            iconText={width <= 500 ? "" : "Transportation"}
                                            value={request.transportation}
                                            onChange={handleChangeTransporationValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">
                                        <KalabIconCheckBox
                                            iconName="local_parking_rounded"
                                            iconText={width <= 500 ? "" : "Parking"}
                                            value={request.parking}
                                            onChange={handleChangeParkingValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="fastfood_rounded"
                                            iconText={width <= 500 ? "" : "Food & Beverage"}
                                            value={request.foodBeverage}
                                            onChange={handleChangeFoodBeverageValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="mic_rounded"
                                            iconText={width <= 500 ? "" : "Equipment"}
                                            value={request.equipment}
                                            onChange={handleChangeEquipmentValue}
                                        />

                                    </div>
                                    <div className="main-detail--row__element--icon">

                                        <KalabIconCheckBox
                                            iconName="hotel"
                                            iconText={width <= 500 ? "" : "Accomodation"}
                                            value={request.accomodation}
                                            onChange={handleChangeAccomadationValue}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="main-detail--row" >
                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="songRequests"
                                        placeholder="Song Requests"
                                        onChange={handleInputTextChange}
                                        value={request.songRequests}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>
                                {
                                    request.equipment ?
                                        (
                                            <div className="main-detail--row__element">
                                                <KalabTextField
                                                    type="text"
                                                    id="equipmentDetails"
                                                    placeholder="Venue Equipment"
                                                    onChange={handleInputTextChange}
                                                    value={request.equipmentDetails}
                                                    isLightTheme
                                                    multiline
                                                    maxLength={500}
                                                    rowsMax={6}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }

                                <div className="main-detail--row__element">
                                    <KalabTextField
                                        type="text"
                                        id="comment"
                                        placeholder="Comment"
                                        onChange={handleInputTextChange}
                                        value={request.comment}
                                        isLightTheme
                                        multiline
                                        maxLength={500}
                                        rowsMax={6}
                                    />
                                </div>


                            </div>
                            <div className="main-detail--row" style={{ alignItems: 'center' }} >
                                <div className="main-detail--row__element">
                                    <div className="price-text-field">
                                        <KalabTextField
                                            type="number"
                                            min='0.00'
                                            step='0.01'
                                            isLightTheme
                                            id="artistPrice"
                                            placeholder={request.isResidency ? "Proposed Price (Total Residency)" : 'Proposed Price'}
                                            value={request.artistPrice}
                                            onChange={handlePriceInputTextChange}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <KalabCurrencyDropDown
                                                        currency={request.currency}
                                                        setCurrency={handleChangeInCurrencyValue}
                                                        isLightTheme
                                                    />
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                    <div className="price-text-field">
                                        <KalabTextField
                                            type="text"
                                            id="artistPrice"
                                            placeholder="Service Fee"
                                            disabled
                                            value={`${getLabelFromValue(request.currency)} ${(parseFloat(request.artistPrice ? request.artistPrice : 0) * getServiceFeePercentage() / 100).toFixed(2)}`}
                                            isLightTheme
                                            multiline
                                            maxLength={500}
                                            rowsMax={6}
                                        />
                                    </div>

                                </div>
                                <div className="main-detail--row__element">
                                    <div className="total-price">
                                        {getLabelFromValue(request.currency)} {(parseFloat(request.artistPrice ? request.artistPrice : 0) * (100 + getServiceFeePercentage()) / 100).toFixed(2)}
                                    </div>
                                </div>


                            </div>
                            {
                                !checkIfAlreadyFoundTemplate() && loggedInUserDetails.user.isEnterprise ?
                                    (
                                        <div className="main-detail--template-save" >
                                            <div className='check-box'>
                                                <KalabCheckBox
                                                    checked={saveTemplate}
                                                    onChange={() => {
                                                        setSaveTemplate(!saveTemplate);
                                                        setRequest({
                                                            ...request,
                                                            templateName: ""
                                                        })

                                                    }}
                                                    label={"Do you want to save this as a template?"}
                                                />
                                            </div>
                                            <div className={saveTemplate ? 'show-template-name' : 'hide-template-name'}>

                                                {
                                                    saveTemplate ?
                                                        (
                                                            <div className="template-name">
                                                                <div style={{ margin: 'auto' }}>
                                                                    <KalabTextField
                                                                        type="text"
                                                                        id="templateName"
                                                                        placeholder="Template Name"
                                                                        onChange={handleInputTextChange}
                                                                        value={request.templateName}
                                                                        isLightTheme
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <KalabIconWithToolTip
                                                                                    toolTipText={request.templateName.trim() === "" ? "Enter a unique template name" : isTemplateNameDuplicate() ? 'Already in Use' : "Success"}
                                                                                    placement='top-start'
                                                                                    iconName={request.templateName.trim() === "" ? 'fas fa-question' : isTemplateNameDuplicate() ? 'fas fa-exclamation' : 'fas fa-check'}
                                                                                    iconSize={12}
                                                                                    isFAIcon={true}
                                                                                    color={request.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                                                    borderColor={request.templateName.trim() === "" ? Colors.lightGrey : isTemplateNameDuplicate() ? Colors.darkRed : Colors.darkGreen}
                                                                                />
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            null
                                                        )
                                                }
                                            </div>

                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 30,
                                    marginBottom: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <KalabMainButton
                                    disabled={!(isFormValid() && isSaveTemplateValid())}
                                    onClick={handleClickSendEventRequestBtn}
                                    text={'Send Request'}
                                    iconName={'send_rounded'} />
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
