import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';
import { useHistory } from "react-router-dom";
import KalabIconButton from '../kalabButtons/KalabIconButton';
import Colors from '../../../constants/Colors';
import { getLabelFromValue } from '../../../utils/Currencies';
import { openInNewTab } from '../../../utils/common';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';

const useStyles = makeStyles({
    root: {
        padding: 10,
        transition: '0.3s',
        borderRadius: 5,
        position: 'relative',
        height: '100%',
        '&:hover': {
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            cursor: 'pointer',
            '& .delete-button': {
                visibility: 'visible'
            },
            '& .detail': {
                visibility: 'visible'
            }
        },
        '& .delete-button': {
            position: 'absolute',
            top: 0,
            right: 0,
            visibility: 'hidden'
        },
        '& .detail': {
            visibility: 'hidden',

            '& .genres': {
                textAlign: 'center',
                textTransform: 'capitalise',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 12,
                fontWeight: '300',
            },
            '& .price': {
                marginTop: 5,
                textAlign: 'center',
                textTransform: 'capitalise',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 13,
                fontWeight: '400',
                color: Colors.primary
            }
        }

    },
    image: {
        width: '100%',
        aspectRatio: 1 / 1,
        objectFit: 'cover',
        borderRadius: '50%',
        borderWidth: 2,
        padding: 2,
        borderColor: Colors.primary,
        '&:hover': {
            borderWidth: 3,
        }
    },
    name: {
        marginTop: 5,
        display: 'block',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 15,
        fontWeight: '300',

        '& .insured': {
            display: 'inline-block'
        }
    }

})

export default function KalabWishlistAdvancedListingCard({ listing, removeFn }) {

    const handleClickOnCard = () => {
        openInNewTab(window.location.origin + '/listing/' + listing.listingToken);
    }
    const handleRemoveBtnClick = () => {
        console.log('test')
        removeFn(listing.listingId)
    }
    const classes = useStyles();
    return (
        <div className={classes.root} >
            <div style={{ width: '50%', margin: 'auto' }}>
                <div className="aspectratio ar1-1">
                    <img
                        className={classes.image}
                        src={listing.listingImages[0].listingImageUrl}
                        alt='listing-pic'
                        onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                        onClick={handleClickOnCard}
                    />
                </div>
            </div>
            <span className={classes.name}>
                {listing.listingName}
                {
                    listing.isInsured ?
                        (
                            <div className='insured'>
                                <KalabIconWithToolTip
                                    toolTipText="Insured"
                                    placement="right-start"
                                    iconName='verified_user_outlined'
                                    iconSize={13}
                                    noBorder={true}
                                    color={Colors.lightGrey}
                                />
                            </div>
                        )
                        :
                        (
                            null
                        )
                }
            </span>
            <div className='detail'>
                <div className='genres'>{listing.genres.map(genre => genre.genreName).join(', ')}</div>
                <div className='price'>{getLabelFromValue(listing.artistTypes[0].currency)} {parseFloat(listing.artistTypes[0].cost).toFixed(2)}</div>
            </div>
            <div className='delete-button'>
                <KalabIconButton
                    iconName={'close'}
                    isLightTheme
                    noBorder
                    onClick={handleRemoveBtnClick}
                />
            </div>
        </div>
    )
}