import React, { useEffect, useContext, useState } from 'react';
import './RegistrationPage.scss';
import MainHeader from '../header/MainHeader';
import { useParams } from 'react-router-dom';
import { getApplicationFromTokenRequest } from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import ProgressBar from '../elements/ProgressBar';
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import ArtistBasicInfo from '../forms/ArtistBasicInfo';
import PersonalDetails from '../forms/PersonalDetails';
import Description from '../forms/Description';
import MusicImages from '../forms/MusicImages';
import PreviewSubmit from '../forms/PreviewSubmit'
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';

function RegistrationPage(props) {
    const { token } = useParams();
    const { height, width } = useWindowDimensions();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [activePage, setActivePage] = useState(1);
    const [application, setApplication] = useState(null);
    const [artist, setArtist] = useState({
        personalDetails: null,
        artistBasicInfo: null,
        description: null,
        musicImages: null
    })
    const [completedStatus, setCompletedStatus] = useState({
        personalDetails: false,
        artistBasicInfo: false,
        description: false,
        musicImages: false
    })
    const notify = useNotification();
    const getApplication = () => {
        showLoader();
        getApplicationFromTokenRequest(token,
            (application) => {
                hideLoader();
                setApplication(application)
                console.log(application);
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })

    }

    const handleLinkButtonClick = (pageNo) => {
        setActivePage(pageNo);

    }
    const setPersonalDetails = (personalDetails, completed = false) => {
        setArtist({
            ...artist,
            personalDetails
        });
        setCompletedStatus({
            ...completedStatus,
            personalDetails: completed
        })
    }
    const setArtistBasicInfoDetails = (artistBasicInfo, completed = false) => {
        setArtist({
            ...artist,
            artistBasicInfo
        });
        setCompletedStatus({
            ...completedStatus,
            artistBasicInfo: completed
        })
    }
    const setDescription = (description, completed = false) => {
        setArtist({
            ...artist,
            description
        });
        setCompletedStatus({
            ...completedStatus,
            description: completed
        })
    }
    const setMusicImages = (musicImages, completed = false, next = false) => {
        console.log('test')
        setArtist({
            ...artist,
            musicImages
        });
        setCompletedStatus({
            ...completedStatus,
            musicImages: completed
        })
        if (next) {
            setActivePage(5)
        }

    }
    const getCompletedPercentage = () => {
        var resultValue = 0;
        if (artist.personalDetails && completedStatus.personalDetails) {
            resultValue = 25
            if (artist.artistBasicInfo && completedStatus.artistBasicInfo) {
                resultValue = 50
                if (artist.description && completedStatus.description) {
                    resultValue = 75
                    if (artist.musicImages && completedStatus.musicImages) {
                        resultValue = 100
                    }
                }
            }
        }
        return (resultValue);
    }

    const getSelectedHeading = () => {
        if (activePage === 1) {
            return (
                {
                    title: 'Personal Details',
                    isCompleted: completedStatus.personalDetails
                }
            )
        }
        else if (activePage === 2) {
            return (
                {
                    title: 'Artist Basic Info',
                    isCompleted: completedStatus.artistBasicInfo
                }
            )
        }
        else if (activePage === 3) {
            return (
                {
                    title: 'Description',
                    isCompleted: completedStatus.description
                }
            )
        }
        else if (activePage === 4) {
            return (
                {
                    title: 'Music & Images',
                    isCompleted: completedStatus.musicImages
                }
            )
        }
        else {
            return (
                {
                    title: 'Preview',
                    isCompleted: true
                }
            )
        }
    }


    const selectedForm = () => {
        switch (activePage) {
            case 1: return (<PersonalDetails application={application} personalDetails={artist.personalDetails} setPersonalDetails={setPersonalDetails} completed={getCompletedPercentage()} />)
            case 2: return (<ArtistBasicInfo application={application} artistBasicInfoDetails={artist.artistBasicInfo} setArtistBasicInfoDetails={setArtistBasicInfoDetails} completed={getCompletedPercentage()} />)
            case 3: return (<Description description={artist.description} setDescription={setDescription} completed={getCompletedPercentage()} />)
            case 4: return (<MusicImages application={application} musicImages={artist.musicImages} setMusicImages={setMusicImages} completed={getCompletedPercentage()} artist={artist}  history={props.history}/>)
            case 5: return (<PreviewSubmit artist={artist} completed={getCompletedPercentage()} history={props.history} />)
            default: return (<PersonalDetails application={application} personalDetails={artist.personalDetails} setPersonalDetails={setPersonalDetails} completed={getCompletedPercentage()} />)
        }
    }

    useEffect(() => {
        if (token && token !== 'temporary') {
            getApplication();
        }

    }, [token])
    return (
        <div className='dark-background-registration'>
            <MainHeader onlyLogo />
            <div className="navigation-bar">
                <div className="progress-bar">
                    <ProgressBar bgcolor={Colors.primary} completed={getCompletedPercentage()} />
                </div>
                {
                    width > 749
                        ?
                        (
                            <div className="nav-button-group">
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 1 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(1); }}>
                                        <span className='text'>
                                            Personal Details
                                        </span>

                                        {artist.personalDetails && !completedStatus.personalDetails && activePage !== 1 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                artist.personalDetails && completedStatus.personalDetails
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 2 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(2); }}>
                                        <span className='text'>
                                            Artist Basic Info
                                        </span>

                                        {artist.artistBasicInfo && !completedStatus.artistBasicInfo && activePage !== 2 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                artist.artistBasicInfo && completedStatus.artistBasicInfo
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 3 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(3); }}>
                                        <span className='text'>
                                            Description
                                        </span>
                                        {artist.description && !completedStatus.description && activePage !== 3 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                artist.description && completedStatus.description
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 4 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(4); }}>
                                        <span className='text'>
                                            Music &amp; Images
                                        </span>
                                        {artist.musicImages && !completedStatus.musicImages && activePage !== 4 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                artist.musicImages && completedStatus.musicImages
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>

                            </div>
                        )
                        :
                        (
                            <div className='navigation-bar-small'>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_left_rounded'
                                        disabled={activePage === 1}
                                        onClick={() => {
                                            setActivePage(activePage - 1);
                                        }}
                                    />
                                </div>
                                <div className='title'>

                                    <span className='text'>{getSelectedHeading().title}</span>
                                    {getSelectedHeading().isCompleted ?
                                        (
                                            <div style={{ marginLeft: 5 }}>
                                                <KalabIconWithToolTip
                                                    toolTipText="Completed"
                                                    placement='top'
                                                    iconName={'fas fa-check'}
                                                    iconSize={12}
                                                    isFAIcon={true}
                                                    color={Colors.darkGreen}
                                                    borderColor={Colors.darkGreen}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                    }

                                </div>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_right_rounded'
                                        disabled={activePage >= 4}
                                        onClick={() => {
                                            setActivePage(activePage + 1);
                                        }}
                                    />
                                </div>

                            </div>
                        )
                }

            </div>

            {application || token === 'temporary' ?  selectedForm() : null}
        </div>
    )
}

export default RegistrationPage
