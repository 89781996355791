module.exports = {
    clientCancellation : [
        {
            id : 1,
            text : 'The date of event has expired',
            isCommentRequired : false
        },
        {
            id : 2,
            text : 'The artist price is out of budget',
            isCommentRequired : false
        },
        {
            id : 3,
            text : 'The negotiation is taking too long',
            isCommentRequired : false
        },
        {
            id : 4,
            text : 'The artist is unwilling to agree on the terms',
            isCommentRequired : false
        },
        {
            id : 5,
            text : 'Other',
            isCommentRequired : true
        }
    ],
    artistRejection : [
        {
            id : 1,
            text : 'Date of event expired',
            isCommentRequired : false
        },
        {
            id : 2,
            text : 'Unavailable on the specified date',
            isCommentRequired : false
        },
        {
            id : 3,
            text : 'Too far from the event location ',
            isCommentRequired : false
        },
        {
            id : 4,
            text : 'Artist price not within client budget ',
            isCommentRequired : false
        },
        {
            id : 5,
            text : 'Other',
            isCommentRequired : true
        }
    ]

}