module.exports = {
    options: [
        {
            label: 'Morning (5AM - 12PM)',
            value: 'morning',
            id : 1,
            startTime : '0500',
            endTime: '1200'
        },
        {
            label: 'Afternoon (12PM - 5PM)',
            value: 'afternoon',
            id : 2,
            startTime : '1200',
            endTime: '1700'
        },
        {
            label: 'Evening (5PM - 9PM)',
            value: 'evening',
            id : 3,
            startTime : '1700',
            endTime: '2100'
        },
        {
            label: 'Night (9PM - 5AM)',
            value: 'night',
            id : 4,
            startTime : '2100',
            endTime: '0500'
        }
    ]
}