import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';
import { dateToShowInMessages } from '../../../utils/Date';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';


import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreVert } from '@material-ui/icons';
import { Icon } from '@material-ui/core';


const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        width: '100%',
        aspectRatio: '4 / 3',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 300ms, box-shadow 300ms',
        borderRadius: 5,
        '@media (hover: hover)': {
            '&:hover': {
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                
                transform: 'scale(1.05)'
            }
        },
        '& .template-card': {
            position: 'relative',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',

            '& .top': {
                flex: 4,
                backgroundColor: Colors.darkBlueGradient,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                '& .name': {
                    textTransform: 'uppercase',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 20,
                    marginTop: 15,
                    marginBottom: 15,
                    fontWeight: '300',
                    color: Colors.primary,
                    wordBreak: 'break-word',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    '-webkit-line-clamp': 2
                }
            },

            '& .bottom': {
                padding: 10,
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',

                '& .date-section': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                    '& .date': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 8,
                        color: Colors.darkGrey,
                        fontWeight: '300',
                    }
                }

            }


        }
    },

    text: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 15,
        fontWeight: '200',
    },
    menu: {
        alignItems: 'center',

        '& .icon': {
            fontSize: 18,
            marginRight: 10,
            color: Colors.black,
        },
        '& .text': {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 15,
            fontWeight: '300',
            color: Colors.darkGrey,
        }
    }
})

export default function KalabTemplateCard({ template , editTemplate, deleteTemplate, duplicateTemplate}) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdateTemplate = () => {
        handleClose();
        editTemplate(template);
    }   

    const handleDeleteTemplate = () => {
        handleClose();
        deleteTemplate(template);
    }   

    const handleDuplicateTemplate = () => {
        handleClose();
        duplicateTemplate(template);
    }   

    return (
        <div className="aspectratio ar4-3">
            <div className={classes.root}>
                <div className="template-card">
                    <div className='top'>
                        <div className="name">
                            {template.templateName}
                        </div>
                    </div>
                    <div className='bottom'>
                        <div className='left'>
                            <div className='date-section'>
                                <KalabIconWithToolTip iconName={"update_rounded"} toolTipText={"Last Updated"} noBorder iconSize={10} />
                                <div className='date'>
                                    {dateToShowInMessages(template.updatedDate)}
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <MoreVert />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem className={classes.menu} onClick={handleUpdateTemplate}>
                                    <Icon className='icon'>edit_rounded</Icon>
                                    <div className='text'>Edit</div>
                                </MenuItem>
                                <MenuItem className={classes.menu} onClick={handleDuplicateTemplate}>
                                    <Icon className='icon'>file_copy_rounded</Icon>
                                    <div className='text'>Duplicate</div>
                                </MenuItem>
                                <MenuItem className={classes.menu} onClick={handleDeleteTemplate}>
                                    <Icon className='icon'>delete_forever_rounded</Icon>
                                    <div className='text'>Delete</div>
                                </MenuItem>
                            </Menu>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
