import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessageList } from '../../../utils/Date';

const useStyles = makeStyles({
    root: props => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: props.isSelected ? '#062a43' : 'transparent',
        borderLeftWidth: props.isSelected ? 4 : 0,
        borderLeftColor: Colors.primaryDark,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#062a43'
        }

    }),
    artistDetail: {
        flex: 1,
        '& .artist-name': {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'capitalize',
            fontWeight: '300',
            fontSize: 15,
            color: Colors.white,
        }
    },
    timeDetail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginLeft: 10,
        '& .time': {
            color: Colors.white,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '300',
            fontSize: 12,

        },

        '& .unseen-count': {
            textAlign: 'center',
            aspectRatio: '1 / 1',
            marginTop: 10,
            backgroundColor: Colors.primaryDark,
            borderRadius: '50%',
            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                textTransform: 'capitalize',
                fontWeight: '400',
                fontSize: 10,
                color: Colors.white,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 6,
                paddingRight: 6,
            },
        }
    },
    avatar: {
        backgroundColor: Colors.primaryDark,
        color: Colors.white,
        fontFamily: 'Montserrat, sans-serif',
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: 15,
        marginRight: 10
    }

})

export default function KalabArtistMessageListCell({ messagedArtist, setSelectedArtist, isSelected }) {
    const classes = useStyles({ isSelected });
    const handleCellOnClick = () => {
        setSelectedArtist(messagedArtist);
    }
    return (
        <div className={classes.root} onClick={handleCellOnClick} >
            <Avatar variant="circle" className={classes.avatar}>
                {messagedArtist.artistName[0]}
            </Avatar>
            <div className={classes.artistDetail}>
                <div className="artist-name">
                    {messagedArtist.artistName}
                </div>
            </div>
            <div className={classes.timeDetail}>
                <div className="time">
                    {dateToShowInMessageList(messagedArtist.lastMessageDate)}
                </div>
                {messagedArtist.unseenMessagesCount > 0 ?
                    (
                        <div className="unseen-count">
                            <div className="text">
                                {messagedArtist.unseenMessagesCount}
                            </div>
                        </div>
                    )
                    :
                    (null)
                }

            </div>


        </div>
    )
}