import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessageList } from '../../../utils/Date';
import KalabRating from '../kalabRating/KalabRating';
import KalabExpandableText from '../kalabExpandableField/KalabExpandableText';

const useStyles = makeStyles((theme) => ({
    root: props => ({
        padding: 10,

    }),
    clientDetailRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    clientDetail: {

        flex: 1,
        '& .client-name': {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: 13,
            color: Colors.black,
        }
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: Colors.primaryDark,
        color: Colors.white,
        fontFamily: 'Montserrat, sans-serif',
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: 10,
        marginRight: 10
    },
    ratingDetail: {
        '& .ratings': {
            padding: 5,
            '& .rating': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',

                '& .text': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '300',
                    fontSize: 10,
                }
            }

        },
        '& .comment': {
            padding: 5,
            paddingTop: 0,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '300',
            fontSize: 12,
            fontStyle: 'italic',

        }
    }

}))

export default function KalabClientRatingCard({ clientRating }) {
    const classes = useStyles();
    return (
        <div className={classes.root} >
            <div className={classes.clientDetailRow}>
                <Avatar variant="circle" className={classes.avatar}>
                    {clientRating.clientCompanyFullName[0]}
                </Avatar>
                <div className={classes.clientDetail}>
                    <div className="client-name">
                        {clientRating.clientCompanyFullName}
                    </div>
                </div>
            </div>

            <div className={classes.ratingDetail}>
                <div className='ratings'>
                    <div className='rating'>
                        <div className='text'>
                            Professionalism
                        </div>
                        <div className='star-rating'>
                            <KalabRating value={clientRating.professionalism} fontSize={12} readOnly />
                        </div>
                    </div>
                    <div className='rating'>
                        <div className='text'>
                            Friendliness
                        </div>
                        <div className='star-rating'>
                            <KalabRating value={clientRating.friendliness} fontSize={12} readOnly />
                        </div>
                    </div>
                    <div className='rating'>
                        <div className='text'>
                            Communication
                        </div>
                        <div className='star-rating'>
                            <KalabRating value={clientRating.communication} fontSize={12} readOnly />
                        </div>
                    </div>
                    <div className='rating'>
                        <div className='text'>
                            Service Quality
                        </div>
                        <div className='star-rating'>
                            <KalabRating value={clientRating.serviceQuality} readOnly fontSize={12} />
                        </div>
                    </div>

                </div>
                {
                    clientRating.comment && clientRating.comment.trim() !== '' ?
                        (
                            <div className='comment'>
                                <KalabExpandableText
                                    text={clientRating.comment}
                                    limitLines={1}
                                />

                            </div>
                        )
                        :
                        (
                            null
                        )
                }

            </div>
        </div>
    )
}