import React, { useState, useEffect, useContext } from 'react'
import './ArtistEventRequestAcceptPopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { openInNewTab } from '../../utils/common';

export default function ArtistEventRequestAcceptPopUp({
    closeFn,
    title,
    okText,
    okBtnColor,
    okFn
}) {


    const handleOkClick = () => {
        okFn()
    }

    const handleClickOnlink = (link) => {
        openInNewTab(window.location.origin + '/' + link);
      }


    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='artist-accept-popup-modal'>
                <div className='artist-accept-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='artist-accept-popup-modal__content--top'>
                        <div className='artist-accept-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='artist-accept-popup-top__title'>
                            <span className='artist-accept-popup-top__title--text'>{title}</span>
                        </div>


                    </div>
                    <div className='artist-accept-popup-modal__content--main'>
                        <div className="condition">
                            <div className="terms">
                                By accepting this request you agree to our <span className='link' onClick={() => { handleClickOnlink('terms-of-service') }}>Terms & Conditions</span>, including our cancellation policy (Section 9).
                            </div>
                            <div className='disclaimer'>
                                Disclaimer: The payout period will differ based on the payment method chosen by your client. Card Payment (approx 7 days) and Bank Transfer (approx 40 days) after performance.
                            </div>
                        </div>

                        <div className="artist-accept-popup-button">
                            <KalabMainButton
                                text={okText}
                                color={okBtnColor}
                                onClick={handleOkClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
