import React, { useState, useEffect } from 'react'
import RegularExpression from '../../../utils/RegularExpression'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        borderRadius: 10,
        boxShadow: '0 0 2px #777781',
        '@media (hover: hover)': {
            transition: 'box-shadow 0.3s',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0 0 10px #777781',
            },

            '&:focus-within': {
                boxShadow: '0 0 10px #777781',
            }
        }

    }
})


export default function KalabMusicIFrame({ url, platform }) {
    const classes = useStyles();
    const [soundCloudUrl, setSoundCloudUrl] = useState(null);
    const getEmbedUrl = () => {
        switch (platform.toLowerCase()) {
            case 'youtube': return (`https://www.youtube.com/embed/${getYoutubeVideoIdFromUrl()}`)
            case 'spotify': return (getSpotifyEmbedUrlFromUrl())
            case 'apple-music': return (getAppleMusicEmbedUrlFromUrl())
            case 'mix-cloud': return (getMixCloudEmbedUrlFromUrl())
            case 'sound-cloud': return soundCloudUrl
            default: return (null)
        }
    }


    const getSpotifyEmbedUrlFromUrl = () => {
        var match = url.match(RegularExpression.spotify);
        if (match) {
            var albumOrTrack = match[1]
            var spotifyID = match[2]
            return (`https://open.spotify.com/embed/${albumOrTrack}/${spotifyID}`)
        }
        else {
            return null
        }
    }

    const getYoutubeVideoIdFromUrl = () => {
        var match = url.match(RegularExpression.youtube);
        if (match && match[2].length == 11) {
            return match[2];
        }
        else {
            return null
        }
    }
    const getMixCloudEmbedUrlFromUrl = () => {
        var match = url.match(RegularExpression.mixcloud);
        if (match) {
            console.log(url);
            const parts = url.split("www.mixcloud.com");
            console.log(parts);
            const musicDetail = parts[1].replace('/', '%2F');
            return (`https://www.mixcloud.com/widget/iframe/?feed=${musicDetail}`);
        }
        else {
            return null
        }
    }

    const getEmbedUrlDetailsForSoundCloud = () => {
        var formData = new FormData();
        formData.append("format", "json");
        formData.append("url", url);
        fetch('https://soundcloud.com/oembed', {
            method: 'POST',
            body: formData
        }).then(function (response) {
            if (response.ok) {
                return (response.json())
            }
            throw response;
        }).then(data => {
            console.log(data.html)
            const parts = data.html.split('src="');
            console.log(parts[1].split('"')[0]);
            setSoundCloudUrl(parts[1].split('"')[0]);

        }).catch(err => {
            console.log(err)
        })
            ;
    }

    const getAppleMusicEmbedUrlFromUrl = () => {
        var match = url.match(RegularExpression.applemusic);
        if (match) {
            console.log(url);
            const parts = url.split("music.apple.com");
            console.log(parts);
            const musicDetail = parts[1];
            return (`https://embed.music.apple.com${musicDetail}`);
        }
        else {
            return null
        }
    }

    useEffect(() => {
        if (platform === 'sound-cloud') {
            getEmbedUrlDetailsForSoundCloud();
        }
    }, [])

    return (
        getEmbedUrl() ?
            (
                <div className={classes.root}>
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" width="100%" height="100%" style={{ borderRadius: 10 }} src={getEmbedUrl()}>
                    </iframe>
                </div>
            ) :
            (
                null
            )
    )
}
