import React, { useContext, useEffect, useState } from 'react'
import './LinkPage.scss';
import { useParams } from 'react-router-dom';
import MainHeader from '../header/MainHeader';
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getDetailFromLinkTokenRequest } from '../../webservices/Webservice';
import BookingLink from '../linkForms/BookingLink';
import KalabEventRequestCard from '../kalab/kalabCards/KalabEventRequestCard';
import KalabEventRequestDetailElement from '../kalab/kalabEventRequestElements/KalabEventRequestDetailElement';
import EventRequest from '../linkForms/EventRequest';
import Booking from '../linkForms/Booking';
import Rejection from '../linkForms/Rejection';

export default function LinkPage(props) {
    const { token } = useParams();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const { height, width } = useWindowDimensions();

    const [data, setData] = useState({
        listing: null,
        bookingLink: null,
        eventRequest: null,
        booking: null,
        rejection : null
    });

    const getDetailFromLinkToken = () => {
        showLoader();
        getDetailFromLinkTokenRequest(
            token,
            (dataFromApi) => {
                hideLoader();
                console.log(dataFromApi)
                setData(dataFromApi)
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            }
        )
    }


    const resultElement = () => {
        if (data.bookingLink) {
            return (<BookingLink  bookingLink={data.bookingLink} listingDetail={null} history={props.history} refresh={getDetailFromLinkToken}/>)
        }
        else if (data.booking) {
            return (<Booking booking={data.booking} history={props.history} refresh={getDetailFromLinkToken} />)
        }
        else if (data.eventRequest) {
            return (<EventRequest eventRequest={data.eventRequest} history={props.history} refresh={getDetailFromLinkToken}/>)
        }
        else if (data.rejection) {
            return (<Rejection rejection={data.rejection} history={props.history} refresh={getDetailFromLinkToken}/>)
        }
        else {
            return (<BookingLink bookingLink={null} listingDetail={data.listing} history={props.history} refresh={getDetailFromLinkToken}/>)
        }
    }

    useEffect(() => {
        if (token) {
            getDetailFromLinkToken();
        }
    }, [token])


    return (
        <div className='link-page'>
            <MainHeader isDarkBackground onlyLogo={true}  />
            {resultElement()}
        </div>
    )
}
