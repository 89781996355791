import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessageList } from '../../../utils/Date';

const useStyles = makeStyles({
    root: props => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 30px',
        backgroundColor: props.isSelected ? '#062a43' : 'transparent',
        borderLeftWidth: props.isSelected ? 4 : 0,
        borderLeftColor: Colors.primaryDark,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#062a43'
        }

    }),
    month: {
        flex: 1,
        '& .month-text': {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'capitalize',
            fontWeight: '300',
            fontSize: 15,
            color: Colors.white,
        }
    },
    countDetail: {
        marginLeft: 10,
        '& .count': {
            textAlign: 'center',
            borderRadius: '50%',
            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                textTransform: 'capitalize',
                fontWeight: '400',
                fontSize: 12,
                color: Colors.white,
               
            },
        }
    }
})

export default function KalabMonthCell({ month, isSelected, setSelectedMonth }) {
    const classes = useStyles({ isSelected });
    const handleCellOnClick = () => {
        setSelectedMonth(month);
    }
    return (
        <div className={classes.root} onClick={handleCellOnClick} >
            <div className={classes.month}>
                <div className="month-text">
                    {month.monthName}
                </div>
            </div>
            <div className={classes.countDetail}>
                <div className="count">
                    <div className="text">
                        {month.noOfBookings}
                    </div>
                </div>
            </div>
        </div>
    )
}