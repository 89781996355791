
module.exports.getMessageLinkDetails = (message) => {
    var linkDetails = {
        artist: null,
        client: null
    }
    if (message.message === 'BOOKING_COMPLETED') {
        if (message.messageDetails) {
            if (message.messageDetails.artist) {
                linkDetails.artist = {
                    linkRelativeUrl: 'artist/rating/' + message.messageDetails.artist,
                    linkText: 'Please rate now'

                }
                console.log(linkDetails);
            }
            if (message.messageDetails.client) {
                linkDetails.client = {
                    linkRelativeUrl: 'client/rating/' + message.messageDetails.client,
                    linkText: 'Please rate now'

                }
            }
        }
    }
    return linkDetails;

}