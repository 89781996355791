import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';
import { openInNewTab } from '../../../utils/common';

const useStyles = makeStyles({
    root: {
        height: '100%',
        transform: 'scale(1)',
        transition: 'transform 0.5s',
        '@media (hover: hover)': {
            '&:hover': {
                transform: 'scale(1.05)',
                cursor: 'pointer'
            }
        }
    },
    image: {
        width: '100%',
        aspectRatio: 16 / 9,
        objectFit: 'scale-down',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    }
})

export default function KalabClientPartnerCard({ imageUrl, name }) {
    const classes = useStyles();
    return (
        <div className={`${classes.root} aspectratio ar16-9`}>
            <img
                className={classes.image}
                src={imageUrl}
                alt='partner-pic'
                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
            />

        </div>
    )
}
