import React from 'react'
import ImageWithCrop from '../elements/ImageWithCrop'
import defaultImage from '../../images/default-img.png';
export default function CollageImage1({ images, setImages, noCropOrEdit }) {
    const setImage = (id, image) => {
        var updatedImages = [];
        images.forEach(image => {
            if (image.id !== id || image.collageId !== 1) {
                updatedImages.push(image)
            }
        });
        updatedImages.push({
            collageId: 1,
            id,
            image
        })
        setImages(updatedImages);
    }
    const getImage = (id) => {
        var resultImage = {
            preview: '',
            previewFile: null,
            rawUrl: '',
            raw: null
        }
        images.forEach(image => {
            if (image.id === id && image.collageId === 1) {
                resultImage = image.image
            }
        });
        return (resultImage);
    }
    return (
        <div className="aspectratio ar3-1">
            <div className="image-box" style={{ borderRadius: 5 }}>
                <div style={{ flex: 1, height: '100%', position: 'relative', padding: '0.5%' }}>
                    {noCropOrEdit ? (
                        <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(1).preview !== "") ? getImage(1).preview : defaultImage} />
                    ) : (
                        <ImageWithCrop cropRatio={3 / 1} id={1} image={getImage(1)} setImage={setImage} />
                    )}

                </div>

            </div>
        </div>
    )
}
