import { directive } from '@babel/types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import MainHeader from '../header/MainHeader';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import './SignUpPage.scss'

function SignUpPage(props) {

    const handleOnClickApplyBtn = () => {
        props.history.push({
            pathname: '/artist/application'
        })
    }


    return (
        <div className="artist-sign-up-page">
            <MainHeader onlyLogo logoIsBlack />
            <div className="heading">
                <div className="heading__title">Join our artist community</div>
                <div className="heading__description">
                    Are you an up-and coming musician who has live performing experience, released music, and seeking support?
                </div>
            </div>
            <div className='features'>
                <div className='features__feature'>
                    <div className='features__feature--number'>1</div>
                    <div className='features__feature--title'>Apply to join us</div>
                    <div className='features__feature--description'>
                        Submit your Instagram and music links. Our team will review your application & determine whether you are a good fit!😉
                    </div>
                </div>
                <div className='features__feature'>
                    <div className='features__feature--number'>2</div>
                    <div className='features__feature--title'>Create your listing for free</div>
                    <div className='features__feature--description'>
                        Show off your best music and videos, set your own prices, and share your story with the world!🌎
                    </div>
                </div>
                <div className='features__feature'>
                    <div className='features__feature--number'>3</div>
                    <div className='features__feature--title'>Get Discovered</div>
                    <div className='features__feature--description'>
                        Start performing for clients, mingle & collaborate with other KALAB artists, and promote your music.🎵
                    </div>
                </div>
            </div>
            <div className='apply-btn'>
                <KalabMainButton
                    text="Apply"
                    onClick={handleOnClickApplyBtn}
                />
            </div>
            <div className="benefit-section">
                <div className='benefit-section__title'>Benefits of being part of our crew</div>
                <div className='benefits'>
                    <div className='benefits__benefit'>
                        <i className="fas fa-check-circle benefits__benefit--icon" />
                        <div className="benefits__benefit--title">Increase your online visibility</div>
                        <div className="benefits__benefit--description">
                            We’re there to help build your digital presence. Think of us as your #1 fan sharing your latest releases, projects, and YOU!🙌🏾
                        </div>
                    </div>
                    <div className='benefits__benefit'>
                        <i className="fas fa-check-circle benefits__benefit--icon" />
                        <div className="benefits__benefit--title">Get bookings for free</div>
                        <div className="benefits__benefit--description">
                            Not free gigs-free for you, silly! We provide all the tools so you can be easily booked and earn some money. Leave all the boring admin to us (we’re nerds)🤓 and you can take care of creating an awesome show✌🏽
                        </div>
                    </div>
                    <div className='benefits__benefit'>
                        <i className="fas fa-check-circle benefits__benefit--icon" />
                        <div className="benefits__benefit--title">Enjoy the Kalab Community</div>
                        <div className="benefits__benefit--description">
                            Share, encourage, and collaborate with other talented artists like yourself!😊 We’re creating a tribe, our music industry partners in crime are here to support, you know what that means? Tons of perks baby!🎉
                        </div>
                    </div>


                </div>

            </div>
        </div>

    )
}

export default SignUpPage
