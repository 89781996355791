import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar, Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessageList, getFormattedDate, getFormattedDateForBookingCell, getFormattedDateForStartEndDate, getFormattedTime, getStatusOfEvent } from '../../../utils/Date';
import { getBookingsOfAMonthForClientRequest } from '../../../webservices/Webservice';
import { getLabelFromValue } from '../../../utils/Currencies';
import moment from 'moment';

const useStyles = makeStyles({
    root: props => ({
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        backgroundColor: props.isSelected ? Colors.lightestBlueishgGrey : 'transparent',
        borderLeftWidth: props.isSelected ? 4 : 0,
        borderLeftColor: Colors.primaryDark,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Colors.lightestBlueishgGrey,

            '& .status': {
                borderColor: Colors.black,
                '& .text': {
                    color: Colors.black
                }
            }
        }

    }),
    booking: {
        flex: 1,
        '& .date-text': {
            fontFamily: 'Montserrat, sans-serif',

            fontWeight: '300',
            fontSize: 13,
            color: Colors.lightGrey,
            display : 'flex',
            alignItems : 'center',
            '& .time': {
                fontSize: 10,
                marginLeft: 10
            }
        },
        '& .event-name-text': {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: 14,
            color: Colors.black,
        },
        '& .listing-name-text': {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: 12,
            color: Colors.black,
        }
    },
    priceStatusDetail: props => ({
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& .price': {
            '& .text': {
                textAlign: 'right',
                fontFamily: 'Montserrat, sans-serif',
                textTransform: 'capitalize',
                fontWeight: '400',
                fontSize: 12,
                color: Colors.black
            },
        },
        '& .status': {
            borderWidth: 1,
            padding: '2px 6px',
            borderColor: props.isSelected ? Colors.black : Colors.primary,

            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                textTransform: 'uppercase',
                fontWeight: '400',
                fontSize: 8,
                color: props.isSelected ? Colors.black : Colors.primary
            },
        }
    })
})

export default function KalabBookingCell({ booking, isSelected, setSelectedBooking, isArtist = false, selectedMonth }) {
    const classes = useStyles({ isSelected });
    const handleCellOnClick = () => {
        setSelectedBooking(booking);
    }
    const getNoOfPerformances = () => {
        var noOfPerformancesInCurrentMonth = 0;
        if (booking.isResidency) {
            booking.timings.forEach(timing => {
                var date = moment(timing.date, 'YYYY-MM-DD')
                if (date.get('month') === selectedMonth - 1) {
                    noOfPerformancesInCurrentMonth = noOfPerformancesInCurrentMonth + 1;
                }
            });
        }
        return noOfPerformancesInCurrentMonth
    }
    return (
        <div className={classes.root} onClick={handleCellOnClick} >
            <div className={classes.booking}>
                <div className="date-text">
                    {/* <Icon style={{fontSize : 13, marginRight : 5}}>{booking.isResidency ? 'date_range_rounded':'event_rounded'}</Icon> */}
                    {booking.isResidency ? getFormattedDateForStartEndDate(booking.date, booking.endDate) : getFormattedDateForBookingCell(booking.date)}
                    {
                        booking.isResidency ?
                            (
                                <span className="time">
                                    {getNoOfPerformances()} / {booking.noOfPerformances} Events
                                </span>
                            )
                            :
                            (
                                <span className="time">
                                    {getFormattedTime(booking.startTime)} - {getFormattedTime(booking.endTime)}
                                </span>
                            )
                    }

                </div>
                <div className="event-name-text">
                    {booking.eventName}
                </div>
                <div className="listing-name-text">
                    {isArtist ? booking.clientCompanyFullName : booking.listingName}
                </div>
            </div>
            <div className={classes.priceStatusDetail}>
                <div className="price">
                    <div className="text">
                        {getLabelFromValue(booking.currency)} {parseFloat(booking.totalPrice).toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    )
}