import React, { createContext, useState, useEffect } from 'react'
import { useNotification } from "./NotificationContext";
import { getAmbiencesRequest, getEventTypesRequest, getGenresRequest, getArtistTypesRequest, getCountriesRequest, getSettingsRequest } from '../webservices/Webservice';


export const DataStoreContext = createContext();

export default function DataStoreContextProvider({ children }) {
    const [artistTypes, setArtistTypes] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [genres, setGenres] = useState([]);
    const [ambiences, setAmbiences] = useState([]);
    const [countries, setCountries] = useState([])
    const [settings, setSettings] = useState([]);
    const notify = useNotification();
    const [loading, setLoading] = useState({
        artistType: false,
        eventType: false,
        genre: false,
        ambience: false,
        country: false,
        setting: false
    })

    const getSettings = () => {
        setLoading({
            ...loading,
            setting: true
        });
        getSettingsRequest(
            (settings) => {
                setSettings(settings);
                setLoading({
                    ...loading,
                    setting: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    setting: false
                })
            }
        )
    }

    const getArtistTypes = () => {
        setLoading({
            ...loading,
            artistType: true
        });
        getArtistTypesRequest(
            (artistTypes) => {
                setArtistTypes(artistTypes);
                setLoading({
                    ...loading,
                    artistType: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    artistType: false
                })
            }
        )
    }
    const getEventTypes = () => {
        setLoading({
            ...loading,
            eventType: true
        });
        getEventTypesRequest(
            (eventTypes) => {
                setEventTypes(eventTypes);
                setLoading({
                    ...loading,
                    eventType: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    eventType: false
                })
            }
        )
    }
    const getAmbiences = () => {
        setLoading({
            ...loading,
            ambience: true
        });
        getAmbiencesRequest(
            (ambiences) => {
                setAmbiences(ambiences);
                setLoading({
                    ...loading,
                    ambience: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    ambience: false
                })
            }
        )
    }
    const getGenres = () => {
        setLoading({
            ...loading,
            genre: true
        });
        getGenresRequest(
            (genres) => {
                setGenres(genres);
                setLoading({
                    ...loading,
                    genre: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    genre: false
                })
            }
        )
    }



    const getCountries = () => {
        setLoading({
            ...loading,
            country: true
        });
        getCountriesRequest(
            (countries) => {
                setCountries(countries);
                setLoading({
                    ...loading,
                    country: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    country: false
                })
            }
        )
    }

    const intializeValues = () => {
        getArtistTypes();
        getEventTypes();
        getAmbiences();
        getGenres();
        getCountries();
        getSettings();
    }

    useEffect(() => {
        intializeValues();
    }, [])

    return (
        <DataStoreContext.Provider value={{ artistTypes, eventTypes, genres, ambiences, countries, settings, loading }}>
            {children}
        </DataStoreContext.Provider>
    )
}
