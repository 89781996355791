import React, { useState, useEffect, useContext } from "react";
import './ArtistHomePage.scss';
import ArtistHeader from "../header/ArtistHeader";
import PortaledComponent from "../elements/portaledComponent";
import PartnerPopUp from "../popUps/PartnerPopUp";
import {
    getArtistDetailsRequest,
    getCountriesRequest,
    getCitiesRequest,
    getLanguagesRequest,
    getCountryCodesRequest,
    checkOTPRequest,
    generateOTPRequest,
    resendOTPRequest,
    updateArtistDetailsRequest,
    changeArtistPasswordRequest,
    getImageUrl,
    getSettingsRequest,
    getListingOfAnArtistRequest,
    changeListingStatusRequest,
    getAccountLinkRequest,
    getStripeDashboardLinkRequest,
} from '../../webservices/Webservice';
import { openInNewTab } from "../../utils/common";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabTextField from "../kalab/kalabTextField/KalabTextField";
import KalabIconButton from "../kalab/kalabButtons/KalabIconButton";
import KalabSmallButton from "../kalab/kalabButtons/KalabSmallButton";
import KalabDropDown from "../kalab/kalabDropDown/KalabDropDown";
import KalabMainButtonWithIcon from "../kalab/kalabButtons/KalabMainButtonWithIcon";
import KalabCard from "../kalab/kalabCards/KalabCard";
import KalabListingCard from "../kalab/kalabCards/KalabListingCard";
import KalabListingCardWithDetails from "../kalab/kalabCards/KalabListingCardWithDetails";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import WhatsappUpdatePopUp from "../popUps/WhatsappUpdatePopUp";

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import KalabIconWithToolTip from "../kalab/kalabToolTip/KalabIconWithToolTip";
import useWindowDimensions from "../../hooks/useWindowDimensions";
SwiperCore.use([Pagination, Navigation]);


const ArtistHomePage = (props) => {
    const { height, width } = useWindowDimensions();
    const [listings, setListings] = useState([]);
    const [partnerPopUpShow, setPartnerPopUpShow] = useState(false);
    const [whatsappUpdatePopUpShow, setWhatsappUpdatePopUpShow] = useState(false);
    const [settings, setSettings] = useState([]);
    const [artist, setArtist] = useState(null)
    const [artistDetails, setArtistDetails] = useState(null)
    const [isEdit, setIsEdit] = useState(false);
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [loading, setLoading] = useState({
        countryCode: false,
        city: false,
        country: false,
        language: false
    })
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [isVerify, setIsVerify] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const [verifiedNumbers, setVerifiedNumbers] = useState([]);
    const [listingSwiper, setListingSwiper] = useState(null);
    const [listingSwiperDetail, setListingSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const notify = useNotification();

    const getArtist = (firstTime = false) => {
        showLoader();
        getArtistDetailsRequest(
            (artist) => {
                hideLoader();
                setArtist(artist);
                setArtistDetails(artist);
                var vNumbers = verifiedNumbers;
                vNumbers.push({
                    countryCode: artist.countryCode,
                    phone: artist.phone
                });
                if (!artist.whatsappIsSame && artist.whatsappCountryCode && artist.whatsappPhone) {
                    vNumbers.push({
                        countryCode: artist.whatsappCountryCode,
                        phone: artist.whatsappPhone
                    });
                }
                setVerifiedNumbers(vNumbers);
                console.log(artist);
                if (artist.whatsappIsSame === null && firstTime) {
                    showWhatsappUpdatePopUp();
                }
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }
    const getListings = () => {
        showLoader();
        getListingOfAnArtistRequest(
            (listings) => {
                hideLoader();
                setListings(listings);
                console.log(listings);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }

    const getCountries = () => {
        setLoading({
            ...loading,
            country: true,
            city: true
        })
        getCountriesRequest(
            (countries) => {
                var countryOptionsFromAPI = [];
                countries.forEach(country => {
                    countryOptionsFromAPI.push({
                        value: country.countryAlpha3Code,
                        label: country.countryAsciiName,
                        country: country
                    })

                });
                setCountryOptions(countryOptionsFromAPI);
                setLoading({
                    ...loading,
                    country: false,
                    city: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    country: false,
                    city: false
                })
            }
        )
    }

    const getCities = (countryAlpha3Code) => {
        setLoading({
            ...loading,
            city: true
        })
        getCitiesRequest(
            countryAlpha3Code,
            (cities) => {
                var cityOptionsFromAPI = [];
                cities.forEach(city => {
                    cityOptionsFromAPI.push({
                        value: city.city,
                        label: city.city_ascii,
                        city
                    })

                });
                setCityOptions(cityOptionsFromAPI);
                setLoading({
                    ...loading,
                    city: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    city: false
                })
            }
        )
    }

    const getLanguages = () => {
        setLoading({
            ...loading,
            language: true
        })
        getLanguagesRequest(
            (languages) => {
                var languageOptionsFromAPI = [];
                languages.forEach(language => {
                    languageOptionsFromAPI.push({
                        value: language.code,
                        label: language.name,
                        language
                    })

                });
                setLanguageOptions(languageOptionsFromAPI);
                setLoading({
                    ...loading,
                    language: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    language: false
                })
            }
        )
    }

    const getCountryCodes = () => {
        setLoading({
            ...loading,
            countryCode: true
        })
        getCountryCodesRequest(
            (countryCodes) => {
                var countryCodeOptionsFromAPI = [];
                countryCodes.forEach(countryCode => {
                    if (countryCodeOptionsFromAPI.filter(cCodeOption => cCodeOption.countryCode.countryCode === countryCode.countryCode).length === 0) {
                        countryCodeOptionsFromAPI.push({
                            value: countryCode,
                            label: `+${countryCode.countryCode} ( ${countryCodes.filter(cCode => cCode.countryCode === countryCode.countryCode).map(cntCode => cntCode.countryAsciiName !== "" ? cntCode.countryAsciiName : cntCode.countryName).join(', ')} )`,
                            countryCode
                        })
                    }
                });
                setCountryCodeOptions(countryCodeOptionsFromAPI);
                setLoading({
                    ...loading,
                    countryCode: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    countryCode: false
                })
            }
        )
    }

    const editBtnPressed = () => {
        if (isEdit) {
            setArtist(artistDetails);
            setCityOptions([]);
        }
        setIsEdit(!isEdit)
    }

    const handleAddListingBtnClick = () => {
        props.history.push({
            pathname: '/artist/listing/add'
        })

    }

    const handleInputTextChange = (e) => {
        var updatedArtist = {
            ...artist
        }
        updatedArtist[e.target.id] = e.target.value
        setArtist(updatedArtist);
    }

    const handleCountryCodeSelectChange = (countryCodeValue) => {
        setArtist({
            ...artist,
            countryCode: countryCodeValue ? countryCodeValue.value.countryCode : null,
        });
        if (isVerify) {
            setIsVerify(false);
        }
    };
    const handleCountrySelectChange = (countryValue) => {
        setArtist({
            ...artist,
            country: countryValue.label,
            city: null
        })
        if (countryValue === null) {
            setCityOptions([]);
        }
        else {
            getCities(countryValue.value);
        }
    }

    const handleCitySelectChange = (cityValue) => {
        setArtist({
            ...artist,
            city: cityValue.label
        })
    }
    const handleLanguagesSelectChange = (languagesValue) => {
        setArtist({
            ...artist,
            languages: languagesValue.map(languageValue => (languageValue.label))
        })
    }

    const getCountryCodeSelectValue = (countryCode) => {
        var countryCodeValues = countryCodeOptions.filter(countryCodeOption => (countryCodeOption.value.countryCode === countryCode));
        return (
            countryCodeValues.length > 0 ? countryCodeValues[0] : null
        )
    }
    const getCountrySelectValue = (country) => {
        var countryValues = countryOptions.filter(countryOption => (countryOption.label === country));
        if (countryValues.length > 0) {
            return (countryValues[0]);
        }
        else {
            return null;
        }
    }

    const getCitySelectValue = (city) => {
        var cityValues = cityOptions.filter(cityOption => (cityOption.label === city));
        return (
            cityValues.length > 0 ? cityValues[0] : null
        )
    }

    const getLanguageSelectValue = (languages) => {
        var languageValues = languageOptions.filter(languageOption => (languages.includes(languageOption.label)));
        return (
            languageValues
        )
    }

    const passwordValid = () => {
        if (
            artist.newPassword &&
            artist.newPassword === artist.cPassword &&
            artist.newPassword !== '' &&
            artist.newPassword.length >= 6
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handlePasswordChangeCancelBtn = () => {
        setIsPasswordChange(false)
        setArtist({
            ...artist,
            oldPassword: null,
            newPassword: null,
            cPassword: null
        })
    }

    const isPasswordFormValid = () => {
        if (passwordValid() && artist.oldPassword && artist.oldPassword.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkPhoneVerified = () => {
        if (
            verifiedNumbers.filter(
                verifiedNumber =>
                (
                    verifiedNumber.countryCode === artist.countryCode &&
                    verifiedNumber.phone === artist.phone
                )
            ).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const checkOTP = () => {
        showLoader();
        checkOTPRequest(
            artist.countryCode,
            artist.phone,
            artist.otp,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var vNumbers = verifiedNumbers;
                vNumbers.push({
                    countryCode: artist.countryCode,
                    phone: artist.phone
                });
                setVerifiedNumbers(vNumbers);
                setArtist({
                    ...artist,
                    otp: null
                })
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleResendOTPButton = (e) => {
        e.preventDefault();
        resendOTP();
    };
    const resendOTP = () => {
        showLoader();
        resendOTPRequest(
            artist.countryCode,
            artist.phone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var count = resendCount + 1;
                setResendCount(count);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleOTPCheckButton = (e) => {
        e.preventDefault();
        checkOTP();
    };
    const handleVerifyButton = (e) => {
        e.preventDefault();
        generateOTP();
    };
    const generateOTP = () => {
        showLoader();
        generateOTPRequest(
            artist.countryCode,
            artist.phone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                setIsVerify(true);
                setResendCount(0);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const isLanguagesChanged = () => {
        var isChanged = false;
        if (artist.languages.length === artistDetails.languages.length) {
            artist.languages.forEach(language => {
                if (!artistDetails.languages.includes(language)) {
                    isChanged = true;
                }
            })
        }
        else {
            isChanged = true;
        }
        return isChanged;
    }

    const isFormValid = () => {
        if (
            (
                artist.city === artistDetails.city &&
                artist.country === artistDetails.country &&
                artist.countryCode === artistDetails.countryCode &&
                artist.phone === artistDetails.phone &&
                artist.fullName === artistDetails.fullName &&
                !isLanguagesChanged() &&
                artist.artistName === artistDetails.artistName
            ) ||
            !checkPhoneVerified()
        ) {
            return false
        }
        else {
            return true
        }
    }

    const getSettings = () => {
        showLoader();
        getSettingsRequest(
            (settings) => {
                hideLoader();
                console.log(settings);
                setSettings(settings);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }

    const closePartnerPopUp = () => {
        document.body.style.overflow = 'unset';
        setPartnerPopUpShow(false);
    }

    const showPartnerPopUp = () => {
        document.body.style.overflow = 'hidden';
        setPartnerPopUpShow(true);
    }

    const closeWhatsappUpdatePopUp = () => {
        document.body.style.overflow = 'unset';
        getArtist();
        setWhatsappUpdatePopUpShow(false);
    }

    const showWhatsappUpdatePopUp = () => {
        document.body.style.overflow = 'hidden';
        setWhatsappUpdatePopUpShow(true);
    }

    const getSettingValue = (settingKey) => {
        var settingValue = ''
        settings.forEach(setting => {
            if (setting.settingKey === settingKey) {
                settingValue = setting.settingValue;
            }
        })
        return settingValue;
    }


    const checkWhatsappIsSame = () => {
        if (
            artist.whatsappCountryCode === artist.countryCode &&
            artist.whatsappPhone === artist.phone
        ) {
            return true
        }
        else {
            return false
        }
    }

    const updateArtist = () => {
        showLoader();
        updateArtistDetailsRequest(
            artist.fullName,
            artist.artistName,
            artist.countryCode,
            artist.phone,
            artist.city,
            artist.country,
            artist.languages,
            checkWhatsappIsSame() ? null : artist.whatsappPhone,
            checkWhatsappIsSame() ? null : artist.whatsappCountryCode,
            checkWhatsappIsSame(),
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                getArtist();
                setIsEdit(false);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
                if (isEdit) {
                    setArtist(artistDetails);
                    setCityOptions([]);
                }
                setIsEdit(false);
            }
        );
    };

    const handleSaveBtnPress = () => {
        if (isFormValid()) {
            updateArtist();
        }
    }

    const changeArtistPassword = () => {
        if (isPasswordFormValid()) {
            showLoader();
            changeArtistPasswordRequest(
                artist.oldPassword,
                artist.newPassword,
                (response) => {
                    hideLoader();
                    notify({
                        type: 'SUCCESS',
                        message: response,
                        title: 'Success!!!',
                    });
                    handlePasswordChangeCancelBtn();
                },
                (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                    handlePasswordChangeCancelBtn();
                })
        }


    }

    const changeListingStatus = (newStatus, listingId) => {
        showLoader();
        changeListingStatusRequest(
            newStatus,
            listingId,
            (response) => {
                hideLoader();
                getListings();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })



    }

    const openAccountLink = () => {
        showLoader();
        getAccountLinkRequest(
            (data) => {
                hideLoader();
                console.log(data);
                window.open(data.link.url, "_self")
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    const openStripeDashboardLink = () => {
        showLoader();
        getStripeDashboardLinkRequest(
            (data) => {
                hideLoader();
                console.log(data);
                openInNewTab(data.link.url)
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    useEffect(() => {
        if (artist && countryOptions.length > 0 && cityOptions.length === 0) {
            if (artist.country) {
                var countryOption = getCountrySelectValue(artist.country);
                getCities(countryOption.value);
            }
        }

    }, [artist, countryOptions, cityOptions])

    useEffect(() => {
        getArtist(true);
        getListings();
        getCountryCodes();
        getCountries();
        getLanguages();
        getSettings();
    }, [])


    return (
        <>
            <ArtistHeader />
            {artist ?
                (
                    <div className='artist-page' style={{ overflow: 'hidden' }}>
                        <div className="block-row">
                            <div className="details-block" >
                                <div className='artist-page__top'>
                                    <span className='artist-page__top--title'>{artist.artistName}</span>
                                    {!isPasswordChange ?
                                        (
                                            <KalabIconButton
                                                onClick={() => {
                                                    editBtnPressed();
                                                }}
                                                iconName={isEdit ? 'close' : 'edit_outlined'}
                                                isLightTheme={true}
                                                iconSize={15}
                                                height={10}
                                                noBorder
                                            />
                                        )
                                        :
                                        (
                                            null
                                        )
                                    }
                                </div>
                                <div className="artist-page__content">
                                    <div className='artist-page__content--form'>
                                        <div className='artist-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                    <KalabTextField
                                                        disabled={!isEdit}
                                                        type="text"
                                                        id="fullName"
                                                        placeholder="Full Name"
                                                        value={artist.fullName}
                                                        onChange={handleInputTextChange}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                    <KalabTextField
                                                        disabled={!isEdit}
                                                        type="text"
                                                        id="artistName"
                                                        placeholder="Artist Name"
                                                        value={artist.artistName}
                                                        onChange={handleInputTextChange}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='artist-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabDropDown
                                                        placeholder="Country"
                                                        isDisabled={!isEdit}
                                                        isLoading={loading.country}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isSearchable={true}
                                                        name="country"
                                                        onChange={handleCountrySelectChange}
                                                        options={countryOptions}
                                                        isLightTheme={true}
                                                        value={getCountrySelectValue(artist.country)}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabDropDown
                                                        placeholder="City"
                                                        isDisabled={!isEdit}
                                                        isLoading={loading.city}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isSearchable={true}
                                                        name="city"
                                                        onChange={handleCitySelectChange}
                                                        options={cityOptions}
                                                        isLightTheme={true}
                                                        value={getCitySelectValue(artist.city)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='artist-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabTextField
                                                        disabled={true}
                                                        type="text"
                                                        id="email"
                                                        placeholder="Email"
                                                        value={artist.email}
                                                        onChange={(e) => { handleInputTextChange(e); }}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabDropDown
                                                        placeholder="Languages"
                                                        isDisabled={!isEdit}
                                                        isLoading={loading.language}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isMulti
                                                        isSearchable={true}
                                                        name="language"
                                                        onChange={handleLanguagesSelectChange}
                                                        options={languageOptions}
                                                        isLightTheme={true}
                                                        value={getLanguageSelectValue(artist.languages)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='artist-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '5px  0px', width: width > 700 ? '50%' : 'unset' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabDropDown
                                                        placeholder="Country Code"
                                                        isDisabled={!isEdit}
                                                        isLoading={loading.countryCode}
                                                        isClearable={true}
                                                        isRtl={false}
                                                        isSearchable={true}
                                                        name="countryCode"
                                                        onChange={handleCountryCodeSelectChange}
                                                        options={countryCodeOptions}
                                                        isLightTheme={true}
                                                        value={getCountryCodeSelectValue(artist.countryCode)}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                <div style={{ margin: '5px auto', textAlign: 'center', padding: '10px 10px' }}>
                                                    <KalabTextField
                                                        disabled={!isEdit}
                                                        type='number'
                                                        id="phone"
                                                        placeholder="Phone"
                                                        value={artist.phone}
                                                        onChange={(e) => { handleInputTextChange(e); setIsVerify(false); }}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='artist-page__content--form_row' style={{ margin: 0 }}>
                                            <div className='whatsapp-status'>
                                                <WhatsAppIcon className="icon" />
                                                <div className="text">{artist.whatsappCountryCode && artist.whatsappPhone ? (`WhatsApp - +${artist.whatsappCountryCode} ${artist.whatsappPhone}`) : ('WhatsApp notifications not enabled')}</div>
                                                {
                                                    isEdit || isPasswordChange ?
                                                        (
                                                            null
                                                        )
                                                        :
                                                        (
                                                            <KalabSmallButton
                                                                text={'Update'}
                                                                isLightTheme
                                                                noBorder
                                                                onClick={showWhatsappUpdatePopUp}
                                                            />
                                                        )
                                                }

                                            </div>
                                        </div>
                                        {isPasswordChange ? (
                                            <>
                                                <div className='artist-page__content--form_row'>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>
                                                        <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                            <KalabTextField
                                                                type="password"
                                                                id="oldPassword"
                                                                placeholder="Old Password"
                                                                value={artist.oldPassword ? artist.oldPassword : ''}
                                                                onChange={handleInputTextChange}
                                                                isLightTheme={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>
                                                        <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                            <KalabTextField
                                                                type="password"
                                                                id="newPassword"
                                                                placeholder="New Password"
                                                                value={artist.newPassword ? artist.newPassword : ''}
                                                                onChange={handleInputTextChange}
                                                                isLightTheme={true}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='artist-page__content--form_row'>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>
                                                        <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                            <KalabTextField
                                                                type="password"
                                                                id="cPassword"
                                                                placeholder="Confirm New Password"
                                                                value={artist.cPassword ? artist.cPassword : ''}
                                                                onChange={handleInputTextChange}
                                                                isLightTheme={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>
                                                        <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                            {
                                                                artist.newPassword && artist.newPassword.length > 0 ?
                                                                    (
                                                                        passwordValid() ?
                                                                            (<><i className="fas fa-check-circle register-icon" /><span className='verified-text'>Password Matches</span></>)
                                                                            : ((artist.newPassword.length < 6) ?
                                                                                (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Need minimum 6 characters</span></>)
                                                                                :
                                                                                (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Passwords are not matching</span></>)
                                                                            )
                                                                    )
                                                                    :
                                                                    ('')
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        ) :
                                            (null)}
                                        <div className='artist-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                {!isEdit ?
                                                    (isPasswordChange ? (
                                                        <div style={{ margin: '5px auto', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 10px' }}>
                                                            <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                                <KalabSmallButton
                                                                    text={'Save Password'}
                                                                    disabled={!isPasswordFormValid()}
                                                                    onClick={() => { changeArtistPassword(); }}
                                                                    isLightTheme
                                                                /></div>
                                                            <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                                <KalabSmallButton
                                                                    text={'Cancel'}
                                                                    onClick={() => { handlePasswordChangeCancelBtn() }}
                                                                    isLightTheme
                                                                /></div>
                                                        </div>
                                                    ) :
                                                        (
                                                            <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                                <KalabSmallButton
                                                                    text={'Change Password'}
                                                                    onClick={() => { setIsPasswordChange(true) }}
                                                                    isLightTheme
                                                                />

                                                            </div>
                                                        )

                                                    ) :
                                                    (
                                                        null
                                                    )
                                                }
                                            </div>
                                            <div style={{ flex: 1, margin: '5px 0px' }}>
                                                {isEdit ?
                                                    (
                                                        checkPhoneVerified() ?
                                                            (
                                                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <i className="fas fa-check-circle register-icon" /><span className='verified-text'>Verified</span>
                                                                </div>
                                                            )
                                                            :
                                                            (isVerify ?
                                                                (
                                                                    <div style={{ width: '75%', margin: '5px auto', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                                                        <div style={{ flex: 1, display: 'flex' }}>
                                                                            <KalabTextField
                                                                                type="number"
                                                                                id="otp"
                                                                                placeholder="OTP"
                                                                                isLightTheme
                                                                                value={artist.otp ? artist.otp : ''}
                                                                                onChange={handleInputTextChange}
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginLeft: 5, marginRight: 5, alignSelf: 'flex-end' }}>
                                                                            <KalabSmallButton
                                                                                text="check"
                                                                                isLightTheme
                                                                                onClick={handleOTPCheckButton}
                                                                                disabled={artist.otp === ""}
                                                                            />
                                                                        </div>
                                                                        <div style={{ alignSelf: 'flex-end' }}>
                                                                            <KalabSmallButton
                                                                                text="Resend"
                                                                                isLightTheme
                                                                                onClick={handleResendOTPButton}
                                                                                disabled={resendCount > 2 ? true : false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', padding: '5px 0px' }}>
                                                                        <KalabSmallButton
                                                                            text="Verify"
                                                                            isLightTheme
                                                                            onClick={handleVerifyButton}
                                                                            disabled={artist.phone === "" || artist.countryCode === null}
                                                                        />
                                                                    </div>
                                                                ))
                                                    ) :
                                                    (
                                                        !isPasswordChange
                                                            ?
                                                            (
                                                                <div style={{ display: width > 700 ? 'flex' : 'block', flexDirection: 'row', margin: width > 700 ? '5px auto' : '15px auto 5px', textAlign: 'center', padding: width > 700 ? '5px 10px' : '15px 10px 5px', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <KalabSmallButton
                                                                        text={artist.stripeAccountId ? 'Update Bank Details' : 'Add Bank Details'}
                                                                        onClick={() => { openAccountLink(); }}
                                                                        isLightTheme
                                                                    />
                                                                    <div style={{ marginLeft: 10, marginTop: width > 700 ? 0 : 10 }}>
                                                                        <KalabIconWithToolTip
                                                                            toolTipText="Contact KALAB team for any issues related to your stripe connected account"
                                                                            placement="bottom"
                                                                            iconName={'fas fa-question'}
                                                                            iconSize={12}
                                                                            isFAIcon={true}
                                                                            isLightTheme
                                                                        />
                                                                    </div>



                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                null
                                                            )

                                                    )
                                                }
                                            </div>

                                        </div>



                                        {!isEdit && !isPasswordChange && artist.stripeAccountId ?
                                            (
                                                <div className='artist-page__content--form_row' style={{ alignItems: 'center' }}>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>
                                                        <div style={{ margin: '5px auto', textAlign: 'center', padding: '5px 10px' }}>
                                                            <KalabSmallButton
                                                                text={'Payment Dashboard'}
                                                                onClick={() => { openStripeDashboardLink(); }}
                                                                isLightTheme
                                                            />

                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1, margin: '5px 0px' }}>

                                                    </div>

                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )}
                                        {isEdit ? (
                                            <div className='artist-page__content--form_row'>
                                                <div style={{ flex: 1, margin: '5px auto', textAlign: 'center', padding: '5px 10px', display: 'flex', justifyContent: 'center' }}>
                                                    <KalabMainButtonWithIcon
                                                        disabled={!isFormValid()}
                                                        onClick={() => { handleSaveBtnPress(); }}
                                                        text={'Save'}
                                                        iconName={'check'}
                                                    />
                                                </div>
                                            </div>
                                        ) : (null)}
                                    </div>
                                </div>
                            </div>
                            <div className="artist-page__listing-block" >
                                <div className="listing-swiper">
                                    <div>
                                        <KalabIconButton
                                            iconName='keyboard_arrow_left_rounded'
                                            isLightTheme
                                            disabled={listingSwiperDetail.isBeginning}
                                            onClick={() => {
                                                listingSwiper.slidePrev();
                                            }}
                                        />
                                    </div>
                                    <Swiper
                                        onUpdate={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiper(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            });
                                        }}
                                        onSwiper={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiper(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            });
                                        }}
                                        spaceBetween={1}
                                        slidesPerView={1}
                                        freeMode={true}
                                        onSlideChange={(swiper) => {
                                            console.log(swiper);
                                            setListingSwiperDetail({
                                                isBeginning: swiper.isBeginning,
                                                isEnd: swiper.isEnd
                                            })
                                        }}
                                        className="mySwiper"
                                    >
                                        {listings.map((listing) => (
                                            <SwiperSlide key={listing.listingId}>
                                                <KalabListingCardWithDetails
                                                    listing={listing}
                                                    changeListingStatus={changeListingStatus}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div>
                                        <KalabIconButton
                                            iconName='keyboard_arrow_right_rounded'
                                            isLightTheme
                                            disabled={listingSwiperDetail.isEnd}
                                            onClick={() => {
                                                listingSwiper.slideNext();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="add-listing">
                                    <KalabSmallButton
                                        text={'Add Listing'}
                                        onClick={() => { handleAddListingBtnClick(); }}
                                        isLightTheme
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='artist-page__bottom'>
                            <div className='artist-page__bottom--card'>
                                <KalabCard
                                    title="Spotify"
                                    description="Stop playing your same old songs on loop and take a listen to the KALAB Spotify playlist filled with latest releases from our independent artist community!"
                                    imageUrl={getImageUrl('Spotify')}
                                    onClick={() => {
                                        openInNewTab(getSettingValue('spotifyUrl'))
                                    }}
                                />
                            </div>
                            <div className='artist-page__bottom--card'>
                                <KalabCard
                                    title="Blog"
                                    description="Keeping up with Kalab! Get the latest news and updates on our bizz, partners, artists, & more!"
                                    imageUrl={getImageUrl('Blog')}
                                    onClick={() => {
                                        openInNewTab(getSettingValue('blogUrl'))
                                    }}
                                />
                            </div>
                            <div className='artist-page__bottom--card'>
                                <KalabCard
                                    title="Our Partners"
                                    description="These rockstar partners are a heaven sent. Check them out & mention you’re a KALAB artist and benefit from a 10% discount! "
                                    imageUrl={getImageUrl('Partners')}
                                    onClick={showPartnerPopUp}
                                />
                            </div>
                        </div>
                        {partnerPopUpShow && <PortaledComponent modal={<PartnerPopUp closeFn={closePartnerPopUp} />} />}
                        {whatsappUpdatePopUpShow && <PortaledComponent modal={<WhatsappUpdatePopUp user={artist} isArtist={true} closeFn={closeWhatsappUpdatePopUp} />} />}
                    </div>
                ) :
                (
                    null
                )}

        </>
    )
}

export default ArtistHomePage