import React, { useState } from 'react'
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core';
import 'react-calendar/dist/Calendar.css';
import Colors from '../../../constants/Colors';
import { BorderColor } from '@material-ui/icons';
import moment from 'moment';
import { isAfter, format } from 'date-fns';

const useStyles = makeStyles({
    root: props => ({
        '& .react-calendar': {
            width: '100%',
            border: 'none',
            padding: props.isSmall ? 2 : 20,

            '& .timing':{
                '& abbr': {
                    textDecoration : 'underline',
                    fontWeight : '500',
                    color : Colors.primary
                }

            },

            '&__navigation': {
                width: props.isSmall ? '100%': '80%',
                margin: '5px auto',
                color: Colors.black,
                '& button': {
                    '&:enabled': {
                        '&:hover': {
                            backgroundColor: Colors.white,
                            color: Colors.primary
                        },
                        '&:focus': {
                            backgroundColor: Colors.white,
                            color: Colors.primary
                        }
                    },
                    '&:disabled': {
                        backgroundColor: Colors.white,
                        color: Colors.lightestGrey
                    }

                },
                '&__label': {
                    fontFamily: 'Montserrat, sans-serif',
                    textTransform: 'capitalize',
                    fontWeight: '300',
                    fontSize: 15,

                    '&:hover': {
                        cursor: 'auto !important'
                    }
                },
                '&__arrow': {
                    fontSize: 25,
                }
            },

            '&__month-view': {
                '&__weekdays': {
                    '&__weekday': {
                        '& abbr': {
                            fontSize: 14,
                            fontWeight: '300',
                            fontFamily: 'Montserrat, sans-serif',
                            textDecoration: 'none'
                        }
                    }
                }
            },

            '&__tile': {
                color: Colors.black,
                padding : 2,
                '& abbr': {
                    border: '1px solid transparent',
                    borderRadius: '100%',
                    backgroundColor: Colors.white,
                    display: 'block',
                    padding: props.isSmall ? 2 : 5,
                    width: props.isSmall ? 25 : 35,
                    margin: 'auto',
                    fontSize: props.isSmall ? 12 : 14,
                    fontWeight: '400',
                    fontFamily: 'Montserrat, sans-serif',

                },

                '&--active':{
                    backgroundColor: Colors.white,
                    '& abbr': {
                        borderColor: Colors.primary + ' !important',
                        backgroundColor: Colors.primary + ' !important',
                        color : Colors.white + ' !important',

                    },
                },

                '&--now': {
                    backgroundColor: Colors.white
                },

                '&:enabled': {
                    
                    '&:hover': {
                        backgroundColor: Colors.white,
                        '& abbr': {
                            borderColor: Colors.lightestBlueishgGrey,
                            backgroundColor: Colors.lightestBlueishgGrey
                        },

                    },

                    '&:focus':{
                        backgroundColor: Colors.white,
                    }

                },

                '&:disabled': {
                    backgroundColor: Colors.white,
                    color: Colors.lightestGrey
                }
            }


        }
    })
});

export default function KalabResidencyCalendar({ startDate, endDate, onChange, timings, selectedDate, isSmall = false}) {
    const classes = useStyles({isSmall});
   
    const getPerformanceDetails= (date) => {
        var dateMoment = moment(date);
        var checkPerformance = false;
        timings.forEach(timing => {
            if (format(timing.date,'yyyy-MM-dd') === dateMoment.format('YYYY-MM-DD')) {
                checkPerformance= true;
            }
        });
        return checkPerformance;
    }
    return (
        <div className={classes.root}>
            <Calendar
                showNavigation={true}
                onClickDay={onChange}
                value={selectedDate}
                view={'month'}
                defaultActiveStartDate={startDate}
                minDate={startDate}
                maxDate={moment(endDate).add(1,'hours').toDate()}
                showNeighboringMonth={false}
                formatShortWeekday={(locale, date) => format(date, isSmall ? 'EEEEE' : 'EEE')}
                formatMonthYear ={(locale, date) => format(date, isSmall ? 'MMM yy' : 'MMMM yyyy') }
                allowPartialRange={true}
                tileClassName={({ date, view }) => view === 'month' && getPerformanceDetails(date) ? 'timing' : null}
            />
        </div>
    )
}
