import React, { useState, useEffect, useContext } from "react";
import './ClientHomePage.scss';
import PortaledComponent from "../elements/portaledComponent";
import PartnerPopUp from "../popUps/PartnerPopUp";
import ClientHeader from "../header/ClientHeader";
import {
    getClientDetailsRequest,
    getCountryCodesRequest,
    checkOTPRequest,
    generateOTPRequest,
    resendOTPRequest,
    updateClientDetailsRequest,
    changeClientPasswordRequest,
    getImageUrl,
    getSettingsRequest
} from '../../webservices/Webservice';
import { openInNewTab } from "../../utils/common";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabTextField from "../kalab/kalabTextField/KalabTextField";
import KalabIconButton from "../kalab/kalabButtons/KalabIconButton";
import KalabSmallButton from "../kalab/kalabButtons/KalabSmallButton";
import KalabDropDown from "../kalab/kalabDropDown/KalabDropDown";
import KalabMainButtonWithIcon from "../kalab/kalabButtons/KalabMainButtonWithIcon";
import KalabCard from "../kalab/kalabCards/KalabCard";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import WhatsappUpdatePopUp from "../popUps/WhatsappUpdatePopUp";

const ClientHomePage = () => {
    const [partnerPopUpShow, setPartnerPopUpShow] = useState(false);
    const [whatsappUpdatePopUpShow, setWhatsappUpdatePopUpShow] = useState(false);
    const { height, width } = useWindowDimensions();
    const [settings, setSettings] = useState([]);
    const [client, setClient] = useState(null)
    const [clientDetails, setClientDetails] = useState(null)
    const [isEdit, setIsEdit] = useState(false);
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const [countryCodeOptions, setCountryCodeOptions] = useState([])
    const [loadingCountryCode, setLoadingCountryCode] = useState(false)
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [isVerify, setIsVerify] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const [verifiedNumbers, setVerifiedNumbers] = useState([]);

    const notify = useNotification();

    const getClient = (firstTime = false) => {
        showLoader();
        getClientDetailsRequest(
            (client) => {
                hideLoader();
                setClient(client);
                setClientDetails(client);
                var vNumbers = verifiedNumbers;
                vNumbers.push({
                    countryCode: client.countryCode,
                    phone: client.phone
                });
                if (!client.whatsappIsSame && client.whatsappCountryCode && client.whatsappPhone) {
                    vNumbers.push({
                        countryCode: client.whatsappCountryCode,
                        phone: client.whatsappPhone
                    });
                }
                setVerifiedNumbers(vNumbers);
                console.log(client);
                if(client.whatsappIsSame === null && firstTime){
                    showWhatsappUpdatePopUp();
                }
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }
    const getSettings = () => {
        showLoader();
        getSettingsRequest(
            (settings) => {
                hideLoader();
                console.log(settings);
                setSettings(settings);
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }
    const changeClientPassword = () => {
        if (isPasswordFormValid()) {
            showLoader();
            changeClientPasswordRequest(
                client.oldPassword,
                client.newPassword,
                (response) => {
                    hideLoader();
                    notify({
                        type: 'SUCCESS',
                        message: response,
                        title: 'Success!!!',
                    });
                    handlePasswordChangeCancelBtn();
                },
                (error) => {
                    notify({
                        type: "ERROR",
                        message: error.message ? error.message : error,
                        title: error.title ? error.title : "Error!!!"
                    })
                    hideLoader();
                    handlePasswordChangeCancelBtn();
                })
        }


    }


    const getCountryCodes = () => {
        setLoadingCountryCode(true)
        getCountryCodesRequest(
            (countryCodes) => {
                var countryCodeOptionsFromAPI = [];
                countryCodes.forEach(countryCode => {
                    if (countryCodeOptionsFromAPI.filter(cCodeOption => cCodeOption.countryCode.countryCode === countryCode.countryCode).length === 0) {
                        countryCodeOptionsFromAPI.push({
                            value: countryCode,
                            label: `+${countryCode.countryCode} ( ${countryCodes.filter(cCode => cCode.countryCode === countryCode.countryCode).map(cntCode => cntCode.countryAsciiName !== "" ? cntCode.countryAsciiName : cntCode.countryName).join(', ')} )`,
                            countryCode
                        })
                    }
                });
                setCountryCodeOptions(countryCodeOptionsFromAPI);
                setLoadingCountryCode(false)
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoadingCountryCode(false)
            }
        )
    }

    const handleInputTextChange = (e) => {
        var updatedClient = {
            ...client
        }
        updatedClient[e.target.id] = e.target.value
        setClient(updatedClient);
    }

    const handleCountryCodeSelectChange = (countryCodeValue) => {
        setClient({
            ...client,
            countryCode: countryCodeValue ? countryCodeValue.value.countryCode : null,
        });
        if (isVerify) {
            setIsVerify(false);
        }
    };

    const editBtnPressed = () => {
        if (isEdit) {
            setClient(clientDetails);
        }
        setIsEdit(!isEdit)
    }
    const getCountryCodeSelectValue = (countryCode) => {
        var countryCodeValues = countryCodeOptions.filter(countryCodeOption => (countryCodeOption.value.countryCode === countryCode));
        return (
            countryCodeValues.length > 0 ? countryCodeValues[0] : null
        )
    }

    const checkPhoneVerified = () => {
        if (
            verifiedNumbers.filter(
                verifiedNumber =>
                (
                    verifiedNumber.countryCode === client.countryCode &&
                    verifiedNumber.phone === client.phone
                )
            ).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const checkOTP = () => {
        showLoader();
        checkOTPRequest(
            client.countryCode,
            client.phone,
            client.otp,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var vNumbers = verifiedNumbers;
                vNumbers.push({
                    countryCode: client.countryCode,
                    phone: client.phone
                });
                setVerifiedNumbers(vNumbers);
                setClient({
                    ...client,
                    otp: null
                })
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleResendOTPButton = (e) => {
        e.preventDefault();
        resendOTP();
    };
    const resendOTP = () => {
        showLoader();
        resendOTPRequest(
            client.countryCode,
            client.phone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                var count = resendCount + 1;
                setResendCount(count);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };
    const handleOTPCheckButton = (e) => {
        e.preventDefault();
        checkOTP();
    };
    const handleVerifyButton = (e) => {
        e.preventDefault();
        generateOTP();
    };
    const generateOTP = () => {
        showLoader();
        generateOTPRequest(
            client.countryCode,
            client.phone,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                setIsVerify(true);
                setResendCount(0);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
            }
        );
    };

    const checkWhatsappIsSame = () => {
        if (
            client.whatsappCountryCode === client.countryCode &&
            client.whatsappPhone === client.phone
        ) {
            return true
        }
        else {
            return false
        }
    }
    const updateClient = () => {
        showLoader();
        updateClientDetailsRequest(
            client.companyFullName,
            client.contactName,
            client.countryCode,
            client.phone,
            checkWhatsappIsSame() ? null : client.whatsappPhone,
            checkWhatsappIsSame() ? null : client.whatsappCountryCode,
            checkWhatsappIsSame(),
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                getClient();
                setIsEdit(false);
            },
            (error) => {
                hideLoader();
                notify({
                    type: 'ERROR',
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : 'Error!!!',
                });
                if (isEdit) {
                    setClient(clientDetails);
                }
                setIsEdit(false);
            }
        );
    };

    const isFormValid = () => {
        if (
            (client.countryCode === clientDetails.countryCode &&
                client.phone === clientDetails.phone &&
                client.companyFullName === clientDetails.companyFullName &&
                client.contactName === clientDetails.contactName) ||
            !checkPhoneVerified()
        ) {
            return false
        }
        else {
            return true
        }
    }

    const passwordValid = () => {
        if (
            client.newPassword &&
            client.newPassword === client.cPassword &&
            client.newPassword !== '' &&
            client.newPassword.length >= 6
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getSettingValue = (settingKey) => {
        var settingValue = ''
        settings.forEach(setting => {
            if (setting.settingKey === settingKey) {
                settingValue = setting.settingValue;
            }
        })
        return settingValue;
    }

    const isPasswordFormValid = () => {
        if (passwordValid() && client.oldPassword && client.oldPassword.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        getSettings();
        getCountryCodes();
        getClient(true);
    }, [])

    const handlePasswordChangeCancelBtn = () => {
        setIsPasswordChange(false)
        setClient({
            ...client,
            oldPassword: null,
            newPassword: null,
            cPassword: null
        })
    }

    const closePartnerPopUp = () => {
        document.body.style.overflow = 'unset';
        setPartnerPopUpShow(false);
    }

    const showPartnerPopUp = () => {
        document.body.style.overflow = 'hidden';
        setPartnerPopUpShow(true);
    }

    const closeWhatsappUpdatePopUp = () => {
        document.body.style.overflow = 'unset';
        getClient();
        setWhatsappUpdatePopUpShow(false);
    }

    const showWhatsappUpdatePopUp = () => {
        document.body.style.overflow = 'hidden';
        setWhatsappUpdatePopUpShow(true);
    }

    const handleSaveBtnPress = () => {
        if (isFormValid()) {
            updateClient();
        }
    }


    return (
        <>
            <ClientHeader />
            {client ?
                (
                    <div className='client-page' style={{ overflow: 'hidden' }}>
                        <div className='client-page__top'>
                            <span className='client-page__top--title'>{client.companyFullName}</span>
                            {!isPasswordChange ?
                                (
                                    <KalabIconButton
                                        onClick={() => {
                                            editBtnPressed();
                                        }}
                                        iconName={isEdit ? 'close' : 'edit_outlined'}
                                        isLightTheme={true}
                                        iconSize={15}
                                        height={10}
                                        noBorder
                                    />
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                        <div className="client-page__content">

                            <div className='client-page__content--form'>
                                <div className='client-page__content--form_row'>
                                    <div style={{ flex: 1, margin: '10px 0px' }}>
                                        <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                            <KalabTextField
                                                disabled={!isEdit}
                                                type="text"
                                                id="companyFullName"
                                                placeholder={client.isEnterprise ? "Company Name" : "Full Name"}
                                                value={client.companyFullName}
                                                onChange={handleInputTextChange}
                                                isLightTheme={true}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, margin: '10px 0px' }}>
                                        {
                                            client.isEnterprise ?
                                                (

                                                    <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                        <KalabTextField
                                                            disabled={!isEdit}
                                                            type="text"
                                                            id="contactName"
                                                            placeholder="Contact Name"
                                                            value={client.contactName}
                                                            onChange={handleInputTextChange}
                                                            isLightTheme={true}
                                                        />
                                                    </div>

                                                )
                                                :
                                                (
                                                    <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                        <KalabTextField
                                                            disabled={true}
                                                            type="text"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={client.email}
                                                            onChange={handleInputTextChange}
                                                            isLightTheme={true}
                                                        />
                                                    </div>
                                                )
                                        }
                                    </div>

                                </div>
                                <div className='client-page__content--form_row'>
                                    <div style={{ flex: 1, margin: '10px 0px', width: width > 700 ? '50%' : 'unset' }}>
                                        <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                            <KalabDropDown
                                                placeholder="Country Code"
                                                isDisabled={!isEdit}
                                                isLoading={loadingCountryCode}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="countryCode"
                                                onChange={handleCountryCodeSelectChange}
                                                options={countryCodeOptions}
                                                isLightTheme={true}
                                                value={getCountryCodeSelectValue(client.countryCode)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, margin: '10px 0px' }}>
                                        <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                            <KalabTextField
                                                disabled={!isEdit}
                                                type='number'
                                                id="phone"
                                                placeholder="Phone"
                                                value={client.phone}
                                                onChange={(e) => { handleInputTextChange(e); setIsVerify(false); }}
                                                isLightTheme={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='client-page__content--form_row' style={{ margin: 0 }}>
                                    <div className='whatsapp-status'>
                                        <WhatsAppIcon className="icon" />
                                        <div className="text">{client.whatsappCountryCode && client.whatsappPhone ? (`WhatsApp - +${client.whatsappCountryCode} ${client.whatsappPhone}`) : ('WhatsApp notifications not enabled')}</div>
                                        {
                                            isEdit || isPasswordChange ?
                                                (
                                                    null
                                                )
                                                :
                                                (
                                                    <KalabSmallButton
                                                        text={'Update'}
                                                        isLightTheme
                                                        noBorder
                                                        onClick={showWhatsappUpdatePopUp}
                                                    />
                                                )
                                        }

                                    </div>
                                </div>




                                {isPasswordChange ? (
                                    <>
                                        <div className='client-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '10px 0px' }}>
                                                <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                    <KalabTextField
                                                        type="password"
                                                        id="oldPassword"
                                                        placeholder="Old Password"
                                                        value={client.oldPassword ? client.oldPassword : ''}
                                                        onChange={handleInputTextChange}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '10px 0px' }}>
                                                <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                    <KalabTextField
                                                        type="password"
                                                        id="newPassword"
                                                        placeholder="New Password"
                                                        value={client.newPassword ? client.newPassword : ''}
                                                        onChange={handleInputTextChange}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='client-page__content--form_row'>
                                            <div style={{ flex: 1, margin: '10px 0px' }}>
                                                <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                    <KalabTextField
                                                        type="password"
                                                        id="cPassword"
                                                        placeholder="Confirm New Password"
                                                        value={client.cPassword ? client.cPassword : ''}
                                                        onChange={handleInputTextChange}
                                                        isLightTheme={true}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ flex: 1, margin: '10px 0px' }}>
                                                <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                    {
                                                        client.newPassword && client.newPassword.length > 0 ?
                                                            (
                                                                passwordValid() ?
                                                                    (<><i className="fas fa-check-circle register-icon" /><span className='verified-text'>Password Matches</span></>)
                                                                    : ((client.newPassword.length < 6) ?
                                                                        (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Need minimum 6 characters</span></>)
                                                                        :
                                                                        (<><i className="fas fa-exclamation-circle register-icon" /><span className='verified-text'>Passwords are not matching</span></>)
                                                                    )
                                                            )
                                                            :
                                                            ('')
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                ) :
                                    (null)}



                                <div className='client-page__content--form_row' style={{ flexDirection: 'row-reverse' }}>

                                    <div style={{ flex: 1, margin: '10px 0px', marginBottom: width <= 700 ? 30 : 10 }}>
                                        {!isEdit ?
                                            (
                                                isPasswordChange ? (
                                                    <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 10px' }}>
                                                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                            <KalabSmallButton
                                                                text={'Save Password'}
                                                                disabled={!isPasswordFormValid()}
                                                                onClick={() => { changeClientPassword(); }}
                                                                isLightTheme
                                                            /></div>
                                                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                            <KalabSmallButton
                                                                text={'Cancel'}
                                                                onClick={() => { handlePasswordChangeCancelBtn() }}
                                                                isLightTheme
                                                            /></div>
                                                    </div>
                                                ) :
                                                    (
                                                        <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                            <KalabSmallButton
                                                                text={'Change Password'}
                                                                onClick={() => { setIsPasswordChange(true) }}
                                                                isLightTheme
                                                            />

                                                        </div>
                                                    )
                                            )
                                            :
                                            (
                                                checkPhoneVerified() ?
                                                    (
                                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <i className="fas fa-check-circle register-icon" /><span className='verified-text'>Verified</span>
                                                        </div>
                                                    )
                                                    :
                                                    (isVerify ?
                                                        (
                                                            <div style={{ width: '75%', margin: '0 auto', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'center' }}>
                                                                <div style={{ flex: 1, display: 'flex' }}>
                                                                    <KalabTextField
                                                                        type="number"
                                                                        id="otp"
                                                                        placeholder="OTP"
                                                                        isLightTheme
                                                                        value={client.otp ? client.otp : ''}
                                                                        onChange={handleInputTextChange}
                                                                    />
                                                                </div>
                                                                <div style={{ marginLeft: 5, marginRight: 5, alignSelf: 'flex-end' }}>
                                                                    <KalabSmallButton
                                                                        text="check"
                                                                        isLightTheme
                                                                        onClick={handleOTPCheckButton}
                                                                        disabled={client.otp === ""}
                                                                    />
                                                                </div>
                                                                <div style={{ alignSelf: 'flex-end' }}>
                                                                    <KalabSmallButton
                                                                        text="Resend"
                                                                        isLightTheme
                                                                        onClick={handleResendOTPButton}
                                                                        disabled={resendCount > 2 ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center' }}>
                                                                <KalabSmallButton
                                                                    text="Verify"
                                                                    isLightTheme
                                                                    onClick={handleVerifyButton}
                                                                    disabled={client.phone === "" || client.countryCode === null}
                                                                />
                                                            </div>
                                                        ))
                                            )
                                        }


                                    </div>
                                    <div style={{ flex: 1, margin: '10px 0px' }}>
                                        {
                                            client.isEnterprise ?
                                                (
                                                    <div style={{ margin: '0 auto', textAlign: 'center', padding: '0 10px' }}>
                                                        <KalabTextField
                                                            disabled={true}
                                                            type="text"
                                                            id="email"
                                                            placeholder="Email"
                                                            value={client.email}
                                                            onChange={handleInputTextChange}
                                                            isLightTheme={true}
                                                        />
                                                    </div>
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }

                                    </div>
                                </div>
                                {isEdit ? (
                                    <div className='client-page__content--form_row'>
                                        <div style={{ flex: 1, margin: '0 auto', textAlign: 'center', padding: '0 10px', display: 'flex', justifyContent: 'center' }}>
                                            <KalabMainButtonWithIcon
                                                disabled={!isFormValid()}
                                                onClick={() => { handleSaveBtnPress(); }}
                                                text={'Save'}
                                                iconName={'check'}
                                            />
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>
                        <div className='client-page__bottom'>
                            <div className='client-page__bottom--card'>
                                <KalabCard
                                    title="Spotify"
                                    description="Stop playing your same old songs on loop and take a listen to the KALAB Spotify playlist filled with latest releases from our independent artist community!"
                                    imageUrl={getImageUrl('Spotify')}
                                    onClick={() => {
                                        openInNewTab(getSettingValue('spotifyUrl'))
                                    }}
                                />
                            </div>
                            <div className='client-page__bottom--card'>
                                <KalabCard
                                    title="Blog"
                                    description="Keeping up with Kalab! Get the latest news and updates on our bizz, partners, artists, & more!"
                                    imageUrl={getImageUrl('Blog')}
                                    onClick={() => {
                                        openInNewTab(getSettingValue('blogUrl'))
                                    }}
                                />
                            </div>
                            <div className='client-page__bottom--card'>
                                <KalabCard
                                    title="Our Partners"
                                    description="These rockstar partners are a heaven sent. Check them out & mention you’re a KALAB client and benefit from a 10% discount! "
                                    imageUrl={getImageUrl('Partners')}
                                    onClick={showPartnerPopUp}
                                />
                            </div>
                        </div>
                        {partnerPopUpShow && <PortaledComponent modal={<PartnerPopUp closeFn={closePartnerPopUp} />} />}
                        {whatsappUpdatePopUpShow && <PortaledComponent modal={<WhatsappUpdatePopUp user={client} isArtist={false} closeFn={closeWhatsappUpdatePopUp} />} />}
                    </div>

                )
                :
                (
                    null
                )}

        </>
    )
}

export default ClientHomePage;
