import React from 'react'
import Select, { components } from 'react-select';
import Colors from '../../../constants/Colors';
import { makeStyles } from '@material-ui/core';
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();
const styles = {
    placeholder: (base, state) => ({
        ...base,
        opacity: 1,
        display:
            state.isFocused || state.isSelected || state.selectProps.inputValue
                ? 'block'
                : 'block',
    }),
}
const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        backgroundColor: 'transparent',
        color: Colors.lightGrey,
        '& .kalab_search_select__control': {
            backgroundColor: 'transparent',
            borderRadius: 0,
            borderWidth: 0,
            boxShadow: 'none',
            margin: '0 auto',
            position: 'unset'
        },
        '& .kalab_search_select__control--menu-is-open': {
            width: '100%'

        },
        '& .kalab_search_select__value-container': {
            justifyContent: 'center',
            position: 'inherit',
        },
        '& .kalab_search_select__single-value': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 14,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
            position: 'unset',
            top : 'unset',
            transform: 'unset',
            flex : 1,
            textAlign: 'center'
        },
        '& .kalab_search_select__placeholder':{
            fontSize: 14,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
            color: Colors.lightGrey
        },
        '& .kalab_search_select__menu-notice':{
            fontSize: 14,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
            color: Colors.lightGrey
        },
        '& .kalab_search_select__input': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 14,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& .kalab_search_select__indicators': {
            '& .kalab_search_select__indicator-separator': {
                display : 'none'
            },
            '& .kalab_search_select__indicator': {
                '&:hover': {
                    color: props.isLightTheme ? Colors.black : Colors.white,
                },
                color: Colors.lightGrey
            }
        },
        '& .kalab_search_select__menu-list': {
            maxHeight: 200,
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '& .kalab_search_select__option': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 14,
                fontWeight: '300'
            },
            '& .kalab_search_select__option--is-focused': {
                backgroundColor: Colors.primaryLightest
            },
            '& .kalab_search_select__option--is-selected': {
                backgroundColor: Colors.primaryLight
            }

        },
        '& .kalab_search_select__multi-value': {
            backgroundColor: 'transparent',
            '& .kalab_search_select__multi-value__label': {
                color: props.isLightTheme ? Colors.black : Colors.white,
                fontSize: 14,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            '& .kalab_search_select__multi-value__remove': {
                backgroundColor: 'transparent',
                '&:hover': {
                    color: props.isLightTheme ? Colors.black : Colors.white,
                }
            }
        }

    })
});

export default function KalabSearchDropDown({
    className = "",
    placeholder,
    isDisabled,
    isLoading,
    isClearable,
    isRtl,
    isSearchable,
    name,
    onChange,
    value,
    options,
    isMulti = false,
    isLightTheme = false
}) {
    const props = { isLightTheme }
    const classes = useStyles(props);
    return (
        <Select
            className={`${classes.root} ${className}`}
            classNamePrefix="kalab_search_select"
            styles={styles}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name={name}
            onChange={onChange}
            value={value}
            options={options}
            isMulti={isMulti}
        />

    )
}
