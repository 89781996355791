var RegularExpression = require('./RegularExpression');


module.exports.isMusicLinkSupported = (link) => {
    if (
        RegularExpression.youtube.test(link) ||
        RegularExpression.spotify.test(link) ||
        RegularExpression.soundcloud.test(link) ||
        RegularExpression.applemusic.test(link) ||
        RegularExpression.mixcloud.test(link)
    ) {
        return true
    }
    else {
        return false
    }

}


module.exports.checkLinkPlatform = (link) => {
    console.log(link)
    if (RegularExpression.spotify.test(link)) {
        return ({ className: "fab fa-spotify", platform: 'spotify' })
    }
    else if (RegularExpression.youtube.test(link)) {
        return ({ className: "fab fa-youtube", platform: 'youtube' })
    }
    else if (RegularExpression.soundcloud.test(link)) {
        return ({ className: "fab fa-soundcloud", platform: 'sound-cloud' })
    }
    else if (RegularExpression.applemusic.test(link)) {
        return ({ className: "fab fa-apple", platform: 'apple-music' })
    }
    else if (RegularExpression.mixcloud.test(link)) {

        return ({ className: "fab fa-mixcloud", platform: 'mix-cloud' })
    }
    else {
        return ({ className: "fas fa-link", platform: '' })
    }

}