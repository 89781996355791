import React from 'react'
import Spinner from "../../images/spinner.gif";
import './Loader.scss';

export default function Loader() {
    return (
        <div className="fp-container">
            <img src={Spinner}  className="fp-loader" alt="loading" />           
        </div>
    )
}
