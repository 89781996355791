import React, { useState, useContext } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import KalabCalculatorDropDown from '../kalabDropDown/KalabCalculatorDropDown';
import { getLabelFromValue } from '../../../utils/Currencies';
import Colors from '../../../constants/Colors';
import { DataStoreContext } from '../../../contexts/DataStoreContext';
import KalabMainButton from '../kalabButtons/KalabMainButton';

const useStyles = makeStyles({
    root: props => ({
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        height: 'fit-content',
        border: '1px solid rgb(227, 227, 227)',
        borderRadius: 10,
        padding: 30,
        '& .title': {
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1.6vw',
            fontWeight: '500',
            '@media only screen and (max-width: 850px)' : {
                fontSize: 16
            },
            marginBottom: 30
        },
        '& .drop-down': {

        },
        '& .price-view': {
            paddingTop: 30,
            paddingBottom: 20,

            '& .artist-price': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10,
                '&__title': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: '300',
                    color: Colors.lightGrey
                },
                '&__value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: '400',
                }
            },
            '& .service-price': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&__title': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: '300',
                    color: Colors.lightGrey
                },
                '&__value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: '400',
                }
            },
            '& .total-price': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 20,
                paddingTop: 10,
                borderTop: '1px solid #f4f4f4',
                '&__value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 15,
                    fontWeight: '600',
                }
            },
            '& .btns': {
                marginTop: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: props.isEnterprise ? 'space-between': 'center',

                '&__book-now': {
                    marginLeft: 10,
                    '&--btn': {
                        padding: 10
                    }
                },
                '&__add-to-wishlist': {
                    marginRight: 10,
                    '&--btn': {
                        padding: 10
                    }

                }
            },

        },
    }),
    animatedShow: {
        animation: "$showPriceView 1s ease-in-out"
    },

    animatedHide: {
        animation: "$hidePriceView 1s ease"
    },
    '@keyframes showPriceView': {
        '0%': {
            opacity: 0,
            maxHeight: 0
        },
        '100%': {
            opacity: 1,
            maxHeight: 1000
        }
    },
    '@keyframes hidePriceView': {
        '0%': {
            opacity: 1,
            height: 100
        },
        '100%': {
            opacity: 0,
            height: 0
        }
    }
})

export default function KalabCalculatorCard({ artistTypes, isPreview = false, addToWishList, requestToBook, isEnterprise = false }) {
    const [artistType, setArtistType] = useState(null)
    const { settings } = useContext(DataStoreContext);
    const handleArtistTypeChange = (value) => {
        setArtistType(value);
    }

    const getServiceFeePercentage = () => {
        var serviceFeePercentage = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeePercentage') {
                serviceFeePercentage = parseFloat(setting.settingValue);
            }
        })
        return serviceFeePercentage
    }
    const getServiceFeeForBookingLink = () => {
        var serviceFeeForBookingLink = 0
        settings.forEach(setting => {
            if (setting.settingKey === 'serviceFeeForBookingLink') {
                serviceFeeForBookingLink = parseFloat(setting.settingValue);
            }
        })
        return serviceFeeForBookingLink
    }

    const getPriceDetails = () => {
        var price = parseFloat(getArtistType().cost ? getArtistType().cost : getArtistType().price);
        var serviceFeePercentage = getServiceFeePercentage();
        var serviceFee = price * serviceFeePercentage / 100;
        var totalPrice = price + serviceFee;

        return {
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }

    const getArtistType = () => {
        var resultArtistType = null
        artistTypes.forEach(singleArtistType => {
            if (singleArtistType.artistTypeId === artistType.value) {
                resultArtistType = singleArtistType
            }
        })
        return resultArtistType
    }
    const artistTypeOptions = () => {
        var artistTypesOptions = [];
        artistTypes.forEach(artistType => {
            artistTypesOptions.push({
                value: artistType.artistTypeId,
                label: artistType.artistTypeName
            })

        });
        return artistTypesOptions;
    }
    const classes = useStyles({isEnterprise});
    return (
        <div className={classes.root}>
            <div className="title">
                Get an instant quote
            </div>
            <div className="drop-down">
                <KalabCalculatorDropDown
                    value={artistType}
                    options={artistTypeOptions()}
                    onChange={handleArtistTypeChange}
                    placeholder='Artist Type'
                />
            </div>
            <div className={artistType ? classes.animatedShow : classes.animatedHide}>
                {artistType ? (
                    <div className="price-view">
                        <div className="artist-price">
                            <div className="artist-price__title">Artist Price (Starting From)</div>
                            <div className="artist-price__value">{getLabelFromValue(getArtistType().currency)} {getPriceDetails().price}</div>
                        </div>
                        <div className="service-price">
                            <div className="service-price__title">Service Fee</div>
                            <div className="service-price__value">{getLabelFromValue(getArtistType().currency)} {getPriceDetails().serviceFee}</div>
                        </div>
                        <div className="total-price">
                            <div className="total-price__value">{getLabelFromValue(getArtistType().currency)} {getPriceDetails().totalPrice}</div>
                        </div>
                        {
                            !isPreview ?
                                (
                                    <div className='btns'>
                                        {isEnterprise ?
                                            (
                                                <div className='btns__add-to-wishlist'>
                                                    <KalabMainButton
                                                        text='Wishlist'
                                                        fontSize={13}
                                                        className='btns__add-to-wishlist--btn'
                                                        onClick={addToWishList}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                        }

                                        <div className='btns__book-now'>
                                            <KalabMainButton
                                                text='Request to book'
                                                fontSize={13}
                                                className='btns__book-now--btn'
                                                onClick={requestToBook}
                                            />
                                        </div>

                                    </div>
                                )
                                :
                                (null)
                        }

                    </div>
                ) : (
                    null
                )}
            </div>
        </div>
    )
}
