import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';
import Colors from '../../../constants/Colors';
import { openInNewTab } from '../../../utils/common';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        width: '100%',
        aspectRatio: '4 / 3',
        '&:hover': {
            cursor:'pointer',
            '& .listing-card__front': {
                opacity: 0
            },
            '& .listing-card__back': {
              
            }
        },
        '& .listing-card': {
            position: 'relative',
            width: '100%',
            height: '100%',
            textAlign: 'center',

            '&__front': {
                borderRadius: 2,
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                transition: 'opacity 300ms ease-in',
                '&--image': {
                    width: '100%',
                    height: '100%',
                    borderRadius: 2,
                    objectFit:'cover',
                }
            },
            '&__back': {
                borderRadius: 2,
                backgroundColor: '#000000',
                backgroundImage: 'linear-gradient(215deg, #000000 0%, #052034 100%)',
                width: '100%',
                height: '100%',
                display:'flex',
                padding :10,
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                transition:'box-shadow 500ms',
                '&--location': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 12,
                    fontWeight:'200',
                    color:Colors.white,
                    wordBreak:'break-word',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    '-webkit-line-clamp': 2
                },
                '&--name': {
                    textTransform: 'uppercase',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 20,
                    marginTop: 15,
                    marginBottom: 15,
                    fontWeight:'300',
                    color:Colors.primary,
                    wordBreak:'break-word',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    '-webkit-line-clamp': 2
                    
                },
                '&--genres': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 12,
                    fontWeight:'200',
                    color:Colors.white,
                    wordBreak:'break-word',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    '-webkit-line-clamp': 2
                }
            }

        }

    },


})

export default function KalabListingCard({ listing }) {
    const classes = useStyles();

    const handleClickOnCard = () =>{
        openInNewTab(window.location.origin+'/listing/'+listing.listingToken);
    }


    return (
        <div className="aspectratio ar4-3">
        <div className={classes.root} onClick={handleClickOnCard}>
            <div className="listing-card">
                <div className="listing-card__front">
                    <img
                        className="listing-card__front--image"
                        alt="slider"
                        src={listing.listingImages[0].listingImageUrl}
                    />
                </div>
                <div className="listing-card__back">
                    <p className="listing-card__back--location">{listing.city}, {listing.country}</p>
                    <h1 className="listing-card__back--name">
                        {listing.listingName}
                    </h1>
                    <p className="listing-card__back--genres">{listing.genres.map(genre => (genre.genreName)).join(', ')}</p>
                </div>
            </div>
        </div>
        </div>
    )
}
