import React from 'react'
import ImageWithCrop from '../elements/ImageWithCrop'
import defaultImage from '../../images/default-img.png';

export default function CollageImage3({ images, setImages, noCropOrEdit }) {
    const setImage = (id, image) => {
        var updatedImages = [];
        images.forEach(image => {
            if (image.id !== id || image.collageId !== 3) {
                updatedImages.push(image)
            }
        });
        updatedImages.push({
            collageId: 3,
            id,
            image
        })
        setImages(updatedImages);
    }
    const getImage = (id) => {
        var resultImage = {
            preview: '',
            rawUrl: '',
            previewFile: null,
            raw: null
        }
        images.forEach(image => {
            if (image.id === id && image.collageId === 3) {
                resultImage = image.image
            }
        });
        return (resultImage);
    }
    return (
        <div className="aspectratio ar3-1">
            <div className="image-box">
                <div style={{ flex: 2, height: '100%', position: 'relative', padding: '0.5%' }}>
                    {noCropOrEdit ? (
                        <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(1).preview !== "") ? getImage(1).preview : defaultImage} />
                    ) : (
                        <ImageWithCrop cropRatio={1.5 / 1} id={1} image={getImage(1)} setImage={setImage} />
                    )}
                </div>
                <div style={{ flex: 1, height: '100%', position: 'relative', padding: '0.5%' }}>
                    {noCropOrEdit ? (
                        <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(2).preview !== "") ? getImage(2).preview : defaultImage} />
                    ) : (
                        <ImageWithCrop cropRatio={0.75 / 1} id={2} image={getImage(2)} setImage={setImage} />
                    )}
                </div>
                <div style={{ flex: 1, height: '100%', position: 'relative', padding: '0.5%' }}>
                    {noCropOrEdit ? (
                        <img className="collage-image-no-edit" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(getImage(3).preview !== "") ? getImage(3).preview : defaultImage} />
                    ) : (
                        <ImageWithCrop cropRatio={0.75 / 1} id={3} image={getImage(3)} setImage={setImage} />
                    )}
                </div>
            </div>
        </div>
    )
}
