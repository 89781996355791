import React, { useEffect, useState, useContext } from 'react';
import { scroller } from 'react-scroll';

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';

import './HomePage.scss';

import MainHeader from '../header/MainHeader';
import SearchBar from '../searchBar/SearchBar';
import KalabValueCard from '../kalab/kalabCards/KalabValueCard';
import KalabSubValueCard from '../kalab/kalabCards/KalabSubValueCard';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import { DataStoreContext } from "../../contexts/DataStoreContext";
import { getLatestListingsRequest, getImportantClientPartnersRequest } from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabListingCard from '../kalab/kalabCards/KalabListingCard';
import KalabClientPartnerCard from '../kalab/kalabCards/KalabClientPartnerCard';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import { getImageUrl } from '../../webservices/Webservice';
import { useHistory } from "react-router-dom";
import { openInNewTab } from '../../utils/common';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabAnimatedDiv from '../kalab/kalabAnimated/KalabAnimatedDiv';

SwiperCore.use([Pagination, Navigation]);

function HomePage() {
  const { height, width } = useWindowDimensions();
  let history = useHistory();
  const [offSet, setOffSet] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [listingSwiper, setListingSwiper] = useState(null);
  const [listingSwiperDetail, setListingSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });
  const [mainValueSwiper, setMainValueSwiper] = useState(null);
  const [mainValueSwiperDetail, setMainValueSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });
  const [valueSwiper, setValueSwiper] = useState(null);
  const [valueSwiperDetail, setValueSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });
  const [partnerSwiper, setPartnerSwiper] = useState(null);
  const [partnerSwiperDetail, setPartnerSwiperDetail] = useState({
    isBeginning: true,
    isEnd: true
  });
  const [listings, setListings] = useState([]);
  const [clientPartners, setClientPartners] = useState([]);
  const notify = useNotification();
  const { artistTypes, eventTypes, genres, ambiences, countries, loading } = useContext(DataStoreContext);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const getLatestListings = () => {
    showLoader();
    getLatestListingsRequest(listings => {
      console.log(listings);
      setListings(listings);
      hideLoader();
    },
      (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      })
  }
  const getClientPartners = () => {
    showLoader();
    getImportantClientPartnersRequest(clientPartners => {
      console.log(clientPartners);
      setClientPartners(clientPartners);
      hideLoader();
    },
      (error) => {
        notify({
          type: "ERROR",
          message: error.message ? error.message : error,
          title: error.title ? error.title : "Error!!!"
        })
        hideLoader();
      })
  }
  const handleSubValueCardClick = (id, name, value) => {
    var data = {
    }
    data[value] = [
      {
        label: name,
        value: id
      }
    ]
    history.push({
      pathname: '/search-results',
      state: data
    })
  }

  const handleClickOnFooterLinks = (linkRelativePath) => {
    openInNewTab(window.location.origin + '/' + linkRelativePath);
  }

  const handleSearchBtnClick = () => {
    history.push({
      pathname: '/search-results',
      state: {
        country: null,
        cities: [],
        artistTypes: [],
        genres: [],
        date: null
      }
    })
  }

  useEffect(() => {
    getLatestListings();
    getClientPartners();
    window.onscroll = () => {
      setOffSet(window.pageYOffset);
    };
  }, []);

  const Cards = [
    {
      id: 1,
      title: 'Event Type',
      description: 'Explore all event types',
      imageKey: 'EventType',
      prefix: 'eventType',
      values: eventTypes
    },
    {
      id: 2,
      title: 'Ambience',
      description: 'Explore all ambiences',
      imageKey: 'Ambience',
      prefix: 'ambience',
      values: ambiences
    },
    {
      id: 3,
      title: 'Genre',
      description: 'Explore all genres',
      imageKey: 'Genre',
      prefix: 'genre',
      values: genres
    },
    {
      id: 4,
      title: 'Artist Type',
      description: 'Explore all artist types',
      imageKey: 'ArtistType',
      prefix: 'artistType',
      values: artistTypes
    },
  ];

  // Auto scrolling after click button expand/hide in main block
  const scrollToExpandedSection = () => {
    scroller.scrollTo('expand-section', {
      duration: 1000,
      delay: 100,
      smooth: 'easeInOutQuart',
      offset: -100,
    });
  };

  const scrollToMainSection = () => {
    scroller.scrollTo('main-block', {
      duration: 100,
      delay: 100,
      smooth: 'easeInOutQuart',
      offset: -250,
    });
  };

  return (
    <>
      <div className="home-page__dark-background">
        <MainHeader fixed={offSet > 500 ? true : false} isVisibleSearch={offSet > 500 ? true : false} />
        <div className="home-page__banner">
          <div className="home-page__banner--view">
            <KalabAnimatedDiv flyIn={false} className="home-page__banner--view__text">
              {/* <span>Book the ideal musician</span>
              <br />
              <span className="">for your&nbsp;</span>
              <span className="banner-words">
                <span></span>
              </span> */}
              <span>READY TO EXPERIENCE?</span>
            </KalabAnimatedDiv>
          </div>
          {/* Search bar */}
          <div
            className={`${width > 600 && offSet > 500
              ? 'animation-hidden-bar'
              : 'animation-show-bar'
              }`}
          >
            {
              width > 600 ?
                (
                  offSet > 500
                    ?
                    (
                      null
                    )
                    :
                    (
                      <SearchBar />
                    )
                )
                :
                (
                  <KalabMainButton
                    text={'Discover artists'}
                    onClick={handleSearchBtnClick}
                  />
                )
            }
          </div>
        </div>
        <div className="main-block">
          <div className="main-block__area">
            {
              width / 250 > Cards.length
                ?
                (
                  Cards.map((item) => (
                    <div className="main-block__area--card">
                      <KalabValueCard
                        title={item.title}
                        imageUrl={getImageUrl(item.imageKey)}
                        onClick={
                          async () => {
                            await setSelectedCard(item);
                            scrollToExpandedSection();
                          }
                        }
                      />
                    </div>
                  ))
                )
                :
                (
                  <div className="main-value-swiper">
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_left_rounded'
                        disabled={mainValueSwiperDetail.isBeginning}
                        isLightTheme
                        onClick={() => {
                          mainValueSwiper.slidePrev();
                        }}
                      />
                    </div>
                    <Swiper
                      onUpdate={(swiper) => {
                        console.log(swiper);
                        setMainValueSwiper(swiper);
                        setMainValueSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      onSwiper={(swiper) => {
                        console.log(swiper);
                        setMainValueSwiper(swiper);
                        setMainValueSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        });
                      }}
                      spaceBetween={5}
                      slidesPerView={Math.floor(width / 250) === 0 ? 1 : Math.floor(width / 250)}
                      freeMode={true}
                      onSlideChange={(swiper) => {
                        console.log(swiper);
                        setMainValueSwiperDetail({
                          isBeginning: swiper.isBeginning,
                          isEnd: swiper.isEnd
                        })
                      }}
                      className="mySwiper"
                    >
                      {Cards.map((item) => (
                        <SwiperSlide key={item.title}>
                          <KalabValueCard
                            title={item.title}
                            imageUrl={getImageUrl(item.imageKey)}
                            onClick={
                              async () => {
                                await setSelectedCard(item);
                                scrollToExpandedSection();
                              }
                            }
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div>
                      <KalabIconButton
                        iconName='keyboard_arrow_right_rounded'
                        disabled={mainValueSwiperDetail.isEnd}
                        isLightTheme
                        onClick={() => {
                          mainValueSwiper.slideNext();
                        }}
                      />
                    </div>
                  </div>
                )}
            {
              selectedCard !== null ?
                (
                  <div
                    id="expand"
                    className="expand-section"
                  >
                    <div className="aspectratio ar3-1">
                      <div
                        className="expand-section__image-view"
                        style={{
                          backgroundImage: `url(${getImageUrl(selectedCard.imageKey)})`
                        }}
                      >
                        <p className="expand-section__image-view--title">
                          {selectedCard.title}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="expand-section__detail">
                        <p className="expand-section__detail--description">
                          {selectedCard.description}
                        </p>
                        <KalabIconButton
                          iconName='keyboard_arrow_up_rounded'
                          iconSize={20}
                          isLightTheme
                          noBorder
                          onClick={async () => {
                            await setSelectedCard(null);
                            scrollToMainSection();
                          }}
                        />
                      </div>
                      <div className='values-swiper'>
                        <div>
                          <KalabIconButton
                            iconName='keyboard_arrow_left_rounded'
                            isLightTheme
                            disabled={valueSwiperDetail.isBeginning}
                            onClick={() => {
                              valueSwiper.slidePrev();
                            }}
                          />
                        </div>
                        <Swiper
                          onUpdate={(swiper) => {
                            console.log(swiper);
                            setValueSwiper(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            });
                          }}
                          onSwiper={(swiper) => {
                            console.log(swiper);
                            setValueSwiper(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            });
                          }}
                          centeredSlides={false}
                          onSlideChange={(swiper) => {
                            console.log(swiper);
                            setValueSwiperDetail({
                              isBeginning: swiper.isBeginning,
                              isEnd: swiper.isEnd
                            })
                          }}
                          spaceBetween={20}
                          slidesPerView={Math.floor(width / 225) === 0 ? 1 : Math.floor(width / 225)}
                          freeMode={true}
                          watchSlidesVisibility={true}
                          className="mySwiper"
                        >
                          {selectedCard.values.map((value) => (
                            <SwiperSlide key={value[`${selectedCard.prefix}Id`]} >
                              <KalabSubValueCard
                                title={value[`${selectedCard.prefix}Name`]}
                                onClick={() => { handleSubValueCardClick(value[`${selectedCard.prefix}Id`], value[`${selectedCard.prefix}Name`], `${selectedCard.prefix}s`) }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <div>
                          <KalabIconButton
                            iconName='keyboard_arrow_right_rounded'
                            isLightTheme
                            disabled={valueSwiperDetail.isEnd}
                            onClick={() => {
                              console.log(valueSwiper);
                              valueSwiper.slideNext();
                            }}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                )
                :
                (
                  null
                )
            }
          </div>
        </div>
        {/* Carousel Block */}
        <div className="listing-block">
          <div className="listing-block__header">
            <p className="listing-block__header--title">our latest members</p>
            <p className="listing-block__header--description">latest additions to our artist community</p>
          </div>
          <div className="listing-swiper">
            <div>
              <KalabIconButton
                iconName='keyboard_arrow_left_rounded'
                disabled={listingSwiperDetail.isBeginning}
                onClick={() => {
                  listingSwiper.slidePrev();
                }}
              />
            </div>
            <Swiper
              onUpdate={(swiper) => {
                console.log(swiper);
                setListingSwiper(swiper);
                setListingSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                });
              }}
              onSwiper={(swiper) => {
                console.log(swiper);
                setListingSwiper(swiper);
                setListingSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                });
              }}
              spaceBetween={5}
              slidesPerView={width / 300 < 4 ? (Math.floor(width / 300) === 0 ? 1 : Math.floor(width / 300)) : 4}
              freeMode={true}
              onSlideChange={(swiper) => {
                console.log(swiper);
                setListingSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                })
              }}
              className="mySwiper"
            >
              {listings.map((listing) => (
                <SwiperSlide key={listing.listingId}>
                  <KalabListingCard
                    listing={listing}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div>
              <KalabIconButton
                iconName='keyboard_arrow_right_rounded'
                disabled={listingSwiperDetail.isEnd}
                onClick={() => {
                  listingSwiper.slideNext();
                }}
              />
            </div>
          </div>
        </div>
        <div className="partner-block">
          <div className="partner-block__header">
            <p className="partner-block__header--title">
              our&nbsp;clients
            </p>
          </div>
          <div className="partner-swiper">
            <div>
              <KalabIconButton
                iconName='keyboard_arrow_left_rounded'
                disabled={partnerSwiperDetail.isBeginning}
                isLightTheme
                onClick={() => {
                  partnerSwiper.slidePrev();
                }}
              />
            </div>
            <Swiper
              onUpdate={(swiper) => {
                setPartnerSwiper(swiper);
                setPartnerSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                });
              }}
              onSwiper={(swiper) => {
                setPartnerSwiper(swiper);
                setPartnerSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                });
              }}
              spaceBetween={10}
              slidesPerView={width / 125 > 6 ? 6 : (Math.floor(width / 125) === 0 ? 1 : Math.floor(width / 125))}
              onSlideChange={(swiper) => {
                setPartnerSwiperDetail({
                  isBeginning: swiper.isBeginning,
                  isEnd: swiper.isEnd
                });
              }}
              freeMode={true}
              className="mySwiper"
            >
              {clientPartners.map((clientPartner) => (
                <SwiperSlide key={clientPartner.impPartnerId}>
                  <KalabClientPartnerCard
                    imageUrl={getImageUrl(clientPartner.imageKey)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div>
              <KalabIconButton
                iconName='keyboard_arrow_right_rounded'
                isLightTheme
                disabled={partnerSwiperDetail.isEnd}
                onClick={() => {
                  partnerSwiper.slideNext();
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer-block">
          <div className="footer-block__link" onClick={() => { handleClickOnFooterLinks('about') }} >About Us</div>
          <div className="footer-block__link" onClick={() => { handleClickOnFooterLinks('privacy-policy') }}>Privacy Policy</div>
          <div className="footer-block__link" onClick={() => { handleClickOnFooterLinks('terms-of-service') }}>Terms of service</div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
