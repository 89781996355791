import React, { useState, useEffect, useRef, useContext } from 'react'
import { colors, makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: props => ({
        position: 'relative',
        height: 'auto',
        '& .select-btn': {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: props.showDropDown ? Colors.black : Colors.lightestGrey,
            borderRadius: 5,
            paddingTop: 5,
            paddingLeft: 10,
            paddingBottom: 5,
            paddingRight: 10,
            display:'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '&:hover': {
                borderColor: Colors.black,
                color: Colors.black,
                
            },
            '&__text': {
                flex :1,
                fontFamily: 'Montserrat, sans-serif',
                textAlign: 'left',
                fontSize: 16,
                color: props.showDropDown || props.value!== null? Colors.black : Colors.lightGrey,
            },
            '&__icon' :{
                fontSize: 20, 
                color: Colors.lightGrey,
                marginLeft: 10,
                '&:hover': {
                    color: Colors.black,
                },
            }

        },
        '& .select-dropdown': {
            position: 'absolute',
            zIndex: 10,
            left: '50%',
            transform: 'translate(-50%,10px)',
            backgroundColor: Colors.white,
            border: '1px solid',
            borderColor: Colors.lightestGrey,
            borderRadius: 5,
            display: 'flex',
            padding: 10,
            overflowY: 'auto',
            minWidth: '100%',
            maxHeight: 200,
            flexDirection: 'column',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            '&__option': {
                display: 'flex',
                padding: 5,
                alignItems: 'center',
                cursor: 'pointer',
                '&--check-box': {
                    fontSize: 15,
                    marginLeft: 10,
                    color: Colors.lightGrey,

                },
                '&--check-box-selected': {
                    color: Colors.primary,

                },
                '&--label-text': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 15,
                    color: Colors.lightGrey,
                    flex :1
                }

            }
        },

    })
});

export default function KalabCalculatorDropDown({
    options,
    onChange,
    value,
    placeholder
}) {
    const wrapperRef = useRef(null);
    const [selectedOptions, setSelectedOptions] = useState([])
    const [showDropDown, setShowDropDown] = useState(false);
    const getBtnTextAccordingToValue = () => {
        if (value) {
            return (value.label);
        }
        else {
            return (placeholder)
        }
    }


    const handleBtnClick = (e) => {
        e.preventDefault();
        setShowDropDown(!showDropDown);
    }
    const handleSelectedOptions = () => {
        var selectedOptions = [];
        options.forEach(option => {
            selectedOptions.push({
                label: option.label,
                value: option.value,
                isSelected:
                    (value && value.value === option.value)
                        ?
                        true
                        :
                        false
            })
        })
        setSelectedOptions(selectedOptions)
    }

    const handleOptionClick = (option) => {
        var newSelectedOptions = [];
        selectedOptions.forEach(selectedOption => {
            newSelectedOptions.push(
                {
                    ...selectedOption,
                    isSelected: (
                        selectedOption.value === option.value ?
                        !selectedOption.isSelected
                        :
                        false
                    )
                }
            )
        })
        setSelectedOptions(newSelectedOptions);
        var filteredSelectedOptions = newSelectedOptions.filter(selectedOption => (selectedOption.isSelected));
        if (filteredSelectedOptions.length > 0) {
            onChange({
                label: filteredSelectedOptions[0].label,
                value: filteredSelectedOptions[0].value
            });
        }
        else {
            onChange(null);
        }
        setShowDropDown(false);

    }

  

    const classes = useStyles({ showDropDown, value });

    useEffect(() => {
        handleSelectedOptions();
    }, [options, showDropDown, value])


    const useClickOutsideModal = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowDropDown(false);
                }
            };

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useClickOutsideModal(wrapperRef);

    return (
        <div ref={wrapperRef} className={classes.root}>
            <button className={`${'select-btn'}`} onClick={handleBtnClick}>
                <div className='select-btn__text' >{getBtnTextAccordingToValue()}</div> 
                <Icon className='select-btn__icon'>{showDropDown ? 'keyboard_arrow_up_rounded' : 'keyboard_arrow_down_rounded'}</Icon>
            </button>
            {
                showDropDown
                    ?
                    (
                        <div className='select-dropdown'>
                            {selectedOptions.map(option => (
                                <div className='select-dropdown__option' onClick={() => { handleOptionClick(option) }}>    
                                    <span className='select-dropdown__option--label-text'>{option.label}</span>
                                    <i className={`select-dropdown__option--check-box ${option.isSelected ? ('far fa-check-circle select-dropdown__option--check-box-selected') : ('far fa-circle')}`} />
                                </div>
                            ))}
                        </div>
                    )
                    :
                    (
                        null
                    )
            }

        </div>
    )
}
