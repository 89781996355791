import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { useNotification } from "../../contexts/NotificationContext";
import defaultImage from '../../images/default-img.png';
import './ImageWithCrop.scss'



export default function ImageWithCrop({ cropRatio, id, image, setImage }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const notify = useNotification();
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const handleChange = (event) => {
        console.log(event);
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            if (file.size < 1024 * 1024 * 1 /*1MB*/) {
                setImage(id,{
                    preview: URL.createObjectURL(file),
                    previewFile : file,
                    rawUrl: URL.createObjectURL(file),
                    raw: file
                });
            }
            else {
                notify({
                    type: "ERROR",
                    message: 'Image exceeds limit (1MB)',
                    title: "Error!!!"
                });
                setImage(id,{ preview: "",previewFile:"", rawUrl: "", raw: "" })
                event.target.value = null;

            }

        }
        else {
            console.log('test');
            setImage(id,{ preview: "", previewFile:"",rawUrl: "", raw: "" })
            event.target.value = null;

        }

    }
    const createImage = url =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src =  url.includes('blob:') ? url : url + '?'+ new Date().getTime();
        })
    async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        )

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise(resolve => {
             canvas.toBlob(file => {
                var myFile = blobToFile(file, "my-image.png") 
                resolve({url : URL.createObjectURL(file),file : myFile })
            }, 'image/jpeg')
        })
    }
    function blobToFile(theBlob, fileName){       
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }
    const handleSaveButtonPress = (e) => {
        e.preventDefault();
        getCroppedImg(
            image.rawUrl,
            croppedAreaPixels
        ).then((croppedImage) => {
            console.log('done', { croppedImage })
            setImage(id,{
                ...image,
                preview: croppedImage.url,
                previewFile: croppedImage.file
            })
            setIsEdit(false);
        })
            .catch(err => {
                notify({
                    type: "ERROR",
                    message: err.message,
                    title: "Error!!!"
                });
                console.error(err)
            })
    }
    const handleEditButtonPress = (e) => {
        e.preventDefault();
        setIsEdit(true);
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        console.log(croppedArea, croppedAreaPixels)
    }, [])
    const [croppedImage, setCroppedImage] = useState(null)
    return (
        <>
            {(isEdit) ? (
                <>
                    <Cropper
                        image={image.rawUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={cropRatio}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                    <button className='registration-button' onClick={handleSaveButtonPress} style={{ position: 'absolute', top: '2%', right: '2%', backgroundColor: 'rgba(0,0,0,0.6)', fontSize: '70%' }} >
                        <i style={{ display: 'block' }} className="fas fa-check" />
                    </button>
                </>
            ) : (
                <>
                    <label style={{ height: '100%', width: '100%', borderRadius: 5 }} htmlFor={`upload-button_${id}`}>
                        <img className="collage-image" style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={(image.preview !== "") ? image.preview : defaultImage} />
                        <div className="collage-image-text" style={{position: 'absolute',padding:5, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0,0,0,0.3)',borderRadius:5, fontSize: '70%', textAlign:'center'}}>
                        <i style={{ display: 'block', color:'#ffffff' }} className="fas fa-upload" />
                        <span style={{ display: 'block',marginTop:5, color:'#ffffff' }} >Click to upload Image</span>
                        </div>
                    </label>
                    <input type="file" id={`upload-button_${id}`} accept="image/*" style={{ display: "none" }} onChange={handleChange} />
                    <button className='registration-button' onClick={handleEditButtonPress} style={{ display: image.preview !== "" ? 'block' : 'none', position: 'absolute', top: '2%', right: '2%', backgroundColor: 'rgba(0,0,0,0.6)', fontSize: '70%' }} >
                        <i style={{ display: 'block' }} className="fas fa-edit" />
                    </button>
                </>
            )}
        </>
    )
}
