import React, { useState, useEffect, useContext } from 'react'
import './EditListingPage.scss';
import MainHeader from '../header/MainHeader';
import ProgressBar from '../elements/ProgressBar';
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import ArtistBasicInfo from '../forms/ArtistBasicInfo';
import PersonalDetails from '../forms/PersonalDetails';
import Description from '../forms/Description';
import MusicImages from '../forms/MusicImages';
import PreviewSubmit from '../forms/PreviewSubmit'

import {
    getListingFromTokenRequest
} from "../../webservices/Webservice";
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabIconWithToolTip from '../kalab/kalabToolTip/KalabIconWithToolTip';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';

export default function EditListingPage(props) {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const { height, width } = useWindowDimensions();

    const [activePage, setActivePage] = useState(1);
    const [listingDetail, setListingDetail] = useState(null)
    const [listing, setListing] = useState({
        personalDetails: null,
        artistBasicInfo: null,
        description: null,
        musicImages: null
    })
    const [oldListing, setOldListing] = useState({
        personalDetails: null,
        artistBasicInfo: null,
        description: null,
        musicImages: null
    })

    const [completedStatus, setCompletedStatus] = useState({
        personalDetails: false,
        artistBasicInfo: false,
        description: false,
        musicImages: false
    })

    const convertListingDetailsToPageListing = (listingFromAPI) => {
        var musicLinks = listingFromAPI.listingMusicLinks.map(listingMusicLink => ({
            url: listingMusicLink.listingMusicLinkUrl,
            platform: listingMusicLink.listingMusicLinkPlatform.toLowerCase()
        }))
        var artistTypePrices = listingFromAPI.artistTypes.map(artistType => ({
            artistTypeId: parseInt(artistType.artistTypeId),
            artistTypeName: artistType.artistTypeName,
            price: parseFloat(artistType.cost).toFixed(2),
            currency: artistType.currency
        }))

        return ({
            personalDetails: {
                city: {
                    label: listingFromAPI.city,
                    value: listingFromAPI.city
                },
                country: {
                    label: listingFromAPI.country,
                    value: listingFromAPI.country
                },
                languages: listingFromAPI.languages.map(language => ({
                    label: language,
                    value: language
                }))
            },
            artistBasicInfo: {
                listingName: listingFromAPI.listingName,
                clients: listingFromAPI.pastClients,
                newArtistType: {
                    label: artistTypePrices[0].artistTypeName,
                    value: artistTypePrices[0].artistTypeId
                },
                eventTypes: listingFromAPI.eventTypes.map(eventType => ({
                    label: eventType.eventTypeName,
                    value: parseInt(eventType.eventTypeId)
                })),
                performanceType: {
                    label: listingFromAPI.performanceType.performanceTypeName,
                    value: parseInt(listingFromAPI.performanceType.performanceTypeId)
                },
                repertoire: {
                    label: `${listingFromAPI.repertoire} ${listingFromAPI.repertoire === 1 ? 'hour' : 'hours'}`,
                    value: listingFromAPI.repertoire
                },
                genres: listingFromAPI.genres.map(genre => ({
                    label: genre.genreName,
                    value: parseInt(genre.genreId)
                })),
                ambiences: listingFromAPI.ambiences.map(ambience => ({
                    label: ambience.ambienceName,
                    value: parseInt(ambience.ambienceId)
                })),
                newPrice: artistTypePrices[0].price,
                currency: artistTypePrices[0].currency,
                artistTypePrices: artistTypePrices,
                alreadyAddedArtistTypePrices: artistTypePrices.filter((artistTypePrice, index) => index !== 0),
                newClientName: listingFromAPI.pastClients.join(', ')
            },
            description: {
                description: listingFromAPI.description,
                setList: listingFromAPI.setList,
                technicalRider: listingFromAPI.technicalRider,
                isInsured: listingFromAPI.isInsured
            },
            musicImages: {
                musicLinks: musicLinks,
                alreadyAddedMusicLinks: musicLinks.filter((musiclink, index) => index !== 0),
                newMusicLink: musicLinks[0].url,
                selectedCollage: parseInt(listingFromAPI.collageUniqueIdentifier.slice(3)),
                images: listingFromAPI.listingImages.map(listingImage => ({
                    collageId: parseInt(listingFromAPI.collageUniqueIdentifier.slice(3)),
                    id: parseInt(listingImage.listingImagePosition),
                    image: {
                        preview: listingImage.listingImageUrl,
                        rawUrl: listingImage.listingImageUrl,
                        previewFile: null,
                        raw: null
                    }
                }))
            }
        })
    }

    const getListing = (listingToken) => {
        showLoader();
        getListingFromTokenRequest(listingToken,
            (listingFromAPI) => {
                hideLoader();
                console.log(listingFromAPI);
                setListingDetail(listingFromAPI);
                setListing({
                    ...listing,
                    ...convertListingDetailsToPageListing(listingFromAPI)
                });
                setOldListing({
                    ...listing,
                    ...convertListingDetailsToPageListing(listingFromAPI)
                });
                setCompletedStatus(
                    {
                        personalDetails: true,
                        artistBasicInfo: true,
                        description: true,
                        musicImages: true
                    }
                )
                setActivePage(1);

            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
                props.history.replace('/')
            })
    }
    const handleLinkButtonClick = (pageNo) => {
        setActivePage(pageNo);

    }
    const setArtistBasicInfoDetails = (artistBasicInfo, completed = false) => {
        setListing({
            ...listing,
            artistBasicInfo
        });
        setCompletedStatus({
            ...completedStatus,
            artistBasicInfo: completed
        })
    }
    const setDescription = (description, completed = false) => {
        setListing({
            ...listing,
            description
        });
        setCompletedStatus({
            ...completedStatus,
            description: completed
        })
    }
    const setMusicImages = (musicImages, completed = false, next = false) => {
        setListing({
            ...listing,
            musicImages
        });
        setCompletedStatus({
            ...completedStatus,
            musicImages: completed
        })
        if (next) {
            setActivePage(4)
        }
    }
    const getCompletedPercentage = () => {
        var resultValue = 0;
        if (listing.artistBasicInfo && completedStatus.artistBasicInfo) {
            resultValue = 100 / 3
            if (listing.description && completedStatus.description) {
                resultValue = 200 / 3
                if (listing.musicImages && completedStatus.musicImages) {
                    resultValue = 300 / 3
                }
            }
        }
        return (resultValue);
    }

    const getSelectedHeading = () => {
        if (activePage === 1) {
            return (
                {
                    title: 'Listing Basic Info',
                    isCompleted: completedStatus.artistBasicInfo
                }
            )
        }
        else if (activePage === 2) {
            return (
                {
                    title: 'Description',
                    isCompleted: completedStatus.description
                }
            )
        }
        else if (activePage === 3) {
            return (
                {
                    title: 'Music & Images',
                    isCompleted: completedStatus.musicImages
                }
            )
        }
        else {
            return (
                {
                    title: 'Preview',
                    isCompleted: true
                }
            )
        }
    }

    const selectedForm = () => {
        switch (activePage) {
            case 1: return (<ArtistBasicInfo application={null} artistBasicInfoDetails={listing.artistBasicInfo} setArtistBasicInfoDetails={setArtistBasicInfoDetails} completed={getCompletedPercentage()} checkCompletedValue={0} />)
            case 2: return (<Description description={listing.description} setDescription={setDescription} completed={getCompletedPercentage()} checkCompletedValue={33} />)
            case 3: return (<MusicImages application={null} musicImages={listing.musicImages} setMusicImages={setMusicImages}  completed={getCompletedPercentage()} checkCompletedValue={66} artist={listing}  history={props.history} isEditListing={true} oldListing={oldListing} listingDetail={listingDetail}/>)
            case 4: return (<PreviewSubmit artist={listing} completed={getCompletedPercentage()} history={props.history} isEditListing={true} oldListing={oldListing} listingDetail={listingDetail} />)
            default: return (<ArtistBasicInfo application={null} artistBasicInfoDetails={listing.artistBasicInfo} setArtistBasicInfoDetails={setArtistBasicInfoDetails} completed={getCompletedPercentage()} />)
        }
    }


    useEffect(() => {
        console.log(props);
        if (props.location.state && props.location.state.listing) {
            getListing(props.location.state.listing.listingToken)
        }
        else {
            props.history.replace('/')
        }

    }, [])



    return (
        <div className="edit-listing-page">
            <MainHeader />
            <div className="navigation-bar">
                <div className="progress-bar">
                    <ProgressBar bgcolor={Colors.primary} completed={getCompletedPercentage()} />
                </div>
                {
                    width > 749
                        ?
                        (
                            <div className="nav-button-group">
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 1 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(1); }}>
                                        <span className='text'>
                                            Listing Basic Info
                                        </span>

                                        {listing.artistBasicInfo && !completedStatus.artistBasicInfo && activePage !== 1 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.artistBasicInfo && completedStatus.artistBasicInfo
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 2 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(2); }}>
                                        <span className='text'>
                                            Description
                                        </span>
                                        {listing.description && !completedStatus.description && activePage !== 2 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.description && completedStatus.description
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>
                                <div className='nav-button'>
                                    <button className={`button ${activePage === 3 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); handleLinkButtonClick(3); }}>
                                        <span className='text'>
                                            Music &amp; Images
                                        </span>
                                        {listing.musicImages && !completedStatus.musicImages && activePage !== 3 ?
                                            (
                                                <div style={{ marginLeft: 5 }}>
                                                    <KalabIconWithToolTip
                                                        toolTipText="Missing Fields"
                                                        placement='top'
                                                        iconName={'fas fa-exclamation'}
                                                        iconSize={12}
                                                        isFAIcon={true}
                                                        color={Colors.darkRed}
                                                        borderColor={Colors.darkRed}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                listing.musicImages && completedStatus.musicImages
                                                    ?
                                                    (
                                                        <div style={{ marginLeft: 5 }}>
                                                            <KalabIconWithToolTip
                                                                toolTipText="Completed"
                                                                placement='top'
                                                                iconName={'fas fa-check'}
                                                                iconSize={12}
                                                                isFAIcon={true}
                                                                color={Colors.darkGreen}
                                                                borderColor={Colors.darkGreen}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            )}
                                    </button>
                                </div>

                            </div>
                        )
                        :
                        (
                            <div className='navigation-bar-small'>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_left_rounded'
                                        disabled={activePage === 1}
                                        onClick={() => {
                                            setActivePage(activePage - 1);
                                        }}
                                    />
                                </div>
                                <div className='title'>

                                    <span className='text'>{getSelectedHeading().title}</span>
                                    {getSelectedHeading().isCompleted ?
                                        (
                                            <div style={{ marginLeft: 5 }}>
                                                <KalabIconWithToolTip
                                                    toolTipText="Completed"
                                                    placement='top'
                                                    iconName={'fas fa-check'}
                                                    iconSize={12}
                                                    isFAIcon={true}
                                                    color={Colors.darkGreen}
                                                    borderColor={Colors.darkGreen}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            null
                                        )
                                    }

                                </div>
                                <div className='left-arrow'>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_right_rounded'
                                        disabled={activePage >= 3}
                                        onClick={() => {
                                            setActivePage(activePage + 1);
                                        }}
                                    />
                                </div>

                            </div>
                        )
                }

            </div>
            {listing.personalDetails && completedStatus.personalDetails && listingDetail ? selectedForm() : null}
        </div>
    )
}
