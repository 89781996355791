import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import KalabIconButton from '../kalabButtons/KalabIconButton';

const useStyles = makeStyles({
    root: props => ({
        '& .MuiPickersBasePicker-container': {
            width: '75%'
        },
        '& .MuiPickersBasePicker-pickerView': {
            width: '100%',
            maxWidth: 'unset',
            position : 'relative'
        },
        '& .MuiPickersCalendarHeader-iconButton' :{
            position:'absolute',
            top: '50%',
            transform:'translate(0,-50%)',
            '&:first-child':{
                left : 0
            },
            '&:last-child':{
                right : 0
            }
        },
        '& .MuiPickersCalendarHeader-daysHeader':{
            marginTop: 20
        },
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .MuiPickersCalendarHeader-dayLabel': {
            width: 40,
            height: 40,
        },
        '& .MuiPickersDay-day': {
            width: 40,
            height: 40
        },
        '& .MuiPickersCalendar-transitionContainer': {
            minHeight: 250
        },
        '& .MuiPickersDay-current': {
            color: Colors.primary
        },
        '& .MuiPickersDay-daySelected': {
            color: props.date !== null ? Colors.white : Colors.primary,
            backgroundColor: props.date !== null ? Colors.primary : Colors.white
        },
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: '500',
            fontFamily: 'Montserrat, sans-serif'
        },
        '& .MuiTypography-body2': {
            fontSize: 15,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif'
        },
        '& .MuiTypography-caption': {
            fontSize: 15,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif'
        },
    })
});

export default function KalabListingCalendar() {
    const [date, setDate] = useState(null);
    const handleDateChangeValue = (val) => {
        setDate(val);
    }
    const classes = useStyles({ date });
   
    return (
        <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        disableToolbar
                        disablePast
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        clearable
                        format='dd/MM/yyyy'
                        value={date}
                        onChange={handleDateChangeValue}
                    />
                </MuiPickersUtilsProvider>
       
        </div>
    )
}
