import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import {
    withStyles, makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';
import { Currencies, getDefaultCurrency } from '../../../utils/Currencies';
const StyledFormControl = withStyles({
    root: {
        '& .MuiInput-root': {
            color: Colors.white,
            fontSize: 16,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& .MuiSelect-icon': {
            color: Colors.lightGrey
        },
        '& .MuiInput-underline':
        {
            '&:before': {
                border: 'none'
            },
            '&:after': {
                border: 'none'
            },
            '&:hover:not(.Mui-disabled):before': {
                border: 'none'
            }
        },
        '& .MuiMenuItem-root': {
            fontSize: 16,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
        }

    }
})(FormControl);

const useStyles = makeStyles({
    root: {
        justifyContent:'center',
        textAlign: 'center',
        fontSize: 14,
        fontWeight: '300',
        fontFamily: 'Montserrat, sans-serif',
        '&:hover': {
            color: Colors.darkGrey,
            backgroundColor : Colors.primaryLightest
        },
        '& + .Mui-selected':{
            backgroundColor : Colors.primaryLight
        },
        '& + .Mui-selected:hover':{
            backgroundColor : Colors.primaryLight
        },
        '& + .Mui-selected':{
            backgroundColor : Colors.primaryLight
        }
    }
});

export default function KalabCurrencyDropDown({ currency, setCurrency , isLightTheme = false , disabled = false}) {

    useEffect(() => {
        if (!currency) {
            setCurrency(getDefaultCurrency().value)
        }

    }, []);

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };
    const classes = useStyles({isLightTheme});

    return (
        <StyledFormControl>
            <Select
                id="standard-select-currency"
                select
                disabled={disabled}
                label="Select"
                value={currency}
                onChange={handleChange}
                style={{color : isLightTheme ? Colors.black : Colors.white}}
            >
                {Currencies.map((option) => (
                    <MenuItem
                        className={classes.root}
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    )
}

