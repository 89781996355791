import React, { useState, useEffect, useContext } from 'react'
import './AlertPopUp.scss'
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';

export default function AlertPopUp({
    closeFn,
    title,
    okText,
    okBtnColor,
    okFn
}) {

   
    const handleOkClick = () =>{
        okFn()
    }

   
    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='alert-popup-modal'>
                <div className='alert-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='alert-popup-modal__content--top'>
                        <div className='alert-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='alert-popup-top__title'>
                            <span className='alert-popup-top__title--text'>{title}</span>
                        </div>


                    </div>
                    <div className='alert-popup-modal__content--main'>

                        <div className="alert-popup-button">
                            <KalabMainButton
                                text={okText}
                                color={okBtnColor}
                                onClick={handleOkClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
