import React, { createContext, useState } from 'react'
import Loader from '../components/loader/Loader';

export const LoaderContext = createContext();

export default function LoaderContextProvider({children}) {
    const [loading , setLoading] = useState(false);

    const showLoader = ()=>{
        setLoading(true);
    }

    const hideLoader = ()=>{
        setLoading(false);
    }
    return (
        <LoaderContext.Provider value = {{showLoader,hideLoader}}>
           {children}
        {loading ? (<Loader/>) : null} 
        </LoaderContext.Provider>
    )
}
