import React, { useEffect, useState, useContext } from 'react'
import './PersonalDetails.scss';
import Select from 'react-select';
import { getCountriesRequest, getLanguagesRequest, getCitiesRequest, getCountryCodesRequest, generateOTPRequest, checkOTPRequest, resendOTPRequest } from '../../webservices/Webservice';
import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import Colors from '../../constants/Colors';
import RegularExpression from '../../utils/RegularExpression';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabDropDown from '../kalab/kalabDropDown/KalabDropDown';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabMainButtonWithIcon from '../kalab/kalabButtons/KalabMainButtonWithIcon';


function PersonalDetails({ application, personalDetails, setPersonalDetails, completed }) {
    const [artistPersonalDetails, setArtistPersonalDetails] = useState({
        fullName: '',
        artistName: '',
        email: '',
        country: null,
        city: null,
        languages: [],
        password: '',
        cpassword: '',
        countryCode: null,
        phone: '',
        otp: ''
    })
    const [loading, setLoading] = useState({
        country: false,
        city: false,
        language: false,
        countryCode: false
    })
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [isVerify, setIsVerify] = useState(false);
    const [verifiedNumber, setVerifiedNumber] = useState({
        countryCode: null,
        phone: null
    });
    const [resendCount, setResendCount] = useState(0);
    const [countryOptions, setCountryOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const notify = useNotification();
    const generateOTP = () => {
        showLoader();
        generateOTPRequest(artistPersonalDetails.countryCode.value.countryCode, artistPersonalDetails.phone,
            (response) => {
                hideLoader();
                notify({
                    type: "SUCCESS",
                    message: response,
                    title: "Success!!!"
                });
                setIsVerify(true);
                setResendCount(0);
            }, (error) => {
                hideLoader();
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
            })
    }
    const checkOTP = () => {
        showLoader();
        checkOTPRequest(artistPersonalDetails.countryCode.value.countryCode, artistPersonalDetails.phone, artistPersonalDetails.otp,
            (response) => {
                hideLoader();
                notify({
                    type: "SUCCESS",
                    message: response,
                    title: "Success!!!"
                });
                setVerifiedNumber({
                    countryCode: artistPersonalDetails.countryCode.value.countryCode,
                    phone: artistPersonalDetails.phone
                })
            }, (error => {
                hideLoader();
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
            }))
    }
    const resendOTP = () => {
        showLoader();
        resendOTPRequest(artistPersonalDetails.countryCode.value.countryCode, artistPersonalDetails.phone,
            (response) => {
                hideLoader();
                notify({
                    type: "SUCCESS",
                    message: response,
                    title: "Success!!!"
                });
                var count = resendCount + 1
                setResendCount(count)
            }, (error => {
                hideLoader();
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
            }))
    }
    const getCountries = () => {
        setLoading({
            ...loading,
            country: true,
            city: true
        })
        getCountriesRequest(
            (countries) => {
                var countryOptionsFromAPI = [];
                countries.forEach(country => {
                    countryOptionsFromAPI.push({
                        value: country.countryAlpha3Code,
                        label: country.countryAsciiName,
                        country: country
                    })

                });
                setCountryOptions(countryOptionsFromAPI);
                setLoading({
                    ...loading,
                    country: false,
                    city: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    country: false,
                    city: false
                })
            }
        )
    }
    const getCities = (countryAlpha3Code) => {
        setLoading({
            ...loading,
            city: true
        })
        getCitiesRequest(
            countryAlpha3Code,
            (cities) => {
                var cityOptionsFromAPI = [];
                cities.forEach(city => {
                    cityOptionsFromAPI.push({
                        value: city.city,
                        label: city.city_ascii,
                        city
                    })

                });
                setCityOptions(cityOptionsFromAPI);
                setLoading({
                    ...loading,
                    city: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    city: false
                })
            }
        )
    }

    const getLanguages = () => {
        setLoading({
            ...loading,
            language: true
        })
        getLanguagesRequest(
            (languages) => {
                var languageOptionsFromAPI = [];
                languages.forEach(language => {
                    languageOptionsFromAPI.push({
                        value: language.code,
                        label: language.name,
                        language
                    })

                });
                setLanguageOptions(languageOptionsFromAPI);
                setLoading({
                    ...loading,
                    language: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    language: false
                })
            }
        )
    }
    const getCountryCodes = () => {
        setLoading({
            ...loading,
            country: true
        })
        getCountryCodesRequest(
            (countryCodes) => {
                var countryCodeOptionsFromAPI = [];
                countryCodes.forEach(countryCode => {
                    countryCodeOptionsFromAPI.push({
                        value: countryCode,
                        label: `+${countryCode.countryCode} (${countryCode.countryAsciiName !== "" ? countryCode.countryAsciiName : countryCode.countryName})`,
                        countryCode
                    })

                });
                setCountryCodeOptions(countryCodeOptionsFromAPI);
                setLoading({
                    ...loading,
                    countryCode: false
                })
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                setLoading({
                    ...loading,
                    countryCode: false
                })
            }
        )
    }
    const handleVerifyButton = (e) => {
        e.preventDefault();
        generateOTP();

    }
    const handleOTPCheckButton = (e) => {
        e.preventDefault();
        checkOTP();
    }
    const handleResendOTPButton = (e) => {
        e.preventDefault();
        resendOTP();
    }

    const handleInputTextChange = (e) => {
        var updatedArtist = {
            ...artistPersonalDetails
        }
        updatedArtist[e.target.id] = e.target.value
        setArtistPersonalDetails(updatedArtist);
    }
    const handleCountrySelectChange = (countryValue) => {
        setArtistPersonalDetails({
            ...artistPersonalDetails,
            country: countryValue,
            city: null
        })
        if (countryValue === null) {
            setCityOptions([]);
        }
        else {
            getCities(countryValue.value);
        }
    }
    const handleLanguagesSelectChange = (languagesValue) => {
        setArtistPersonalDetails({
            ...artistPersonalDetails,
            languages: languagesValue
        })
    }
    const handleCountryCodeSelectChange = (countryCodeValue) => {
        setArtistPersonalDetails({
            ...artistPersonalDetails,
            countryCode: countryCodeValue
        })
        if (isVerify) {
            setIsVerify(false)
        }
    }
    const handleCitySelectChange = (cityValue) => {
        setArtistPersonalDetails({
            ...artistPersonalDetails,
            city: cityValue
        })
    }
    const handleContinueButtonClick = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            setPersonalDetails(artistPersonalDetails);
        }
    }
    const passwordValid = () => {
        if (artistPersonalDetails.password === artistPersonalDetails.cpassword && artistPersonalDetails.password !== "" && artistPersonalDetails.password.length >= 6) {
            return (true)
        }
        else {
            return (false)
        }
    }
    const isFormValid = () => {
        if (
            RegularExpression.email.test(artistPersonalDetails.email) &&
            artistPersonalDetails.fullName !== null &&
            artistPersonalDetails.fullName.trim() !== "" &&
            artistPersonalDetails.artistName !== null &&
            artistPersonalDetails.artistName.trim() !== "" &&
            artistPersonalDetails.city !== null &&
            artistPersonalDetails.country !== null &&
            artistPersonalDetails.languages.length > 0 &&
            artistPersonalDetails.countryCode != null &&
            artistPersonalDetails.phone !== null &&
            passwordValid() &&
            checkPhoneVerified()
        ) {
            return true;
        }
        else {
            return false
        }
    }
    const isValueChanged = () => {
        if (personalDetails && JSON.stringify(personalDetails) === JSON.stringify(artistPersonalDetails)) {
            return false
        }
        else {
            return true
        }
    }
    const checkPhoneVerified = () => {
        if (
            verifiedNumber.phone &&
            verifiedNumber.countryCode &&
            artistPersonalDetails.countryCode &&
            artistPersonalDetails.phone === verifiedNumber.phone &&
            artistPersonalDetails.countryCode.value.countryCode === verifiedNumber.countryCode
        ) {
            return true;
        }
        else {
            return false;
        }
    }


    useEffect(() => {
        if (personalDetails) {
            setArtistPersonalDetails(personalDetails);
            setVerifiedNumber({
                phone: personalDetails.phone,
                countryCode: personalDetails.countryCode && personalDetails.countryCode.value.countryCode ? personalDetails.countryCode.value.countryCode : null
            })
            if (personalDetails.country && personalDetails.country.value) {
                getCities(personalDetails.country.value);
            }
        }
        else {
            if (application) {
                setArtistPersonalDetails({
                    ...artistPersonalDetails,
                    fullName: application.fullName,
                    email: application.email,
                    artistName: application.artistName
                })
            }
        }
        getCountries();
        getLanguages();
        getCountryCodes();
    }, [])


    useEffect(() => {
        setPersonalDetails(artistPersonalDetails, isFormValid());
    }, [artistPersonalDetails, verifiedNumber])


    return (
        <div className="personal-details">
            <div className="input-group">
                <div className="input">
                    <KalabTextField
                        disabled={application ? true : false}
                        type="text"
                        id="email"
                        placeholder="Email"
                        value={artistPersonalDetails.email}
                        onChange={handleInputTextChange}
                    />
                </div>
                <div className="input">
                    <KalabTextField
                        type="text"
                        id="fullName"
                        placeholder="Full Name"
                        value={artistPersonalDetails.fullName}
                        onChange={handleInputTextChange}
                    />
                </div>
                <div className="input">
                    <KalabTextField
                        type="text"
                        id="artistName"
                        placeholder="Artist Name"
                        value={artistPersonalDetails.artistName}
                        onChange={handleInputTextChange}
                    />
                </div>
            </div>
            <div className="input-group">
                <div className="input">
                    <KalabDropDown
                        classNamePrefix="select"
                        placeholder="Country"
                        isDisabled={false}
                        isLoading={loading.country}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="country"
                        onChange={handleCountrySelectChange}
                        value={artistPersonalDetails.country}
                        options={countryOptions}
                    />
                </div>
                <div className="input">
                    <KalabDropDown
                        placeholder="City"
                        isDisabled={false}
                        isLoading={loading.city}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="city"
                        onChange={handleCitySelectChange}
                        options={cityOptions}
                        value={artistPersonalDetails.city}
                    />
                </div>
                <div className="input">
                    <KalabDropDown
                        placeholder="Languages"
                        isDisabled={false}
                        isLoading={loading.language}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="languages"
                        onChange={handleLanguagesSelectChange}
                        options={languageOptions}
                        isMulti
                        value={artistPersonalDetails.languages}
                    />
                </div>
            </div>
            <div className="input-group">
                <div className="input">
                    <KalabTextField
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={artistPersonalDetails.password}
                        onChange={handleInputTextChange}
                    />
                </div>
                <div className="input">
                    <KalabTextField
                        type="password"
                        id="cpassword"
                        placeholder="Confirm Password*"
                        value={artistPersonalDetails.cpassword}
                        onChange={handleInputTextChange}
                    />
                </div>
                <div className='input-text' style={{}}>
                    {
                        artistPersonalDetails.password.length > 0 ?
                            (
                                passwordValid() ?
                                    (<><i className="fas fa-check-circle icon" /><span className='text'>Password Matches</span></>)
                                    : (artistPersonalDetails.password.length < 6 ?
                                        (<><i className="fas fa-exclamation-circle icon" /><span className='text'>Need minimum 6 characters</span></>)
                                        :
                                        (<><i className="fas fa-exclamation-circle icon" /><span className='text'>Passwords are not matching</span></>)
                                    )
                            )
                            :
                            ('')
                    }
                </div>
            </div>
            <div className="input-group">
                <div className="input">
                    <KalabDropDown
                        placeholder="Country Code"
                        isDisabled={false}
                        isLoading={loading.countryCode}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="countryCode"
                        onChange={handleCountryCodeSelectChange}
                        options={countryCodeOptions}
                        value={artistPersonalDetails.countryCode}

                    />
                </div>
                <div className="input">
                    <KalabTextField
                        type="number"
                        id="phone"
                        placeholder="Phone"
                        value={artistPersonalDetails.phone}
                        onChange={(e) => {
                            handleInputTextChange(e);
                            if (isVerify) {
                                setIsVerify(false)
                            }
                        }}
                    />
                </div>
                {checkPhoneVerified() ?
                    (
                        <div className='input-text'>
                            <i className="fas fa-check-circle icon" /><span className='text'>Verified</span>
                        </div>
                    )
                    :
                    (isVerify ?
                        (
                            <div className='otp-verification'>
                                <div style={{ flex: 1, display: 'flex' }}>
                                    <KalabTextField
                                        type="number"
                                        id="otp"
                                        placeholder="OTP"
                                        value={artistPersonalDetails.otp}
                                        onChange={handleInputTextChange}
                                    />
                                </div>
                                <div style={{ marginLeft: 5, marginRight: 5, alignSelf: 'flex-end' }}>
                                    <KalabSmallButton
                                        text="check"
                                        onClick={handleOTPCheckButton}
                                        disabled={artistPersonalDetails.otp === ""}
                                    />
                                </div>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <KalabSmallButton
                                        text="Resend"
                                        onClick={handleResendOTPButton}
                                        disabled={resendCount > 2 ? true : false}
                                    />
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className='verify-btn'>
                                <KalabSmallButton
                                    text="Verify"
                                    onClick={handleVerifyButton}
                                    disabled={artistPersonalDetails.phone === "" || artistPersonalDetails.countryCode === null}
                                />
                            </div>
                        ))}

            </div>
            {/* <div
                        style={{
                            textAlign: 'center',
                            marginBottom: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >


                        <KalabMainButtonWithIcon
                            disabled={!isFormValid() || (!isValueChanged())}
                            onClick={handleContinueButtonClick}
                            text={personalDetails ? ('Next ') : ('Next ')}
                            iconName={'arrow_forward'}
                        />
                    </div> */}

        </div>
    )
}

export default PersonalDetails
