import React, { useEffect, useState, useContext } from 'react';
import './TemplatePage.scss';
import ClientHeader from "../header/ClientHeader";
import PortaledComponent from "../elements/portaledComponent";

import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import { DataStoreContext } from "../../contexts/DataStoreContext";
import {
    getEventTemplatesRequest,
    deleteEventTemplateRequest
} from '../../webservices/Webservice'
import KalabNewTemplateCard from '../kalab/kalabCards/KalabNewTemplateCard';
import CreateTemplatePopUp from '../popUps/CreateTemplatePopUp';
import KalabTemplateCard from '../kalab/kalabCards/KalabTemplateCard';
import UpdateTemplatePopUp from '../popUps/UpdateTemplatePopUp';
import AlertPopUp from '../popUps/AlertPopUp';

export default function TemplatePage(props) {
    const [templates, setTemplates] = useState([]);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { artistTypes, eventTypes, genres, ambiences, countries, loading } = useContext(DataStoreContext);
    const notify = useNotification();

    const [createTemplatePopUpShow, setCreateTemplatePopUpShow] = useState(false)
    const [duplicateTemplate, setDuplicateTemplate] = useState(null)
    const closeCreateTemplatePopUp = () => {
        document.body.style.overflow = 'unset';
        setCreateTemplatePopUpShow(false);
        setDuplicateTemplate(null);
        getTemplates();
    }

    const showCreateTemplatePopUp = (template = null) => {
        document.body.style.overflow = 'hidden';
        setDuplicateTemplate(template);
        setCreateTemplatePopUpShow(true);
    }


    const [updateTemplatePopUpShow, setUpdateTemplatePopUpShow] = useState(false)
    const [updateTemplate, setUpdateTemplate] = useState(null)
    const closeUpdateTemplatePopUp = () => {
        document.body.style.overflow = 'unset';
        setUpdateTemplatePopUpShow(false);
        setUpdateTemplate(null)
        getTemplates();
    }

    const showUpdateTemplatePopUp = (template) => {
        document.body.style.overflow = 'hidden';
        setUpdateTemplate(template)
        setUpdateTemplatePopUpShow(true);
    }


    const [deleteTemplatePopUpShow, setDeleteTemplatePopUpShow] = useState(false)
    const [deleteTemplate, setDeleteTemplate] = useState(null)
    const closeDeleteTemplatePopUp = () => {
        document.body.style.overflow = 'unset';
        setDeleteTemplatePopUpShow(false);
        setDeleteTemplate(null)
        getTemplates();
    }

    const showDeleteTemplatePopUp = (template) => {
        document.body.style.overflow = 'hidden';
        setDeleteTemplate(template)
        setDeleteTemplatePopUpShow(true);
    }

    const deleteTemplateFn = () =>{
        showLoader();
        deleteEventTemplateRequest(
            deleteTemplate.templateId,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                closeDeleteTemplatePopUp();

            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }

    const getTemplates = () => {
        showLoader();
        getEventTemplatesRequest(
            (eventTemplates) => {
                console.log(eventTemplates);
                setTemplates(eventTemplates);
                hideLoader();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }

    useEffect(() => {
        getTemplates();
    }, [])


    return (
        <>
            <ClientHeader />
            <div className='template-page'>
                <div className='template-page__main-section'>
                    <div className="template-page__main-section--template">
                        <KalabNewTemplateCard
                            action={() => { showCreateTemplatePopUp() }}
                        />
                    </div>
                    {
                        templates.map(template => (
                            <div className="template-page__main-section--template">
                                <KalabTemplateCard
                                    template={template}
                                    editTemplate = {showUpdateTemplatePopUp}
                                    deleteTemplate = {showDeleteTemplatePopUp}
                                    duplicateTemplate={showCreateTemplatePopUp}
                                />
                            </div>
                        ))
                    }

                </div>
            </div>
            {createTemplatePopUpShow && <PortaledComponent modal={<CreateTemplatePopUp closeFn={closeCreateTemplatePopUp} templates={templates} duplicateTemplate={duplicateTemplate} />} />}
            {updateTemplatePopUpShow && <PortaledComponent modal={<UpdateTemplatePopUp closeFn={closeUpdateTemplatePopUp } templates = {templates} templateToUpdate={updateTemplate}/> } />}
            {deleteTemplatePopUpShow && <PortaledComponent modal={<AlertPopUp closeFn={closeDeleteTemplatePopUp} title={`Are you sure to delete the template "${deleteTemplate.templateName.toUpperCase()}"`} okText={"OK"} okFn={() => { deleteTemplateFn() }} />} />}
        </>
    )
}