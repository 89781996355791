import React, { useContext, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';
import { getLabelFromValue } from '../../../utils/Currencies';
import { dateToShowInMessages, getFormattedDate, getFormattedDateForStartEndDate, getFormattedTime } from '../../../utils/Date';
import { getPaymentStatusOfBookingForArtist, getPaymentStatusOfBookingForClient, getStatusOfEvent } from '../../../utils/Status';
import { openInNewTab } from '../../../utils/common';
import KalabIconCheckBox from '../kalabCheckBoxes/KalabIconCheckBox';
import {
    LocationOnOutlined,
    HourglassEmptyRounded,
    ScheduleRounded,
    CakeOutlined,
    PersonOutlineRounded,
    MusicNoteRounded,
    CreateRounded
} from '@material-ui/icons';
import KalabSmallButton from '../kalabButtons/KalabSmallButton';
import PaymentMethods from '../../../constants/PaymentMethods';
import KalabIconWithToolTip from '../kalabToolTip/KalabIconWithToolTip';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import KalabExpandableText from '../kalabExpandableField/KalabExpandableText';
import KalabIconButton from '../kalabButtons/KalabIconButton';
import TimingPopUp from '../../popUps/TimingPopUp';
import PortaledComponent from '../../elements/portaledComponent/portaledComponent';
import SharePopUp from '../../popUps/SharePopUp';

const useStyles = makeStyles({
    root: (props) => ({
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 2,
        width: '100%',
        maxHeight: 'calc(100vh - 160px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '& .updation-details': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& .detail': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '& .icon': {
                    color: Colors.lightGrey,
                    fontSize: 10,
                    marginRight: 5
                },
                '& .value': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 8,
                    fontWeight: '300',
                    color: Colors.lightGrey
                }
            }
        },
        '& .main-detail': {
            '& .event-name': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 500 ? 20 : (props.width / 100 * 4 < 16 ? 16 : Math.floor(props.width / 100 * 4)),
                fontWeight: '300',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
            '& .event-between-detail': {
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 12 ? 12 : Math.floor(props.width / 100 * 3)),
                fontWeight: '400',
                color: Colors.lightGrey,
                textTransform: 'capitalize',
                textAlign: 'center',
                borderBottomWidth: 1,
                borderBottomColor: Colors.lightestGrey,
                paddingBottom: 5,

                '& .client-details': {
                    marginLeft: 10,
                    marginRight: 10
                },
                '& .listing-details': {
                    marginLeft: 10,
                    marginRight: 10,
                    '&:hover': {
                        color: Colors.primaryDark,
                        cursor: 'pointer'
                    },
                    '& .insured': {
                        display: 'inline-block'
                    }
                }
            },
            '& .date': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 12 ? 12 : Math.floor(props.width / 100 * 3)),
                fontWeight: '300',
                textTransform: 'capitalize',
                textAlign: 'center',
                color: Colors.lightGrey,
                paddingTop: 5,
                '& .residency-icon': {
                    display: 'inline-block',
                    verticalAlign: 'text-bottom',
                }
            },
            '& .row-details': {
                paddingTop: 2,
                paddingBottom: 2,
                '& .row-value': {
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 5,
                    marginBottom: 5,
                    '& .single-data': {
                        flex: 1,
                        marginRight: 10,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        '& .icon-value': {
                            flex: 1,
                            color: Colors.lightGrey,
                            fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 13 ? 13 : Math.floor(props.width / 100 * 3)),
                            textAlign: 'center'
                        },
                        '& .text-value': {
                            flex: 2,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                            fontWeight: '300',
                            color: Colors.lightGrey,
                            '& .timing-btn': {
                                display: 'inline-block',
                                marginLeft: 2,
                                verticalAlign: 'text-bottom'
                            }
                        },
                        '& .timing-txt': {
                            textDecoration: 'underline',
                            fontWeight: '500',
                            '&:hover': {
                                color: Colors.primaryDark,
                                cursor: 'pointer'
                            },
                        }

                    },
                    '& .kalab-icon-checkbox': {
                        flex: 1,
                        padding: 2
                    },
                    '& .provide-text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                        fontWeight: '300',
                        color: Colors.black
                    }
                }
            },
            '& .other-details': {
                borderTopWidth: 1,
                borderTopColor: Colors.lightestGrey,
                paddingTop: 2,
                paddingBottom: 2,

                '& .single-detail': {
                    flex: 1,
                    padding: 2,
                    '& .title': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 12 : (props.width / 100 * 2.4 < 10 ? 10 : Math.floor(props.width / 100 * 2.4)),
                        fontWeight: '400',
                        color: Colors.black
                    },
                    '& .value': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                        fontWeight: '300',
                        color: Colors.lightGrey,
                        textAlign: 'justify'
                    }


                }
            },
            '& .price-details': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                borderTopWidth: 1,
                borderTopColor: Colors.lightestGrey,
                paddingTop: 5,
                paddingBottom: 5,
                '& .other': {
                    flex: 1,
                    '& .price': {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        '& .title': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                            fontWeight: '400',
                            color: Colors.black
                        },
                        '& .value': {
                            flex: 1,
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: props.width > 500 ? 13 : (props.width / 100 * 2.6 < 11 ? 11 : Math.floor(props.width / 100 * 2.6)),
                            fontWeight: '400',
                            color: Colors.lightGrey,
                            textAlign: 'right'
                        }
                    }

                },
                '& .total-price': {
                    flex: 1,
                    '& .value': {
                        flex: 1,
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 17 : (props.width / 100 * 3.4 < 14 ? 14 : Math.floor(props.width / 100 * 3.4)),
                        fontWeight: '500',
                        color: Colors.primary,
                        textAlign: 'right'

                    }
                }
            },
            '& .payment-details': {
                borderTopWidth: 1,
                borderTopColor: Colors.lightestGrey,
                paddingTop: 2,
                paddingBottom: 2,
                flexDirection: 'row',
                '& .title-payment': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 12 : (props.width / 100 * 2.4 < 10 ? 10 : Math.floor(props.width / 100 * 2.4)),
                    fontWeight: '400',
                    color: Colors.black
                },
                '& .row-payment': {
                    display: 'flex',
                    flexDirection: 'row',

                    '& .heading-payment': {
                        flex: 1,
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                        fontWeight: '400',
                        color: Colors.black,

                    },
                    '& .value-payment': {
                        flex: 1,
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                        fontWeight: '400',
                        color: Colors.lightGrey,
                        textAlign: 'right',
                        '&:first-letter': {
                            textTransform: 'capitalize'
                        }
                    }
                }
            },
            '& .buttons': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                paddingTop: 5,
                paddingBottom: 5,
                '& .button': {
                    padding: 5
                }
            },
            '& .status': {
                marginTop: 15,
                marginBottom: 5,
                '& .status-value': {
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .text': {
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: props.width > 500 ? 15 : (props.width / 100 * 3 < 12 ? 12 : Math.floor(props.width / 100 * 3)),
                        fontWeight: '400',
                        textTransform: 'uppercase',

                        borderWidth: 1,
                        borderStyle: 'solid',
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5
                    }
                },
                '& .requested-by': {
                    marginTop: 12,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                    fontWeight: '300',
                },
                '& .reason': {
                    marginTop: 2,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: props.width > 500 ? 11 : (props.width / 100 * 2.2 < 9 ? 9 : Math.floor(props.width / 100 * 2.2)),
                    fontWeight: '300',
                }
            }


        }

    }
    )


}
)

export default function KalabBookingCard({ booking, isArtist = false }) {
    const { height, width } = useWindowDimensions();
    const classes = useStyles({ width });

    const [showTimings, setShowTimings] = useState(false);

    const [sharePopUp, setSharePopUp] = useState(false);

    const showSharePopUp = () => {
        document.body.style.overflow = 'hidden';
        setSharePopUp(true);
    }

    const closeSharePopUp = () => {
        document.body.style.overflow = 'unset';
        setSharePopUp(false);
    }

    const showTimingPopUp = () => {
        document.body.style.overflow = 'hidden';
        setShowTimings(true);
    }

    const closeTimingPopUp = () => {
        document.body.style.overflow = 'unset';
        setShowTimings(false);
    }

    const getPriceDetails = () => {
        var price = parseFloat(booking.artistPrice);
        var totalPrice = parseFloat(booking.totalPrice);
        var serviceFeePercentage = (totalPrice - price) / price * 100;
        var serviceFee = totalPrice - price;
        return {
            isPriceUpdated: booking.isUpdated && (booking.lastUpdatedColumns.includes('artistPrice') || booking.lastUpdatedColumns.includes('currency')),
            price: price.toFixed(2),
            serviceFeePercentage: serviceFeePercentage,
            serviceFee: serviceFee.toFixed(2),
            totalPrice: totalPrice.toFixed(2)
        }
    }

    const handleClickOnListing = () => {
        openInNewTab(window.location.origin + '/listing/' + booking.listingToken);
    }
    return (
        <div className={classes.root} >
            <div className='updation-details'>
                <div className='left'>
                    <KalabIconButton
                        iconName={'fas fa-external-link-alt'}
                        iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                        isFAIcon
                        isLightTheme
                        noBorder
                        noDisabled
                        onClick={() => { showSharePopUp() }}

                    />
                </div>
                <div className='right'>
                    {/* <div className='detail'>
                    <CreateRounded className='icon' />
                    <div className='value'>
                        {dateToShowInMessages(booking.createdDate)}
                    </div>
                </div> */}
                </div>
            </div>
            <div className="main-detail">
                <div className="event-name">
                    {booking.eventName}
                </div>
                <div className="event-between-detail">
                    <div className="client-details">
                        {booking.clientCompanyFullName}
                    </div>
                    x
                    <div className="listing-details" onClick={handleClickOnListing} >
                        {booking.listingName}
                        {
                            booking.listingIsInsured ?
                                (
                                    <div className='insured'>
                                        <KalabIconWithToolTip
                                            toolTipText="Insured"
                                            placement="right"
                                            iconName='verified_user_outlined'
                                            iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                            noBorder={true}
                                            color={Colors.lightGrey}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                    </div>
                </div>
                <div className="date">
                    {booking.isResidency ? getFormattedDateForStartEndDate(booking.date, booking.endDate) : getFormattedDate(booking.date)}
                    {/* <div className='residency-icon'>
                        <KalabIconWithToolTip
                            toolTipText={booking.isResidency ? "Residency" : "Single Booking"}
                            placement="bottom-start"
                            iconName={booking.isResidency ? 'date_range_rounded' : 'event_rounded'}
                            iconSize={width > 500 ? 14 : (width / 100 * 2.8 < 11 ? 11 : Math.floor(width / 100 * 2.8))}
                            noBorder={true}
                            color={Colors.lightGrey}
                        />
                    </div> */}
                </div>
                <div className="row-details">
                    <div className="row-value">
                        <div className="single-data">
                            <LocationOnOutlined className="icon-value" />
                            <div className="text-value" style={{ textTransform: 'capitalize' }} >
                                {booking.location}
                            </div>
                        </div>
                    </div>
                    {
                        !booking.isResidency ?
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <div className="icon-value">
                                            <KalabIconWithToolTip
                                                toolTipText="Artist Arrival - Departure"
                                                placement="right"
                                                iconName='schedule_rounded'
                                                iconSize={width > 500 ? 15 : (width / 100 * 3 < 13 ? 13 : Math.floor(width / 100 * 3))}
                                                noBorder={true}
                                                color={Colors.lightGrey}
                                            />
                                        </div>
                                        {/* < ScheduleRounded className="icon-value" /> */}
                                        <div className="text-value" >{getFormattedTime(booking.startTime)} - {getFormattedTime(booking.endTime)}</div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <div className="icon-value">
                                            <KalabIconWithToolTip
                                                toolTipText="No of Events"
                                                placement="right"
                                                iconName='library_music_rounded'
                                                iconSize={width > 500 ? 15 : (width / 100 * 3 < 13 ? 13 : Math.floor(width / 100 * 3))}
                                                noBorder={true}
                                                color={Colors.lightGrey}
                                            />
                                        </div>
                                        {/* < ScheduleRounded className={`icon-value ${isDataUpdated('startTime') || isDataUpdated('endTime') ? 'update' : ''}`} /> */}
                                        <div className={`text-value timing-txt`} onClick={() => { showTimingPopUp() }}>{booking.noOfPerformances} Events
                                            {/* <div className='timing-btn'>
                                                <KalabIconButton
                                                    iconName={'fas fa-calendar-alt'}
                                                    iconSize={width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6))}
                                                    isFAIcon
                                                    isLightTheme
                                                    noBorder
                                                    noDisabled
                                                    onClick={() => { showTimingPopUp() }}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                    }

                    {
                        !booking.isResidency ?
                            (
                                <div className="row-value">
                                    <div className="single-data">
                                        <HourglassEmptyRounded className="icon-value" />
                                        <div className="text-value" >{booking.hours} hour{booking.hours > 1 ? 's' : ''} set</div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }


                    <div className="row-value">
                        <div className="single-data">
                            <CakeOutlined className="icon-value" />
                            <div className="text-value" >{booking.eventTypeName}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <PersonOutlineRounded className="icon-value" />
                            <div className="text-value" >{booking.artistTypeName}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <MusicNoteRounded className="icon-value" />
                            <div className="text-value" >{booking.genres.map(genre => genre.genreName).join(', ')}</div>
                        </div>
                    </div>
                    <div className="row-value">
                        <div className="single-data">
                            <i className="icon-value fas fa-tshirt" style={{ fontSize: width > 500 ? 13 : (width / 100 * 2.6 < 11 ? 11 : Math.floor(width / 100 * 2.6)) }} />
                            <div className="text-value" >{booking.dressCode}</div>
                        </div>
                    </div>
                </div>
                <div className="row-details">
                    <div className="row-value">
                        <div className="provide-text">
                            {isArtist ? 'Client will provide the following...' : 'I am willing to provide the following...'}
                        </div>
                    </div>
                    <div className="row-value" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="kalab-icon-checkbox">
                            <KalabIconCheckBox
                                iconName="directions_car"
                                iconText={width < 500 ? '' : `Transportation`}
                                value={booking.transportation}
                                disabled
                                iconSize={20}
                                textSize={8}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">
                            <KalabIconCheckBox
                                iconName="local_parking_rounded"
                                iconText={width < 500 ? '' : `Parking`}
                                value={booking.parking}
                                disabled
                                iconSize={20}
                                textSize={8}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="fastfood_rounded"
                                iconText={width < 500 ? '' : `Food & Beverage`}
                                value={booking.foodBeverage}
                                disabled
                                iconSize={20}
                                textSize={8}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="mic_rounded"
                                iconText={width < 500 ? '' : `Equipment`}
                                value={booking.equipment}
                                disabled
                                iconSize={20}
                                textSize={8}
                            />

                        </div>
                        <div className="kalab-icon-checkbox">

                            <KalabIconCheckBox
                                iconName="hotel"
                                iconText={width < 500 ? '' : `Accomodation`}
                                value={booking.accomodation}
                                disabled
                                iconSize={20}
                                textSize={8}
                            />

                        </div>
                    </div>
                </div>
                {
                    (
                        (booking.songRequests === null || booking.songRequests === '') &&
                        (booking.comment === null || booking.comment === '') &&
                        (booking.equipmentDetails === null || booking.equipmentDetails === '')
                    )
                        ?
                        (
                            null
                        )
                        :
                        (
                            <div className="other-details">
                                {
                                    (booking.equipmentDetails === null || booking.equipmentDetails === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Venue Equipment</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={booking.equipmentDetails}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    (booking.comment === null || booking.comment === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Comment</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={booking.comment}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                                {
                                    (booking.songRequests === null || booking.songRequests === '') ?
                                        (
                                            null
                                        )
                                        :
                                        (
                                            <div className="single-detail">
                                                <div className="title">Song Requests</div>
                                                <div className="value">
                                                    <KalabExpandableText
                                                        text={booking.songRequests}
                                                        limitLines={1}
                                                    />
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        )
                }
                {isArtist ?
                    (
                        <div className="payment-details">
                            <div className='title-payment'>
                                Payment Details
                            </div>
                            <div className='row-payment'>
                                <div className='heading-payment'>
                                    Status
                                </div>
                                <div className='value-payment' style={{ color: getPaymentStatusOfBookingForArtist(booking).color }}>
                                    {getPaymentStatusOfBookingForArtist(booking).text}
                                </div>
                            </div>
                            {
                                !booking.isCancelled ?
                                    (
                                        <div className='row-payment'>
                                            <div className='heading-payment'>
                                                Method
                                            </div>
                                            <div className='value-payment'>
                                                {PaymentMethods[booking.paymentMethod].artistText}
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                            {
                                booking.isPaidOut && booking.payOutDetails && booking.payOutDetails.details ?
                                    (
                                        <div className='row-payment'>
                                            <div className='heading-payment'>
                                                Details
                                            </div>
                                            <div className='value-payment'>
                                                {booking.payOutDetails.details}
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                        </div>
                    )
                    :
                    (
                        <div className="payment-details">
                            <div className='title-payment'>
                                Payment Details
                            </div>
                            <div className='row-payment'>
                                <div className='heading-payment'>
                                    Status
                                </div>
                                <div className='value-payment' style={{ color: getPaymentStatusOfBookingForClient(booking).color }}>
                                    {getPaymentStatusOfBookingForClient(booking).text}
                                </div>
                            </div>
                            <div className='row-payment'>
                                <div className='heading-payment'>
                                    Method
                                </div>
                                <div className='value-payment'>
                                    {PaymentMethods[booking.paymentMethod].text}
                                </div>
                            </div>
                            {
                                booking.paymentDetails ?
                                    (
                                        booking.paymentMethod === 'DIRECT' ?
                                            (
                                                booking.refundDetails ?
                                                    (
                                                        <div className='row-payment'>
                                                            <div className='heading-payment'>
                                                                Refund Details
                                                            </div>
                                                            <div className='value-payment'>
                                                                {booking.refundDetails.details}
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className='row-payment'>
                                                            <div className='heading-payment'>
                                                                Details
                                                            </div>
                                                            <div className='value-payment'>
                                                                {booking.paymentDetails.details}
                                                            </div>
                                                        </div>
                                                    )

                                            )
                                            :
                                            (
                                                <div className='row-payment'>
                                                    <div className='heading-payment'>
                                                        Card
                                                    </div>
                                                    <div className='value-payment'>
                                                        {booking.paymentDetails.details.brand} ending in {booking.paymentDetails.details.last4}
                                                    </div>
                                                </div>
                                            )

                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                        </div>
                    )
                }

                <div className="price-details">

                    <div className="other">
                        <div className="price">
                            <div className="title">Artist Price</div>
                            <div className="value">{getLabelFromValue(booking.currency)} {getPriceDetails().price}</div>
                        </div>
                        <div className="price">
                            <div className="title">Service Fee</div>
                            <div className="value">{getLabelFromValue(booking.currency)} {getPriceDetails().serviceFee}</div>
                        </div>
                    </div>
                    <div className="total-price">
                        <div className="value">{getLabelFromValue(booking.currency)} {getPriceDetails().totalPrice}</div>
                    </div>
                </div>

                <div className="status">
                    <div className="status-value">
                        <div
                            className="text"
                            style={
                                {
                                    color: getStatusOfEvent(booking).color,
                                    borderColor: getStatusOfEvent(booking).color,
                                }
                            }>
                            {getStatusOfEvent(booking).text}
                        </div>
                    </div>
                    {booking.isCancelled ?
                        (
                            <>
                                <div className="requested-by">
                                    Requested by : {booking.isCancelledByArtist === null ? 'Admin' : booking.isCancelledByArtist ? 'Artist' : 'Client'}
                                </div>
                                <div className="reason">
                                    Reason : {booking.cancellationReason}
                                </div>
                            </>
                        )
                        : (
                            null
                        )
                    }

                </div>
            </div >
            {showTimings && <PortaledComponent modal={<TimingPopUp closeFn={closeTimingPopUp} request={booking} />} />}
            {sharePopUp &&
                <PortaledComponent
                    modal={
                        <SharePopUp
                            title={'The link of the booking is the following'}
                            closeFn={closeSharePopUp}
                            linkToShare={window.location.origin + '/link/' + booking.token}
                            okText={'Open'}
                            okFn={() => { openInNewTab(window.location.origin + '/link/' + booking.token); closeSharePopUp(); }}
                            okBtnColor={Colors.primary}
                        />
                    }
                />
            }
        </div >
    )
}