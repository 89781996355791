import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessages } from '../../../utils/Date';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5,
        margin : 10,
        '& .text-box': {
            backgroundColor: Colors.lightestBlueishgGrey,
            paddingRight : 5,
            paddingLeft : 5,
            borderRadius : 5,
            maxWidth : 400,
            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '300',
                fontSize: 15,
                color: Colors.black,
                wordBreak:'break-word'
            },
            '& .time': {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '100',
                fontSize: 10,
                color: Colors.black,
                textAlign : 'right'
            }
        }

    }

})

export default function KalabReceivedMessageCell({ message,createdDate}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>

            <div className='text-box'>
                <div className='text'>
                    {message}
                </div>
                <div className='time'>
                    {dateToShowInMessages(createdDate)}
                </div>
            </div>
        </div>
    )
}
