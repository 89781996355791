import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Colors from '../../../constants/Colors';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import KalabIconButton from '../kalabButtons/KalabIconButton';
const useStyles = makeStyles({
    root: props => ({
        '& .MuiInput-underline': {
            '&:after': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2,
            },
            '&:before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2,

            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0.2
            },
            '&.Mui-disabled:before': {
                borderBottomColor: Colors.lightGrey,
                borderBottomWidth: 0,
                borderBottomStyle: 'none'
            },

        },
        '& label': {
            '&.MuiInputLabel-animated': {
                transition: 'color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            },

            '&.Mui-focused':
            {
                color: props.isLightTheme ? Colors.black : Colors.white

            },
            '&.MuiInputLabel-shrink': {
                transform: 'translate(-50%, 0px) scale(0.75)',
                transformOrigin: 'top',
                left: '50%'
            },
            '&.Mui-disabled': {
                color: Colors.lightGrey,
            },
            color: Colors.lightGrey,
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'uppercase',
            fontWeight: '300',
            fontSize: 15,
            left: '50%',
            transform: 'translate(-50%, 24px) scale(1)',
            border: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        '& input': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif',
        },
        '& textarea': {
            color: props.isLightTheme ? Colors.black : Colors.white,
            fontSize: 16,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        },
        '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: '300',
            textAlign: 'center',
            fontFamily: 'Montserrat, sans-serif'
        },
        transition: 'width 500ms',
        width: props.pickerOpen ? '100%' : '75%',
        margin: '0 auto',

    })
});

const defaultMaterialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h2: {
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            subtitle1: {
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            body1: {
                fontSize: 16,
                fontWeight: '400',
                fontFamily: 'Montserrat, sans-serif'
            },
            body2: {
                fontSize: 15,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            },
            caption: {
                fontSize: 15,
                fontWeight: '300',
                fontFamily: 'Montserrat, sans-serif'
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: Colors.primary
            }
        },
        MuiPickersDay: {
            current: {
                color: Colors.primary
            },
            daySelected: {
                backgroundColor: Colors.primary,
                '&:hover': {
                    backgroundColor: Colors.primary
                }
            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: Colors.primary,
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: Colors.primary,
            },
            thumb: {
                backgroundColor: Colors.primary,
                borderColor: Colors.primary
            },
            noPoint: {
                backgroundColor: Colors.primary,
            }
        }

    },
})
export default function KalabTimePickerDropDown({ value, label, onChange, isLightTheme = false, disabled = false }) {
    const [pickerOpen, setPickerOpen] = useState(false);
    const classes = useStyles({ isLightTheme, pickerOpen });
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
                <TimePicker
                    className={classes.root}
                    autoOk
                    variant="inline"
                    format='hh:mm a'
                    label={label}
                    disabled={disabled}
                    clearable
                    value={value}
                    onChange={onChange}
                    onOpen={() => { setPickerOpen(true) }}
                    onClose={() => { setPickerOpen(false) }}
                    InputProps={{
                        endAdornment:
                            <>
                                {value !== null && !disabled ? (
                                    <div style={{ padding: 6 }}>
                                        <KalabIconButton
                                            noBorder
                                            iconName="clear"
                                            iconSize={16}
                                            noDisabled={true}
                                            isWhite={true}
                                            isLightTheme={isLightTheme}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onChange(null);
                                            }}
                                        />
                                    </div>
                                ) : (null)}
                                {disabled ?
                                    (null)
                                    :
                                    (
                                        <div div style={{ margin: 6, borderLeftWidth: 1, borderColor: isLightTheme ? Colors.black : Colors.white }}>
                                            <KalabIconButton
                                                noBorder
                                                iconName="expand_more_rounded"
                                                iconSize={24}
                                                disabled={disabled}
                                                noDisabled={true}
                                                isWhite={true}
                                                isLightTheme={isLightTheme}
                                            />
                                        </div>
                                    )}


                            </>
                    }}

                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}