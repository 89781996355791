import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'

import App from './App';
import './styles/index.scss';
import './index.scss';
import LoaderContextProvider from "./contexts/LoaderContext";
import NotificationProvider from "./contexts/NotificationContext";
import DataStoreContextProvider from "./contexts/DataStoreContext";
import UserContextProvider from "./contexts/UserContext";
import Config from './config/Config';



const tagManagerArgs = {
  gtmId: Config.gtmId
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <LoaderContextProvider>
    <NotificationProvider>
      <UserContextProvider>
        <DataStoreContextProvider>
          <App />
        </DataStoreContextProvider>
      </UserContextProvider>
    </NotificationProvider>
  </LoaderContextProvider>,
  document.getElementById('root')
);
