import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessageList } from '../../../utils/Date';
import { openInNewTab } from '../../../utils/common';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        margin : 10,
        '& .text-box': {
            backgroundColor: Colors.primaryLightest,
            paddingRight : 5,
            paddingLeft : 5,
            borderRadius : 5,
            textAlign : 'center',
            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '200',
                fontSize: 12,
                color: Colors.black,
            },
            '& .link': {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '400',
                fontSize: 12,
                color: Colors.black,
                '&:hover': {
                    cursor: 'pointer',
                    color: Colors.primaryDark
                },
            }
        }

    }

})

export default function KalabAdminMessageCell({ message, linkDetails = null }) {
    const classes = useStyles();
    const handleClickOnLink= () =>{
        openInNewTab(window.location.origin+'/'+ linkDetails.linkRelativeUrl);
    }
    return (
        <div className={classes.root}>
            <div className='text-box'>
                <div className='text'>
                    {message}{linkDetails ? '. ' :''}
                    {
                        linkDetails ? 
                        (
                            <span className='link' onClick={handleClickOnLink}>{linkDetails.linkText}</span>
                        )
                        :
                        (
                            null
                        )
                    }
                    
                </div>
            </div>
        </div>
    )
}
