import React from 'react';
import Colors from '../../../constants/Colors';
import {
    makeStyles
} from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: props=>({
        fontFamily: 'Montserrat, sans-serif',
        color: props.isFilled ? Colors.white : props.color,
        backgroundColor: props.isFilled ? props.color : 'transparent',
        border: '1px solid',
        borderColor: props.color,
        borderRadius: 25,
        fontSize: props.fontSize,
        textTransform: 'uppercase',
        paddingTop : props.paddingVertical,
        paddingLeft : props.paddingHorizontal,
        paddingBottom : props.paddingVertical,
        paddingRight: props.paddingHorizontal,
        '&:hover:not([disabled])': {
            backgroundColor: props.isFilled ? props.highlightColor : props.color,
            borderColor: props.isFilled ? props.highlightColor : props.color,
            color: props.isFilled ? Colors.white : Colors.white,
            cursor: 'pointer',
            outline: 'none'
        },
        '&:disabled' :{
            borderColor: Colors.lightGrey,
            cursor: 'not-allowed',
            color: props.isFilled ? Colors.white : Colors.lightGrey,
            backgroundColor: props.isFilled ? Colors.lightGrey : 'transparent',
        }
    })
});

export default function KalabMainButton({ className = "", disabled, onClick, text, fontSize=16, color=Colors.primary, highlightColor=Colors.primaryDark , isFilled = false, type="button", paddingHorizontal = 20, paddingVertical = 5}) {
    const classes = useStyles({fontSize, color, isFilled, highlightColor, paddingHorizontal, paddingVertical});
    return (
        <button className={`${classes.root} ${className}`}  disabled={disabled} onClick={onClick} type={type}>
            {text}
        </button>
    )
}
