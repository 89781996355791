import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: {
        background: Colors.white,
        borderColor: Colors.lightGrey,
        borderRadius: 10,
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.3)',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        height: '100%',
        '@media (hover: hover)': {

            transform: 'translate(0, 0)  scale(1)',
            transition: 'transform 300ms, box-shadow 300ms',
            '&:hover': {
                cursor: 'pointer',
                transform: 'translate(0, -10px)  scale(1.05)',
                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.3)'
            }
        }
    },
    title: {
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 14,
    },

})

export default function KalabSubValueCard({ title, onClick }) {
    const classes = useStyles();
    return (
        <div className={classes.root} onClick={onClick} >
            <span className={classes.title}>{title}</span>
        </div>
    )
}