import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './ResultPage.scss';
import MainHeader from '../header/MainHeader';
import thanksImage from '../../images/thanks.gif'
import { getImageUrl } from '../../webservices/Webservice';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';
import PortaledComponent from '../modalPortal/ModalPortal';
import { ArtistSignInPopUp } from '../popUps/ArtistSignInPopUp';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import { useNotification } from "../../contexts/NotificationContext";
import { openInNewTab } from '../../utils/common';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function ResultPage(props) {
    const [modal, setModal] = useState(false);
    const notify = useNotification();
    const { height, width } = useWindowDimensions();
    const openModal = () => {
        document.body.style.overflow = 'hidden';
        setModal(true);
    }

    const closeModal= () => {
        setModal(false);
        document.body.style.overflow = 'unset';
    }
    var result = (props.location && props.location.state && props.location.state.response.data.resultMessage) ? props.location.state.response.data.resultMessage : props.resultMessage
    console.log(result)
    const handleShareBtnClick = () => {
        navigator.clipboard.writeText(window.location.origin + '/listing/' + result.listingToken);
        notify({
            type: "SUCCESS",
            message: 'Copied to clipboard',
            title: "Copied!!!"
        })
    }

    const openListingPage = () => {
        openInNewTab(window.location.origin + '/listing/' + result.listingToken);
    }
    return (
        <>
            <MainHeader onlyLogo logoIsBlack />
            <div className='result-page'>
                <div className='heading'>{result.header}</div>
                <div className="main">
                    <div className='text'>{result.description}</div>

                    {
                        result && result.listingToken ?
                            (
                                <div className='listing-details'>
                                    <div className='text'>
                                        Here’s your personal listing link. You can share this on your instagram, personal website, anywhere to make it super simple for anyone to book you!
                                    </div>
                                    <div className='link'>
                                        <div className='text'>
                                            {window.location.origin + '/listing/' + result.listingToken}
                                        </div>
                                        <div>
                                            <KalabSmallButton
                                                text='Copy'
                                                isLightTheme
                                                noBorder
                                                onClick={handleShareBtnClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                null
                            )
                    }

                    <div className='btn-grp'>
                        {
                            result && result.listingToken ?
                                (
                                    <div className='listing-btn'>
                                        <KalabMainButton
                                            text={'Check out the listing'}
                                            onClick={() => {
                                                openListingPage();
                                            }}
                                            fontSize={width < 700 ? 12 : 16}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }
                        {
                            result && result.artistSignIn ?
                                (
                                    <div className='sign-in-btn'>
                                        <KalabMainButton
                                            text={'Sign In'}
                                            onClick={() => {
                                                openModal();
                                            }}
                                            fontSize={width < 700 ? 12 : 16}
                                        />
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                        }

                    </div>


                    <img
                        src={getImageUrl(result.imageKey)}
                        alt="Thanks"
                        className="image"
                        onError={(e) => { e.target.onerror = null; e.target.src = thanksImage }}
                    />


                </div>
            </div>
            {modal && (
                <PortaledComponent
                    modal={
                        (
                            <ArtistSignInPopUp modal={modal} setModal={setModal} closeModal={closeModal}/>
                        )
                    }
                />
            )}
        </>
    )
}
