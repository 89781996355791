import React , {useState, useEffect, useContext} from 'react'
import MainHeader from '../header/MainHeader';

import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";

import { getAboutRequest } from '../../webservices/Webservice';

export default function AboutPage() {
    const [content, setContent] = useState('');
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();


    const getAbout = () => {
        showLoader();
        getAboutRequest(
            (aboutContent) => {
                hideLoader();
                setContent(aboutContent)
                console.log(aboutContent);
            }, (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })

    }

    useEffect(() => {
        getAbout()
    }, [])

    return (
        <>
            <MainHeader onlyLogo={true} isDarkBackground={true} />
            <div className="file-data">
                <div className="content" dangerouslySetInnerHTML={{ __html: content }}>

                </div>

            </div>
        </>
    )
}
