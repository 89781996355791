import React, { useState, useContext, useEffect,useRef } from 'react'
import './ClientInboxPage.scss';
import ClientHeader from '../header/ClientHeader'
import PortaledComponent from "../elements/portaledComponent";
import ClientEventRequestUpdatePopUp from "../popUps/ClientEventRequestUpdatePopUp";
import ClientEventRequestBookPopUp from '../popUps/ClientEventRequestBookPopUp';
import AlertPopUpWithReason from '../popUps/AlertPopUpWithReason';
import AlertPopUp from '../popUps/AlertPopUp';
import StripeContextProvider from "../../contexts/StripeContext";
import {
    getMessagedArtistListForClientRequest,
    getMessagesForClientFromArtistRequest,
    sendMessagesFromClientRequest,
    getEventRequestsForClientRequest,
    cancelEventRequestForClientRequest,
    getBookingsForClientRequest,
    getRejectionsForClientRequest,
    bookEventRequestForClientRequest,
    getServerUrl
} from '../../webservices/Webservice';
import { Avatar } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';

import { LoaderContext } from "../../contexts/LoaderContext";
import { UserContext } from "../../contexts/UserContext";
import { useNotification } from "../../contexts/NotificationContext";
import KalabArtistMessageListCell from '../kalab/kalabMessageElements/KalabArtistMessageListCell';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabMessageField from '../kalab/kalabTextField/KalabMessageField';
import KalabAdminMessageCell from '../kalab/kalabMessageElements/KalabAdminMessageCell';
import EventMessages from '../../constants/EventMessages';
import KalabReceivedMessageCell from '../kalab/kalabMessageElements/KalabReceivedMessageCell';
import KalabSentMessageCell from '../kalab/kalabMessageElements/KalabSentMessageCell';
import KalabSmallButton from '../kalab/kalabButtons/KalabSmallButton';
import KalabEventRequestCard from '../kalab/kalabCards/KalabEventRequestCard';
import KalabBookingCard from '../kalab/kalabCards/KalabBookingCard';
import KalabRejectionCard from '../kalab/kalabCards/KalabRejectionCard';
import { clientCancellation } from '../../constants/Reasons';
import Colors from '../../constants/Colors';

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';

//Socket
import socketIOClient from "socket.io-client";
import { getMessageLinkDetails } from '../../utils/EventMessageDetails';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';



SwiperCore.use([Pagination, Navigation]);

export default function ClientInboxPage() {
    const { height, width } = useWindowDimensions();
    const [messagedArtistList, setMessagedArtistList] = useState([]);
    const [eventRequests, setEventRequests] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [rejections, setRejections] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [messages, setMessages] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState(null)
    const [showDetails, setShowDetails] = useState(false);
    const [showList, setShowList] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState(null)

    const [eventRequestSwiper, setEventRequestSwiper] = useState(null);
    const [eventRequestSwiperDetail, setEventRequestSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const [clientEventRequestUpdatePopUpShow, setClientEventRequestUpdatePopUpShow] = useState(false);
    const [eventRequestToUpdate, setEventRequestToUpdate] = useState(null);

    const [eventRequestCancelPopUpShow, setEventRequestCancelPopUpShow] = useState(false);
    const [eventRequestToCancel, setEventRequestToCancel] = useState(null);

    const [eventRequestBookPopUpShow, setEventRequestBookPopUpShow] = useState(false);
    const [eventRequestToBook, setEventRequestToBook] = useState(null);

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();
    const { loggedInUserDetails } = useContext(UserContext);
    const [update, setUpdate] = useState(false);
    const messageBeginningRef = useRef(null)
    const closeEventRequestBookPopUp = () => {
        document.body.style.overflow = 'unset';
        setEventRequestBookPopUpShow(false);
        setEventRequestToBook(null);
        updateAllData();
    }

    const showEventRequestBookPopUp = (eventRequest) => {
        document.body.style.overflow = 'hidden';
        setEventRequestToBook(eventRequest);
        setEventRequestBookPopUpShow(true);
    }

    const closeEventRequestCancelPopUp = () => {
        document.body.style.overflow = 'unset';
        setEventRequestCancelPopUpShow(false);
        setEventRequestToCancel(null);
        updateAllData();
    }

    const showEventRequestCancelPopUp = (eventRequest) => {
        document.body.style.overflow = 'hidden';
        setEventRequestToCancel(eventRequest);
        setEventRequestCancelPopUpShow(true);
    }


    const closeEventRequestUpdatePopUp = () => {
        document.body.style.overflow = 'unset';
        setClientEventRequestUpdatePopUpShow(false);
        setEventRequestToUpdate(null);
        updateAllData();
    }

    const showEventRequestUpdatePopUp = (eventRequest) => {
        document.body.style.overflow = 'hidden';
        setEventRequestToUpdate(eventRequest);
        setClientEventRequestUpdatePopUpShow(true);
    }

    const handleCreateNewOffer = (eventRequest) => {
        showEventRequestUpdatePopUp(eventRequest);
    }
    const handleCancelEventRequest = (eventRequest) => {
        showEventRequestCancelPopUp(eventRequest);
    }

    const handleBookAnEvent = (eventRequest) => {
        showEventRequestBookPopUp(eventRequest);
    }

    const scrollToBegining = () => {
        messageBeginningRef.current?.scrollIntoView({ behavior: "smooth" })
      }


    const cancelEventRequest = (reason, comment) => {
        showLoader();
        cancelEventRequestForClientRequest(
            eventRequestToCancel.eventRequestId,
            reason,
            comment,
            (response) => {
                hideLoader();
                notify({
                    type: 'SUCCESS',
                    message: response,
                    title: 'Success!!!',
                });
                closeEventRequestCancelPopUp();
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            }
        )
    }


    const getMessagedArtistList = (loading = true) => {
        if (loading) {
            showLoader();
        }
        getMessagedArtistListForClientRequest(
            ((artistList) => {
                //console.log(artistList);
                if (artistList.length > 0 && selectedArtist === null) {
                    setSelectedArtist(artistList[0]);
                }
                setMessagedArtistList(artistList);
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }
    const getMessages = (loading = true) => {
        if (loading) {
            showLoader();
        }
        getMessagesForClientFromArtistRequest(selectedArtist.artistId,
            ((messages) => {
                // console.log(messages);
                setMessages(messages);
                getMessagedArtistList(false);
                hideLoader();
                scrollToBegining();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }
    const getEventRequests = (loading = true) => {
        if (loading) {
            showLoader();
        }
        getEventRequestsForClientRequest(selectedArtist.artistId,
            ((eventRequests) => {
                console.log(eventRequests);
                setEventRequests(eventRequests);
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }
    const getBookings = (loading = true) => {
        if (loading) {
            showLoader();
        }
        getBookingsForClientRequest(selectedArtist.artistId,
            ((bookings) => {
                console.log(bookings);
                setBookings(bookings);
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }
    const getRejections = (loading = true) => {
        if (loading) {
            showLoader();
        }
        getRejectionsForClientRequest(selectedArtist.artistId,
            ((rejections) => {
                //  console.log(rejections);
                setRejections(rejections);
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }
    const sendMessage = () => {
        showLoader();
        sendMessagesFromClientRequest(selectedArtist.artistId, messageText,
            ((response) => {
                //console.log(response);
                getMessages();
                setMessageText('');
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        )
    }

    const handleSendMessageButtonClick = () => {
        if (isMessageValid()) {
            sendMessage();
        }
    }

    const isMessageValid = () => {
        if (messageText === "") {
            return false
        }
        else {
            return true
        }
    }

    const handleMessageTextChange = (e) => {
        setMessageText(e.target.value);
    }
    const toggleShowDetails = (open) => {
        if(open){
            toggleShowList(false);
        }
        setShowDetails(open);
    }

    const toggleShowList = (open) => {
        if(open){
            toggleShowDetails(false);
        }
        setShowList(open);
    }

    const updateAllData = () => {
        getEventRequests(false);
        getBookings(false);
        getRejections(false);
        getMessages(false);
        getMessagedArtistList(false);
    }

    useEffect(() => {
        getMessagedArtistList();
    }, [])

    useEffect(() => {
        if (selectedArtist) {
            getMessages();
            setShowDetails(width > 1250 ? true : false);
            setShowList(false);
            setEventRequests([]);
            setBookings([]);
            setRejections([]);
            setSelectedHeader(null);
            getEventRequests();
            getBookings();
            getRejections();

        }
    }, [selectedArtist])

    useEffect(() => {
        if (eventRequests.length > 0 && selectedHeader === null) {
            setSelectedHeader(1)
        }
        else if (bookings.length > 0 && selectedHeader === null) {
            setSelectedHeader(2)
        }
        else if (rejections.length > 0 && selectedHeader === null) {
            setSelectedHeader(3)
        }
        else {

        }
    }, [eventRequests, bookings, rejections])


    useEffect(() => {
        const socket = socketIOClient(
            getServerUrl(),
            {
                query: {
                    isClient: true,
                    id: loggedInUserDetails.user.clientId
                }
            }

        );
        socket.on("updateClient", ({ clientIds }) => {
            console.log('updateClient', clientIds)
            if (clientIds.includes(loggedInUserDetails.user.clientId)) {
                setUpdate(true);
            }
        });
        return () => {
            socket.disconnect();
        }
    }, [])

    useEffect(() => {
        if (update) {
            updateAllData();
            setUpdate(false);
        }
    }, [update])


    return (
        <>
            <ClientHeader />
            <div className="client-inbox-page">
                <div className={`artist-list ${width <= 800 ? (showList ? 'animated-artist-list-section-open' : 'animated-artist-list-section-close') : ('')}`}>
                    <div className="artist-list__title" >
                        Messages
                    </div>
                    {
                        messagedArtistList.length === 0 ?
                            (
                                <div className="artist-list__no-chats">
                                    No Chats to show
                                </div>

                            ) :
                            (
                                <div className="artist-list__cells">
                                    {messagedArtistList.map(messagedArtist =>
                                    (
                                        <KalabArtistMessageListCell
                                            messagedArtist={messagedArtist}
                                            setSelectedArtist={setSelectedArtist}
                                            isSelected={selectedArtist && selectedArtist.artistId === messagedArtist.artistId ? true : false}
                                        />
                                    )
                                    )}
                                </div>
                            )
                    }
                </div>
                <div className="detail-section" >
                    <div className="message-section">
                        {selectedArtist ?
                            (
                                <>
                                    <div className="message-section__header">
                                        <div className="message-section__header--left">
                                            {
                                                width <= 800 ?
                                                    (
                                                        <div style={{marginRight : 10}}>
                                                            <KalabIconButton
                                                                iconName='arrow_backward'
                                                                isLightTheme
                                                                noBorder
                                                                onClick={() => { toggleShowList(true); }}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                            <Avatar className="avatar">
                                                {selectedArtist.artistName[0]}
                                            </Avatar>
                                            <div className="artist-name">
                                                {selectedArtist.artistName}
                                            </div>

                                        </div>
                                        <div className="message-section__header--right">
                                            {
                                                width <= 1250 ?
                                                    (
                                                        showDetails ? 
                                                        (
                                                            null
                                                        )
                                                        :
                                                        (
                                                            <KalabMainButton 
                                                                text={'Request Details'}
                                                                fontSize={10}
                                                                paddingHorizontal={5}
                                                                isFilled
                                                                onClick={() => { toggleShowDetails(!showDetails) }}
                                                            />
                                                        )
                                                        
                                                    )
                                                    :
                                                    (
                                                        <KalabSmallButton
                                                            text={showDetails ? 'Hide Details' : 'Show Details'}
                                                            isLightTheme
                                                            onClick={() => { toggleShowDetails(!showDetails) }}
                                                        />
                                                    )
                                            }

                                        </div>
                                    </div>
                                    <div className="message-section__main" onClick={() => { if(width<=1250) {toggleShowList(false); toggleShowDetails(false)} }}>
                                        <div ref={messageBeginningRef}></div>
                                        {messages.length > 0 ?
                                            (
                                                messages.map(message => {
                                                    if (message.isSendByAdmin) {
                                                        return (
                                                            <KalabAdminMessageCell
                                                                message={EventMessages[message.message].client}
                                                                linkDetails={getMessageLinkDetails(message).client}
                                                            />
                                                        )
                                                    }
                                                    else {
                                                        if (message.isSendByArtist) {
                                                            return (
                                                                <KalabReceivedMessageCell
                                                                    message={message.message}
                                                                    createdDate={message.createdDate}
                                                                />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <KalabSentMessageCell
                                                                    message={message.message}
                                                                    createdDate={message.createdDate}
                                                                    isSeen={message.isSeen}
                                                                    seenTime={message.seenTime}
                                                                />
                                                            )
                                                        }
                                                    }
                                                })
                                            )
                                            :
                                            (
                                                <div className="no-messages">
                                                    No Messages
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="message-section__footer">
                                        <div className="text-box">
                                            <KalabMessageField
                                                value={messageText}
                                                onChange={handleMessageTextChange}
                                                onClick={() => { if(width<=1250) {toggleShowList(false); toggleShowDetails(false)} }}
                                                isLightTheme
                                                placeholder={'Type your message'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <KalabIconButton
                                                            onClick={handleSendMessageButtonClick}
                                                            disabled={!isMessageValid()}
                                                            iconName={'far fa-paper-plane'}
                                                            iconSize={15}
                                                            height={15}
                                                            isFilled
                                                            isLightTheme
                                                            isFAIcon
                                                        />
                                                    </InputAdornment>
                                                }

                                            />
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <div className="message-section__no-messages">
                                    No Messages Selected
                                </div>
                            )
                        }
                    </div>
                    <div className={showDetails ? 'animated-detail-section-open' : 'animated-detail-section-close'}>
                        {showDetails ?
                            (
                                <>
                                    <div className='request-header-buttons'>

                                        {
                                            width <= 1250 ?
                                                (
                                                    <div>
                                                        <KalabIconButton
                                                            iconName='arrow_forward'
                                                            iconSize={width > 500 ? 18 : 16}
                                                            isLightTheme
                                                            noBorder
                                                            onClick={() => { toggleShowDetails(false) }}
                                                        />
                                                    </div>
                                                )
                                                :
                                                (
                                                    null
                                                )
                                        }

                                        <div className={`request-header-buttons__button ${selectedHeader && selectedHeader === 1 ? 'active' : ''}`} onClick={() => { setSelectedHeader(1) }}>
                                            Negotiations
                                            <span className='request-header-buttons__button--count'>({eventRequests.length})</span>
                                        </div>
                                        <div className={`request-header-buttons__button ${selectedHeader && selectedHeader === 2 ? 'active' : ''}`} onClick={() => { setSelectedHeader(2) }}>
                                            Bookings
                                            <span className='request-header-buttons__button--count'>({bookings.length})</span>
                                        </div>
                                        <div className={`request-header-buttons__button ${selectedHeader && selectedHeader === 3 ? 'active' : ''}`} onClick={() => { setSelectedHeader(3) }}>
                                            Rejections
                                            <span className='request-header-buttons__button--count'>({rejections.length})</span>
                                        </div>
                                    </div>
                                    <div className='request-detail'>
                                        <div className="event-request-swiper">
                                            <div>
                                                <KalabIconButton
                                                    iconName='keyboard_arrow_left_rounded'
                                                    disabled={eventRequestSwiperDetail.isBeginning}
                                                    isLightTheme
                                                    onClick={() => {
                                                        eventRequestSwiper.slidePrev();
                                                    }}
                                                />
                                            </div>
                                            <Swiper
                                                onUpdate={(swiper) => {
                                                    // console.log(swiper);
                                                    setEventRequestSwiper(swiper);
                                                    setEventRequestSwiperDetail({
                                                        isBeginning: swiper.isBeginning,
                                                        isEnd: swiper.isEnd
                                                    });
                                                }}
                                                onSwiper={(swiper) => {
                                                    //  console.log(swiper);
                                                    setEventRequestSwiper(swiper);
                                                    setEventRequestSwiperDetail({
                                                        isBeginning: swiper.isBeginning,
                                                        isEnd: swiper.isEnd
                                                    });
                                                }}
                                                spaceBetween={2}
                                                slidesPerView={1}
                                                freeMode={true}
                                                onSlideChange={(swiper) => {
                                                    //  console.log(swiper);
                                                    setEventRequestSwiperDetail({
                                                        isBeginning: swiper.isBeginning,
                                                        isEnd: swiper.isEnd
                                                    })
                                                }}
                                                className="mySwiper"
                                            >
                                                {
                                                    selectedHeader && selectedHeader === 1 ?
                                                        (eventRequests.length > 0 ? (
                                                            (eventRequests.map((eventRequest) => (
                                                                <SwiperSlide key={eventRequest.eventRequestId}>
                                                                    <KalabEventRequestCard
                                                                        eventRequest={eventRequest}
                                                                        createNewOffer={() => { handleCreateNewOffer(eventRequest); }}
                                                                        cancelEventRequest={() => { handleCancelEventRequest(eventRequest); }}
                                                                        isArtist={false}
                                                                        book={() => { handleBookAnEvent(eventRequest); }}
                                                                    />
                                                                </SwiperSlide>
                                                            )))
                                                        ) :
                                                            (
                                                                <SwiperSlide >
                                                                    <div className="empty-list">No Event Requests to show</div>
                                                                </SwiperSlide>

                                                            )
                                                        )
                                                        :
                                                        (null)
                                                }

                                                {
                                                    selectedHeader && selectedHeader === 2 ?
                                                        (bookings.length > 0 ? (
                                                            (bookings.map((booking) => (
                                                                <SwiperSlide key={booking.bookingId}>
                                                                    <KalabBookingCard
                                                                        booking={booking}
                                                                        isArtist={false}
                                                                    />
                                                                </SwiperSlide>
                                                            )))) :
                                                            (
                                                                <SwiperSlide >
                                                                    <div className="empty-list">No Bookings</div>
                                                                </SwiperSlide>

                                                            )
                                                        ) :
                                                        (null)
                                                }

                                                {
                                                    selectedHeader && selectedHeader === 3 ?
                                                        (rejections.length > 0 ? (
                                                            (rejections.map((rejection) => (
                                                                <SwiperSlide key={rejection.rejectionId}>
                                                                    <KalabRejectionCard
                                                                        rejection={rejection}
                                                                        isArtist={false}
                                                                    />
                                                                </SwiperSlide>
                                                            )))) :
                                                            (
                                                                <SwiperSlide >
                                                                    <div className="empty-list">No Rejections</div>
                                                                </SwiperSlide>

                                                            )
                                                        ) :
                                                        (null)
                                                }

                                            </Swiper>
                                            <div>
                                                <KalabIconButton
                                                    iconName='keyboard_arrow_right_rounded'
                                                    disabled={eventRequestSwiperDetail.isEnd}
                                                    isLightTheme
                                                    onClick={() => {
                                                        eventRequestSwiper.slideNext();
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )
                            :
                            (
                                null
                            )
                        }

                    </div>
                </div>
                {clientEventRequestUpdatePopUpShow && <PortaledComponent modal={<ClientEventRequestUpdatePopUp closeFn={closeEventRequestUpdatePopUp} eventRequest={eventRequestToUpdate} />} />}
                {eventRequestCancelPopUpShow && <PortaledComponent modal={<AlertPopUpWithReason closeFn={closeEventRequestCancelPopUp} title={`Why do you want to cancel the event request - "${eventRequestToCancel.eventName}" ?`} reasons={clientCancellation} okText={"Cancel Request"} okFn={(reason, comment) => { cancelEventRequest(reason, comment) }} />} />}
                {eventRequestBookPopUpShow && <PortaledComponent modal={
                    <StripeContextProvider>
                        <ClientEventRequestBookPopUp closeFn={closeEventRequestBookPopUp} eventRequest={eventRequestToBook} />
                    </StripeContextProvider>
                }
                />}
            </div>
        </>
    )
}
