import React, { useState, useEffect, useContext } from "react";
import './ArtistBookingsPage.scss';
import ArtistHeader from "../header/ArtistHeader";
import { scroller } from 'react-scroll';

import KalabArtistCalendar from "../kalab/kalabCalendar/KalabArtistCalendar";

import { LoaderContext } from "../../contexts/LoaderContext";
import { useNotification } from "../../contexts/NotificationContext";
import moment from "moment";
import KalabIconButton from "../kalab/kalabButtons/KalabIconButton";
import { getBookingsSummaryOfAYearForArtistRequest, getBookingsOfAMonthForArtistRequest, getListingOfAnArtistRequest } from "../../webservices/Webservice";
import KalabMonthCell from "../kalab/kalabCalendar/KalabMonthCell";
import KalabBookingCell from "../kalab/kalabBookingElements/KalabBookingCell";
import KalabBookingDetailElement from "../kalab/kalabBookingElements/KalabBookingDetailElement";
import KalabBookingListingCard from "../kalab/kalabCards/KalabBookingListingCard";

// Import Swiper
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';


export default function ArtistBookingsPage() {
    const [listings, setListings] = useState([]);
    const [selectedListing, setSelectedListing] = useState(null);
    const [months, setMonths] = useState([]);
    const [year, setYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [selectedDateValue, setSelectedDateValue] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [listingSwiper, setListingSwiper] = useState(null);
    const [listingSwiperDetail, setListingSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const [bookingSwiper, setBookingSwiper] = useState(null);
    const [bookingSwiperDetail, setBookingSwiperDetail] = useState({
        isBeginning: true,
        isEnd: true
    });

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const notify = useNotification();


    const getListings = () => {
        showLoader();
        getListingOfAnArtistRequest(
            (listings) => {
                hideLoader();
                setListings(listings);
                console.log(listings);
                if (listings.length > 0) {
                    setSelectedListing(listings[0]);
                }
            },
            (error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
    }

    const getBookingsSummary = () => {
        showLoader();
        getBookingsSummaryOfAYearForArtistRequest(year,
            selectedListing.listingId,
            ((bookingSummary) => {
                console.log(bookingSummary);
                setMonths(bookingSummary);
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        );
    }
    const getBookings = () => {
        showLoader();
        getBookingsOfAMonthForArtistRequest(
            year,
            selectedListing.listingId,
            selectedMonth,
            ((bookings) => {
                console.log(bookings);
                setBookings(bookings);
                if (selectedBooking && bookings.filter(booking => booking.bookingId === selectedBooking.bookingId).length === 0) {
                    setSelectedBooking(null);
                }
                hideLoader();
            }),
            ((error) => {
                notify({
                    type: "ERROR",
                    message: error.message? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                })
                hideLoader();
            })
        );
    }
    const getCurrentSelectedDate = () => {
        if (selectedDateValue && selectedMonth && year) {
            var date = new Date(year, selectedMonth - 1, selectedDateValue);
            console.log(date)
            return date
        }
        else {
            return null;
        }
    }
    const getCurrentMonthDate = () => {
        var date = new Date(year, selectedMonth - 1, 1);
        console.log(date)
        return date
    }

    const getCurrentSelectedMonth = () => {
        return moment.months()[selectedMonth - 1]
    }

    const handlePrevYearBtnClick = () => {
        setYear(year - 1);
        setSelectedMonth(12);
        setSelectedDateValue(null);
    }
    const handleNextYearBtnClick = () => {
        setYear(year + 1);
        setSelectedMonth(1);
        setSelectedDateValue(null);
    }
    const handleSelectMonth = (month) => {
        setSelectedMonth(month.monthNo)
        setSelectedDateValue(null);
    }
    const handleDateChangeValue = (value, event) => {
        if (selectedDateValue === value.getDate()) {
            setSelectedDateValue(null)
        }
        else {
            setSelectedDateValue(value.getDate());
        }
        // var date = moment(value);
        // var filterBookings = bookings.filter(booking => !booking.isResidency && booking.date === date.format('YYYY-MM-DD'))
        // bookings.filter(booking => booking.isResidency).forEach(booking => {
        //     booking.timings.forEach(timing => {
        //         if (timing.date === date.format('YYYY-MM-DD')) {
        //             filterBookings.push(booking);
        //         }
        //     })
        // })
        // console.log(filterBookings);
        // if (filterBookings.length > 0) {
        //     setSelectedBooking(filterBookings[0]);
        // }
        // else {
        //     setSelectedBooking(null);
        // }
    }
    const handleSelectBooking = (booking) => {
        setSelectedBooking(booking);
        // var date = moment(booking.date, 'YYYY-MM-DD');
        // if (booking.isResidency) {
        //     if (date.get('month') === selectedMonth - 1) {
        //         setSelectedDateValue(date.get('date'));
        //     }
        //     else {
        //         booking.timings.forEach(timing => {
        //             var timingDate = moment(timing.date, 'YYYY-MM-DD');
        //             if (timingDate.get('month') === selectedMonth - 1) {
        //                 setSelectedDateValue(timingDate.get('date'));
        //             }
        //         })
        //     }
        // }
        // else {
        //     setSelectedDateValue(date.get('date'));
        // }

    }

    const getHighlightedDates = () => {
        var highlightedDates = [];
        if (selectedBooking) {
            if (selectedBooking.isResidency) {
                selectedBooking.timings.forEach(timing => {
                    highlightedDates.push(timing.date);
                })
            }
            else {
                highlightedDates = [selectedBooking.date]
            }
        }
        return highlightedDates
    }

    const scrollToEventDetailSection = () => {
        scroller.scrollTo('event-detail', {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: 0,
        });
    };

    const scrollToCalendarDetailSection = () => {
        scroller.scrollTo('calendar-detail', {
            duration: 1000,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -80,
        });
    };

    const getNoOfEventsInAMonth = () => {
        var count = 0;
        bookings.forEach(booking => {
            if (booking.isResidency) {
                booking.timings.forEach(timing => {
                    var date = moment(timing.date, 'YYYY-MM-DD')
                    if (date.get('month') === selectedMonth - 1) {
                        count = count + 1;
                    }
                });
            }
            else {
                count = count + 1;
            }
        })
        return count
    }

    const getBookingsToShow = () => {
        var bookingsToShow = []
        if (selectedDateValue && selectedMonth && year) {
            var date = new Date(year, selectedMonth - 1, selectedDateValue);
            var dateMoment = moment(date);
            bookings.forEach(booking => {
                if (booking.isResidency) {
                    booking.timings.forEach(timing => {
                        if (timing.date === dateMoment.format('YYYY-MM-DD')) {
                            bookingsToShow.push(booking);
                        }
                    })
                }
                else {
                    if (booking.date === dateMoment.format('YYYY-MM-DD')) {
                        bookingsToShow.push(booking);
                    }
                }
            })
        }

        return bookingsToShow.sort((a, b) => {
            if (selectedBooking && a.bookingId === selectedBooking.bookingId) {
                return -1
            }
            else {
                return 0
            }
        });
    }



    useEffect(() => {
        getListings();
        var date = new Date();
        setYear(date.getFullYear());
        setSelectedMonth(date.getMonth() + 1);
    }, [])

    useEffect(() => {
        if (year && selectedListing) {
            getBookingsSummary();
        }
    }, [year, selectedListing])

    useEffect(() => {
        if (year && selectedMonth && selectedListing) {
            getBookings();
        }
    }, [year, selectedMonth, selectedListing])


    useEffect(() => {
        if (getBookingsToShow().length > 0) {
            scrollToEventDetailSection();
        }
        else {
            scrollToCalendarDetailSection();
        }

    }, [selectedDateValue])

    return (
        <>
            <ArtistHeader />
            <div className="artist-bookings-page">
                <div className="calendar-detail">
                    <div className="month-year">
                        <div className="year-details">
                            <div className="left-arrow">
                                <KalabIconButton
                                    iconName='keyboard_arrow_left_rounded'
                                    noBorder
                                    onClick={handlePrevYearBtnClick}
                                />

                            </div>
                            <div className="year">
                                {year}
                            </div>
                            <div className="right-arrow">
                                <KalabIconButton
                                    iconName='keyboard_arrow_right_rounded'
                                    noBorder
                                    onClick={handleNextYearBtnClick}
                                />
                            </div>

                        </div>
                        <div className="months-list">
                            {months.map(month => (
                                <KalabMonthCell
                                    key={`${month.monthNo}`}
                                    month={month}
                                    setSelectedMonth={handleSelectMonth}
                                    isSelected={month.monthNo === selectedMonth}
                                />
                            ))}
                        </div>


                    </div>
                    <div className="main-section">
                        <div className="listing-details">
                            <div className="listing-details__swiper">
                                <div>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_left_rounded'
                                        disabled={listingSwiperDetail.isBeginning}
                                        onClick={() => {
                                            listingSwiper.slidePrev();
                                        }}
                                        isLightTheme
                                    />
                                </div>
                                <Swiper
                                    onUpdate={(swiper) => {
                                        console.log(swiper);
                                        setListingSwiper(swiper);
                                        setListingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        });
                                    }}
                                    onSwiper={(swiper) => {
                                        console.log(swiper);
                                        setListingSwiper(swiper);
                                        setListingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        });
                                    }}
                                    spaceBetween={5}
                                    slidesPerView={4}
                                    freeMode={true}
                                    onSlideChange={(swiper) => {
                                        console.log(swiper);
                                        setListingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        })
                                    }}
                                    className="mySwiper"
                                >
                                    {listings.map((listing) => (
                                        <SwiperSlide key={listing.listingId}>
                                            <KalabBookingListingCard
                                                listing={listing}
                                                selected={selectedListing && listing.listingId === selectedListing.listingId}
                                                setSelectedListing={setSelectedListing}
                                            />
                                        </SwiperSlide>
                                    ))
                                    }

                                </Swiper>
                                <div>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_right_rounded'
                                        disabled={listingSwiperDetail.isEnd}
                                        isLightTheme
                                        onClick={() => {
                                            listingSwiper.slideNext();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="calendar">
                            <div className="month-name">
                                {getCurrentSelectedMonth()}
                            </div>

                            <KalabArtistCalendar
                                selectedDate={getCurrentSelectedDate()}
                                onChange={handleDateChangeValue}
                                bookings={bookings}
                                showDate={getCurrentMonthDate()}
                                highlightedDates={getHighlightedDates()}
                            />

                           
                        </div>
                        {getBookingsToShow().length > 0  ?
                                (
                                    <div className="down-icon">
                                        <KalabIconButton
                                           isLightTheme
                                           iconName={'keyboard_arrow_down_rounded'}
                                           onClick={scrollToEventDetailSection}
                                           noBorder
                                           noDisabled
                                           iconSize={35}
                                        />
                                    </div>
                                )
                                :
                                (null)
                            }
                    </div>
                    <div className="booking-list">
                        <div className="booking-list__title">
                            Events ({getNoOfEventsInAMonth()})
                        </div>
                        <div className="booking-list_cells">
                            {
                                bookings.length > 0 ?

                                    bookings.map(booking => (
                                        <KalabBookingCell
                                            booking={booking}
                                            isSelected={selectedBooking && booking.bookingId === selectedBooking.bookingId}
                                            setSelectedBooking={handleSelectBooking}
                                            isArtist={true}
                                            selectedMonth={selectedMonth}
                                        />
                                    )
                                    )
                                    :
                                    (
                                        <div className="no-bookings">No Events</div>
                                    )}
                        </div>
                    </div>
                </div>
                {
                     getBookingsToShow().length > 0  ? (
                        <div className="event-detail">
                             <div className="booking-swiper">
                                <div>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_left_rounded'
                                        disabled={bookingSwiperDetail.isBeginning}
                                        onClick={() => {
                                            bookingSwiper.slidePrev();
                                        }}
                                    />
                                </div>
                                <Swiper
                                    onUpdate={(swiper) => {
                                        // console.log(swiper);
                                        setBookingSwiper(swiper);
                                        setBookingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        });
                                    }}
                                    onSwiper={(swiper) => {
                                        //  console.log(swiper);
                                        setBookingSwiper(swiper);
                                        setBookingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        });
                                    }}
                                    spaceBetween={2}
                                    slidesPerView={1}
                                    freeMode={true}
                                    onSlideChange={(swiper) => {
                                        //  console.log(swiper);
                                        setBookingSwiperDetail({
                                            isBeginning: swiper.isBeginning,
                                            isEnd: swiper.isEnd
                                        })
                                    }}
                                    className="mySwiper"
                                >
                                    {

                                        getBookingsToShow().length > 0 ? (
                                            (getBookingsToShow().map((booking) => (
                                                <SwiperSlide key={booking.bookingId}>
                                                    <KalabBookingDetailElement
                                                        booking={booking}
                                                        isArtist={true}
                                                        selectedDate={getCurrentSelectedDate()}
                                                    />
                                                </SwiperSlide>
                                            )))
                                        ) :
                                            (
                                                <SwiperSlide >
                                                    <div className="empty-list">No Events to show</div>
                                                </SwiperSlide>

                                            )

                                    }


                                </Swiper>
                                <div>
                                    <KalabIconButton
                                        iconName='keyboard_arrow_right_rounded'
                                        disabled={bookingSwiperDetail.isEnd}
                                        onClick={() => {
                                            bookingSwiper.slideNext();
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <div className="up-icon">
                                <KalabIconButton
                                    iconName={'keyboard_arrow_up_rounded'}
                                    onClick={scrollToCalendarDetailSection}
                                    noBorder
                                    iconSize={35}
                                />
                            </div>
                        </div>
                    )
                        :
                        (
                            null
                        )
                }

            </div>
        </>
    )
}
