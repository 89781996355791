import React from 'react';
import Icon from '@material-ui/core/Icon';

import {
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';

const useStyles = makeStyles({
    root: props => ({
        textAlign:'center',
        transition:'transform 0.5s',
        '& .icon-text': {
            color: props.value ? Colors.primary : Colors.lightGrey,
            fontSize: props.textSize,
            fontWeight: '300',
            fontFamily: 'Montserrat, sans-serif',
            textTransform:'capitalize',
            textAlign:'center'
        },
        '&:hover': {
            cursor: !props.disabled ? 'pointer' : 'unset',
            transform: !props.disabled ? 'scale(1.15)' : 'unset',
        }
    })
})

export default function KalabIconCheckBox({ iconName, iconText, value, onChange, isFAIcon = false, iconSize = 30, disabled=false , textSize=11 }) {
    const classes = useStyles({ value , disabled, textSize});
    const handleOnClick = () => {
        if(!disabled){
            onChange(!value);
        }
        
    }
    return (
        <div className={classes.root} onClick={handleOnClick} >
            {!isFAIcon ? (
                <Icon style={{ fontSize: iconSize, color: value ? Colors.primary : Colors.lightestGrey }}>{iconName}</Icon>
            ) : (
                <i className={`${iconName}`} style={{ fontSize: iconSize - 2 }}></i>
            )}
            <div className="icon-text">{iconText}</div>
        </div>
    )
}
