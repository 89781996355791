const { getUserIpDetails } = require("../session/user");

const currencies = [
    {
        value: 'USD',
        label: '$',
        default: true
    },
    {
        value: 'EUR',
        label: '€',
        default: false
    },
    {
        value: 'GBP',
        label: '£',
        default: false
    },
    {
        value: 'CHF',
        label: 'CHF',
        default: false
    }
];

module.exports.getLabelFromValue = function (value) {
    var label = "";
    currencies.forEach(currency => {
        if (currency.value === value) {
            label = currency.label;
        }
    })
    return label;
}
module.exports.Currencies = currencies;

module.exports.getDefaultCurrency = function () {
    var defaultCurrency = null;
    var ipDetails = getUserIpDetails();
    if (ipDetails) {
        var selectedCurrencies = currencies.filter(currency => currency.value === ipDetails.currency);
        console.log(ipDetails.currency)
        if (selectedCurrencies.length > 0) {
            defaultCurrency = selectedCurrencies[0];
        }
    }
    if (defaultCurrency === null) {
        currencies.forEach(currency => {
            if (currency.default) {
                defaultCurrency = currency;
            }
        })
    }
    return defaultCurrency;
}