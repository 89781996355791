import React from 'react';
import Rating from '@material-ui/lab/Rating';
import {
    withStyles,
    makeStyles
} from '@material-ui/core/styles'
import Colors from '../../../constants/Colors';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles({
    root: props => ({
        fontSize: props.fontSize,
        '& label': {
            fontSize: props.fontSize,
            padding: props.padding,
            '& .MuiRating-iconEmpty': {
                color: Colors.lightGrey
            }
            
        },
        color: Colors.primary,
        '& .MuiRating-iconEmpty': {
            color: Colors.lightGrey
        },

       
       
    })
});

export default function KalabRating({ value, onChange, fontSize = 30, padding = 0 , readOnly= false}) {
    const props = ({
        fontSize,
        padding,
    })
    const classes = useStyles(props);
    return (
        <Rating
            className={`${classes.root}`}
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            readOnly={readOnly}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
        />
    )
}
