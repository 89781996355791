import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import defaultImage from '../../../images/default-img.png';
import { openInNewTab } from '../../../utils/common';

const useStyles = makeStyles({
    root: {
        height: '100%',
        transform: 'scale(1)',
        transition: 'transform 0.5s',
        '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer'
        }
    },
    image: {
        width: '100%',
        aspectRatio: 16 / 9,
        objectFit: 'scale-down',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    },
    name: {
        marginTop: 10,
        display: 'block',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 12,
        fontWeight: '300',
    },
    category: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: '300',
        padding: 10,
        display: 'block',
        textAlign: 'center'
    }

})

export default function KalabPartnerCard({ imageUrl, name, category, url }) {
    const classes = useStyles();
    return (
        <div className={classes.root} onClick={() => { openInNewTab(url); }}>
            <div className="aspectratio ar16-9">
                <img
                    className={classes.image}
                    src={imageUrl}
                    alt='partner-pic'
                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                />
            </div>
            <span className={classes.name}>{name}</span>
            <span className={classes.category}>{category}</span>
        </div>
    )
}
