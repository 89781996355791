import React, { useState, useEffect, useContext } from 'react'
import './AlertPopUpWithReason.scss'
import { LoaderContext } from '../../contexts/LoaderContext';
import { useNotification } from '../../contexts/NotificationContext';
import KalabIconButton from '../kalab/kalabButtons/KalabIconButton';
import KalabCheckBox from '../kalab/kalabCheckBoxes/KalabCheckBox';
import KalabTextField from '../kalab/kalabTextField/KalabTextField';
import KalabMainButton from '../kalab/kalabButtons/KalabMainButton';

export default function AlertPopUpWithReason({
    closeFn,
    title,
    reasons,
    okText,
    okBtnColor,
    okFn
}) {

    const [selectedReason, setSelectedReason] = useState(null)
    const [comment, setComment] = useState('');
    const onClickingCheckBox = (reason) => {
        console.log(reason)
        if (selectedReason && reason.id === selectedReason.id) {
            setSelectedReason(null);
        }
        else {
            setSelectedReason(reason);
        }
    }
    const handleCommentTextChange = (e) => {
        setComment(e.target.value);
    }

    const handleOkClick = () =>{
        okFn(selectedReason.text,comment)
    }

    const isButtonDisabled = () =>{
        if(selectedReason){
            if(selectedReason.isCommentRequired){
                if(comment === ''){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false
            }
        }
        else{
            return true
        }
    }

    useEffect(() => {
       setSelectedReason(null)
       setComment('')
    }, [])

    return (
        <div className='modal-bg' onClick={() => { }}>
            <div className='alert-reason-popup-modal'>
                <div className='alert-reason-popup-modal__content'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <div className='alert-reason-popup-modal__content--top'>
                        <div className='alert-reason-popup-top__close-btn' style={{ justifyContent: 'flex-end' }}>
                            <KalabIconButton
                                iconName={'fas fa-times'}
                                iconSize={20}
                                isFAIcon
                                noDisabled
                                height={10}
                                onClick={() => {
                                    closeFn();
                                }}
                                isLightTheme
                            />
                        </div>
                        <div className='alert-reason-popup-top__title'>
                            <span className='alert-reason-popup-top__title--text'>{title}</span>
                        </div>


                    </div>
                    <div className='alert-reason-popup-modal__content--main'>
                        <div className='alert-reason-popup-reason-section'>
                            <div className='alert-reason-popup-reason-section__reasons'>
                                {reasons.map(reason => (
                                    <div className='alert-reason-popup-reason-section__reasons--each'>
                                        <KalabCheckBox
                                            label={reason.text}
                                            onChange={() => { onClickingCheckBox(reason) }}
                                            checked={selectedReason && selectedReason.id === reason.id ? true : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='alert-reason-popup-reason-section__comment'>
                                <KalabTextField
                                    value={comment}
                                    placeholder='Comment'
                                    isLightTheme
                                    onChange={handleCommentTextChange}
                                    multiline
                                    maxLength={500}
                                    rowsMax={6}
                                />
                            </div>
                        </div>
                        <div className="alert-reason-popup-button">
                            <KalabMainButton
                                text={okText}
                                disabled={isButtonDisabled()}
                                color={okBtnColor}
                                onClick={handleOkClick}
                            />
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}
