import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar, Icon } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import { dateToShowInMessages } from '../../../utils/Date';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 5,
        margin: 10,
        '& .text-box': {
            backgroundColor: Colors.darkBlueGradient,
            paddingRight: 5,
            paddingLeft: 5,
            borderRadius: 5,
            maxWidth : 400,
            '& .text': {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '300',
                fontSize: 15,
                color: Colors.white,
                wordBreak:'break-word'
            },
            '& .message-detail': {
                display:'flex',
                alignItems:'center',
                justifyContent: 'flex-end',
                '& .time': {
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '200',
                    fontSize: 10,
                    color: Colors.white,
                    textAlign: 'right'
                }

            }
        }

    }

})

export default function KalabSentMessageCell({ message, createdDate, isSeen, seenTime }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className='text-box'>
                <div className='text'>
                    {message}
                </div>
                <div className="message-detail">
                    <div className='time'>
                        {dateToShowInMessages(createdDate)}
                    </div>
                    <Icon style={{color :isSeen ? Colors.primary : Colors.white , fontSize : 14, marginLeft: 3}}>
                        done_all
                    </Icon>
                </div>
            </div>
        </div>
    )
}
