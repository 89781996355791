import axios from 'axios';
import Config from '../config/Config';
import { getToken, getRefeshToken, getTokenExpiry, removeUserSession, setNewSession } from "../session/user";

let requestMethod = {
    post: 'POST',
    get: 'GET'
}

let serverUrl = Config.serverUrl
let baseUrl = `${serverUrl}/api/`;

let relativeUrl = {
    refreshToken: 'general/token/refresh',
    apply: 'general/application',
    getApplicationFromToken: 'general/application/from-token',
    getCountries: 'general/geo/countries',
    getLanguages: 'general/geo/languages',
    getCities: 'general/geo/cities-country',
    getAllCities: 'general/geo/all-cities',
    getCountryCodes: 'general/geo/country-codes',
    generateOTP: 'general/otp/code',
    resendOTP: 'general/otp/resend',
    generateWhatsappOTP: 'general/otp/wa/code',
    resendWhatsappOTP: 'general/otp/wa/resend',
    checkOTP: 'general/otp/check',
    getEventTypes: 'general/event-type',
    getGenres: 'general/genre',
    getAmbiences: 'general/ambience',
    getArtistTypes: 'general/artist-type',
    getPerformanceTypes: 'general/performance-type',
    postClientRegistrationInfo: 'client/register',
    postClientLoginInfo: 'client/login',
    postArtistLoginInfo: '',
    uploadingListingImage: 'general/listing-image/upload',
    registerArtist: 'general/artist/register',
    addNewListing: 'artist/listing',
    updateListing: 'artist/listing/update',
    registerClientCompany: 'general/client/register-company',
    registerClientIndividual: 'general/client/register-individual',
    getImage: 'general/image',
    verifyEmail: 'general/client/email-verification',
    loginClient: 'client/login',
    loginArtist: 'artist/login',
    getClientDetails: 'client/detail',
    updateClientDetails: 'client/detail/update',
    updateClientWhatsappDetails: 'client/whatsapp/update',
    changeClientPassword: 'client/password/change',
    getAllSettings: 'general/setting',
    getPartners: 'general/partner',
    getArtistDetails: 'artist/detail',
    updateArtistDetails: 'artist/detail/update',
    updateArtistWhatsappDetails: 'artist/whatsapp/update',
    changeArtistPassword: 'artist/password/change',
    getLatestListings: 'general/listing/latest',
    getImportantClientPartners: 'general/partner/important-client',
    getListingsWithFilter: 'general/listing/filter',
    getListingFromToken: 'general/listing',
    getClientRatingsFromListingId: 'general/listing/rating',
    getListingsOfAnArtist: 'artist/listing',
    changeListingStatus: 'artist/listing/change-status',
    getClientWishlistListings: 'client/wishlist',
    deleteClientWishlistListing: 'client/wishlist/delete',
    addListingToClientWishlist: 'client/wishlist',
    availableListings: 'client/wishlist/available',
    createEventRequests: 'client/event-request',
    getMessagedArtistListForClient: 'client/message/list',
    getMessagedClientListForArtist: 'artist/message/list',
    getMessageForClientFromArtist: 'client/message/artist',
    getMessageForArtistFromClient: 'artist/message/client',
    sendMessageFromClient: 'client/message/send',
    sendMessageFromArtist: 'artist/message/send',
    getEventRequestForArtist: 'artist/event-request/client',
    getEventRequestForClient: 'client/event-request/artist',
    getListingDetailsOfEventRequestForClient: 'client/event-request/listing',
    getListingDetailsOfEventRequestForArtist: 'artist/event-request/listing',
    updateEventRequestForClient: 'client/event-request/update',
    updateEventRequestForArtist: 'artist/event-request/update',
    cancelEventRequestForClient: 'client/event-request/cancel',
    rejectEventRequestForArtist: 'artist/event-request/reject',
    getBookingsForArtist: 'artist/booking/client',
    getBookingsForClient: 'client/booking/artist',
    getRejectionsForArtist: 'artist/rejection/client',
    getRejectionsForClient: 'client/rejection/artist',
    acceptEventRequestForArtist: 'artist/event-request/accept',
    bookEventRequestForClient: 'client/event-request/book',
    bookEventRequestForClientWithoutPayment: 'client/event-request/book/direct',
    getBookingsSummaryOfAYearForClient: 'client/booking/summary',
    getBookingsOfAMonthForClient: 'client/booking/month',
    getBookingsSummaryOfAYearForArtist: 'artist/booking/summary',
    getBookingsOfAMonthForArtist: 'artist/booking/month',
    getTermsOfService: 'general/file/terms-of-service',
    getPrivacyPolicy: 'general/file/privacy-policy',
    getAbout: 'general/file/about',
    forgotClientPassword: 'client/password/reset',
    forgotArtistPassword: 'artist/password/reset',
    getPublishableKey: 'client/payment/publishable-key',
    createPaymentIntent: 'client/payment/create-intent',
    getAccountLink: 'artist/account/link',
    getStripeDashboardLink: 'artist/account/dashboard-link',
    getClientRating: 'client/rating/from-token',
    updateClientRating: 'client/rating',
    getArtistRating: 'artist/rating/from-token',
    updateArtistRating: 'artist/rating',
    getEventTemplates: 'client/event-template',
    addNewEventTemplate: 'client/event-template',
    updateEventTemplate: 'client/event-template/update',
    deleteEventTemplate: 'client/event-template/delete',
    generateBookingLink: 'artist/booking-link',
    getDetailFromLinkToken: 'general/booking-link/token',
    sendEventRequest: 'general/event-request/',
    getPublishableKeyWithoutLogin: 'general/payment/publishable-key',
    createPaymentIntentWithoutLogin: 'general/payment/create-intent',
    bookEventRequestForClientWithoutLogin: 'general/event-request/book',
    bookEventRequestForClientWithoutPaymentAndLogin: 'general/event-request/book/direct',
}
const makeRequest = (method, relativeUrl, data, authorised) => {
    return new Promise(function (resolve, reject) {
        checkAndUpdateToken(authorised)
            .then(function (status) {
                var options = {
                    'method': method,
                    'url': baseUrl + relativeUrl,
                    'data': data
                };
                if (authorised) {
                    options['headers'] = { 'Authorization': 'Bearer ' + getToken() };
                }
                axios(options)
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (err) {
                        var errorResponse = (err.response && err.response.data) ? err.response.data : err;
                        if (errorResponse) {
                            reject(errorResponse);
                        }
                        else {
                            console.log(err);
                            reject({
                                message: 'Some Unknown Error',
                                errorDetail: err
                            });
                        }
                    })
            })
            .catch(function (err) {
                reject(err);
            })
    })
}

const checkAndUpdateToken = (authorised) => {
    return new Promise(function (resolve, reject) {
        if (authorised) {
            var tokenExpiry = getTokenExpiry();
            var refreshToken = getRefeshToken();
            var accessToken = getToken();
            if (tokenExpiry && parseInt(tokenExpiry) < new Date().getTime()) {
                axios({
                    'method': requestMethod.post,
                    'url': baseUrl + relativeUrl.refreshToken,
                    'data': {
                        accessToken,
                        refreshToken
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        var info = response.data.data;
                        setNewSession(info.access_token, info.refresh_token, info.expires_in);
                        resolve(true)
                    })
                    .catch(function (err) {
                        removeUserSession();
                        reject({
                            title: 'Session Expired',
                            message: 'Please log in again',
                            errorDetail: err
                        });
                    })
            }
            else {
                resolve(true)
            }
        }
        else {
            resolve(true)
        }
    })
}

export const getServerUrl = () => {
    return (serverUrl);
}

export const getImageUrl = (key) => {
    return (baseUrl + relativeUrl.getImage + '?key=' + key);
}

export const getDetailsFromIP = (callback, errorcallback) => {
    axios.get('https://ipapi.co/json/')
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//Check Token Request

export const checkTokenRequest = (callback, errorcallback) => {
    checkAndUpdateToken(true)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}



//Post Application

export const applicationRequest = (fullName, artistName, email, instaAccount, fbAccount, musicLinks, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.apply, {
        fullName,
        artistName,
        email,
        instaAccount,
        fbAccount,
        musicLinks
    }, false)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getApplicationFromTokenRequest = (token, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getApplicationFromToken, {
        token
    }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getCountriesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getCountries, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getLanguagesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getLanguages, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getCitiesRequest = (countryAlpha3Code, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getCities, { countryAlpha3Code }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getAllCitiesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getAllCities, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getCountryCodesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getCountryCodes, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const generateOTPRequest = (countryCode, phone, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.generateOTP, { countryCode, phone }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const resendOTPRequest = (countryCode, phone, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.resendOTP, { countryCode, phone }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const generateWhatsappOTPRequest = (countryCode, phone, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.generateWhatsappOTP, { countryCode, phone }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const resendWhatsappOTPRequest = (countryCode, phone, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.resendWhatsappOTP, { countryCode, phone }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const checkOTPRequest = (countryCode, phone, code, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.checkOTP, { countryCode, phone, code }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getEventTypesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getEventTypes, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getGenresRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getGenres, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getPerformanceTypesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getPerformanceTypes, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getAmbiencesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getAmbiences, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getArtistTypesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getArtistTypes, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}


//Artist Registration


export const uploadListingImageRequest = (imageFiles, callback, errorcallback) => {
    var bodyFormData = new FormData();
    imageFiles.forEach(imageFile => {
        bodyFormData.append('listingImages', imageFile);
    })
    makeRequest(requestMethod.post, relativeUrl.uploadingListingImage, bodyFormData, true)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const registerArtistRequest = (
    imageFiles,
    fullName,
    email,
    password,
    artistName,
    countryCode,
    languages,
    city,
    country,
    latitude,
    longitude,
    phone,
    repertoire,
    pastClients,
    genreIds,
    ambienceIds,
    eventTypeIds,
    artistTypes,
    performanceTypeId,
    description,
    setList,
    technicalRider,
    isInsured,
    musicLinks,
    collageIdentifier,
    listingName,
    callback,
    errorcallback) => {
    uploadListingImageRequest(imageFiles,
        (imageResponse) => {
            var listingImages = imageResponse.data;
            console.log(listingImages);
            makeRequest(requestMethod.post, relativeUrl.registerArtist, {
                fullName,
                email,
                password,
                artistName,
                countryCode,
                languages,
                city,
                country,
                latitude,
                longitude,
                phone,
                repertoire,
                pastClients,
                genreIds,
                ambienceIds,
                eventTypeIds,
                artistTypes,
                performanceTypeId,
                description,
                setList,
                technicalRider,
                isInsured,
                musicLinks,
                collageIdentifier,
                listingName,
                listingImages
            }, false)
                .then(function (response) {
                    callback(response)
                })
                .catch(function (err) {
                    errorcallback(err)
                })
        },
        (err) => {
            errorcallback(err)

        })
}

//New Listing

export const addNewListingRequest = (
    imageFiles,
    repertoire,
    pastClients,
    genreIds,
    ambienceIds,
    eventTypeIds,
    artistTypes,
    performanceTypeId,
    description,
    setList,
    technicalRider,
    isInsured,
    musicLinks,
    collageIdentifier,
    listingName,
    callback,
    errorcallback) => {
    uploadListingImageRequest(imageFiles,
        (imageResponse) => {
            var listingImages = imageResponse.data;
            console.log(listingImages);
            makeRequest(requestMethod.post, relativeUrl.addNewListing, {
                repertoire,
                pastClients,
                genreIds,
                ambienceIds,
                eventTypeIds,
                artistTypes,
                performanceTypeId,
                description,
                setList,
                technicalRider,
                isInsured,
                musicLinks,
                collageIdentifier,
                listingName,
                listingImages
            }, true)
                .then(function (response) {
                    callback(response)
                })
                .catch(function (err) {
                    errorcallback(err)
                })
        },
        (err) => {
            errorcallback(err)

        })
}

//Update Listing

export const updateListingRequest = (
    imageFilesToUploadWithPosition,
    imagesToRetain,
    listingId,
    oldImages,
    repertoire,
    pastClients,
    genreIds,
    ambienceIds,
    eventTypeIds,
    artistTypes,
    performanceTypeId,
    description,
    setList,
    technicalRider,
    isInsured,
    musicLinks,
    collageIdentifier,
    listingName,
    callback,
    errorcallback) => {
    if (imageFilesToUploadWithPosition.length > 0) {
        uploadListingImageRequest(imageFilesToUploadWithPosition.map(
            imageFileToUploadWithPosition =>
                (imageFileToUploadWithPosition.file)
        ),
            (imageResponse) => {
                var listingImages = [];
                imagesToRetain.forEach(imageToRetain => {
                    listingImages.push(imageToRetain)
                });
                var uploadedImages = imageResponse.data;
                uploadedImages.forEach((uploadedImage, index) => {
                    listingImages.push({
                        imageUrl: uploadedImage.imageUrl,
                        imageName: uploadedImage.imageName,
                        imageSize: uploadedImage.imageSize,
                        imagePosition: imageFilesToUploadWithPosition[index].position
                    })
                })
                console.log(listingImages);
                makeRequest(requestMethod.post, relativeUrl.updateListing, {
                    listingId,
                    repertoire,
                    pastClients,
                    genreIds,
                    ambienceIds,
                    eventTypeIds,
                    artistTypes,
                    performanceTypeId,
                    description,
                    setList,
                    technicalRider,
                    isInsured,
                    musicLinks,
                    collageIdentifier,
                    listingName,
                    listingImages,
                    oldImages
                }, true)
                    .then(function (response) {
                        callback(response)
                    })
                    .catch(function (err) {
                        errorcallback(err)
                    })
            },
            (err) => {
                errorcallback(err)

            })
    }
    else {
        var listingImages = [];
        imagesToRetain.forEach(imageToRetain => {
            listingImages.push(imageToRetain)
        });
        console.log(listingImages);
        makeRequest(requestMethod.post, relativeUrl.updateListing, {
            listingId,
            repertoire,
            pastClients,
            genreIds,
            ambienceIds,
            eventTypeIds,
            artistTypes,
            performanceTypeId,
            description,
            setList,
            technicalRider,
            isInsured,
            musicLinks,
            collageIdentifier,
            listingName,
            listingImages,
            oldImages
        }, true)
            .then(function (response) {
                callback(response)
            })
            .catch(function (err) {
                errorcallback(err)
            })
    }

}

//Register Client
export const registerClientCompanyRequest = (companyName, contactName, email, password, countryCode, phone, secretKey, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.registerClientCompany, {
        companyName,
        contactName,
        email,
        password,
        countryCode,
        phone,
        secretKey
    }, false)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const registerClientIndividualRequest = (fullName, email, password, countryCode, phone, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.registerClientIndividual, {
        fullName,
        email,
        password,
        countryCode,
        phone
    }, false)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const verifyEmailRequest = (mailVerificationToken, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.verifyEmail, {
        mailVerificationToken
    }, false)
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const clientLoginRequest = (email, password, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.loginClient, {
        email,
        password
    }, false).then(function (response) {
        callback(response)
    })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const artistLoginRequest = (email, password, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.loginArtist, {
        email,
        password
    }, false).then(function (response) {
        callback(response)
    })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getClientDetailsRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getClientDetails, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const updateClientDetailsRequest = (companyFullName, contactName, countryCode, phone, whatsappPhone, whatsappCountryCode, whatsappIsSame, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateClientDetails, {
        companyFullName, contactName, countryCode, phone, whatsappPhone, whatsappCountryCode, whatsappIsSame
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const updateClientWhatsappDetailsRequest = (whatsappPhone, whatsappCountryCode, whatsappIsSame, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateClientWhatsappDetails, {
        whatsappPhone, whatsappCountryCode, whatsappIsSame
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const changeClientPasswordRequest = (oldPassword, newPassword, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.changeClientPassword, {
        oldPassword, newPassword
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getSettingsRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getAllSettings, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getPartnersRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getPartners, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getArtistDetailsRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getArtistDetails, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}


export const updateArtistDetailsRequest = (fullName, artistName, countryCode, phone, city, country, languages, whatsappPhone, whatsappCountryCode, whatsappIsSame, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateArtistDetails, {
        fullName, artistName, countryCode, phone, city, country, languages, whatsappPhone, whatsappCountryCode, whatsappIsSame
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const updateArtistWhatsappDetailsRequest = (whatsappPhone, whatsappCountryCode, whatsappIsSame, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateArtistWhatsappDetails, {
        whatsappPhone, whatsappCountryCode, whatsappIsSame
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const changeArtistPasswordRequest = (oldPassword, newPassword, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.changeArtistPassword, {
        oldPassword, newPassword
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getLatestListingsRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getLatestListings, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getImportantClientPartnersRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getImportantClientPartners, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getListingsWithFilterRequest = (
    cities,
    country,
    artistTypeIds,
    eventTypeIds,
    ambienceIds,
    genreIds,
    startingPrice,
    endingPrice,
    pageNo,
    isInsured,
    listingName,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.getListingsWithFilter,
        {
            cities,
            country,
            artistTypeIds,
            eventTypeIds,
            ambienceIds,
            genreIds,
            startingPrice,
            endingPrice,
            pageNo,
            isInsured,
            listingName
        },
        false
    )
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getListingFromTokenRequest = (listingToken, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getListingFromToken, { listingToken }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getClientRatingsFromListingIdRequest = (listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getClientRatingsFromListingId, { listingId }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getListingOfAnArtistRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getListingsOfAnArtist, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const changeListingStatusRequest = (activeStatus, listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.changeListingStatus, {
        activeStatus, listingId
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getClientWishlistListingsRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getClientWishlistListings, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const deleteClientWishlistListingRequest = (listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.deleteClientWishlistListing, {
        listingId
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const addListingToClientWishlistRequest = (listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.addListingToClientWishlist, {
        listingId
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getAvailableListingsRequest = (eventTypeId, artistTypeId, genreIds, date, timeOfEvents, endDate, isResidency, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.availableListings, { eventTypeId, artistTypeId, genreIds, date, timeOfEvents, endDate, isResidency }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const createEventRequestsRequest = (
    listingIds,
    eventTypeId,
    genreIds,
    artistTypeId,
    songRequests,
    comment,
    date,
    startTime,
    endTime,
    eventName,
    location,
    hours,
    parking,
    accomodation,
    foodBeverage,
    transportation,
    equipment,
    dressCode,
    equipmentDetails,
    saveTemplate,
    templateName,
    isResidency,
    endDate,
    timings,
    currency,
    artistPrice,
    callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.createEventRequests, {
        listingIds,
        eventTypeId,
        genreIds,
        artistTypeId,
        songRequests,
        comment,
        date,
        startTime,
        endTime,
        eventName,
        location,
        hours,
        parking,
        accomodation,
        foodBeverage,
        transportation,
        equipment,
        dressCode,
        equipmentDetails,
        saveTemplate,
        templateName,
        isResidency,
        endDate,
        timings,
        currency,
        artistPrice,
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//Messages

export const getMessagedArtistListForClientRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getMessagedArtistListForClient, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getMessagedClientListForArtistRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getMessagedClientListForArtist, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getMessagesForClientFromArtistRequest = (artistId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getMessageForClientFromArtist, { artistId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getMessagesArtistFromClientRequest = (clientId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getMessageForArtistFromClient, { clientId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const sendMessagesFromArtistRequest = (clientId, message, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.sendMessageFromArtist, { clientId, message }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const sendMessagesFromClientRequest = (artistId, message, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.sendMessageFromClient, { artistId, message }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getEventRequestsForClientRequest = (artistId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getEventRequestForClient, { artistId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getEventRequestsForArtistRequest = (clientId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getEventRequestForArtist, { clientId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}


export const getListingDetailsOfEventRequestForClientRequest = (listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getListingDetailsOfEventRequestForClient, { listingId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getListingDetailsOfEventRequestForArtistRequest = (listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getListingDetailsOfEventRequestForArtist, { listingId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const updateEventRequestForClientRequest = (
    eventRequestId,
    artistTypeId,
    eventTypeId,
    genreIds,
    date,
    eventName,
    startTime,
    endTime,
    location,
    hours,
    transportation,
    accomodation,
    foodBeverage,
    equipment,
    parking,
    songRequests,
    comment,
    dressCode,
    artistPrice,
    currency,
    equipmentDetails,
    isResidency,
    endDate,
    timings,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.updateEventRequestForClient,
        {
            eventRequestId,
            artistTypeId,
            eventTypeId,
            genreIds,
            date,
            eventName,
            startTime,
            endTime,
            location,
            hours,
            transportation,
            accomodation,
            foodBeverage,
            equipment,
            parking,
            songRequests,
            comment,
            dressCode,
            artistPrice,
            currency,
            equipmentDetails,
            isResidency,
            endDate,
            timings,
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const updateEventRequestForArtistRequest = (
    eventRequestId,
    artistTypeId,
    eventTypeId,
    genreIds,
    date,
    eventName,
    startTime,
    endTime,
    location,
    hours,
    transportation,
    accomodation,
    foodBeverage,
    equipment,
    parking,
    songRequests,
    comment,
    dressCode,
    artistPrice,
    currency,
    equipmentDetails,
    isResidency,
    endDate,
    timings,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.updateEventRequestForArtist,
        {
            eventRequestId,
            artistTypeId,
            eventTypeId,
            genreIds,
            date,
            eventName,
            startTime,
            endTime,
            location,
            hours,
            transportation,
            accomodation,
            foodBeverage,
            equipment,
            parking,
            songRequests,
            comment,
            dressCode,
            artistPrice,
            currency,
            equipmentDetails,
            isResidency,
            endDate,
            timings,
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const cancelEventRequestForClientRequest = (
    eventRequestId,
    rejectionReason,
    rejectionComment,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.cancelEventRequestForClient,
        {
            eventRequestId,
            rejectionReason,
            rejectionComment
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const rejectEventRequestForArtistRequest = (
    eventRequestId,
    rejectionReason,
    rejectionComment,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.rejectEventRequestForArtist,
        {
            eventRequestId,
            rejectionReason,
            rejectionComment
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getBookingsForClientRequest = (artistId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsForClient, { artistId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getBookingsForArtistRequest = (clientId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsForArtist, { clientId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getRejectionsForClientRequest = (artistId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getRejectionsForClient, { artistId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getRejectionsForArtistRequest = (clientId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getRejectionsForArtist, { clientId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const acceptEventRequestForArtistRequest = (
    eventRequestId,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.acceptEventRequestForArtist,
        {
            eventRequestId,
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const bookEventRequestForClientRequest = (
    eventRequestId,
    paymentIntentId,
    paymentMethodId,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.bookEventRequestForClient,
        {
            eventRequestId,
            paymentIntentId,
            paymentMethodId,
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const bookEventRequestForClientWithoutPaymentRequest = (
    eventRequestId,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.bookEventRequestForClientWithoutPayment,
        {
            eventRequestId
        },
        true
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getBookingsSummaryOfAYearForClientRequest = (year, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsSummaryOfAYearForClient, { year }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getBookingsOfAMonthForClientRequest = (year, month, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsOfAMonthForClient, { year, month }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getBookingsSummaryOfAYearForArtistRequest = (year, listingId, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsSummaryOfAYearForArtist, { year, listingId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getBookingsOfAMonthForArtistRequest = (year, listingId, month, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getBookingsOfAMonthForArtist, { year, month, listingId }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//Terms of Service

export const getTermsOfServiceRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getTermsOfService, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//Privacy Policy

export const getPrivacyPolicyRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getPrivacyPolicy, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//About

export const getAboutRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getAbout, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

//Forgot Password

export const forgotClientPasswordRequest = (email, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.forgotClientPassword, {
        email
    }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const forgotArtistPasswordRequest = (email, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.forgotArtistPassword, {
        email
    }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getPublishableKeyRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getPublishableKey, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const createPaymentIntentRequest = (eventRequestId, amount, currency, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.createPaymentIntent, { eventRequestId, amount, currency }, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getAccountLinkRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getAccountLink, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            if (err.code === "StripeInvalidRequestError") {
                err['title'] = "Please contact Admin"
            }
            errorcallback(err)
        })
}
export const getStripeDashboardLinkRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getStripeDashboardLink, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getClientRatingFromTokenRequest = (token, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getClientRating, { token }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getArtistRatingFromTokenRequest = (token, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getArtistRating, { token }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const updateClientRatingRequest = (clientRatingId, token, professionalism, communication, friendliness, serviceQuality, comment, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateClientRating, { clientRatingId, token, professionalism, communication, friendliness, serviceQuality, comment }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const updateArtistRatingRequest = (artistRatingId, token, professionalism, communication, comment, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateArtistRating, { artistRatingId, token, professionalism, communication, comment }, false)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getEventTemplatesRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getEventTemplates, null, true)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const addNewEventTemplateRequest = (
    templateName,
    artistTypeId,
    eventTypeId,
    genreIds,
    eventName,
    location,
    hours,
    transportation,
    accomodation,
    foodBeverage,
    equipment,
    equipmentDetails,
    parking,
    songRequests,
    comment,
    dressCode,
    callback,
    errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.addNewEventTemplate, {
        templateName,
        artistTypeId,
        eventTypeId,
        genreIds,
        eventName,
        location,
        hours,
        transportation,
        accomodation,
        foodBeverage,
        equipment,
        equipmentDetails,
        parking,
        songRequests,
        comment,
        dressCode
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}


export const updateEventTemplateRequest = (
    templateId,
    templateName,
    artistTypeId,
    eventTypeId,
    genreIds,
    eventName,
    location,
    hours,
    transportation,
    accomodation,
    foodBeverage,
    equipment,
    equipmentDetails,
    parking,
    songRequests,
    comment,
    dressCode,
    callback,
    errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.updateEventTemplate, {
        templateId,
        templateName,
        artistTypeId,
        eventTypeId,
        genreIds,
        eventName,
        location,
        hours,
        transportation,
        accomodation,
        foodBeverage,
        equipment,
        equipmentDetails,
        parking,
        songRequests,
        comment,
        dressCode
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const deleteEventTemplateRequest = (
    templateId,
    callback,
    errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.deleteEventTemplate, {
        templateId
    }, true)
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const generateBookingLinkRequest = (
    listingId,
    eventTypeId,
    genreIds,
    artistTypeId,
    songRequests,
    equipmentDetails,
    comment,
    date,
    startTime,
    endTime,
    eventName,
    location,
    hours,
    parking,
    accomodation,
    foodBeverage,
    transportation,
    equipment,
    dressCode,
    artistPrice,
    currency,
    companyFullName,
    email,
    countryCode,
    phone,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.generateBookingLink,
        {
            listingId,
            eventTypeId,
            genreIds,
            artistTypeId,
            songRequests,
            equipmentDetails,
            comment,
            date,
            startTime,
            endTime,
            eventName,
            location,
            hours,
            parking,
            accomodation,
            foodBeverage,
            transportation,
            equipment,
            dressCode,
            artistPrice,
            currency,
            companyFullName,
            email,
            countryCode,
            phone,
        },
        true
    )
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const getDetailFromLinkTokenRequest = (token, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.getDetailFromLinkToken, { token }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const sendEventRequestRequest = (
    listingId,
    eventTypeId,
    genreIds,
    artistTypeId,
    songRequests,
    equipmentDetails,
    comment,
    date,
    startTime,
    endTime,
    eventName,
    location,
    hours,
    parking,
    accomodation,
    foodBeverage,
    transportation,
    equipment,
    dressCode,
    artistPrice,
    currency,
    companyFullName,
    email,
    countryCode,
    phone,
    token,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.sendEventRequest,
        {
            listingId,
            eventTypeId,
            genreIds,
            artistTypeId,
            songRequests,
            equipmentDetails,
            comment,
            date,
            startTime,
            endTime,
            eventName,
            location,
            hours,
            parking,
            accomodation,
            foodBeverage,
            transportation,
            equipment,
            dressCode,
            artistPrice,
            currency,
            companyFullName,
            email,
            countryCode,
            phone,
            token
        },
        false
    )
        .then(function (response) {
            callback(response)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const getPublishableKeyWithoutLoginRequest = (callback, errorcallback) => {
    makeRequest(requestMethod.get, relativeUrl.getPublishableKeyWithoutLogin, null, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}


export const createPaymentIntentWithoutLogInRequest = (eventRequestId, amount, currency, callback, errorcallback) => {
    makeRequest(requestMethod.post, relativeUrl.createPaymentIntentWithoutLogin, { eventRequestId, amount, currency }, false)
        .then(function (response) {
            callback(response.data)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}
export const bookEventRequestForClientWithoutLogInRequest = (
    eventRequestId,
    paymentIntentId,
    paymentMethodId,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.bookEventRequestForClientWithoutLogin,
        {
            eventRequestId,
            paymentIntentId,
            paymentMethodId,
        },
        false
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}

export const bookEventRequestForClientWithoutPaymentAndLogInRequest = (
    eventRequestId,
    callback,
    errorcallback
) => {
    makeRequest(
        requestMethod.post,
        relativeUrl.bookEventRequestForClientWithoutPaymentAndLogin,
        {
            eventRequestId
        },
        false
    )
        .then(function (response) {
            callback(response.message)
        })
        .catch(function (err) {
            errorcallback(err)
        })
}